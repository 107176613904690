import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import corn from '../img/ninepages/earnestroi-Web-3.0.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faShieldHalved, faGears, faHeadphonesSimple, faFileExport, faUsers, faFileSignature, faBoxesStacked, faInfinity, faWallet, faRotate, faGamepad, faDesktop, faFileVideo, faSitemap, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Timeline2compo from './Timeline2compo';
import Footern from './Footern';
import { Helmet } from 'react-helmet';

const Webthree = () => {

  useEffect( () => {

    $(document).ready(function(){
      $(this).scrollTop(0);
    });

    $(document).ready(function(){
      
      $('.slick-prev').hide();
      $('.slick-next').hide();

    });

    Aos.init({

      disable: function() {
        var maxWidth = 1024;
        return window.innerWidth < maxWidth;
      }

    });

  } );

  useEffect( () => {

    Aos.init({ duration: 3000 });

  } );

  return (
    <>
        <Helmet>
          <title>Earnestroi Technology | Web Three | Web Design | Decentralized</title>
          <meta name="title" content="Earnestroi Technology | Web Three | Web Design | Decentralized" />
          <meta name="description" content="We're here to make it easier for you to join and use the decentralized web. Join us to get immediate access to our white paper and more." />
          <meta name="keywords" content='web design, web development, web3, software development, website development, fullstack dev' />
          <meta name="robots" content="index,follow" />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="language" content="English" />
        </Helmet>
        <section className=' lg:pt-4 xl:pt-0 px-2 md:px-0'>
            <div className='container mx-auto'>
                <div className='flex flex-col-reverse lg:flex-row justify-center items-center xl:pt-20'>
                    <div className='flex flex-col w-full lg:w-1/2'>
                        <h1 className='text-third text-5xl lg:text-6xl mb-4 mt-4 lg:mt-0 font_primary capitalize'>Web 3.0<br /> Development Services</h1>
                        <br />
                        <h2 className='text-xl lg:text-3xl mb-4 mt-4 lg:mt-0 font_primary capitalize' >End-to-end solutions to make it easier for you to join and use the decentralized web</h2>
                    </div>
                    <div className=' w-full lg:w-1/2'>
                    <img src={corn} alt='Web 3.0 development ' className='mx-auto w-1/2 sm:w-1/4 lg:w-1/2' />
                    </div>
                </div>
            </div>
        </section>
        <section className=' px-2 md:px-0'>
            <div className='container mx-auto'>
                <div className='flex justify-center py-4 lg:py-10'>
                    <Link to='/'><h2 className='mx-2 text-third font_primary'><FontAwesomeIcon icon={ faHome } className='mx-2 text-third' />Digital Marketing Agency</h2></Link><h2 className='mx-2 text-third'>/</h2><h2 className='mx-2 text-third font_primary'>NFT Development</h2>
                </div>
            </div>  
        </section>
        <section className=' py-6 lg:py-12 px-2 md:px-0'>
            <div className='container mx-auto'>
                <div className='w-full lg:w-3/4 mx-auto'>
                  <h2 className='mb-2 mt-4 lg:mt-0 font_primary capitalize text-third text-center text-xl lg:text-3xl' data-aos='fade-up'>Lead the way with our Web3 Development Services</h2>
                  <p className='text-center text-base sm:text-lg mb-2 lg:mb-2 font_secondary' data-aos='fade-up'>In the past three decades, the web has traveled a far distance. The internet has developed to meet user needs, going from a read-only web (web 1.0) to a read-write web (web 2.0) to a read-write-own web (web 3.0). Profit from the chance to move from Web 2.0 to Web 3.0 as an entrepreneur to offer cutting-edge solutions to your clients. Our Web3 development business provides complete solutions to hasten and ease your move to Web3. Utilize our knowledge to guide you through the web 3.0 development process.</p>
                </div>
            </div>
        </section>
        <section className='py-4 lg:py-12 bg-main px-2 md:px-0'>
            <div className='container mx-auto pt-4 lg:pt-8'>
                <div className='w-full lg:w-[60%] mx-auto'>
                    <h2 className='text-xl lg:text-3xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third text-center'>The Need and Benefits of Web3 Development</h2>
                    <p className='text-center text-base sm:text-lg mb-4 lg:mb-8 font_secondary text-[white]'>Web 3.0 seeks to improve web productivity. Businesses who decide to invest in Web 3 development might accomplish the following goals in addition to staying ahead of the curve:</p>
                </div>
                <div className='container mx-auto pb-4 md:pb-6'>
                    <div className='grid sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
                        <div className='p-4 h-full rounded bg-[#77c1dd14] border-t-8 border-third '>
                            <div className='flex flex-col items-center justify-center'>
                                <FontAwesomeIcon icon={ faUsers } className='text-5xl text-third mx-auto my-4 text-center' />
                                <h2 className='mb-0 font_primary text-center text-third text-xl lg:text-2xl'>Decentralization</h2>
                            </div>
                            <p className='text-base font_secondary my-4 text-center text-[white]'>Decentralization is one of Web 3.0's key components. Because it is a distributed network, there is no need for a centralized organization to manage the internet. Users are in charge of their content and data.</p>
                        </div>
                        <div className='p-4 h-full rounded  bg-[#77c1dd14] border-t-8 border-third '>
                            <div className='flex flex-col items-center justify-center'>
                                <FontAwesomeIcon icon={ faBoxesStacked } className='text-5xl text-third mx-auto my-4 text-center' />
                                <h2 className='mb-0 font_primary text-center text-third text-xl lg:text-2xl'>Blockchain Technology</h2>
                            </div>
                            <p className='text-base font_secondary my-4 text-center text-[white]'>Transparent, secure, and tamper-proof transactions are made possible by blockchain. It can change how consumers conduct online transactions and make them safer.</p>
                        </div>
                        <div className='p-4 h-full rounded  bg-[#77c1dd14] border-t-8 border-third '>
                            <div className='flex flex-col items-center justify-center'>
                                <FontAwesomeIcon icon={ faShieldHalved } className='text-5xl text-third mx-auto my-4 text-center' />
                                <h2 className='mb-0 font_primary text-center text-third text-xl lg:text-2xl'>Augmented Security and Privacy</h2>
                            </div>
                            <p className='text-base font_secondary my-4 text-center text-[white]'>Decentralization makes greater security possible because there is no single point of vulnerability. Users also have control over who and how they can view their data.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className=' px-2 md:px-0'>
          <div className='container mx-auto py-4 lg:py-8'>
            <div className='w-full lg:w-3/4 mx-auto'>
              <h2 className='text-lg lg:text-2xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third text-center' data-aos='fade-up'>Our Web 3.0 Development Services</h2>
              <p className=' text-center text-base sm:text-lg mb-2 lg:mb-2 font_secondary' data-aos='fade-up'>The list of services our Web 3.0 developers can provide for you includes those listed below.</p>
            </div>
          </div>
          <div className='container mx-auto pb-4 md:pb-6'>
            <div className='grid sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
              <div className='p-4 h-full bg-[#fff] rounded bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faInfinity } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center'>Web 3 Metaverse Development</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center'>Our Web 3 metaverse development services are geared towards creating immersive platforms for diverse industries - such as Gaming, Entertainment, Real Estate, Fashion, Social Media, and more.</p>
              </div>
              <div className='p-4 h-full bg-[#fff] rounded bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faWallet } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center'>Web 3 Wallet Development</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center'>We offer specialized web3 wallet creation services to give you a safe web3 crypto wallet in line with your company demands, whether you need a multichain web3 wallet or a blockchain-specific wallet.</p>
              </div>
              <div className='p-4 h-full bg-[#fff] rounded bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faRotate } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center'>Web 3 Exchange Development</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center'>Our business-oriented web 3.0 decentralized exchange development services empower businesses to disrupt the crypto market with a robust exchange fortified with world-class performance and security.</p>
              </div>
            </div>
          </div>
        </section>
        <section className=' px-2 md:px-0'>
          <div className='container mx-auto pb-4 md:pb-6'>
            <div className='grid sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
              <div className='p-4 h-full bg-[#fff] rounded bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faGamepad } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center'>Web 3 Metaverse Game Development</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center'>We provide complete end-to-end web 3 metaverse game creation services to create NFTs and immersive gaming settings for an engaging experience. Utilize our knowledge to make your web 3 metaverse game.</p>
              </div>
              <div className='p-4 h-full bg-[#fff] rounded bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faFileSignature } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center'>Web3 Smart Contract Development</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center'>To strengthen decentralized apps, our web3 smart contract developer specializes in building highly secure smart contracts. We profit from our web3 smart contract development expertise to enter the DeFi market.</p>
              </div>
              <div className='p-4 h-full bg-[#fff] rounded bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faDesktop } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center'>Web 3.0 Browsers</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center'>Our team of Web 3.0 developers uses blockchain technology to its full potential to create highly secure applications and browsers that run on Web 3.0 and store user data anonymously.</p>
              </div>
            </div>
          </div>
        </section>
        <section className=' px-2 md:px-0'>
          <div className='container mx-auto pb-4 md:pb-6'>
            <div className='grid sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
              <div className='p-4 h-full bg-[#fff] rounded bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faFileVideo } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center'>Web Streaming Services</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center'>We build web streaming services based on blockchain and smart contracts that allow content producers to collaborate openly and receive compensation.</p>
              </div>
              <div className='p-4 h-full bg-[#fff] rounded bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faSitemap } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center'>Decentralized Autonomous Organization (DAO)</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center'>Our Web 3.0 developers create and implement DAO systems powered by smart contracts for corporate use cases.</p>
              </div>
              <div className='p-4 h-full bg-[#fff] rounded bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faPeopleGroup } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center'>Decentralized Finance (DeFi)</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center'>We produce a variety of DeFi platforms, such as borrowing/lending, staking, yield farming, and more, as a top Web 3.0 development business.</p>
              </div>
            </div>
          </div>
        </section>
        <section className='py-4 lg:py-10 bg-main px-2 md:px-0'>
            <div className='container mx-auto py-4 lg:py-12'>
                <div className=''>
                    <h2 className='mx-auto w-full lg:w-[70%] text-xl lg:text-3xl mb-2 mt-4 lg:mt-0 font_primary capitalize text-third text-center' data-aos='fade-up'>The way we navigate our Web3 Development Journey</h2>
                    <p className='mx-auto w-full lg:w-[60%] text-center text-base sm:text-lg mb-2 lg:mb-2 font_secondary text-[white]' data-aos='fade-up'>We follow a coherent roadmap to seamlessly drive your Web3 development process</p>
                </div>
            </div>
            <Timeline2compo />
        </section>
        <section className='py-4 lg:py-12 px-2 md:px-0'>
            <div className='container mx-auto pt-4 lg:pt-8'>
            <div className='w-full lg:w-[60%] mx-auto'>
                <h2 className='text-xl lg:text-3xl mt-4 lg:mt-0 font_primary capitalize text-third text-center mb-4 lg:mb-8' data-aos='fade-up'>What distinguishes us as the Top Web 3.0 Development Company</h2>
            </div>
            <div className='container mx-auto pb-4 md:pb-6'>
                <div className='grid sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
                <div className='p-4 h-full rounded bg-[#77c1dd14] border-t-8 border-third '>
                    <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faShieldHalved } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className='mb-0 text-base font_primary text-center'>Technical Process</h2>
                    </div>
                    <p className='text-base font_secondary my-4 text-center'>We exclusively work in Blockchain technologies. We just concentrate on one thing, and we excel at it.</p>
                </div>
                <div className='p-4 h-full rounded  bg-[#77c1dd14] border-t-8 border-third '>
                    <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faUsers } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className='mb-0 text-base font_primary text-center'>Expert Team</h2>
                    </div>
                    <p className='text-base font_secondary my-4 text-center'>With the support of our 500 professionals, you can improve your offering, get the finest IT solution, and even create communities and campaigns.</p>
                </div>
                <div className='p-4 h-full rounded  bg-[#77c1dd14] border-t-8 border-third '>
                    <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faGears } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className='mb-0 text-base font_primary text-center'>Rapid Development</h2>
                    </div>
                    <p className='text-base font_secondary my-4 text-center'>We offer goods that are especially suited to your intended consumer base. A well-organized roadmap ensures rapid and efficient development and implementation.</p>
                </div>
                </div>
            </div>
            <div className='container mx-auto pt-4 md:pt-6'>
                <div className='flex flex-col lg:flex-row lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
                <div className='p-4 h-full lg:w-[30%] xl:w-1/4 2xl:w-[30%] w-full rounded  bg-[#77c1dd14] border-t-8 border-third'>
                    <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faHeadphonesSimple } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className='mb-0 text-base font_primary text-center'>Complete Support</h2>
                    </div>
                    <p className='text-base font_secondary my-4 text-center'>After the product launch, we continue to work and provide post-delivery support.</p>
                </div>
                <div className='p-4 h-full lg:w-[30%] xl:w-1/4 2xl:w-[30%] w-full rounded  bg-[#77c1dd14] border-t-8 border-third'>
                    <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faFileExport } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className='mb-0 text-base font_primary text-center'>Meaningful Outcomes</h2>
                    </div>
                    <p className='text-base font_secondary my-4 text-center'>We make a real difference. We make sure your investments have value for you.</p>
                </div>
                </div>
            </div>
            </div>
        </section>
        <section className=' px-2 md:px-0'>
            <Footern />
        </section>
    </>
  )
}

export default Webthree;