import React, { useEffect } from 'react';
import corn from '../img/ninepages/earnestroi-Semi-Fungible-Token.png';
import img_3 from '../img/ninepages/earnestroi-Why-Semi-Fungible-Token-Development-Services.png';
import img_3s from '../img/ninepages/earnestroi-Semi-Fungible-Token-Development-Benefits.jpg';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUsers, faGears, faHeadphonesSimple, faFileExport, faCoins, faCode, faNoteSticky, faCircle, faUser, faChartLine, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { faShieldHalved } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Footern from './Footern';
import { Helmet } from 'react-helmet';

const SemiNFT = () => {

  useEffect( () => {

    $(document).ready(function(){
      $(this).scrollTop(0);
    });

    $(document).ready(function(){
      
      $('.slick-prev').hide();
      $('.slick-next').hide();

    });

    Aos.init({

      disable: function() {
        var maxWidth = 1024;
        return window.innerWidth < maxWidth;
      }

    });

} );

  useEffect( () => {

    Aos.init({ duration: 3000 });

} );
  
  return (
    <>
        <Helmet>
          <title>Earnestroi Technology | Semi Fungible token development | crypto currency | blockchain</title>
          <meta name="title" content="Earnestroi Technology | Semi Fungible token development | crypto currency | blockchain" />
          <meta name="description" content='SFTs, also known as "smart tokens", are a new type of token that combine the properties of NFTs and FTs. SFTs are developing with fast pace in the cryptocurrency and blockchain space.' />
          <meta name="keywords" content='digital marketing agency, online marketing, marketing agency, project management, development services' />
          <meta name="robots" content="index,follow" />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="language" content="English" />
        </Helmet>
        <section className=' lg:pt-4 xl:pt-0 bg-main px-1 xl:px-0'>
          <div className='container mx-auto'>
            <div className='flex flex-col-reverse lg:flex-row justify-center items-center xl:pt-20'>
              <div className='flex flex-col w-full lg:w-1/2'>
                  <h1 className='text-[white] text-5xl lg:text-6xl mb-4 mt-4 lg:mt-0 font_primary capitalize' data-aos="fade-left">Semi Fungible Token Development Services</h1>
              </div>
              <div className=' w-full lg:w-1/2'>
                <img src={corn} alt='Semi fungible token ' className='mx-auto w-1/2 sm:w-1/4 lg:w-3/4' />
              </div>
            </div>
          </div>
        </section>
        <section className='bg-main'>
          <div className='container mx-auto'>
            <div className='flex justify-center py-4 lg:py-16'>
              <Link to='/'><h2 className='mx-2 text-third font_primary'><FontAwesomeIcon icon={ faHome } className='mx-2 text-third' />Digital Marketing Agency</h2></Link><h2 className='mx-2 text-third'>/</h2><h2 className='mx-2 text-third font_primary'>NFT Development</h2>
            </div>
          </div>
        </section>
        <section className='py-4 md:py-10 px-1 xl:px-0'>
          <div className='container mx-auto'>
            <div className='grid-cols-2 flex flex-col lg:flex-row justify-between items-center'>
              <div className=''>
                <img src={img_3} alt='Semi fumgible service ' className='my-8 lg:my-0' />
              </div>
              <div className='lg:w-[40%] lg:px-2 p-2 sm:p-0'>
                <h2 className='text-2xl lg:text-3xl font_primary text-third'>Why Semi Fungible Token Development Services?</h2>
                <br />
                <p className='text-base font_secondary my-4' data-aos="fade-right">NFTs altered the representation of asset ownership. We can define an investment's right in a secure, transparent, and unchangeable manner thanks to NFTs. Transferring an entire collection of NFTs could be faster and more efficient. SFT development is becoming increasingly popular.</p>
                <p className='text-base font_secondary my-4' data-aos="fade-right">Batch token transfers are made more usable by creating semi-fungible tokens because they support fungible and non-fungible (NFT) tokens. Different NFTs are made using a single contract, which lowers gas costs.</p>
                <p className='text-base font_secondary my-4' data-aos="fade-right">Individuals like artists, gamers, and brands can take advantage of SFTs' capacity to combine several tokens into one contract by using Earnestroi's Launch your SFT token. This reduces expenses while also making token handling more straightforward.</p>  
              </div>
            </div>
          </div>
        </section>
        <section className='bg-main py-4 lg:py-12'>
            <div className='container mx-auto pt-4 lg:pt-8'>
                <div className='w-full lg:w-[60%] mx-auto'>
                <h2 className='text-lg lg:text-2xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third text-center'>Earnestroi's Semi-Fungible Token Development Services</h2>
                <p className='text-center text-base sm:text-lg mb-4 lg:mb-8 font_secondary text-[white]'>Using the ERC-1155 multi-token standard, we develop SFT. As a result, each token ID can represent a brand-new token type that can be quickly set to have various distinct features.</p>
                </div>
                <div className='container mx-auto pb-4 md:pb-6'>
                <div className='grid sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
                    <div className='p-4 h-full bg-[#77c1dd14] rounded bg-clip-padding backdrop-filter backdrop-blur-md '>
                    <div className='flex flex-col items-center justify-center'>
                        <FontAwesomeIcon icon={ faCoins } className='text-5xl text-third mx-auto my-4 text-center' />
                    </div>
                    <p className='text-[white] text-base font_secondary my-4 text-center'>Token ID, token count, and metadata are features each token under this token will have.</p>
                    </div>
                    <div className='p-4 h-full bg-[#77c1dd14] rounded bg-clip-padding backdrop-filter backdrop-blur-md '>
                    <div className='flex flex-col items-center justify-center'>
                        <FontAwesomeIcon icon={ faNoteSticky } className='text-5xl text-third mx-auto my-4 text-center' />
                    </div>
                    <p className='text-[white] text-base font_secondary my-4 text-center'>SFT token development using an expert approach and complete support.</p>
                    </div>
                    <div className='p-4 h-full bg-[#77c1dd14] rounded bg-clip-padding backdrop-filter backdrop-blur-md '>
                    <div className='flex flex-col items-center justify-center'>
                        <FontAwesomeIcon icon={ faCode } className='text-5xl text-third mx-auto my-4 text-center' />
                    </div>
                    <p className='text-[white] text-base font_secondary my-4 text-center'>Harness our expertise and services to build your semi-fungible token.</p>
                    </div>
                </div>
                </div>
            </div>
        </section>
        <section className=' py-4 lg:py-12 px-1 xl:px-0'>
            <div className='container mx-auto'>
                <h2 className='text-lg lg:text-2xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third text-center'>Semi-Fungible Token Development Benefits</h2>
                <div className='flex flex-col-reverse lg:flex-row justify-center items-center'>
                    <div className='p-2 w-full lg:w-1/2'>
                        <p className='text-third font_primary mb-0'><FontAwesomeIcon icon={ faCircle } className='text-[10px] mx-1' />Benefits for Token Holders</p>
                        <div className='px-1 my-1 lg:my-4'>
                            <div className='grid grid-cols-2 gap-2 lg:gap-4'>
                                <div className='p-4 bg-[#77c1dd14] rounded-xl'>
                                    <FontAwesomeIcon icon={ faUser } className='text-third text-2xl lg:text-3xl mx-auto block' />
                                    <h2 className='text-sm lg:text-base mt-4 lg:mt-0 font_secondary capitalize font_secondary py-2 lg:py-4'>Managing fungible and non-fungible tokens is simple.</h2>
                                </div>
                                <div className='p-4 bg-[#77c1dd14] rounded-xl'>
                                    <FontAwesomeIcon icon={ faChartLine } className='text-third text-2xl lg:text-3xl mx-auto block' />
                                    <h2 className='text-sm lg:text-base mt-4 lg:mt-0 font_secondary capitalize font_secondary py-2 lg:py-4'>The owner can unbundle the tokens and trade each one independently, even though they are all part of a single, smart contract.</h2>
                                </div>
                            </div>
                        </div>
                        <p className='text-third font_primary mb-0'><FontAwesomeIcon icon={ faCircle } className='text-[10px] mx-1' />Benefits for Token Creators</p>
                        <div className='px-1 my-1 lg:my-4'>
                            <div className='grid grid-cols-2 gap-2 lg:gap-4'>
                                <div className='p-4 bg-[#77c1dd14] rounded-xl'>
                                    <FontAwesomeIcon icon={ faScrewdriverWrench } className='text-third text-2xl lg:text-3xl mx-auto block' />
                                    <h2 className='text-sm lg:text-base mt-4 lg:mt-0 font_secondary capitalize font_secondary py-2 lg:py-4'>Open, simple, and inexpensive minting</h2>
                                </div>
                                <div className='p-4 bg-[#77c1dd14] rounded-xl'>
                                    <FontAwesomeIcon icon={ faGears } className='text-third text-2xl lg:text-3xl mx-auto block' />
                                    <h2 className='text-sm lg:text-base mt-4 lg:mt-0 font_secondary capitalize font_secondary py-2 lg:py-4'>It takes just one smart contract to manage both fungible and non-fungible tokens.</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='p-2 w-full lg:w-1/2'>
                        <img src={ img_3s } alt='Semi fungible benefits ' className='mx-auto block' /> 
                    </div>
                </div>
            </div>
        </section>
        <section className='bg-main py-4 lg:py-12 px-1 xl:px-0'>
          <div className='container mx-auto pt-4 lg:pt-8'>
            <div className='w-full lg:w-[60%] mx-auto'>
              <h2 className='text-lg lg:text-2xl mt-4 lg:mt-0 font_primary capitalize text-third text-center mb-4 lg:mb-8'>Reasons to choose us to create your semi-fungible token</h2>
            </div>
            <div className='container mx-auto pb-4 md:pb-6'>
              <div className='grid sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
                <div className='p-4 h-full bg-[#77c1dd14] rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md'>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faShieldHalved } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className='mb-0 text-base font_primary text-third text-center'>Technical Process</h2>
                  </div>
                  <p className='text-[white] text-base font_secondary my-4 text-center'>We exclusively work in Blockchain technologies. We just concentrate on one thing, and we excel at it.</p>
                </div>
                <div className='p-4 h-full bg-[#77c1dd14] rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md'>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faUsers } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className='mb-0 text-base font_primary text-third text-center'>Expert Team</h2>
                  </div>
                  <p className='text-[white] text-base font_secondary my-4 text-center'>With the support of our 500 professionals, you can improve your offering, get the finest IT solution, and even create communities and campaigns.</p>
                </div>
                <div className='p-4 h-full bg-[#77c1dd14] rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md'>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faGears } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className='mb-0 text-base font_primary text-third text-center'>Rapid Development</h2>
                  </div>
                  <p className='text-[white] text-base font_secondary my-4 text-center'>We offer goods that are especially suited to your intended consumer base. A well-organized roadmap ensures rapid and efficient development and implementation.</p>
                </div>
              </div>
            </div>
            <div className='container mx-auto pt-4 md:pt-6'>
              <div className='flex flex-col lg:flex-row lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
                <div className='p-4 h-full lg:w-[30%] xl:w-1/4 2xl:w-[30%] w-full bg-[#77c1dd14] rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md'>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faHeadphonesSimple } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className='mb-0 text-third text-base font_primary text-center'>Complete Support</h2>
                  </div>
                  <p className='text-[white] text-base font_secondary my-4 text-center'>After the product launch, we continue to work and provide post-delivery support.</p>
                </div>
                <div className='p-4 h-full lg:w-[30%] xl:w-1/4 2xl:w-[30%] w-full bg-[#77c1dd14] rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md'>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faFileExport } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className='mb-0 text-third text-base font_primary text-center'>Meaningful Outcomes</h2>
                  </div>
                  <p className='text-[white] text-base font_secondary my-4 text-center'>We make a real difference. We make sure your investments have value for you.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
            <Footern />
        </section>
    </>
  )
}

export default SemiNFT;