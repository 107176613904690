import React, { useEffect } from 'react';
import smm_1 from '../img/earnestroi-social-media-index.webp';
import img_two from '../img/earnestroi-ui-ux-two.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import Footern from './Footern';
import { Helmet } from 'react-helmet';
// import WhatsappChat from './WhatsappChat';

const UiUxDevelopment = () => {

  useEffect( () => {

    Aos.init({

      disable: function() {
        var maxWidth = 1023;
        return window.innerWidth < maxWidth;
      }

    });

    $(document).ready(function(){
        $(this).scrollTop(0);
    });

  } );

  useEffect( () => {

    Aos.init({ duration: 3000 });

  } )

  return (
    <>
      <Helmet>
        <title>Earnestroi Tecnology | Ux Ui Designer | Full Stack Developer</title>
        <meta name="title" content="Earnestroi Tecnology | Ux Ui Designer | Full Stack Developer" />
        <meta name="description" content="We are a UI/UX design company with a focus on user experience. We offer comprehensive service for both branding and mobile app design." />
        <meta name="keywords" content='online marketing, ux ui designer, project management, Internet Marketing, Digital Marketing, Online Marketing Agency' />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
      </Helmet>
      <div className=' bg-[white] py-12'>
        <div className='container mx-auto my-4 lg:my-16'>
          <div className='flex flex-col md:flex-row justify-center items-center'>
            <div className=''>
              <h1 className='text-second text-5xl lg:text-6xl mb-4 mt-4 lg:mt-0 capitalize font_primary' data-aos="fade-left">UI/UX <br />Design</h1>
            </div>
            <div className=''>
              <img src={ smm_1 } alt='UI/UX design ' />
            </div>
          </div>
        </div>
      </div>
      <div className='container mx-auto'>
        <div className='flex justify-center py-4 lg:py-10'>
          <Link to='/'><h2 className='mx-2 text-second font_primary'><FontAwesomeIcon icon={ faHome } className='mx-2 text-second font_primary' />Digital Marketing Agency</h2></Link><h2 className='mx-2'>/</h2><h2 className='mx-2 text-second font_primary'>UI/UX Design</h2>
        </div>
      </div>
      <div className='container mx-auto py-4 lg:py-16'>
        <div className='flex flex-col lg:flex-row justify-center items-center px-4 lg:px-0'>
          <div className='container mx-auto my-12 lg:my-16 border-l-2 border-second w-full lg:w-1/2 py-6'>
            <p className='pl-8 font_secondary'>WE'RE SOCIALLY AWARE</p>
            <br />
            <h2 className='text-base sm:text-3xl w-3/4 border-l-4 border-second pl-8 font_secondary' data-aos="fade-up">This is a proven fact after all first impressions are 94% design-related. As a result, many UX studios all over the world have sprung up. Not only for first impressions, but UI/UX design also affects marketing and sales, let’s take a look at how…</h2>
          </div>
          <div className='w-full lg:w-1/2'>
            <p className='mb-6 text-base sm:text-lg font_secondary'>Every stage of the product's development involves a substantial amount of engagement from UI/UX designers. The success of the product is greatly influenced by their efforts to do user research, collect, and communicate that data. They also conduct testing and contribute to future advancements. Everyone cannot complete this process. Handpicked experts that are committed and highly educated are required.</p>
            <p className='mb-6 text-base sm:text-lg font_secondary'>We are aware of this, which is why we select the best members of our team for this job. Because of this, our staff is the finest at offering you UX services. The same group of people helped us become a recognised leader in digital marketing. So get in touch with us if you want your goods to dominate the markets.</p>
          </div>
        </div>
      </div>
      <section className='bg-main py-4 lg:py-20'>
        <div className='container mx-auto'>
          <div className='grid-cols-2 flex flex-col-reverse lg:flex-row justify-around items-center'>
            <div className='px-2 w-full lg:w-[50%] py-8 lg-py-4' data-aos='fade-up'>   
              <p className='text-base sm:text-lg lg:text-xl mb-4 text-[white] font_primary'>UI/UX design refers to the design of user interfaces and user experiences for digital products, such as websites, mobile apps, and software applications. The main goal of UI/UX design is to create a seamless and intuitive experience for users, so they can easily accomplish their tasks and goals within the product.</p>
            </div>
            <div className='px-2 w-full lg:w-[40%] py-8 lg-py-4'>   
              <img src={ img_two } className='mx-auto block' alt='UI/UX design 1' />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='container mx-auto my-12 lg:my-16 border-l-2 border-second'>
          <p className='pl-8 font_secondary'></p>
          <br />
          <h2 className='text-base sm:text-3xl w-3/4 border-l-4 border-second pl-8 font_secondary' data-aos="fade-up">Here are some key elements to consider when designing the content for a website</h2>
        </div>
      </section>
      <section className='py-10 bg-bg_img bg-no-repeat px-1 xl:px-0' >
        <div className='container mx-auto'>
          <div className='grid sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-10 justify-center items-center'>
            <div className='p-4 h-full bg-[#00000012] bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
              <h2 className='text-lg mb-2 font_primary'><b>User goals</b></h2>
              <p className='text-base font_secondary'>What tasks or actions do users want to accomplish on the website? Make sure the content is organized and presented in a way that helps users achieve their goals.</p>
            </div>
            <div className='p-4 h-full bg-[#00000012] bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
              <h2 className='text-lg mb-2 font_primary'><b>Clarity and simplicity</b></h2>
              <p className='text-base font_secondary'>Keep the content clear and concise, and use headings, subheadings, and bullet points to break up large blocks of text.</p>
            </div>
            <div className='p-4 h-full bg-[#00000012] bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
              <h2 className='text-lg mb-2 font_primary'><b>Visual design</b></h2>
              <p className='text-base font_secondary'>Use images, videos, and other visual elements to supplement the text and help convey your message.</p>
            </div>
            <div className='p-4 h-full bg-[#00000012] bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
              <h2 className='text-lg mb-2 font_primary'><b>Mobile responsiveness</b></h2>
              <p className='text-base font_secondary'>Make sure the website is optimized for viewing on different devices, including smartphones and tablets.</p>
            </div>
            <div className='p-4 h-full bg-[#00000012] bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
              <h2 className='text-lg mb-2 font_primary'><b>Accessibility</b></h2>
              <p className='text-base font_secondary'>Consider the needs of users with disabilities and ensure that the website is accessible to all users.</p>
            </div>
            <div className='p-4 h-full bg-[#00000012] bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
              <h2 className='text-lg mb-2 font_primary'><b>Scanability</b></h2>
              <p className='text-base font_secondary'>Many users scan web pages rather than reading them word for word, so use formatting techniques like bolding, italicizing, and using different font sizes to make important information stand out.</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='container mx-auto py-4 lg:py-16'>
          <div className='flex flex-col lg:flex-row justify-center items-center px-4 lg:px-0'>
            <div className='my-12 lg:my-16 border-l-2 border-second w-full lg:w-[50%] py-6'>
              <p className='pl-8 font_secondary'>GET SOCIAL WITH US</p>
              <br />
              <h2 className='text-base sm:text-3xl w-3/4 border-l-4 border-second pl-8 font_secondary' data-aos="fade-up">UX design, a more general phrase for the process, includes UI design. The user's experience with a product is a key consideration in UX design. UX studios are concerned with how user-friendly and straightforward the product is. Additionally, they are focusing on how to give customers a memorable experience with the product.</h2>
            </div>
            <div className='w-full lg:w-[50%]'>
              <h2 className='text-base sm:text-3xl w-full my-6 text-second font_primary'>Why UI/UX is  important ?</h2>
              <p className='mb-6 text-base sm:text-lg font_secondary'>After a negative experience, 88% of online users are less likely to visit the same website again .Without UI/UX design, websites, apps, and related goods cannot succeed. </p>
              <p className='mb-6 text-base sm:text-lg font_secondary'>If your product doesn't offer a wonderful user experience, it doesn't matter how revolutionary it is for the market. Everyone leads a busy life in this fast-paced world. Nobody wants to waste time figuring out which features of your product do what.</p>
              <p className='mb-6 text-base sm:text-lg font_secondary'>They expect their encounters to be quick, simple, and seamless. The importance of having a well-thought-out UI/UX design increases for startups. When a startup is about to enter the market, they need to make a good first impression.</p>
            </div>
          </div>
        </div>
      </section>
      <Footern />
    </>
  )
}

export default UiUxDevelopment;