import React from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faFilePen, faGears, faLaptopCode, faSquareCheck  } from '@fortawesome/free-solid-svg-icons';

const TimelineCompo = () => {
  return (
    <>
        <VerticalTimeline animate={ true } lineColor={ '#77c1dd' }>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                // date="2008 - 2010"
                iconStyle={{ background: 'rgb(0, 0, 0)' }}
                icon={<FontAwesomeIcon icon={ faFilePen } className='text-3xl font_primary text-third' />}
            >
                <h2 className='text-xl lg:text-3xl text-third font_primary my-4'>Analyzing the Requirments</h2>
                <ul>
                    <li className='my-4 font_secondary'><FontAwesomeIcon icon={ faSquareCheck } className='text-third text-lg mx-2' />Comprehend business requirements</li>
                    <li className='my-4 font_secondary'><FontAwesomeIcon icon={ faSquareCheck } className='text-third text-lg mx-2' />Pick the business logic to be infused</li>
                    <li className='my-4 font_secondary'><FontAwesomeIcon icon={ faSquareCheck } className='text-third text-lg mx-2' />Prepare the roadmap</li>
                </ul>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                // date="2006 - 2008"
                iconStyle={{ background: 'rgb(0, 0, 0)' }}
                icon={<FontAwesomeIcon icon={ faGears } className='text-3xl font_primary text-third' />}
            >
                <h2 className='text-xl lg:text-3xl text-third font_primary my-4'>Creating the Technical Design</h2>
                <ul>
                    <li className='my-4 font_secondary'><FontAwesomeIcon icon={ faSquareCheck } className='text-third text-lg mx-2' />Create a document defining the smart contract</li>
                    <li className='my-4 font_secondary'><FontAwesomeIcon icon={ faSquareCheck } className='text-third text-lg mx-2' />Data flow diagram creation</li>
                    <li className='my-4 font_secondary'><FontAwesomeIcon icon={ faSquareCheck } className='text-third text-lg mx-2' />Design technical architecture</li>
                </ul>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                // date="April 2013"
                iconStyle={{ background: 'rgb(0, 0, 0)' }}
                icon={<FontAwesomeIcon icon={ faLaptopCode } className='text-3xl font_primary text-third' />}
            >
                <h2 className='text-xl lg:text-3xl text-third font_primary my-4'>Proceeding with the Development</h2>
                <ul>
                    <li className='my-4 font_secondary'><FontAwesomeIcon icon={ faSquareCheck } className='text-third text-lg mx-2' />Implement the proposed smart contract solution</li>
                    <li className='my-4 font_secondary'><FontAwesomeIcon icon={ faSquareCheck } className='text-third text-lg mx-2' />Client evaluation and feedback</li>
                    <li className='my-4 font_secondary'><FontAwesomeIcon icon={ faSquareCheck } className='text-third text-lg mx-2' />Complete the smart contract development</li>
                </ul>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                // date="November 2012"
                iconStyle={{ background: 'rgb(0, 0, 0)' }}
                icon={<FontAwesomeIcon icon={ faArrowRightFromBracket } className='text-3xl font_primary text-third' />}
            >
                <h2 className='text-xl lg:text-3xl text-third font_primary my-4'>Deployment and Testing</h2>
                <ul>
                    <li className='my-4 font_secondary'><FontAwesomeIcon icon={ faSquareCheck } className='text-third text-lg mx-2' />Testnet deployment</li>
                    <li className='my-4 font_secondary'><FontAwesomeIcon icon={ faSquareCheck } className='text-third text-lg mx-2' />Smart contract deployment on the Main network</li>
                    <li className='my-4 font_secondary'><FontAwesomeIcon icon={ faSquareCheck } className='text-third text-lg mx-2' />Prioritize the backlog</li>
                </ul>
            </VerticalTimelineElement>
            </VerticalTimeline>
    </>
  )
}

export default TimelineCompo