import { Table } from 'antd';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'react-toastify/dist/ReactToastify.css';

const ResumeDash = () => {
  const [ContactData, setContactData] = useState([]);
  const [resumeFileURL, setResumeFileURL] = useState("");

  const Career = () => {
    const CheckJwt = localStorage.getItem('token');
    const postData = { jwt: CheckJwt };

    console.log(postData);
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH + "view_career",
      data: postData,
    })
      .then(async function (response) {
        const successData = response.data.Success;

        // Check if Resume exists before accessing FileURL
        if (successData.Resume && successData.Resume.FileURL) {
          console.log(successData.Resume.FileURL);
          setResumeFileURL(successData.Resume.FileURL);
        }

        setContactData(successData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    Career();
  }, [])

  const GetResume = (fileURL) => {
    console.log(fileURL)
    const CheckJwt = localStorage.getItem('token');
    const postData = { jwt: CheckJwt };

    console.log(postData)
    axios({
      method: "GET",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH + "getfile/" + fileURL,
      data: postData,
    })
      .then(async function (response) {
        console.log(response);
      })
      .catch((err) => {
        console.log(err)
      });
  };

  const columns = [
    {
      title: "DATE",
      dataIndex: "Created_On",
      key: "Created_On",
    },
    {
      title: "FORM ID",
      dataIndex: "FormID",
      key: "FormID",
    },
    {
      title: "NAME",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "EMAIL",
      dataIndex: "Email",
      key: "Email",
    },
    {
      title: "PHONE",
      dataIndex: "Phone",
      key: "Phone",
    },
    {
      title: "CURRENT CTC",
      dataIndex: "Current_CTC",
      key: "Current_CTC",
    },
    {
      title: "POSITION",
      dataIndex: "Position",
      key: "Position",
    },
    {
      title: "RESUME",
      dataIndex: "Resume",
      key: "Resume",
      render: (text, record) => (
        <>
          {console.log(record.Resume.FileURL)}
          <a href={'https://api.earnestroi.com/private/getfile/' + record.Resume.FileURL}
            onClick={() => GetResume(record.Resume.FileURL)}
            target="_blank"
            rel="noopener noreferrer">
            Open Resume
          </a>
        </>
      ),
    },
  ];

  const dataSource = [];
  if (ContactData) {
    for (let i = 0; i < ContactData.length; ++i) {
      dataSource.push({
        key: i,
        Created_On: ContactData[i].Created_On,
        FormID: ContactData[i].FormID,
        Name: ContactData[i].Name,
        Email: ContactData[i].Email,
        Phone: ContactData[i].Phone,
        Current_CTC: ContactData[i].Current_CTC,
        Position: ContactData[i].Position,
        Resume: ContactData[i].Resume,
      });
    }
  }

  return (
    <>
      <section className="w-full">
        <div className="container mx-auto mt-12 px-2 sm:px-4 mb-6">
          <div className="container mx-auto">
            <h2 className="text-second font_primary text-center text-6xl">
              Hiring Resumes
            </h2>
          </div>
          <div class="table-responsive mt-5">
            <Table size="small" dataSource={dataSource} columns={columns} />
          </div>
        </div>
      </section>
    </>
  );
}

export default ResumeDash;