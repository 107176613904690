import React, { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import $ from 'jquery';

const TestiCaro = () => {

    useEffect( () => {

        $(document).ready(function(){

            $('.carousel-status').hide();
            $('.control-prev').hide();
            $('.control-next').hide();
            $('.thumbs-wrapper').hide();

        })

    } )

  return (
    <>
        <Carousel autoPlay='true' stopOnHover='true' showThumbs={ false } showStatus='false' infiniteLoop='true' interval='7000' className="bg-second rounded-2xl">
            <div className="h-full">
                <div className="h-full px-4 py-12 flex flex-col justify-center items-center">
                    <p className="text-[white] text-center font_secondary">"These agency have been impressive, good team, very prompt in responding to queries and respect time. They provide good ideas and are they are very practical. They provide you insights and suggestions, which you otherwise don’t think of. My experience has been amazing !!"</p>
                    <p className="mt-4 text-[white] text-[14px] text-center font_primary">"Daraksha"</p>
                    <p className="text-[white] text-[14px] text-center font_primary">"DKCare (Dentist)"</p>
                </div>
            </div>
            <div className="h-full">
                <div className="h-full px-4 py-12 flex flex-col justify-center items-center">
                    <p className="text-[white] text-center font_secondary">“It really met my requirements. You guys were very patient even though there were delays from my side. The price was competitive and all our requirements were met. If somebody were to ask me for reference, I would definitely recommend these agency!”</p>
                    <p className="mt-4 text-[white] text-[14px] text-center font_primary">"Shamshad"</p>
                    <p className="text-[white] text-[14px] text-center font_primary"></p>
                </div>
            </div>
            <div className="h-full">
                <div className="h-full px-4 py-12 flex flex-col justify-center items-center">
                    <p className="text-[white] text-center font_secondary">We were truly impressed! Ther social media marketing work was excellent too. All the deliverables reached us on time. The marketing produced definitive and solid results. Everything was organized to perfection and delivered very systematically. The team was always on hand to support us.</p>
                    <p className="mt-4 text-[white] text-[14px] text-center font_primary">"Imran Qureshi"</p>
                    <p className="text-[white] text-[14px] text-center font_primary">"Owner of AFZ"</p>
                </div>
            </div>
        </Carousel>
    </>
  )
}

export default TestiCaro;