import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../img/earnestroi-logo-60x60.png';

const Loginpage = () => {

  const [emailHandleChange, setEmailHandleChange] = useState();
  const [passwordHandleChange, setPasswordHandleChange] = useState();

  const EmailHandleChange = ((e) => {
    console.log(e.target.value);
    setEmailHandleChange(e.target.value);
  })
  const PasswordHandleChange = ((e) => {
    console.log(e.target.value);
    setPasswordHandleChange(e.target.value);
  })

  const Login = () => {
    const postdata = { email: emailHandleChange, password: passwordHandleChange };

    console.log("Success:", postdata);

    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH + "login",
      data: postdata,
    })
      .then(async function (response) {
        console.log(response);
        const res = await response.data;
        console.log(res.token);
        if (res.token) {
          console.log("Login Successfully!")
        }
        localStorage.setItem("token", res.JWT);
        window.location.href = "/admin/blogs";
      })
      .catch((err) => {
        console.log(err)
      });
  };

  return (
    <>
      <section className=' lg:pt-4 xl:pt-0 bg-main px-1 sm:px-0 lg:px-1 xl:px-0 py-20 h-screen'>
        <div className='py-8'>
          <div className='container mx-auto'>
            <div className='w-full md:w-3/4 pt-16 pb-4 lg:w-1/2 xl:w-1/4 px-4 sm:px-2 border-2 border-second rounded-xl mx-auto'>
              <img src={logo} alt='logo earnestroi' className='mx-auto block w-1/4' />
              <h2 className='text-third font_primary text-center text-5xl my-2'>Sign In</h2>
              <form className='py-8 px-4'>
                <input type='email' name='email' onChange={EmailHandleChange} className='border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-2' placeholder='Email id' />
                <input type='password' name='password' onChange={PasswordHandleChange} className='border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-2' placeholder='Password' />
                <button type="button" onClick={Login} className='text-base mr-auto lg:mx-auto hover:shadow-[0_0_12px_#FE8067] bg-second px-8 py-2 my-2 hover:border-2 border-second border-2 rounded-lg hover:bg-[black] block hover:text-second mx-auto hover:border-second font_primary'>Sign In</button>
              </form>
              <p><Link to='/' className='text-third underline mx-auto block text-center font_primary'>Earnestroi Home</Link></p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Loginpage;