import React, { useEffect } from 'react';
import corn from '../img/ninepages/earnestroi-Cryptocurrency-Wallet-Development-Services.jpg';
import img_3 from '../img/ninepages/earnestroi-Providing-Wallet-Services-help-to-Store-Tokens-Safely.jpg';
import corn_n from '../img/ninepages/earnestroi-Development-of-Cryptocurrency-Wallets.jpg';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUsers, faGears, faHeadphonesSimple, faFileExport, faWallet, faKey, faHandshake, faArrowsRotate, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { faShieldHalved } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { faClockFour } from '@fortawesome/free-regular-svg-icons';
import Footern from './Footern';
import { Helmet } from 'react-helmet';

const CryptoWallet = () => {  
  
  useEffect( () => {

    $(document).ready(function(){
      $(this).scrollTop(0);
    });

    $(document).ready(function(){
      
      $('.slick-prev').hide();
      $('.slick-next').hide();

    });

    Aos.init({

      disable: function() {
        var maxWidth = 1024;
        return window.innerWidth < maxWidth;
      }

    });

  } );

  useEffect( () => {

    Aos.init({ duration: 3000 });

  } );
  
  return (
    <>
      <Helmet>
        <title>Earnestroi Technology | Crypto Development | Digital Currencies</title>
        <meta name="title" content="Earnestroi Technology | Crypto Development | Digital Currencies" />
        <meta name="description" content="Our crypto development services are designed to help startups and small businesses launch their own cryptocurrency, with cutting-edge blockchain technology." />
        <meta name="keywords" content='crypto wallet, crypto currency wallet, best crypto wallet, cryptocurrency list, marketing agency, online marketing' /> 
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
      </Helmet>
      <section className=' lg:pt-4 xl:pt-0 bg-main px-1 xl:px-0'>
        <div className='container mx-auto'>
          <div className='flex flex-col-reverse lg:flex-row justify-center items-center xl:pt-20'>
            <div className='flex flex-col w-full lg:w-1/2'>
                <h1 className='text-[white] text-5xl lg:text-6xl mb-4 mt-4 lg:mt-0 font_primary capitalize' data-aos="fade-left">Cryptocurrency Wallet Development Services</h1>
                <br />
                <h2 className='text-base lg:text-2xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third'  data-aos="fade-left">Services for creating cryptocurrency wallets for businesses and institutions</h2>
            </div>
            <div className=' w-full lg:w-1/2'>
              <img src={corn} alt='Crypto Currency Wallet' className='mx-auto w-1/2 sm:w-1/4 lg:w-3/4 block' />
            </div>
          </div>
        </div>
      </section>
      <section className='bg-main py-4 lg:py-10'>
        <div className='container mx-auto'>
          <div className='flex justify-center py-4 lg:py-20'>
            <Link to='/'><h2 className='mx-2 text-third font_primary'><FontAwesomeIcon icon={ faHome } className='mx-2 text-third' />Digital Marketing Agency</h2></Link><h2 className='mx-2 text-third'>/</h2><h2 className='mx-2 text-third font_primary'>Cryptocurrency Wallet Development</h2>
          </div>
        </div> 
      </section>
      <section className='py-4 md:py-10'>
        <div className='container mx-auto'>
          <div className='grid-cols-2 flex flex-col lg:flex-row justify-between items-center'>
            <div className=''>
              <img src={img_3} alt='Crypto currency Wallet Service' className='my-8 lg:my-0' />
            </div>
            <div className='lg:w-[50%] lg:px-2 p-2 sm:p-0'>
              <h2 className='text-2xl lg:text-3xl font_primary text-third'>Providing Wallet Services  help to Store Tokens Safely</h2>
              <br />
              <p className='text-base font_secondary' data-aos="fade-right">Hire professionals at a top firm that develops bitcoin wallets to create reliable and secure cryptocurrency wallet solutions for your next project. With years of experience creating cryptocurrency wallets, Earnestroi uses cutting-edge security features like two-factor authentication and mobile passwords/fingerprints to generate and offer extremely safe cryptocurrency wallets. Use our knowledge and experience to start your cryptocurrency wallet.</p>
              <br />  
            </div>
          </div>
        </div>
      </section>
      <section className='bg-main py-4 lg:py-10'>
        <div className='container mx-auto'>
          <div className='grid-cols-2 flex flex-col lg:flex-row justify-around items-center'>
            <div className='w-full lg:w-[50%] lg:px-2 p-2 sm:p-0'>
              <h2 className='text-2xl lg:text-3xl font_primary text-third'>Development of Cryptocurrency Wallets: Increasing the Value of Your Company with Blockchain-Powered Wallets.</h2>
              <br />
              <p className='text-base font_secondary text-[white]' data-aos="fade-left">The number of users on the worldwide crypto market was 295 million in 2021, and by December 2022, it is anticipated that this number will reach one billion. Financial organizations and cryptocurrency businesses are taking advantage of the chance to enter the booming cryptocurrency sector and increase their earning potential.</p>
              <br />  
              <p className='text-base font_secondary text-[white]' data-aos="fade-left">Earnestroi streamlines the process of launching crypto wallets for international enterprises and provides comprehensive crypto wallet development services. We have a wealth of knowledge and an in-depth understanding of the wallet market. Our objective is to give users a thrilling wallet experience. We provide white-label wallet solutions to enable businesses swiftly build their cryptocurrency wallets since the number of users of white-label crypto wallets is rapidly increasing. We can also create digital wallets specifically tailored to your needs and purposes.</p>
              <br />  
            </div>
            <div className='w-full lg:w-[50%]'>
              <img src={corn_n} alt='Crypto Currency Powered Wallet ' className='my-8 lg:my-0 mx-auto lg:mr-auto w-1/2 sm:w-1/4 lg:w-1/2 block' />
            </div>
          </div>
        </div>
      </section>
      <section className='py-4 md:py-20'>
        <div className='container mx-auto pt-4 lg:pt-8'>
          <div className='w-full lg:w-[60%] mx-auto'>
            <h2 className='text-lg lg:text-2xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third text-center' data-aos='fade-up'>Critical Characteristics of White-Label Cryptographic Wallet</h2>
          </div>
        </div>
        <div className='container mx-auto'>
          <div className='grid sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-8 justify-center items-center'>
            <div className='p-4 h-full hover:bg-[#77c1dd14] hover:cursor-pointer rounded-2xl'>
              <FontAwesomeIcon icon={ faKey } className='text-5xl text-third' />
              <h2 className='text-lg mb-2 font_primary text-third'>Multi-Factor Authentication</h2>
              <p className='text-base font_secondary'>Add multi-factor authentication to the wallet to increase security. We integrated this security feature while considering your wallet's needs.</p>
            </div>
            <div className='p-4 h-full hover:bg-[#77c1dd14] hover:cursor-pointer rounded-2xl'>
              <FontAwesomeIcon icon={ faHandshake } className='text-5xl text-third' />
              <h2 className='text-lg mb-2 font_primary text-third'>Multi-Crypto Support</h2>
              <p className='text-base font_secondary'>Our professionals created crypto wallet app solutions for popular cryptocurrencies like Bitcoin, Ethereum, Litecoin, and other Altcoins.</p>
            </div>
            <div className='p-4 h-full hover:bg-[#77c1dd14] hover:cursor-pointer rounded-2xl'>
              <FontAwesomeIcon icon={ faArrowsRotate  } className='text-5xl text-third' />
              <h2 className='text-lg mb-2 font_primary text-third'>Transaction History</h2>
              <p className='text-base font_secondary'>The wallet stores information on peer-to-peer transactions that users have started.</p>
            </div>
            <div className='p-4 h-full hover:bg-[#77c1dd14] hover:cursor-pointer rounded-2xl'>
              <FontAwesomeIcon icon={ faClockFour } className='text-5xl text-third' />
              <h2 className='text-lg mb-2 font_primary text-third'>Real-Time Conversion Rates Updates</h2>
              <p className='text-base font_secondary'>Users of our bitcoin wallet solutions receive automatic exchange rate updates. Looking at instructive charts and graphs aids users in making informed judgments.</p>
            </div>
            <div className='p-4 h-full hover:bg-[#77c1dd14] hover:cursor-pointer rounded-2xl'>
              <FontAwesomeIcon icon={ faWallet } className='text-5xl text-third' />
              <h2 className='text-lg mb-2 font_primary text-third'>Wallet Backup</h2>
              <p className='text-base font_secondary'>Digital wallets are developed by our crypto wallet specialists taking unforeseen events like hacks and phishing assaults into account. The application includes a backup mechanism that users can use to retrieve data.</p>
            </div>
            <div className='p-4 h-full hover:bg-[#77c1dd14] hover:cursor-pointer rounded-2xl'>
              <FontAwesomeIcon icon={ faRightFromBracket } className='text-5xl text-third' />
              <h2 className='text-lg mb-2 font_primary text-third'>Automated Session Logout</h2>
              <p className='text-base font_secondary'>If the wallet session is inactive for a predetermined time, it automatically logs out. On the wallet, it stops the fraudulent activity. Once the password has been entered, the user can access the wallet.</p>
            </div>
          </div>
        </div>
      </section>
      <section className='bg-main py-4 lg:py-12 px-1 xl:px-0'>
        <div className='container mx-auto pt-4 lg:pt-8'>
          <div className='w-full lg:w-[60%] mx-auto'>
            <h2 className='text-lg lg:text-2xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third text-center lg:mb-8'>Why are we the best company for Cryptocurrency Wallet Development?</h2>
          </div>
          <div className='container mx-auto pb-4 md:pb-6'>
            <div className='grid sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
              <div className='p-4 h-full bg-[#77c1dd14] rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md '>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faShieldHalved } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center text-third'>Technical Process</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center text-[white]'>We exclusively work in Blockchain technologies. We just concentrate on one thing, and we excel at it.</p>
              </div>
              <div className='p-4 h-full bg-[#77c1dd14] rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md '>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faUsers } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center text-third'>Expert Team</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center text-[white]'>With the support of our 500 professionals, you can improve your offering, get the finest IT solution, and even create communities and campaigns.</p>
              </div>
              <div className='p-4 h-full bg-[#77c1dd14] rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md '>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faGears } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center text-third'>Rapid Development</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center text-[white]'>We offer goods that are especially suited to your intended consumer base. A well-organized roadmap ensures rapid and efficient development and implementation.</p>
              </div>
            </div>
          </div>
          <div className='container mx-auto pt-4 md:pt-6'>
            <div className='flex flex-col lg:flex-row lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
              <div className='p-4 h-full lg:w-[30%] xl:w-1/4 2xl:w-[30%] w-full bg-[#77c1dd14] rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md '>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faHeadphonesSimple } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center text-third'>Complete Support</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center text-[white]'>After the product launch, we continue to work and provide post-delivery support.</p>
              </div>
              <div className='p-4 h-full lg:w-[30%] xl:w-1/4 2xl:w-[30%] w-full bg-[#77c1dd14] rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md '>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faFileExport } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center text-third'>Meaningful Outcomes</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center text-[white]'>We make a real difference. We make sure your investments have value for you.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Footern />
      </section>
    </>
  )
}

export default CryptoWallet;