import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUserGroup, faBullhorn, faFilePen, faMessage, faAddressCard, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import graphics_1 from '../img/earnestroi-influencer-marketing.png';
import graphics_2 from '../img/earnestroi-influencer-marketing-two.png';
import Aos from 'aos';
import 'aos/dist/aos.css';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import Footern from './Footern';
import { Helmet } from 'react-helmet';
// import WhatsappChat from './WhatsappChat';

const InfluencerMarketing = () => {

  useEffect( () => {

    Aos.init({

      disable: function() {
        var maxWidth = 1023;
        return window.innerWidth < maxWidth;
      }

    });

    $(document).ready(function(){
        $(this).scrollTop(0);
    });

  } );

  useEffect( () => {

    Aos.init({ duration: 3000 });

  } )

  return (
    <>
      <Helmet>
        <title>Earnestroi Tecnology | Influencer Marketing | Brand Awareness</title>
        <meta name="title" content="Earnestroi Tecnology | Influencer Marketing | Brand Awareness" />
        <meta name="description" content="Influencer Marketing is one of the most effective ways of marketing. By partnering with us you can reach billions of consumers, increase brand awareness." /> 
        <meta name="keywords" content='influencer marketing, Website Development & Design Services,Digital & Creative Marketing Agency, marketing plan' />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
      </Helmet>
      <div className=' py-10'>
        <div className='container mx-auto'>
          <div className='flex flex-col lg:flex-row justify-center items-center'>
            <img src={ graphics_1 } className='' alt='Infulencer marketing ' />
            <h1 className='text-second text-5xl lg:text-6xl mb-4 mt-4 lg:mt-0 capitalize font_primary' data-aos="fade-right">Influencer Marketing</h1>
          </div>
        </div>
        <div className='container mx-auto'>
          <div className='flex justify-center py-4 lg:py-10'>
            <Link to='/'><h2 className='mx-2 text-second font_primary'><FontAwesomeIcon icon={ faHome } className='mx-2 text-second' />Digital Marketing Agency</h2></Link><h2 className='mx-2 text-second'>/</h2><h2 className='mx-2 text-second font_primary'>Influencer Marketing</h2>
          </div>
        </div>
        <div className='container mx-auto py-4 lg:py-16'>
          <div className='flex flex-col lg:flex-row justify-center items-center px-4 lg:px-0'>
            <div className='container mx-auto my-12 lg:my-16 border-l-2 border-second w-full lg:w-1/2 py-6'>
              <p className='pl-8 font_secondary'>THE BEST YOU EVER HAD!</p>
              <br />
              <h2 className='text-base sm:text-3xl w-full lg:w-3/4 border-l-4 border-second pl-8 font_secondary font_primary text-second' data-aos="fade-up">The potential components that you may want to include on a website dedicated to influencer marketing:</h2>
              <br />
              <p className='pl-8 w-full lg:w-3/4 my-2 lg:my-4 font_secondary'>An Influencer Marketing involves partnering with individuals who have a large following on social media or other platforms and promoting your product or service to their audience.</p>
            </div>
            <div className='w-full lg:w-1/2'>
              <img src={ graphics_2 } alt='Infulencer marketing 1' className='mx-auto lg:ml-auto lg:mr-0' />
            </div>
          </div>
        </div>
      </div>
      <section className='py-4 md:py-20 bg-main'>
          <div className='container mx-auto'>
            <div className='w-full lg:w-[60%] mx-auto'>
              <h2 className='text-lg lg:text-2xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-second text-center' data-aos='fade-up'>Components of Influencer Marketing</h2>
            </div>
          </div>
          <div className='container mx-auto'>
            <div className='grid sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-8 justify-center items-center'>
              <div className='p-4 h-full hover:bg-[#FE806714] hover:cursor-pointer rounded-2xl'>
                <FontAwesomeIcon icon={ faBullhorn } className='text-5xl text-second' />
                <h2 className='text-lg mb-2 font_primary text-second capitalize'>influencer marketing strategy</h2>
                <p className='text-base font_secondary text-[white]'>This could include information on the types of influencers you work with, the industries or niches you focus on, and the overall goals of your influencer marketing campaigns.</p>
              </div>
              <div className='p-4 h-full hover:bg-[#FE806714] hover:cursor-pointer rounded-2xl'>
                <FontAwesomeIcon icon={ faFilePen } className='text-5xl text-second' />
                <h2 className='text-lg mb-2 font_primary text-second capitalize'>Case studies</h2>
                <p className='text-base font_secondary text-[white]'>Share examples of successful influencer partnerships and the results they achieved, such as increased brand awareness, sales, or engagement.</p>
              </div>
              <div className='p-4 h-full hover:bg-[#FE806714] hover:cursor-pointer rounded-2xl'>
                <FontAwesomeIcon icon={ faMessage } className='text-5xl text-second' />
                <h2 className='text-lg mb-2 font_primary text-second capitalize'>Testimonials from influencers</h2>
                <p className='text-base font_secondary text-[white]'>Include quotes or video testimonials from influencers who have worked with your brand and can speak to the value of your product or service.</p>
              </div>
              <div className='p-4 h-full hover:bg-[#FE806714] hover:cursor-pointer rounded-2xl'>
                <FontAwesomeIcon icon={ faUserGroup } className='text-5xl text-second' />
                <h2 className='text-lg mb-2 font_primary text-second capitalize'>influencers worked with</h2>
                <p className='text-base font_secondary text-[white]'>Create NFT tokens with distinct product identity and authentication; NFTs are excellent instruments for addressing product duplication.</p>
              </div>
              <div className='p-4 h-full hover:bg-[#FE806714] hover:cursor-pointer rounded-2xl'>
                <FontAwesomeIcon icon={ faAddressCard } className='text-5xl text-second' />
                <h2 className='text-lg mb-2 font_primary text-second capitalize'>contact information for influencers</h2>
                <p className='text-base font_secondary text-[white]'>Make it easy for influencers to reach out to you if they are interested in partnering with your brand.</p>
              </div>
              <div className='p-4 h-full hover:bg-[#FE806714] hover:cursor-pointer rounded-2xl'>
                <FontAwesomeIcon icon={ faScrewdriverWrench } className='text-5xl text-second' />
                <h2 className='text-lg mb-2 font_primary text-second capitalize'>Resources for influencers</h2>
                <p className='text-base font_secondary text-[white]'>Consider providing resources such as tips on how to create effective sponsored content, guidelines for working with brands, or information on your brand's values and mission.</p>
              </div>
            </div>
          </div>
        </section>
      <Footern />
    </>
  )
}

export default InfluencerMarketing;