import React from 'react';
import img1 from '../img/landing/mainsection/earnestroi-landing-mainsection-shape-1.webp';
import img2 from '../img/landing/mainsection/earnestroi-landing-mainsection-shape-2.webp';
import img3 from '../img/landing/mainsection/earnestroi-landing-mainsection-shape-3.webp';
import img4 from '../img/landing/mainsection/earnestroi-landing-mainsection-shape-4.webp';
import img5 from '../img/landing/mainsection/earnestroi-landing-mainsection-shape-5.webp';
import img6 from '../img/landing/mainsection/earnestroi-landing-mainsection-shape-6.webp';
import img7 from '../img/landing/mainsection/earnestroi-landing-mainsection-shape-7.webp';
import img8 from '../img/landing/mainsection/earnestroi-landing-mainsection-shape-8.webp';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import landing from '../Lottie/landing.json';

const MainsectionLanding = () => {
  return (
    <>
        <img src={ img1 } className='absolute top-[13%] left-[8%] hidden md:block animate-rotate' alt='img1 landing mainsection' />
        <img src={ img2 } className='absolute top-[10%] left-[25%] hidden md:block animate-moveUpDown' alt='img2 landing mainsection' />
        <img src={ img3 } className='absolute top-[6%] left-[42%] hidden md:block animate-moveLeftRight' alt='img3 landing mainsection' />
        <img src={ img7 } className='absolute top-[12%] right-[9%] hidden md:block animate-moveUpDown' alt='img7 landing mainsection' />
        <img src={ img6 } className='absolute bottom-[12%] right-[25%] hidden md:block animate-moveLeftRight' alt='img6 landing mainsection' />
        <img src={ img5 } className='absolute bottom-[24%] left-[35%] hidden md:block animate-moveAround' alt='img5 landing mainsection' />
        <img src={ img4 } className='absolute top-[4%] right-[36%] hidden md:block animate-rotate' alt='img4 landing mainsection' />
        <img src={ img8 } className='absolute bottom-[16%] left-[8%] hidden md:block animate-rotate' alt='img8 landing mainsection' />
        <div className='flex flex-col-reverse md:flex-row justify-around items-center h-full pb-12 md:py-4'>
            <div className=' w-full lg:w-1/2'>
                <h2 className='modal-title text-center m-0 capitalize text-third font_primary text-2xl md:text-3xl lg:text-5xl'>
                    Earnestroi - Help you to <br />
                    make a brand <br />
                    <span className='text-second'>Creative, Smart, Powerful</span>.
                </h2>
                <Link to='/contactsl' className='text-base md:text-xl lg:text-2xl mt-8 hover:shadow-[0_0_12px_#FE8067] text-[white] border-2 border-[white] px-12 py-2 rounded-lg hover:text-[white] m-0 hover:border-[white] font_primary block w-max text-center mx-auto' >Let Us Help You</Link>
            </div>
            <div className='p-4 w-full lg:w-1/2'>
                <Lottie animationData={ landing } className='mx-auto block w-[95%] lg:w-3/4 2xl:w-1/2 h-auto' />
            </div>
        </div>
    </>
  )
}

export default MainsectionLanding;
