import React, { useEffect } from "react";

const LiveTraffic = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//cdn.livetrafficfeed.com/static/v5/live.js?bc=ffffff&tc=000000&brd1=77c1dd&lnk=fe8067&hc=fe8067&hfc=000000&nc=19ff19&vv=210&tft=3&ro=0&tz=America%2FNew_York&res=0";
    script.async = true;
    document.getElementById('traffic').appendChild(script);
  }, []);

  return (
    <>
      <noscript id="LTF_live_website_visitor">
        <a href="https://earnestroi.com/">Visitor Counter</a>
      </noscript>
    </>
  )
};

export default LiveTraffic;