import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Panel = () => {

  const [apisubmit, setApisubmit] = useState({ description: '', });
  const [img, setImg] = useState(null);
  const [headers, setHeaders] = useState('');
  const [forURL, setforURL] = useState("");
  const [subheader, setSubheader] = useState('');
  const [formname, setFormname] = useState('Earnestroi');
  const [email, setEmail] = useState('info@earnestroi.com');
  const [tags, setTags] = useState('');
  const [layout, setLayout] = useState('layout_one');
  const [desciption, setDescription] = useState('')
  const [imgPath, setImgPath] = useState(null)

  console.log("imgPath", imgPath)

  // const handleFileChange = (event) => {
  //   const selectedFile = event.target.files[0];
  
  //   if (selectedFile) {
  //     // Extract the file name
  //     const imageName = selectedFile.name;
  
  //     // Set the image name to the state variable
  //     setImgPath(imageName);
  
  //     let reader = new FileReader();
  //     console.log('reader', reader);
  
  //     reader.onload = (e) => {
  //       // You can also set the image data to another state variable if needed
  //       // setImgData(e.target.result);
  //     };
  
  //     reader.readAsDataURL(selectedFile);
  //   }
  // };

  const handleFileChange = (event) => {
    let reader = new FileReader();
    console.log('reader' , reader)

    reader.onload = (e) => {
      setImgPath(e.target.result)
    };

    reader.readAsDataURL(event.target.files[0]);
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    const jwt = localStorage.getItem("token");
    const postData = {
      jwt: jwt,
      heading: headers,
      subheading: subheader,
      layout: 1,
      url: forURL,
      tag: tags,
      description: desciption,
      banner: imgPath,
      images: [],
      image_description: "",
      gallery_title: "",
      quote_description: "",
      quote: "",
      // name: formname,
      // email: email,
    }

    console.log(postData)
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH + 'add_blog',
      data: postData,
    })
      .then(res => {
        if (res.data.Success) {
          toast.success('Blog Created successfully');
        }
      })
      .catch(err => {
        if (err.res.data.Error !== '') {
          toast.error('Something Went Wrong');
        }
      })
  };

  return (
    <section className="py-8">
      <h2 className="text-third text-center text-3xl font_primary">Layout 1</h2>
      <div className="container mx-auto py-8">
        <form className="px-2" onSubmit={handleSubmit}>
          <div className="justify-center items-center flex-col sm:flex-row hidden">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Layout: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="layout"
                value={layout}
                onChange={(e) => setLayout(e.target.value)}
                readOnly
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="layout"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Header: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                name="header"
                value={headers}
                onChange={(e) => setHeaders(e.target.value)}
                type="text"
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Header"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Sub-Header: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="subheader"
                value={subheader}
                onChange={(e) => setSubheader(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Sub-Header"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">forURL: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="forURL"
                value={forURL}
                onChange={(e) => setforURL(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="forURL"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Banner: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="file"
                name="banner_img"
                accept="image/*"
                onChange={handleFileChange}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Sub-Header"
              />
              <img src={imgPath} alt="" />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Description: </h2>
            </div>
            <div className="w-full sm:w-3/4 py-2">
              <CKEditor
                name="description"
                editor={ClassicEditor}
                // value={apisubmit.description || ""}
                value={desciption}
                // onChange={(e) => {setDescription(e.target.value)}}
                data="<p>Welcome to <b>Earnestroi</b> !!!<br />
                            <br /></p>"
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  // console.log( 'Editor is ready to use!', editor );
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  // console.log( { event, editor, data } );
                  // setApisubmit({ ...apisubmit, description: data });
                  setDescription(data)
                }}
                onBlur={(event, editor) => {
                  // console.log( 'Blur.', editor );
                }}
                onFocus={(event, editor) => {
                  // console.log( 'Focus.', editor );
                }}
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Tags: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="tags"
                value={tags}
                onChange={(e) => setTags(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Tags"
              />
            </div>
          </div>
          <div className="justify-center items-center flex-col sm:flex-row hidden">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Name: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="name"
                value={formname}
                disabled
                onChange={(e) => setFormname(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Name"
              />
            </div>
          </div>
          <div className="justify-center items-center flex-col sm:flex-row hidden">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Email: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="email"
                disabled
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Email"
              />
            </div>
          </div>
          <input
            type="submit"
            className="py-2 px-4 mt-4 rounded-lg font_secondary my-1 w-1/4 bg-second ml-auto hover:cursor-pointer block font_primary"
          />
        </form>
      </div>
    </section>
  );
}

export default Panel;