import React from 'react';
import { Fragment, useState } from "react";
import { faPhotoFilm, faPalette, faFileCircleCheck, faLaptopFile, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";

const AccordionComponentTwo = () => {

    const [open, setOpen] = useState(1);

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

  return (
    
    <>
        
        <div className='text-center w-full lg:w-[40%] px-2'>
            {/* <div className='px-2 w-3/4'> */}
                <Fragment>
                    <Accordion open={open === 1}>
                        <AccordionHeader className='text-[white] pl-4 font_primary font-light justify-start border-[1px] border-[red] rounded-xl my-2 shadow-[0px_0px_6px_#fff]' onClick={() => handleOpen(1)}>
                        <FontAwesomeIcon icon={ faFileCircleCheck } className='text-5xl text-[white] mx-2' />
                        CONTENT MARKETING
                        </AccordionHeader>
                        <AccordionBody className='text-[white] font_secondary text-left text-base p-2 rounded-lg'>
                        With our content strategy and marketing services, you can turn good content into outstanding content.
                        </AccordionBody>
                    </Accordion>
                    <Accordion open={open === 2}>
                        <AccordionHeader className='text-[white] pl-4 font_primary font-light justify-start border-[1px] border-[#56f85b] rounded-xl my-2 shadow-[0px_0px_6px_#fff]' onClick={() => handleOpen(2)}>
                        <FontAwesomeIcon icon={ faPalette } className='text-5xl text-[white] mx-2' />
                        BRANDING
                        </AccordionHeader>
                        <AccordionBody className='text-[white] font_secondary text-left text-base p-2 rounded-lg'>
                        With our creative conceptualization and brand story, you can establish assertive communication for your brand.
                        </AccordionBody>
                    </Accordion>
                    <Accordion open={open === 3}>
                        <AccordionHeader className='text-[white] pl-4 font_primary font-light justify-start border-[1px] border-[#e3b503] rounded-xl my-2 shadow-[0px_0px_6px_#fff]' onClick={() => handleOpen(3)}>
                        <FontAwesomeIcon icon={ faLaptopFile  } className='text-5xl text-[white] mx-2' />
                        UI / UX
                        </AccordionHeader>
                        <AccordionBody className='text-[white] font_secondary text-left text-base p-2 rounded-lg'>
                        Make your website visitors' online experience memorable.
                        </AccordionBody>
                    </Accordion>
                    <Accordion open={open === 4}>
                        <AccordionHeader className='text-[white] pl-4 font_primary font-light justify-start border-[1px] border-[#eb64ad] rounded-xl my-2 shadow-[0px_0px_6px_#fff]' onClick={() => handleOpen(4)}>
                        <FontAwesomeIcon icon={ faPalette } className='text-5xl text-[white] mx-2' />
                        GRAPHIC DESIGN
                        </AccordionHeader>
                        <AccordionBody className='text-[white] font_secondary text-left text-base p-2 rounded-lg'>
                        Change how people perceive your brand with creative concepts and designs created by a detail-oriented team.
                        </AccordionBody>
                    </Accordion>
                    <Accordion open={open === 5}>
                        <AccordionHeader className='text-[white] pl-4 font_primary font-light justify-start border-[1px] border-second rounded-xl my-2 shadow-[0px_0px_6px_#fff]' onClick={() => handleOpen(5)}>
                        <FontAwesomeIcon icon={ faScrewdriverWrench } className='text-5xl text-[white] mx-2' />
                        SOCIAL MEDIA OPTIMIZATION
                        </AccordionHeader>
                        <AccordionBody className='text-[white] font_secondary text-left text-base p-2 rounded-lg'>
                        With our data-backed creative campaign, you can increase brand visibility, engagement, and business.
                        </AccordionBody>
                    </Accordion>
                    <Accordion open={open === 6}>
                        <AccordionHeader className='text-[white] pl-4 font_primary font-light justify-start border-[1px] border-[red] rounded-xl my-2 shadow-[0px_0px_6px_#fff]' onClick={() => handleOpen(6)}>
                        <FontAwesomeIcon icon={ faPhotoFilm } className='text-5xl text-[white] mx-2' />
                        MEDIA PLANNING SERVICE
                        </AccordionHeader>
                        <AccordionBody className='text-[white] font_secondary text-left text-base p-2 rounded-lg'>
                        Our Media Planning expertise can get your brand noticed on the right platform at the right time and place.
                        </AccordionBody>
                    </Accordion>
                </Fragment>
            {/* </div> */}
        </div>
        
    </>

  )
}

export default AccordionComponentTwo;