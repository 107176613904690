import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import corn from '../img/ninepages/earnestroi-Smart-Contract-Development-Company.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faChartPie, faHome, faFileSignature, faLaptopCode, faFileCircleCheck, faRotate, faPeopleCarryBox, faClockFour, faEye, faUsers, faGears, faHeadphonesSimple, faFileExport, faShieldHalved, faCircleCheck, faPiggyBank } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import TimelineCompo from './TimelineCompo';
import img_1_sc from '../img/sc-lockchain-icons/earnestroi-sc-blockchain-etherium.svg';
import img_2_sc from '../img/sc-lockchain-icons/earnestroi-sc-blockchain-etherium-tron.png';
import img_3_sc from '../img/sc-lockchain-icons/earnestroi-sc-blockchain-etherium-hyperledger.svg';
import img_4_sc from '../img/sc-lockchain-icons/earnestroi-sc-blockchain-etherium-binance.svg';
import img_5_sc from '../img/sc-lockchain-icons/earnestroi-sc-blockchain-EOS.svg';
import img_6_sc from '../img/sc-lockchain-icons/earnestroi-sc-blockchain-polkadot.svg';
import Footern from './Footern';
import { Helmet } from 'react-helmet';
// import WhatsappChat from './WhatsappChat';

const SmartContract = () => {

  useEffect( () => {

    $(document).ready(function(){
      $(this).scrollTop(0);
    });

    $(document).ready(function(){
      
      $('.slick-prev').hide();
      $('.slick-next').hide();

    });

    Aos.init({

      disable: function() {
        var maxWidth = 1024;
        return window.innerWidth < maxWidth;
      }

    });

} );

  useEffect( () => {

    Aos.init({ duration: 3000 });

} );

  return (
    <>
      <Helmet>
        <title>Earnestroi Technology | Smart Contract Development | Blockchain Technology</title>
        <meta name="title" content="Earnestroi Technology | Smart Contract Development | Blockchain Technology" />
        <meta name="description" content="We are a team of highly skilled developers and experts with a passion for blockchain technology, working every day to help companies automate many tasks that were previously impossible." />
        <meta name="keywords" content='smart contract development service, smart contract development company, smart contract development, project management, Creative Marketing Agency' />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
      </Helmet>
      <section className=' lg:pt-4 xl:pt-0 px-1 xl:px-0'>
        <div className='container mx-auto'>
          <div className='flex flex-col-reverse lg:flex-row justify-center items-center xl:pt-20'>
            <div className='flex flex-col w-full lg:w-1/2'>
                <h1 className='text-third text-5xl lg:text-6xl mb-4 mt-4 lg:mt-0 font_primary capitalize' data-aos="fade-left">Smart Contract Development Company</h1>
                <br />
                <h2 className='text-lg md:text-2lg lg:text-2xl mb-4 mt-4 lg:mt-0 font_primary capitalize'  data-aos="fade-left">Accept Transparency In Business Practices</h2>
            </div>
            <div className=' w-full lg:w-1/2'>
              <img src={corn} alt='Smart Contract ' className='mx-auto w-1/2 sm:w-1/2 lg:w-3/4' />
            </div>
          </div>
        </div>
      </section>
      <section className=''>
        <div className='container mx-auto'>
          <div className='flex justify-center py-4 lg:py-10'>
            <Link to='/'><h2 className='mx-2 text-third font_primary'><FontAwesomeIcon icon={ faHome } className='mx-2 text-third' />Digital Marketing Agency</h2></Link><h2 className='mx-2 text-third'>/</h2><h2 className='mx-2 text-third font_primary'>NFT Development</h2>
          </div>
        </div>  
      </section>
      <section className='bg-main py-8 md:py-16 sm:py-6 lg:py-12 px-1 xl:px-0'>
        <h2 className='text-xl lg:text-3xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third text-center w-full lg:w-1/2 mx-auto px-2 lg:px-12' data-aos='fade-up'>Smart Contracts are the Future</h2>
        <div className='container mx-auto'>
          <div className='flex flex-col lg:flex-row justify-center items-center'>
            <div className='w-full flex flex-row lg:flex-col justify-center items-center lg:w-1/4 px-1 lg:px-4 bg-[#77c1dd38] py-4 md:py-10 border-third rounded-2xl'>
              <FontAwesomeIcon icon={ faChartLine } className='text-third py-4 mx-4 lg:mx-0 text-[6rem] lg:py-4' />
              <h2 className='text-lg font_primary text-[white]'>The global smart contracts market size is estimated to amount to USD 345.4 million by 2026.</h2>
            </div>
            <div className='w-full lg:w-1/2 py-8 lg:py-0 px-2 lg:px-12'>
              <p className='  text-[white] text-base sm:text-lg mb-4 lg:mb-8 font_secondary' data-aos='fade-up'>Self-executing smart contracts and self-enforcing protocols governed by explicit terms and conditions can transform agreements in various industries, including real estate, supply chain, telecom, manufacturing, healthcare, and others.</p>
              <p className=' text-[white]  text-base sm:text-lg mb-4 lg:mb-8 font_secondary' data-aos='fade-up'>A seasoned, smart contract development company specializing in building smart contracts specific to different industries and business types is Earnestroi Solutions. We have a team of talented smart contract developers behind us, and we know what it takes to build an excellent computer-based protocol to automate commercial agreements.</p>
            </div>
            <div className='w-full flex flex-row lg:flex-col justify-center items-center lg:w-1/4 px-1 lg:px-4 bg-[#77c1dd38] py-4 md:py-10 border-third rounded-2xl'>
              <FontAwesomeIcon icon={ faChartPie } className='text-third py-4 mx-4 lg:mx-0 text-[6rem] lg:py-4' />
              <h2 className='text-lg font_primary text-[white]'>Organizations using smart contracts will increase the overall data quality by 50%.</h2>
            </div>
          </div>
        </div>
      </section>
      <section className='py-12'>
        <div className='container mx-auto pt-4 lg:pt-8'>
          <div className='w-full lg:w-[60%] mx-auto'>
            <h2 className='mb-2 mt-4 lg:mt-0 font_primary capitalize text-third text-center text-xl lg:text-3xl'>Our Smart Contract Development Services</h2>
            <p className='text-center text-base sm:text-lg mb-2 lg:mb-2 font_secondary'>Earnestroi Solutions, a leading smart contract development company, assists enterprises in achieving business automation by providing advanced smart contract development services.</p>
          </div>
          <br />
          <div className='grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4'>
            <div className='flex flex-col sm:flex-row md:flex-col lg:flex-row items-center justify-center px-8 py-2 md:px-10 md:py-10'>
              <div className=''>
                <FontAwesomeIcon icon={ faFileSignature } className='text-third py-4 mx-4 lg:mx-2 text-[4rem] lg:py-4' />
              </div>
              <div className=''>
                <h2 className='font_primary text-xl text-third'>Smart Contract Architecture</h2>
                <p className='font_secondary'>To ensure the quality of the code, our smart contract application development team adheres to resilient smart contract architecture practices.</p>
              </div>
            </div>
            <div className='flex flex-col sm:flex-row md:flex-col lg:flex-row items-center justify-center px-8 py-2 md:px-10 md:py-10'>
              <div className=''>
                <FontAwesomeIcon icon={ faLaptopCode } className='text-third py-4 mx-4 lg:mx-2 text-[4rem] lg:py-4' />
              </div>
              <div className=''>
                <h2 className='font_primary text-xl text-third'>Smart Contract Development</h2>
                <p className='font_secondary'>We create cutting-edge self-executing custom smart contracts with numerous features for various industries.</p>
              </div>
            </div>
            <div className='flex flex-col sm:flex-row md:flex-col lg:flex-row items-center justify-center px-8 py-2 md:px-10 md:py-10'>
              <div className=''>
                <FontAwesomeIcon icon={ faFileCircleCheck } className='text-third py-4 mx-4 lg:mx-2 text-[4rem] lg:py-4' />
              </div>
              <div className=''>
                <h2 className='font_primary text-xl text-third'>Smart Contract Audit</h2>
                <p className='font_secondary'>We offer meticulous smart contract audit services to assist businesses in correcting smart contract codes and conducting security audits from the ground up.</p>
              </div>
            </div>
            <div className='flex flex-col sm:flex-row md:flex-col lg:flex-row items-center justify-center px-8 py-2 md:px-10 md:py-10'>
              <div className=''>
                <FontAwesomeIcon icon={ faRotate } className='text-third py-4 mx-4 lg:mx-2 text-[4rem] lg:py-4' />
              </div>
              <div className=''>
                <h2 className='font_primary text-xl text-third'>Smart Contract Optimization</h2>
                <p className='font_secondary'>Our certified smart contract developers optimize smart contracts using optimization techniques to lower the average gas fee and reduce computational resource requirements.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='bg-main py-12 px-1 xl:px-0'>
        <div className='container mx-auto pt-4 lg:pt-8'>
          <div className=''>
            <h2 className='mx-auto w-full lg:w-[70%] text-xl lg:text-3xl mb-2 mt-4 lg:mt-0 font_primary capitalize text-third text-center' data-aos='fade-up'>Why does your organization require Smart Contract Application Development Services?</h2>
            <p className='mx-auto w-full lg:w-[60%] text-center text-base sm:text-lg mb-2 lg:mb-2 font_secondary text-[white]' data-aos='fade-up'>Smart contracts provide automation that allows unprecedented efficiency and cost reductions throughout business operations.</p>
          </div>
        </div>
        <div className='container mx-auto'>
          <div className='grid gap-2 lg:gap-4 sm:grid-cols-2 lg:grid-cols-3'>
            <div className='p-8 bg-[#77c1dd14] rounded-xl'>
              <FontAwesomeIcon icon={ faPeopleCarryBox } className='text-5xl text-third' />
              <h2 className='text-xl text-third font_primary'>Disintermediation</h2>
              <p className='text-[white] font_secondary'>Smart contracts allow parties to enter into agreements without the involvement of a third party.</p>
            </div>
            <div className='p-8 bg-[#77c1dd14] rounded-xl'>
              <FontAwesomeIcon icon={ faClockFour } className='text-5xl text-third' />
              <h2 className='text-xl text-third font_primary'>Real-Time Execution</h2>
              <p className='text-[white] font_secondary'>Once the required criteria are met, smart contracts are executed concurrently across participating computers.</p>
            </div>
            <div className='p-8 bg-[#77c1dd14] rounded-xl'>
              <FontAwesomeIcon icon={ faEye } className='text-5xl text-third' />
              <h2 className='text-xl text-third font_primary'>Transparency</h2>
              <p className='text-[white] font_secondary'>All blockchain network users may see the information in the contract, promoting trust in the network.</p>
            </div>
            <div className='p-8 bg-[#77c1dd14] rounded-xl'>
              <FontAwesomeIcon icon={ faShieldHalved } className='text-5xl text-third' />
              <h2 className='text-xl text-third font_primary'>Security</h2>
              <p className='text-[white] font_secondary'>The distributed ledger strengthens the security paradigm since it is impenetrable and resistant to changes.</p>
            </div>
            <div className='p-8 bg-[#77c1dd14] rounded-xl'>
              <FontAwesomeIcon icon={ faCircleCheck } className='text-5xl text-third' />
              <h2 className='text-xl text-third font_primary'>Accuracy</h2>
              <p className='text-[white] font_secondary'>Automate record-keeping, storage, and retrieval to eliminate human mistakes.</p>
            </div>
            <div className='p-8 bg-[#77c1dd14] rounded-xl'>
              <FontAwesomeIcon icon={ faPiggyBank } className='text-5xl text-third' />
              <h2 className='text-xl text-third font_primary'>Financial Saving</h2>
              <p className='text-[white] font_secondary'>Automated operations eliminate the need for human labor, reducing costs and risks.</p>
            </div>
          </div>
        </div>
      </section>
      <section className='py-4 lg:py-10 px-1 xl:px-0'>
        <div className='container mx-auto py-4 lg:py-12'>
          <div className=''>
            <h2 className='mx-auto w-full lg:w-[70%] text-xl lg:text-3xl mb-2 mt-4 lg:mt-0 font_primary capitalize text-third text-center'>Our Smart Contract Development Process</h2>
            <p className='mx-auto w-full lg:w-[60%] text-center text-base sm:text-lg mb-2 lg:mb-2 font_secondary'>We devise a coherent roadmap to navigate your smart contracts development journey.</p>
          </div>
        </div>
        <TimelineCompo />
      </section>
      <section className='bg-main py-12 px-1 xl:px-0'>
        <div className='container mx-auto pt-4 lg:pt-8'>
          <div className=''>
            <h2 className='mx-auto w-full lg:w-[70%] text-xl lg:text-3xl mb-2 mt-4 lg:mt-0 font_primary capitalize text-third text-center'>Smart Contract Development on Various Blockchain Platforms</h2>
            <p className='mx-auto w-full lg:w-[60%] text-center text-base sm:text-lg mb-2 lg:mb-2 font_secondary text-[white]'>Whether TRON or Hyperledger, our multidisciplinary team can develop and deploy smart contracts on renowned Blockchain networks.</p>
          </div>
        </div>
        <div className='container mx-auto'>
          <div className='grid gap-2 lg:gap-4 sm:grid-cols-2 lg:grid-cols-3'>
            <div className='p-8'>
              <img src={ img_1_sc } alt='smart contract blockchain images' className='' />
              <h2 className='text-lg text-third font_primary'>Ethereum Smart Contract Development</h2>
              <p className='text-[white] font_secondary'>We create a harmonious trifecta of technical prowess, intelligent strategies, and a design-thinking-driven process to deliver Ethereum smart contract development solutions.</p>
            </div>
            <div className='p-8'>
              <img src={ img_2_sc } alt='smart contract blockchain images' className='' />
              <h2 className='text-lg text-third font_primary'>TRON Smart Contract Development</h2>
              <p className='text-[white] font_secondary'>We offer TRON smart contract development services to serve our clients in developing practical and scalable decentralized systems.</p>
            </div>
            <div className='p-8'>
              <img src={ img_3_sc } alt='smart contract blockchain images' className='' />
              <h2 className='text-lg text-third font_primary'>Hyperledger Smart Contract Development</h2>
              <p className='text-[white] font_secondary'>Our skilled developers commit their time to provide businesses with safe Hyperledger smart contract creation services tailored to their needs.</p>
            </div>
            <div className='p-8'>
              <img src={ img_4_sc } alt='smart contract blockchain images' className='' />
              <h2 className='text-lg text-third font_primary'>Binance Smart Contract Development</h2>
              <p className='text-[white] font_secondary'>We provide BSC smart contracts with a high level of automated asset management and precision for optimum use by participants looking for Binance smart contract creation services.</p>
            </div>
            <div className='p-8'>
              <img src={ img_5_sc } alt='smart contract blockchain images' className='' />
              <h2 className='text-lg text-third font_primary'>EOS Smart Contract Development</h2>
              <p className='text-[white] font_secondary'>We build smart contracts using EOS to support the development of cutting-edge dApps that can carry out their intended function following user needs.</p>
            </div>
            <div className='p-8'>
              <img src={ img_6_sc } alt='smart contract blockchain images' className='' />
              <h2 className='text-lg text-third font_primary'>Polkadot Smart Contract Development</h2>
              <p className='text-[white] font_secondary'>We create solid, smart contracts on the Polkadot Blockchain using the platform's interoperability features.</p>
            </div>
          </div>
        </div>
      </section>
      <section className='py-4 lg:py-12 px-1 xl:px-0'>
        <div className='container mx-auto pt-4 lg:pt-8'>
          <div className='w-full lg:w-[60%] mx-auto'>
            <h2 className='text-xl lg:text-3xl mt-4 lg:mt-0 font_primary capitalize text-third text-center mb-4 lg:mb-8'>Why Choose Earnestroi Solutions as Your Smart Contract Development Company?</h2>
          </div>
          <div className='container mx-auto pb-4 md:pb-6'>
            <div className='grid sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
              <div className='p-4 h-full rounded bg-[#77c1dd14] border-t-8 border-third '>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faShieldHalved } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center'>Technical Process</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center'>We exclusively work in Blockchain technologies. We just concentrate on one thing, and we excel at it.</p>
              </div>
              <div className='p-4 h-full rounded  bg-[#77c1dd14] border-t-8 border-third '>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faUsers } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center'>Expert Team</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center'>With the support of our 500 professionals, you can improve your offering, get the finest IT solution, and even create communities and campaigns.</p>
              </div>
              <div className='p-4 h-full rounded  bg-[#77c1dd14] border-t-8 border-third '>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faGears } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center'>Rapid Development</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center'>We offer goods that are especially suited to your intended consumer base. A well-organized roadmap ensures rapid and efficient development and implementation.</p>
              </div>
            </div>
          </div>
          <div className='container mx-auto pt-4 md:pt-6'>
            <div className='flex flex-col lg:flex-row lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
              <div className='p-4 h-full lg:w-[30%] xl:w-1/4 2xl:w-[30%] w-full rounded  bg-[#77c1dd14] border-t-8 border-third'>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faHeadphonesSimple } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center'>Complete Support</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center'>After the product launch, we continue to work and provide post-delivery support.</p>
              </div>
              <div className='p-4 h-full lg:w-[30%] xl:w-1/4 2xl:w-[30%] w-full rounded  bg-[#77c1dd14] border-t-8 border-third'>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faFileExport } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center'>Meaningful Outcomes</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center'>We make a real difference. We make sure your investments have value for you.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Footern />
      </section>
    </>
  )
}

export default SmartContract;