import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import popupimg from '../img/landing/earnestroi-landing-popup.jpg';

const ModalLanding = props => {

    const closeOnEscapeKeyDown = (e) => {

        if( (e.charCode || e.keyCode) === 27 ) {

            props.onClose();

        }

    }

    useEffect( () => {
        document.body.addEventListener('keydown', closeOnEscapeKeyDown)
        return function cleanup() {
            document.body.removeEventListener('keydown', closeOnEscapeKeyDown)
        }
    } )

    if( !props.show ) {
        return null
    }

  return (
    <>
        <div className='modal fade fixed left-0 right-0 top-0 bottom-0 z-50 flex items-center rounded-lg justify-center bg-[#00000080] bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 border border-gray-100' onClick={ props.onClose }>
            <div className='modal-content w-[80%] lg:w-3/4 xl:w-1/2 shadow-[5px_5px_5px_FE8067]' onClick={ e => e.stopPropagation() }>
                <button className="button absolute top-1 right-1 px-2 rounded-full bg-rose-600 bg-[white] border-2 border-[white] font_primary" onClick={ props.onClose }>X</button>
                <div className="modal-body py-8">
                    <Link to='/contactsl' className='text-xl md:text-2xl lg:text-3xl mx-auto hover:shadow-[0_0_12px_#77c1dd] text-third px-1 py-2 rounded-lg hover:text-[white] m-0 hover:border-third font_primary block w-full md:w-3/4 text-center' >
                        <img src={ popupimg } className='mx-auto block' alt='popup imagedv' />
                    </Link>
                </div>
            </div>
        </div>
    </>
  )
}

export default ModalLanding