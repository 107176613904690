import React, { useState } from "react";
import data from "../Components/Tab.json";

const TabComponent = () => {
  const [activeTab, setActiveTab] = useState(data[0].id);

  const handleTabClick = (id) => {
    setActiveTab(id);
  };

  return (
    <div>
      <section className=" lg:pt-4 xl:pt-0 px-1 sm:px-0 lg:px-1 xl:px-0">
        <div className="container mx-auto">
          <div className="md:grid md:grid-cols-4 md:gap-5 shadow-main drop-shadow-lg bottom-4">
            {data.map((button) => (
              <button
                key={button.id}
                className={`px-7 w-full mt-4 md:mt-0 py-5 mx-2 rounded-2xl font-bold bg-[#f2f2f2] border border-main border-b-8 ${
                  button.id === activeTab
                    ? "bg-[#f57f63] text-[#000] font-bold"
                    : "bg-gray-300 text-gray-700"
                }`}
                onClick={() => handleTabClick(button.id)}
              >
                {button.title}
              </button>
            ))}
          </div>
          <div className="mt-4 w-full px-1 md:px-11">
            {data.map((button) => (
              <div
                key={button.id}
                className={`py-4 col-span-4 md:col-span-1 rounded bg-[#f2f2f2] ${
                  button.id === activeTab ? "block" : "hidden"
                }`}
              >
                {button.description}
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default TabComponent;
