import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import caro_1 from '../img/icons/partners/earnestroi-digital-marketing-partner-1.png';
import caro_2 from '../img/icons/partners/earnestroi-digital-marketing-partner-2.png';
import caro_3 from '../img/icons/partners/earnestroi-digital-marketing-partner-3.png';
import caro_4 from '../img/icons/partners/earnestroi-digital-marketing-partner-4.png';
import caro_5 from '../img/icons/partners/earnestroi-digital-marketing-partner-5.png';

const PressCaro = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        autoplay: true,
        responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
              }
            },
        ]
    };

  return (
    <>
    <div className="container mx-auto px-1 xl:px-0">
        <div className='border-b-2 border-second w-full mb-4 pb-[2px] lg:pb-[8px]'>
            <span className='border-b-[4px] border-second text-3xl lg:text-5xl font_primary text-second'>Our Clients</span>
        </div>
        <div>
            <Slider {...settings}>
              <div className="">
                  <img alt='carousel_images' src={caro_1} className='mx-auto block' />
              </div>
              <div className="">
                  <img alt='carousel_images' src={caro_2} className='mx-auto block' />
              </div>
              <div className="">
                  <img alt='carousel_images' src={caro_3} className='mx-auto block' />
              </div>
              <div className="">
                  <img alt='carousel_images' src={caro_4} className='mx-auto block' />
              </div>
              <div className="">
                  <img alt='carousel_images' src={caro_5} className='mx-auto block' />
              </div>
            </Slider>
        </div>
    </div>
    </>
  )
}

export default PressCaro;