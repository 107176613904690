import React, { useEffect } from 'react'
import Aos from "aos";
import img_1 from "../img/earnestroi-bot.png";
import img_2 from "../img/earnestroi_bottradingside.png";
import "aos/dist/aos.css";
import Sectioncard from './Sectioncard';
import Footern from './Footern';
import TabComponent from "./TabComponent";
import Featuresnew from "./Featuresnew";
import FAQ from './Faq';
import { Link } from 'react-router-dom';



function CryptoTrading() {

     useEffect(() => {
       Aos.init({ duration: 3000 });
     });

  return (
    <>
      <section
        className="  xl:pt-0 xs:bg-main md:bg-bg_botbg px-1 sm:px-0 bg-main lg:px-1 xl:px-0  flex-shrink-1"
        // style={{
        //   background: `url("data:image/svg+xml,${encodeURIComponent(
        //     `<svg viewBox='0 0 1440 100' fill='none' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'><path d='M0 0H1440V55C1296.5 74.5 1016.5 98 706.5 78C521.833 65.5 430.667 22.5 353 32C309.5 36.5 259 61 172 61C85 61 49.5 36.5 0 55V0Z' fill='black'/></svg>`
        //   )}")`,
        //   backgroundRepeat: "no-repeat",
        //   backgroundPosition: "bottom",
        //   backgroundSize: "100% 100%",
        // }}
      >
        <div className="container mx-auto">
          <div className="md:flex  md:flex-col-reverse lg:flex-row justify-around items-center xl:pt-20">
            <div className="flex flex-col w-full lg:w-5/12 px-4 ">
              <h1
                className="text-second text-2xl md:text-2xl xl:text-3xl  mb-4 mt-4 lg:mt-0 font_primary capitalize"
                data-aos="fade-right"
              >
                "Trade Like a Pro: Your Custom Bot Unveiled!"
              </h1>
              <br />
              <div className="border-l-4  border-[#82c2df]">
                <p
                  className="text-[white] xl:text-md font_primary capitalize pl-8 mb-4 text-justify"
                  data-aos="fade-left"
                >
                  Discover the Future of Trading with Earnestroi, Your Premier
                  Cryptocurrency Trading Bot Company! Are you looking to
                  maximize your profits and minimize risks in the dynamic world
                  of cryptocurrencies? Look no further than Earnestroi, the
                  leading cryptocurrency trading bot company. Powered by
                  cutting-edge AI technology, our user-friendly solutions cater
                  to both newbies and seasoned traders, ensuring everyone can
                  harness the full potential of crypto trading.
                </p>
                <Link
                  to="/contacts-1"
                  className="animate-topbtn text-center ml-2 sm:ml-6 text-[white] bg-second  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-[12px] sm:text-sm md:text-base px-2 py-1 sm:px-4 sm:py-2 md:px-5 md:py-2.5 mr-1 md:mr-2 focus:outline-none dark:focus:ring-blue-800"
                >
                  Get Demo
                </Link>
              </div>
              <br />
            </div>
            <div className=" w-full sm:w-1/3 lg:w-1/2">
              <img src={img_1} alt="Earnestroi" />
            </div>
          </div>
        </div>
      </section>

      <section className="mt-[3.5rem] lg:pt-4 xl:pt-0  px-1 sm:px-0  lg:px-1 xl:px-0 ">
        <div className="container mx-auto">
          <div className=" items-center w-full text-center">
            <div className="flex justify-center  w-full">
              <h2
                className="text-second text-2xl md:text-2xl  xl:text-4xl xl:w-1/2 mb-4 mt-4 pb-2 lg:mt-0 font_primary capitalize border-b-4 border-second border-gray-400 border-solid "
               
              >
                Cryptocurrency Trading <br />
                <span className="text-main">Bot Development Company</span>
              </h2>
            </div>
            <div className="w-full flex justify-center">
              <p className="md:w-3/4 md:text-md xl:text-lg  text-sm text-justify md:justify-center">
                Earnestroi stands out as the foremost Cryptocurrency trading bot
                development company, backed by a team of skilled blockchain
                developers who can successfully bring your dream project to
                life. With a proven track record, we specialize in crafting
                cryptocurrency trading bots with all the required features and
                functionalities. Our blockchain experts boast extensive
                experience in the crypto markets, enabling them to tackle any
                challenges that may arise during development adeptly.
              </p>
            </div>
            <br />
            <div className="w-full flex justify-center">
              <p className="md:w-3/4 md:text-md xl:text-lg text-sm text-justify md:justify-center ">
                Our commitment to excellence extends to creating a robust admin
                panel with deployable codes and intelligent functionalities.
                Additionally, we excel in providing arbitrage crypto trading bot
                development services that handle risks automatically and
                meticulously. Trust Earnestroi to deliver cutting-edge solutions
                that elevate your trading experience.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-[4.5rem]  lg:pt-4 xl:pt-0  px-1 sm:px-0  lg:px-1 xl:px-0">
        <div className="container mx-auto">
          <div className="items-center w-full text-center">
            <div className="flex justify-center  w-full ">
              <h2 className="text-main text-2xl  xl:text-4xl  mb-4 mt-5 pb-2 lg:mt-0 font_primary capitalize border-b-4 border-second border-gray-400 border-solid ">
                Our trading bot development services include
              </h2>
            </div>
          </div>
          <Sectioncard />
        </div>
      </section>

      <section className="mt-[3.5rem] lg:pt-4 xl:pt-0  px-1 sm:px-0  lg:px-1 xl:px-0">
        <div className="container mx-auto">
          <div className="items-center w-full text-center">
            <div className="flex justify-center  w-full ">
              <h2 className="text-main text-2xl  lg:text-4x1 xl:text-4xl  mb-4 mt-4 pb-2 lg:mt-0 font_primary capitalize border-b-4 border-second border-gray-400 border-solid ">
                Benefits of Crypto Trading Bot Development
              </h2>
            </div>
            <section className="mt-[3.5rem] lg:pt-4 xl:pt-0  px-1 sm:px-0  lg:px-1 xl:px-0">
              <div className="md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-4">
                <div className="w-full xl:h-44 lg:w-[95%] bg-[#f4f4f4] rounded-2xl">
                  <div className="container">
                    <h1 className="border-b-4 text-lg xl:text-2xl font-bold border-second border-gray-400 border-solid px-5 py-2">
                      Speed and Efficiency:
                    </h1>
                  </div>
                  <p className="text-justify text-sm xl:text-md py-3 px-5">
                    A well-developed crypto trading bot offers unmatched speed
                    and efficiency, swiftly scanning multiple exchanges,
                    executing trades instantly, and capitalizing on profitable
                    opportunities that manual trading might miss.
                  </p>
                </div>
                <div className="w-full xl:h-44 md:mt-0 mt-4 lg:w-[95%]  bg-[#f4f4f4] rounded-2xl">
                  <div className="container">
                    <h1 className="border-b-4 text-lg xl:text-2xl font-bold border-second border-gray-400 border-solid px-5 py-2">
                      Risk Management:
                    </h1>
                  </div>
                  <p className="text-justify py-3 px-5 text-sm xl:text-md">
                    A successful crypto trading bot includes risk management
                    features like stop-loss and take-profit orders, limiting
                    potential losses, and securing profits at desired levels.
                    This helps you trade with confidence and reduce exposure to
                    market volatility.
                  </p>
                </div>
                <div className="w-full xl:h-44 md:mt-0 mt-4 lg:w-[95%]  bg-[#f4f4f4] rounded-2xl">
                  <div className="container">
                    <h1 className="border-b-4 text-lg xl:text-2xl font-bold border-second border-gray-400 border-solid px-5 py-2">
                      24/7 Trading:
                    </h1>
                  </div>
                  <p className="text-justify py-3 px-5 text-sm xl:text-md">
                    Cryptocurrency markets operate 24/7, making it challenging
                    for human traders to monitor them constantly. By employing a
                    trading bot, you ensure round-the-clock monitoring and the
                    ability to seize profitable trades anytime, even during your
                    off-hours.
                  </p>
                </div>
                <div className="w-full xl:h-44 md:mt-0 mt-4 lg:w-[95%]  bg-[#f4f4f4] rounded-2xl">
                  <div className="container">
                    <h1 className="border-b-4 text-lg xl:text-2xl font-bold border-second border-gray-400 border-solid px-5 py-2">
                      Emotionless Decisions:
                    </h1>
                  </div>
                  <p className="text-justify py-3 px-5 text-sm xl:text-md">
                    Crypto trading bots eliminate emotions from trading
                    decisions, relying on predefined parameters and algorithms.
                    This data-driven approach helps you avoid emotional pitfalls
                    and stick to a disciplined trading strategy.
                  </p>
                </div>
                <div className="w-full xl:h-44 md:mt-0 mt-4 lg:w-[95%]  bg-[#f4f4f4] rounded-2xl">
                  <div className="container">
                    <h1 className="border-b-4 text-lg xl:text-2xl font-bold border-second border-gray-400 border-solid px-5 py-2">
                      Quick Profits:
                    </h1>
                  </div>
                  <p className="text-justify py-3 px-5 text-sm xl:text-md">
                    Crypto trading often involves making quick profits by
                    exploiting price discrepancies or executing short-term
                    trades. A proficient trading bot executes rapid trades,
                    accumulating small profits over time for a significant
                    overall return.
                  </p>
                </div>
                <div className="w-full xl:h-44 md:mt-0 mt-4 lg:w-[95%]  bg-[#f4f4f4] rounded-2xl">
                  <div className="container">
                    <h1 className="border-b-4 text-lg xl:text-2xl font-bold border-second border-gray-400 border-solid px-5 py-2">
                      Market Data Analysis:
                    </h1>
                  </div>
                  <p className="text-justify py-3 px-5 text-sm xl:text-md">
                    Crypto trading bots excel in analyzing vast market data
                    using advanced algorithms and real-time analysis. They
                    identify profitable trades efficiently, which can be
                    challenging for human traders manually.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

      <section className="mt-[3.5rem] lg:pt-4 xl:pt-0  px-1 sm:px-0  lg:px-1 xl:px-0">
        {/* <div className="container mx-auto"> */}
        <div className="items-center w-full text-center">
          <div className="flex justify-center  w-full ">
            <h2 className="text-main text-2xl md:text-3xl xl:text-4xl  mb-4 mt-4 pb-2 lg:mt-0 font_primary capitalize border-b-4 border-second border-gray-400 border-solid ">
              Features of Our Cryptocurrency Trading Bot Development
            </h2>
          </div>
          <div className="w-full  bg-bg_bottrading">
            <section className="mt-[3.5rem] lg:pt-4 xl:pt-0  px-1 sm:px-0  lg:px-1 xl:px-0">
              {/* <div className="container mx-auto"> */}
              <div className="items-center w-full text-center">
                <div className="container mx-auto">
                  <div className="md:flex  md:flex-col-reverse lg:flex-row justify-around items-center py-10">
                    <div className="flex flex-col w-full lg:w-5/12 px-4 ">
                      {/* <p className='border-2 border-[white] text-[white]'>jgjjghjgh</p> */}
                      {/* <Features /> */}
                      <Featuresnew />
                    </div>
                    <div className=" w-full md:w-80 py-4">
                      <img src={img_2} alt="Earnestroi" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* </div> */}
      </section>

      <section className="mt-[3.5rem] lg:pt-4 xl:pt-0  px-1 sm:px-0  lg:px-1 xl:px-0 flex items-center justify-center">
        <div className="container mx-auto">
          <div className="items-center w-full text-center">
            <div className="flex justify-center  w-full ">
              <h2 className="text-main text-2xl md:text-2xl xl:text-4xl lg:w-1/2  mb-4 mt-4 pb-2 lg:mt-0 font_primary capitalize border-b-4 border-second border-gray-400 border-solid ">
                Our Cryptocurrency Trading Bot Development Process
              </h2>
            </div>
            <section className="mt-[3.5rem] lg:pt-4 xl:pt-0  px-1 sm:px-0  lg:px-1 xl:px-0 ">
              <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-5 border-dashed items-center justify-items-center  justify-center">
                {/* <div className="connecting-line left-[50%] w-px h-[50%] top-[50%] -translate-y-1/2"></div>
                <div className="connecting-line top-[50%] w-[50%] h-px left-[50%] -translate-x-1/2"></div> */}
                <div className="w-[285px] h-[285px] xl:w-[350px] xl:h-[350px] flex items-center justify-center mt-2  bg-[#414141] rounded-full">
                  <div className="flex items-center flex-col justify-center w-[270px] h-[270px] xl:w-[330px] xl:h-[330px] rounded-full border-dashed border-2 border-second ">
                    <div>
                      <h5 className="xl:text-2xl text-second font-bold px-5 py-2">
                        Requirement Gathering:
                      </h5>
                      <p className="text-sm text-center text-[white] py-3 px-5">
                        Initially, we engage in in-depth client discussions to
                        grasp their unique trading requirements, strategies,
                        risk preferences, and bot features.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-[285px] h-[285px] xl:w-[350px] xl:h-[350px] flex items-center justify-center mt-2  bg-[#414141] rounded-full">
                  <div className="flex items-center flex-col justify-center w-[270px] h-[270px] xl:w-[330px] xl:h-[330px] rounded-full border-dashed border-2 border-[white] p-1">
                    <div>
                      <h5 className="xl:text-2xl text-second font-bold   px-5 py-2">
                        Planning and Strategy Design:
                      </h5>
                      <p className="text-sm text-center text-[white] py-3 px-5">
                        Our expert team creates a thorough plan for the trading
                        bot, outlining functions, algorithms, risk management,
                        and key elements.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-[285px] h-[285px] xl:w-[350px] xl:h-[350px] flex items-center justify-center mt-2  bg-[#414141] rounded-full">
                  <div className="flex items-center flex-col justify-center w-[270px] h-[270px] xl:w-[330px] xl:h-[330px] rounded-full border-dashed border-2 border-second p-2">
                    <div>
                      <h5 className="xl:text-2xl text-second font-bold   px-5 py-2">
                        Development and Testing:
                      </h5>
                      <p className="text-sm text-center text-[white] py-3 px-5">
                        Coding starts as developers implement strategies, risk
                        protocols, and real-time analysis. Rigorous testing
                        follows to fix bugs and ensure smooth operation.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-[285px] h-[285px] xl:w-[350px] xl:h-[350px] flex items-center justify-center mt-2  bg-[#414141] rounded-full">
                  <div className="flex items-center flex-col justify-center w-[270px] h-[270px] xl:w-[330px] xl:h-[330px] rounded-full border-dashed border-2 border-[white] p-2">
                    <div>
                      <h5 className="xl:text-2xl text-second font-bold   px-5 py-2">
                        Backtesting and Optimization:
                      </h5>
                      <p className="text-sm text-center text-[white] py-3 px-5">
                        We extensively backtest with historical data to optimize
                        bot performance, refine parameters, and ensure
                        profitability across various market scenarios.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-[285px] h-[285px] xl:w-[350px] xl:h-[350px] flex items-center justify-center mt-2  bg-[#414141] rounded-full">
                  <div className="flex items-center flex-col justify-center w-[270px] h-[270px] xl:w-[330px] xl:h-[330px] rounded-full border-dashed border-2 border-second p-2">
                    <div>
                      <h5 className="xl:text-2xl text-second font-bold   px-5 py-2">
                        Security and Visual Design:
                      </h5>
                      <p className="text-sm text-center text-[white] py-3 px-5">
                        We emphasize security with strong safeguards for funds
                        and data. Our user-friendly interface, crafted by the
                        design team, enables easy client performance monitoring
                        and management.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-[285px] h-[285px] xl:w-[350px] xl:h-[350px] flex items-center justify-center mt-2  bg-[#414141] rounded-full">
                  <div className="flex items-center flex-col justify-center w-[270px] h-[270px] xl:w-[330px] xl:h-[330px] rounded-full border-dashed border-2 border-[white] p-2">
                    <div>
                      <h5 className="xl:text-2xl text-second font-bold   px-5 py-2">
                        Deployment and Support:
                      </h5>
                      <p className="text-sm text-center text-[white] py-3 px-5">
                        Post successful testing, we launch the bot on the chosen
                        platform. Our support team offers ongoing assistance for
                        queries, concerns, and technical issues during live
                        trading.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="connecting-line left-[50%] w-px h-[50%] top-[50%] -translate-y-1/2"></div>
            <div className="connecting-line top-[50%] w-[50%] h-px left-[50%] -translate-x-1/2"></div>
            <div></div>
          </div>
        </div>
      </section>

      <section className="mt-[3.5rem] lg:pt-4 xl:pt-0  px-1 sm:px-0  lg:px-1 xl:px-0">
        <div className="container mx-auto">
          <div className="items-center w-full text-center">
            <div className="flex justify-center  w-full ">
              <h2 className="text-main text-2xl md:text-3xl xl:text-4xl lg:w-1/2  mb-4 mt-4 pb-2 lg:mt-0 font_primary capitalize border-b-4 border-second border-gray-400 border-solid ">
                What Makes Us the Top Crypto Trading Bot Development Company
              </h2>
            </div>
            <TabComponent />
          </div>
        </div>
      </section>

      <section className="mt-[3.5rem] lg:pt-4 xl:pt-0  px-1 sm:px-0  lg:px-1 xl:px-0 flex items-center justify-center w-100 xl  :h-[600px] bg-main">
        <div className="container mx-auto">
          <div className="items-center w-full text-center  py-20">
            <div className="container mx-auto">
              <div className="xl:flex  lg:flex-col-reverse xl:flex-row justify-around items-center xl:py-15 ">
                <div className="flex flex-col w-full xl:w-5/12 px-4 mt-4 border-2 border-[white] p-10 rounded-3xl">
                  <h1
                    className="text-[white] text-2xl md:text-2xl xl:text-3xl mb-4 mt-4 lg:mt-0 font_primary text-center xl:text-left"
                    data-aos="fade-right"
                  >
                    Create Your Trading Bot and Make Trading Automated.
                  </h1>
                  <br />
                  <div>
                    <Link
                      to="/contacts-1"
                      className="text-base my-2 text-[white] border-2 bg-second text-left px-4 py-3 rounded-lg hover:bg-main hover:shadow-[0_0_12px_#FE8067] hover:text-second m-0 hover:border-second font_primary"
                    >
                      GET A DEMO
                    </Link>
                  </div>
                  <br />
                </div>
                <div className=" w-full xl:w-1/2 py-7 px-0">
                  <FAQ />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footern />
    </>
  );
}

export default CryptoTrading;