import React, { useEffect } from 'react';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import send from '../img/whatsapp.svg';

const WhatsappChat = () => {

    useEffect( () => {
  
        $('#whats-chat').click(showchatbox);
        $('#chat-top-right').click(closechatbox);
        $('#send-btn').click(sendmsg);
        showchatboxtime();
      
      }, [] );
    
      function showchatbox() {
        document.getElementById('chat-box').style.right = '8%'
    }
    
    function closechatbox() {
        document.getElementById('chat-box').style.right = '-500px'
    }
    
    function showchatboxtime() {
        // setTimeout(launchbox, 5000)
    }
    
    function sendmsg() {
        var msg = document.getElementById('whats-in').value;
        var relmsg = msg.replace(/ /g, "%20");
        window.open('https://api.whatsapp.com/send?phone=971523031866&text=' + relmsg, '_blank');
    }

  return (
    <>
        <div id='chat-box' className='fixed right-[-500px] bottom-[13%] w-[250px] h-[200px] transition-all duration-500 z-20'>
          <div id='chat-top' className='w-full leading-[2] bg-[#128C7E] text-[white] text-center rounded-t-xl px-2' >Need HELP? 
            <span id='chat-top-right' className='float-right px-0 py-[5px]'>
              <svg id='close-box' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 48 48">
                <path d="M38 12.83L35.17 10 24 21.17 12.83 10 10 12.83 21.17 24 10 35.17 12.83 38 24 26.83 35.17 38 38 35.17 26.83 24z" fill='#fff'/>
              </svg>
            </span>
            <div className='clear clear-both'></div>
          </div>
          <div id='chat-msg' className='bg-[#ece5dd] p-2 rounded-b-xl' style={{ boxShadow: '0 0 25px -10px #999' }}>
            <p className='text-[14px] p-[5px] bg-[white] rounded-xl rounded-tl-none mb-2 text-main'>Welcome to Earnestroi Support</p>
            <div id='chat-form' className='flex'>
              <div className='chat-in w-3/4'>
                <input className='rounded-lg border-none outline-none text-[14px] p-[5px] leading-[2] h-8 mt-2' type='text' id='whats-in' placeholder='Send Your Message...' />
              </div>
              <div id='send-btn' className='w-1/4 py-2 px-[5px] cursor-pointer ml-2'>
                <img src={ send } alt='whats app icon' />
              </div>
            </div>
          </div>
        </div>
        <div id='whats-chat' className='fixed right-[3%] bottom-[10%] p-4 bg-[#25D366] py-[0.7rem] px-[0.9rem] rounded-full z-20 hover:cursor-pointer'>
          <FontAwesomeIcon icon={ faWhatsapp } className='text-5xl text-[white]' />
        </div>
    </>
  )
}

export default WhatsappChat;