import React from 'react'
import img1 from "../img/bot/advance-trading-bot.webp";
import img2 from "../img/bot/Customizable-Strategies.webp";
import img3 from "../img/bot/Risk-Management-Features.webp";
import img4 from "../img/bot/Real-Time-Market-Analysis.webp";
import img5 from "../img/bot/Backtesting-Capabilities.webp";
import img6 from "../img/bot/User-Friendly-Interface.webp";


function Sectioncard() {
  return (
    <>
      <div>
        <section className="mt-7 lg:pt-4 xl:pt-0  px-4 sm:px-0  lg:px-1 xl:px-0 ">
          <div className="container md:mx-4">
            <div className="md:grid xl:grid-cols-3 md:grid-cols-2 md:gap-5">
              <div className="">
                <div className="bg-main md:w-[95%]  z-10">
                  <div
                    className=" hidden  
                    bg-main absolute -m-6 z-10 w-24 h-24 border-2 px-7 border-[white] rounded-full items-center md:flex"
                  >
                    <img src={img1} alt="advanced_tading"  />
                  </div>

                  <h1 className="text-[white] justify-end py-3 px-3 text-center ">
                    Advanced Trading Bots:
                  </h1>
                </div>
                <div className=" py-1 flex justify-around w-[98%] md:w-[90%] md:h-48  bg-second rounded-br-3xl rounded-bl-3xl mb-14">
                  <p className="md:w-4/5 ml-8  md:text-md">
                    Transform your trading with AI-driven bots. Evolving in
                    real-time from market data, they execute lightning-fast,
                    precise trades. Take prime opportunities, stay ahead, and
                    conquer the market like never before.
                  </p>
                </div>
              </div>{" "}
              <div className="">
                <div className="bg-main md:w-[95%] z-10">
                  <div
                    className=" hidden md:flex 
                    bg-main absolute -m-6 z-10 w-24 h-24 border-2 px-7 border-[white] rounded-full items-center"
                  >
                    <img src={img2} alt="advanced_tading"  />
                  </div>

                  <h1 className="text-[white] justify-end py-3 px-3 text-center">
                    Customizable Strategies:
                  </h1>
                </div>
                <div className=" py-1  flex justify-around w-[98%] md:w-[90%] md:h-48 bg-second rounded-br-3xl rounded-bl-3xl mb-14">
                  <p className="md:w-4/5 ml-8  md:text-md">
                    At Earnestroi, we understand your individual trading needs.
                    Customize our bots to match your risk appetite – cautious,
                    balanced, or bold. Take command of your trading future with
                    strategies tailored exclusively to your style.
                  </p>
                </div>
              </div>{" "}
              <div className="">
                <div className="bg-main md:w-[95%]  z-10">
                  <div
                    className=" hidden md:flex 
                    bg-main absolute -m-6 z-10 w-24 h-24 border-2 px-7 border-[white] rounded-full items-center "
                  >
                    <img src={img3} alt="advanced_tading"  />
                  </div>

                  <h1 className="text-[white] justify-end py-3 px-3 text-center">
                    Risk Management Features:
                  </h1>
                </div>
                <div className=" py-1 flex justify-around w-[98%] md:w-[90%] md:h-48 bg-second rounded-br-3xl rounded-bl-3xl mb-14">
                  <p className="md:w-4/5 ml-8  md:text-md">
                    Stay informed, and trade smart. Our real-time analysis bots
                    monitor markets 24/7, spotting entry and exit chances.
                    Empower your decisions with timely, accurate insights for
                    confident trading.
                  </p>
                </div>
              </div>{" "}
              <div className="">
                <div className="bg-main md:w-[95%]  z-10">
                  <div
                    className=" hidden md:flex 
                    bg-main absolute -m-6 z-10 w-24 h-24 border-2 px-7 border-[white] rounded-full items-center"
                  >
                    <img src={img4} alt="advanced_tading"  />
                  </div>

                  <h1 className="text-[white] justify-end py-3 px-3 text-center">
                    Real-Time Market Analysis:
                  </h1>
                </div>
                <div className=" py-1 flex justify-around w-[98%] md:w-[90%] md:h-48 bg-second rounded-br-3xl rounded-bl-3xl mb-14">
                  <p className="md:w-4/5 ml-8  md:text-md">
                    Stay in the know and trade smart with our real-time market
                    analysis. Our bots track market shifts, news, and indicators
                    24/7, pinpointing prime entry and exit opportunities.
                    Empower your trading decisions with timely, accurate
                    insights for confident and precise trading.
                  </p>
                </div>
              </div>{" "}
              <div className="">
                <div className="bg-main md:w-[95%]  z-10">
                  <div
                    className=" hidden md:flex 
                    bg-main absolute -m-6 z-10 w-24 h-24 border-2 px-7 border-[white] rounded-full items-center"
                  >
                    <img src={img5} alt="advanced_tading"  />
                  </div>
                  <h1 className="text-[white] justify-end py-3 px-3 text-center">
                    Backtesting Capabilities:
                  </h1>
                </div>
                <div className=" py-15 pl-4 flex justify-around w-[98%] md:w-[90%] md:h-48 bg-second rounded-br-3xl rounded-bl-3xl mb-14">
                  <p className="md:w-4/5 ml-8  md:text-md">
                    Explore past strategy performance with our bots' historical
                    backtesting. Evaluate your approach to pre-live trading,
                    gaining vital insights into strategy success. Make informed
                    choices backed by proven outcomes.
                  </p>
                </div>
              </div>
              <div className="">
                <div className="bg-main md:w-[95%]  z-10">
                  <div
                    className=" hidden md:flex 
                    bg-main absolute -m-6 z-10 w-24 h-24 border-2 px-7 border-[white] rounded-full items-center"
                  >
                    <img src={img6} alt="advanced_tading"  />
                  </div>

                  <h1 className="text-[white] justify-end py-3 px-3 text-center">
                    User-Friendly Interface:
                  </h1>
                </div>
                <div className=" py-1 flex justify-around w-[98%] md:w-[90%] md:h-48 bg-second rounded-br-3xl rounded-bl-3xl mb-14">
                  <p className="md:w-4/5 ml-8  md:text-md">
                    Our user-friendly bots cater to all traders, regardless of
                    tech expertise. Seamlessly harness their full potential
                    whether you're a beginner or a pro, ensuring a smooth
                    trading journey for everyone.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Sectioncard