import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = (props) => {
  const { Component } = props;
  let login = localStorage.getItem('token');

  const navigate = useNavigate();
  useEffect(() => {
    let login = localStorage.getItem('token');
    if (!login) {
      navigate('/signin');
    }
  })
  return (
    <>
    {login? <Component />: navigate('/')}
     
    </>
  )
}

export default ProtectedRoute;