import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import seo_1 from '../img/earnestroi-web-development-one.png';
import web_two from '../img/earnestroi-web-development-two.png';
import Aos from 'aos';
import 'aos/dist/aos.css';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import Footern from './Footern';
import { Helmet } from 'react-helmet';
// import WhatsappChat from './WhatsappChat';

const WebDevelopment = () => {

  useEffect( () => {

    Aos.init({

      disable: function() {
        var maxWidth = 1023;
        return window.innerWidth < maxWidth;
      }

    });

    $(document).ready(function(){
        $(this).scrollTop(0);
    });

  } );

  useEffect( () => {

    Aos.init({ duration: 3000 });

  } )

  return (
    <>
      <Helmet>
        <title>Earnestroi Technology | Web Development | Web Site Design Service</title>
        <meta name="title" content="Earnestroi Technology | Web Development | Web Site Design Service" />
        <meta name="description" content="We are a company dedicated to providing the highest quality of website design services.Our main goal is to help you get the best possible professional website." />
        <meta name="keywords" content='web development, html, software development, developers, digital marketing agency' />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
      </Helmet>
      <section className=' py-10'>
        <div className='container mx-auto'>
          <div className='flex flex-col-reverse lg:flex-row justify-center items-center'>
            <h1 className='text-second text-5xl lg:text-6xl mb-4 mt-4 lg:mt-0 capitalize text-center font_primary' data-aos="fade-right">Web Development<br /> Services</h1>
            <img src={ web_two } className='' alt='Web Development Service ' />
          </div>
        </div>
        <div className='container mx-auto'>
          <div className='flex justify-center py-4 lg:py-10'>
            <Link to='/'><h2 className='mx-2 text-second font_primary'><FontAwesomeIcon icon={ faHome } className='mx-2 text-second' />Digital Marketing Agency</h2></Link><h2 className='mx-2'>/</h2><h2 className='mx-2 text-second font_primary'>Web Development Services</h2>
          </div>
        </div>
      </section>
      <section>
        <div className='container mx-auto py-4 lg:py-16'>
          <div className='flex flex-col lg:flex-row justify-center items-center px-4 lg:px-0'>
            <div className='container mx-auto my-12 lg:my-16 border-l-2 border-second w-full lg:w-1/2 py-6'>
              <h2 className='text-base sm:text-3xl w-3/4 border-l-4 border-second pl-8 font_secondary' data-aos="fade-up">Do you have thoughts about website developers? For great work and timely delivery of results, you can always rely on Earnestroi.</h2>
              <br />
              <p className='pl-8 w-3/4 font_secondary'>We are the best website design business that offers transparency and uncompromised quality.</p>
            </div>
            <div className='w-full lg:w-1/2 lg:pr-8'>
              <p className='mb-6 text-base sm:text-lg font_secondary'>We design websites that have the potential to advance your company. Our methodical approach to website development includes a foundation that allows us to finish the tasks we set out to do.</p>
              <p className='mb-6 text-base sm:text-lg font_secondary'>Additionally, we concentrate on factors that can provide your online business a competitive edge in the relevant niche. Our team of developers has constructive conversations with clients to learn about their requirements prior to constructing websites.</p>
              <p className='mb-6 text-base sm:text-lg font_secondary'>Additionally, our developers illustrate the potential and range of their value-added tasks. Additionally, these experts talk about technical elements of website construction in plain language that is accessible to everyone. </p>
              <p className='mb-6 text-base sm:text-lg font_secondary'>As a result, we are the best website design business that offers transparency and uncompromised quality.</p>
            </div>
          </div>
        </div>
      </section>
      <section className='bg-main py-4 lg:py-20'>
        <div className='container mx-auto'>
          <div className='grid-cols-2 flex flex-col lg:flex-row justify-around items-center'>
            <div className='px-2 w-full lg:w-[40%]'>
              <div className='border-b-2 pb-[2px] border-second w-full mb-4'>
                <p className='text-3xl text-[white] font_primary'><span className='border-b-0 lg:border-b-0 xl:border-b-4 sm:border-b-4 border-second text-[white]'>Web design</span> and development</p>
              </div>
              <p className='text-base sm:text-lg mb-4 text-[white] font_secondary' data-aos="fade-left">Before creating or designing any website, we always follow a precise set of steps. It is crucial since it is impossible to get the greatest results without a predetermined framework of work. As we carry out the procedures in accordance with the established plan, Earnestroi is the best web development.</p>
              <p className='text-base sm:text-lg mb-4 text-[white] font_secondary' data-aos="fade-left">Analysis of competitors, customers, and targeted markets of our clients</p>
              <p className='text-base sm:text-lg mb-4 text-[white] font_secondary' data-aos="fade-left">Careful consideration of SEO Services components at every stage of web designing and development </p>
              <p className='text-base sm:text-lg mb-4 text-[white] font_secondary' data-aos="fade-left">Adding Call-to-Actions to maximise conversions </p>
              <p className='text-base sm:text-lg mb-4 text-[white] font_secondary' data-aos="fade-left">Project delivery within the timeframe we have set.</p>
            </div>
            <div className='px-2 w-full lg:w-[50%]'>
              <img src={ seo_1 } className='block mx-auto' alt='Web Design ' />
            </div>
          </div>
        </div>
      </section>
      <Footern />
    </>
  )
}

export default WebDevelopment;