import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MenuItems from '../Components/MenuItems';
import { menuItems } from '../menuItems';
import logo_m from '../img/earnestroi-logo.png';
import logo_s from '../img/earnestroi-logo-60x60.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  
    const [navbarOpen, setNavbarOpen] = useState(false);

     const handleMenuItemClick = () => {
       setNavbarOpen(false); // Close the navbar after a menu item is clicked
     };


    return (
      <>
        <nav className="flex flex-wrap items-center justify-between px-2 lg:px-0 py-3 bg-cyan-500 w-full mb-0  top-0 bg-main z-50 shadow-[0_0_6px_#fe8067]">
          <div className="container px-4 lg:px-0 mx-auto flex flex-wrap items-center justify-between">
            <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
              <Link
                to="/"
                className="text-[white] flex justify-center items-center"
              >
                <img
                  src={logo_m}
                  alt="logo_main"
                  className="w-3/4 lg:hidden xl:block"
                />
                <img
                  src={logo_s}
                  alt="logo_main"
                  className="w-3/4 hidden lg:block xl:hidden"
                />
              </Link>
              <button
                className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <FontAwesomeIcon className="text-[white]" icon={faBars} />
              </button>
            </div>
            <div
              className={
                "lg:flex flex-grow items-center flex-col lg:flex-row" +
                (navbarOpen ? " flex" : " hidden")
              }
              id="example-navbar-danger"
            >
              <Link
                to="/we-are-hiring"
                rel="noreferrer noopener"
                className="mr-auto lg:mx-auto animate-blinker text-third font_primary opacity-0"
              >
                We're Hiring
              </Link>
              <ul className="flex flex-col lg:flex-row list-none lg:ml-auto w-full lg:w-auto text-end">
                {menuItems.map((menu, index) => {
                  const depthLevel = 0;
                  return (
                    <MenuItems
                      style={{ marginLeft: "auto" }}
                      items={menu}
                      key={index}
                      depthLevel={depthLevel}
                      onClick={handleMenuItemClick}
                    />
                  );
                })}
              </ul>
              <Link
                to="/contacts-1"
                className="text-base mr-auto lg:mx-auto hover:shadow-[0_0_12px_#FE8067] text-[white] border-2 bg-second px-2 py-1 rounded-lg hover:bg-[black] hover:text-second m-0 hover:border-second font_primary"
              >
                Lets Talk
              </Link>
            </div>
          </div>
        </nav>
      </>
    );

}

export default Navbar