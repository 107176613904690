import img_1_s from '../img/blockchain-icons/earnestroi-blockchain-network-1.png';
import img_2_s from '../img/blockchain-icons/earnestroi-blockchain-network-2.png';
import img_3_s from '../img/blockchain-icons/earnestroi-blockchain-network-3.png';
import img_4_s from '../img/blockchain-icons/earnestroi-blockchain-network-4.png';
import img_5_s from '../img/blockchain-icons/earnestroi-blockchain-network-5.png';
import img_6_s from '../img/blockchain-icons/earnestroi-blockchain-network-6.png';
import img_7_s from '../img/blockchain-icons/earnestroi-blockchain-network-7.png';
import img_8_s from '../img/blockchain-icons/earnestroi-blockchain-network-8.png';
import img_9_s from '../img/blockchain-icons/earnestroi-blockchain-network-9.png';
import img_10_s from '../img/blockchain-icons/earnestroi-blockchain-network-10.png';
import img_11_s from '../img/blockchain-icons/earnestroi-blockchain-network-11.png';
import img_12_s from '../img/blockchain-icons/earnestroi-blockchain-network-12.png';
import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const BlockchainNetworks = () => {
  
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        autoplay: true,
    };

  return (
    <>
    <div className="container mx-auto">
        <div className='border-b-2 border-third w-full mb-4 pb-[2px] lg:pb-[8px]'>
            <span className='border-b-[4px] border-third text-3xl lg:text-5xl font_primary text-third'>Blockchain</span><span className='text-3xl lg:text-5xl font_primary text-third'> Networks</span>
        </div>
        <div>
            <Slider {...settings}>
                <div className="">
                    <img alt='carousel_images' src={ img_1_s } className='mx-auto block' />
                    <p className='font_secondary text-base text-center text-[12px] sm:text-base text-[white] font_primary'>Avalanche</p>
                </div>
                <div className="">
                    <img alt='carousel_images' src={ img_2_s } className='mx-auto block' />
                    <p className='font_secondary text-base text-center text-[12px] sm:text-base text-[white] font_primary'>Bitcoin</p>
                </div>
                <div className="">
                    <img alt='carousel_images' src={ img_3_s } className='mx-auto block' />
                    <p className='font_secondary text-base text-center text-[12px] sm:text-base text-[white] font_primary'>BscScan</p>
                </div>
                <div className="">
                    <img alt='carousel_images' src={ img_4_s } className='mx-auto block' />
                    <p className='font_secondary text-base text-center text-[12px] sm:text-base text-[white] font_primary'>Ethereum</p>
                </div>
                <div className="">
                    <img alt='carousel_images' src={ img_5_s } className='mx-auto block' />
                    <p className='font_secondary text-base text-center text-[12px] sm:text-base text-[white] font_primary'>Harmony</p>
                </div>
                <div className="">
                    <img alt='carousel_images' src={ img_6_s } className='mx-auto block' />
                    <p className='font_secondary text-base text-center text-[12px] sm:text-base text-[white] font_primary'>NEAR</p>
                </div>
                <div className="">
                    <img alt='carousel_images' src={ img_7_s } className='mx-auto block' />
                    <p className='font_secondary text-base text-center text-[12px] sm:text-base text-[white] font_primary'>Polygon</p>
                </div>
                <div className="">
                    <img alt='carousel_images' src={ img_8_s } className='mx-auto block' />
                    <p className='font_secondary text-base text-center text-[12px] sm:text-base text-[white] font_primary'>Cardano</p>
                </div>
                <div className="">
                    <img alt='carousel_images' src={ img_9_s } className='mx-auto block' />
                    <p className='font_secondary text-base text-center text-[12px] sm:text-base text-[white] font_primary'>Solana</p>
                </div>
                <div className="">
                    <img alt='carousel_images' src={ img_10_s } className='mx-auto block' />
                    <p className='font_secondary text-base text-center text-[12px] sm:text-base text-[white] font_primary'>Tezos</p>
                </div>
                <div className="">
                    <img alt='carousel_images' src={ img_11_s } className='mx-auto block' />
                    <p className='font_secondary text-base text-center text-[12px] sm:text-base text-[white] font_primary'>Polkadot</p>
                </div>
                <div className="">
                    <img alt='carousel_images' src={ img_12_s } className='mx-auto block' />
                    <p className='font_secondary text-base text-center text-[12px] sm:text-base text-[white] font_primary'>Tron</p>
                </div>
            </Slider>
        </div>
    </div>
    </>
  )

}

export default BlockchainNetworks;