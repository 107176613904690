import React, { useEffect } from 'react';
import corn from '../img/ninepages/earnestroi-nft-token-development-services.png';
import img_3 from '../img/ninepages/earnestroi-Why-Consider-hiring-a-Dedicated.jpg';
import img_3s from '../img/ninepages/earnestroi-NFT-Development-Decentralized-at-Your-Service.jpg';
import img_ico_1 from '../img/blockchain-icons/earnestroi-blockchain-network-1.png';
import img_ico_2 from '../img/blockchain-icons/earnestroi-blockchain-network-2.png';
import img_ico_3 from '../img/blockchain-icons/earnestroi-bscscan-black.png';
import img_ico_4 from '../img/blockchain-icons/earnestroi-blockchain-network-4.png';
import img_ico_5 from '../img/blockchain-icons/earnestroi-blockchain-network-5.png';
import img_ico_6 from '../img/blockchain-icons/earnestroi-near-black.png';
import img_ico_7 from '../img/blockchain-icons/earnestroi-blockchain-network-7.png';
import img_ico_8 from '../img/blockchain-icons/earnestroi-blockchain-network-8.png';
import img_ico_9 from '../img/blockchain-icons/earnestroi-blockchain-network-9.png';
import img_ico_10 from '../img/blockchain-icons/earnestroi-blockchain-network-10.png';
import img_ico_11 from '../img/blockchain-icons/earnestroi-blockchain-network-11.png';
import img_ico_12 from '../img/blockchain-icons/earnestroi-blockchain-network-12.png';
import img_ico_13 from '../img/blockchain-icons/earnestroi-blockchain-network-13.png';
import img_ico_14 from '../img/blockchain-icons/earnestroi-blockchain-network-14.png';
import img_ico_15 from '../img/blockchain-icons/earnestroi-blockchain-network-15.png';
import img_ico_16 from '../img/blockchain-icons/earnestroi-blockchain-network-16.png';
import img_ico_17 from '../img/blockchain-icons/earnestroi-blockchain-network-17.png';
import img_ico_18 from '../img/blockchain-icons/earnestroi-blockchain-network-18.png';
import img_nft_circle from '../img/earnestroi-nft-circle.png';
import img_tech_1 from '../img/earnestroi-technologies-1.png';
import img_tech_2 from '../img/earnestroi-technologies-2.png';
import img_tech_3 from '../img/earnestroi-technologies-3.png';
import img_tech_4 from '../img/earnestroi-technologies-4.png';
import img_tech_5 from '../img/earnestroi-technologies-5.png';
import img_tech_6 from '../img/earnestroi-technologies-6.png';
import img_tech_7 from '../img/earnestroi-technologies-7.png';
import img_tech_8 from '../img/earnestroi-technologies-8.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faStarHalfStroke, faCrown, faChartLine, faPalette, faMusic, faFilePen, faRotate, faFileSignature, faHammer, faUsers, faGears, faHeadphonesSimple, faFileExport} from '@fortawesome/free-solid-svg-icons';
import { faShieldHalved } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Footern from './Footern';
import { Helmet } from 'react-helmet';
// import WhatsappChat from './WhatsappChat';

const NftDevelopment = () => {

  useEffect( () => {

    $(document).ready(function(){
      $(this).scrollTop(0);
    });

    $(document).ready(function(){
      
      $('.slick-prev').hide();
      $('.slick-next').hide();

    });

    Aos.init({

      disable: function() {
        var maxWidth = 1024;
        return window.innerWidth < maxWidth;
      }

    });

} );

  useEffect( () => {

    Aos.init({ duration: 3000 });

} );
  
  return (
    <>
        <Helmet>
          <title>Earnestroi Technology | NFT Marketplace | Nonfungible Token</title>
          <meta name="title" content="Earnestroi Technology | Nft Marketplace | Nonfungible Token" />
          <meta name="description" content="The marketplace uses blockchain technology to tokenize your physical or digital art and make it non-fungible by marking each item with a unique digital fingerprint." />
          <meta name="keywords" content='nft marketplace, nonfungible token, digital asset, nft market, nft art, project management' />
          <meta name="robots" content="index,follow" />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="language" content="English" />
        </Helmet>
        <section className=' lg:pt-4 xl:pt-0 bg-main px-1 xl:px-0'>
          <div className='container mx-auto'>
            <div className='flex flex-col-reverse lg:flex-row justify-center items-center xl:pt-20'>
              <div className='flex flex-col w-full lg:w-1/2'>
                  <h1 className='text-[white] text-5xl lg:text-6xl mb-4 mt-4 lg:mt-0 font_primary capitalize' data-aos="fade-left">NFT Token Development Services</h1>
                  <br />
                  <h2 className='text-base lg:text-lg mb-4 mt-4 lg:mt-0 font_primary capitalize text-third'  data-aos="fade-left">Tokenize your collectibles with Earnestroi's top NFT developers.</h2>
                  <Link to='/contact' data-aos="fade-left" className='text-base my-2  w-max border-2 bg-third px-2 py-1 rounded-lg hover:bg-main hover:shadow-[0_0_12px_#77c1dd] hover:text-third m-0 hover:border-third font_primary' >Lets Talk</Link>
              </div>
              <div className=' w-full lg:w-1/2'>
                <img src={corn} alt='NFT Token ' className='mx-auto w-1/2 sm:w-1/4 lg:w-3/4' />
              </div>
            </div>
          </div>
        </section>
        <section className='bg-main px-1 xl:px-0'>
          <div className='container mx-auto'>
            <div className='flex justify-center py-4 lg:py-10'>
              <Link to='/'><h2 className='mx-2 text-third font_primary'><FontAwesomeIcon icon={ faHome } className='mx-2 text-third' />Digital Marketing Agency</h2></Link><h2 className='mx-2 text-third'>/</h2><h2 className='mx-2 text-third font_primary'>NFT Development</h2>
            </div>
          </div>  
          <div className='container mx-auto py-4 lg:py-8'>
            <div className='w-full lg:w-[60%] mx-auto'>
              <h2 className='text-lg lg:text-2xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third text-center' data-aos='fade-up'>NFT Development Services Are Becoming More Popular</h2>
              <p className='text-[white] text-center text-base sm:text-lg mb-4 lg:mb-8 font_secondary' data-aos='fade-up'>Earnestroi is your dependable technology partner for the development of NFT tokens. Earnestroi is a world-class NFT development firm that offers exceptional non-fungible token development services. Earnestroi has the resources and experience to turn your dream into a reality, whether you want to create a non-fungible token or build an NFT marketplace. NFT tokens impact the blockchain world; they are used in various fields, such as digital art, gaming, and more.</p>
            </div>
          </div>
        </section>
        <section className='py-4 md:py-10'>
          <div className='container mx-auto'>
            <div className='grid-cols-2 flex flex-col lg:flex-row justify-between items-center'>
              <div className='lg:w-[40%] lg:px-2 p-2 sm:p-0'>
                <h2 className='text-2xl lg:text-3xl font_primary text-third'>Why Consider hiring a Dedicated NFT Token Development Firm?</h2>
                <br /><br />
                <p className='text-base font_secondary' data-aos="fade-left"><Link to='/' className='text-third'>Earnestroi</Link>, a dedicated NFT token development company, has experience building high-quality products and providing NFT development services for complex algorithms; NFT software development is a critical component of our DeFi COE. We use the DNFT protocol to create decentralized non-fungible tokens aligned with various business needs. Our NFT developers and subject matter experts collaborate to provide meticulously crafted, decentralized non-fungible token development services to help you achieve your business objectives. Whether you want to tokenize your artwork, a video file, or any other content, we provide mission-driven NFT software development solutions to fit your demands.</p>
                <br />  
              </div>
              <div className=''>
                <img src={img_3} alt='Development Service' className='my-8 lg:my-0' />
              </div>
            </div>
          </div>
        </section>
        <section className='py-4 md:py-20 px-1 xl:px-0'>
          <div className='container mx-auto pt-4 lg:pt-8'>
            <div className='w-full lg:w-[60%] mx-auto'>
              <h2 className='text-lg lg:text-2xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third text-center' data-aos='fade-up'>Achieving Irrefutable Ownership With NFT Development</h2>
              <p className=' text-center text-base sm:text-lg mb-4 lg:mb-8 font_secondary'  data-aos='fade-up'><Link to='/' className='text-third'>Earnestroi</Link> is a high-end NFT development firm with a solid foundation in decentralized apps. With our premium NFT token development services, we help our clients advance their businesses. To ensure that clients get the best, our NFT developers incorporate the most recent features and functionalities into the solutions.</p>
            </div>
          </div>
          <div className='container mx-auto'>
            <div className='grid sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-8 justify-center items-center'>
              <div className='p-4 h-full hover:bg-[#00000012] hover:cursor-pointer rounded-lg '>
                <FontAwesomeIcon icon={ faStarHalfStroke } className='text-5xl text-third' />
                <h2 className='text-lg mb-2 font_primary text-third'>Uniqueness</h2>
                <p className='text-base font_secondary'>Create NFT tokens with distinct product identity and authentication; NFTs are excellent instruments for addressing product duplication.</p>
              </div>
              <div className='p-4 h-full hover:bg-[#00000012] hover:cursor-pointer rounded-lg '>
                <FontAwesomeIcon icon={ faCrown } className='text-5xl text-third' />
                <h2 className='text-lg mb-2 font_primary text-third'>Resale and Royalty</h2>
                <p className='text-base font_secondary'>Create non-fungible tokens to enable 100% transparency in the product sale process. NFT development allows tracking the product lifecycle with utmost granularity.</p>
              </div>
              <div className='p-4 h-full hover:bg-[#00000012] hover:cursor-pointer rounded-lg '>
                <FontAwesomeIcon icon={ faChartLine } className='text-5xl text-third' />
                <h2 className='text-lg mb-2 font_primary text-third'>Performance</h2>
                <p className='text-base font_secondary'>The high-security code makes the certification indestructible and timeless because NFT development takes place on a blockchain platform.</p>
              </div>  
            </div>
          </div>
        </section>
        <section className='bg-main py-4 sm:py-8 lg:py-12'>
          <div className='container mx-auto py-4 lg:py-8'>
            <div className='w-full lg:w-[60%] mx-auto'>
              <h2 className='text-lg lg:text-2xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third text-center'>NFT Token Development on Leading Blockchain Networks</h2>
              <p className='text-[white] text-center text-base sm:text-lg mb-4 lg:mb-8 font_secondary'>We offer non-fungible token development services on any blockchain of your choice.</p>
            </div>
          </div>
          <div className='container mx-auto'>
            <div className='grid-cols-7 gap-0 md:gap-2 lg:gap-20 block sm:flex justify-center items-center'>
              <div className='w-max mx-auto'>
                <div className='px-8 py-6 sm:px-2 sm:py-2 xl:px-4 xl:py-4 bg-[white] flex w-max rounded-2xl justify-center items-center'>
                  <img src={ img_ico_1 } alt='blockchain networks' className='mx-auto' />
                </div>
                <p className='font_primary text-center text-third'>Avalanche</p>
              </div>
              <div className='w-max mx-auto'>
                <div className='px-8 py-6 sm:px-2 sm:py-2 xl:px-4 xl:py-4 bg-[white] flex w-max rounded-2xl justify-center items-center'>
                  <img src={ img_ico_2 } alt='blockchain networks' className='mx-auto' />
                </div>
                <p className='font_primary text-center text-third'>Bitcoin</p>
              </div>
              <div className='w-max mx-auto'>
                <div className='px-8 py-6 sm:px-2 sm:py-2 xl:px-4 xl:py-4 bg-[white] flex w-max rounded-2xl justify-center items-center'>
                  <img src={ img_ico_3 } alt='blockchain networks' className='mx-auto' />
                </div>
                <p className='font_primary text-center text-third'>BscScan</p>
              </div>
              <div className='w-max mx-auto'>
                <div className='px-8 py-6 sm:px-2 sm:py-2 xl:px-4 xl:py-4 bg-[white] flex w-max rounded-2xl justify-center items-center'>
                  <img src={ img_ico_4 } alt='blockchain networks' className='mx-auto' />
                </div>
                <p className='font_primary text-center text-third'>Ethereum</p>
              </div>
              <div className='w-max mx-auto'>
                <div className='px-8 py-6 sm:px-2 sm:py-2 xl:px-4 xl:py-4 bg-[white] flex w-max rounded-2xl justify-center items-center'>
                  <img src={ img_ico_5 } alt='blockchain networks' className='mx-auto' />
                </div>
                <p className='font_primary text-center text-third'>Harmony</p>
              </div>
              <div className='w-max mx-auto'>
                <div className='px-8 py-6 sm:px-2 sm:py-2 xl:px-4 xl:py-4 bg-[white] flex w-max rounded-2xl justify-center items-center'>
                  <img src={ img_ico_6 } alt='blockchain networks' className='mx-auto' />
                </div>
                <p className='font_primary text-center text-third'>NEAR</p>
              </div>
              <div className='w-max mx-auto'>
                <div className='px-8 py-6 sm:px-2 sm:py-2 xl:px-4 xl:py-4 bg-[white] flex w-max rounded-2xl justify-center items-center'>
                  <img src={ img_ico_7 } alt='blockchain networks' className='mx-auto' />
                </div>
                <p className='font_primary text-center text-third'>Polygon</p>
              </div>
            </div>
          </div>
          <div className='container mx-auto py-16'>
            <div className='grid-cols-6 gap-0 md:gap-2 lg:gap-20 block sm:flex justify-center items-center'>
              <div className='w-max mx-auto'>
                <div className='px-8 py-6 sm:px-2 sm:py-2 xl:px-4 xl:py-4 bg-[white] flex w-max rounded-2xl justify-center items-center'>
                  <img src={ img_ico_8 } alt='blockchain networks' className='mx-auto' />
                </div>
                <p className='font_primary text-center text-third'>Cardano</p>
              </div>
              <div className='w-max mx-auto'>
                <div className='px-8 py-6 sm:px-2 sm:py-2 xl:px-4 xl:py-4 bg-[white] flex w-max rounded-2xl justify-center items-center'>
                  <img src={ img_ico_9 } alt='blockchain networks' className='mx-auto' />
                </div>
                <p className='font_primary text-center text-third'>Solana</p>
              </div>
              <div className='w-max mx-auto'>
                <div className='px-8 py-6 sm:px-2 sm:py-2 xl:px-4 xl:py-4 bg-[white] flex w-max rounded-2xl justify-center items-center'>
                  <img src={ img_ico_10 } alt='blockchain networks' className='mx-auto' />
                </div>
                <p className='font_primary text-center text-third'>Tezos</p>
              </div>
              <div className='w-max mx-auto'>
                <div className='px-8 py-6 sm:px-2 sm:py-2 xl:px-4 xl:py-4 bg-[white] flex w-max rounded-2xl justify-center items-center'>
                  <img src={ img_ico_11 } alt='blockchain networks' className='mx-auto' />
                </div>
                <p className='font_primary text-center text-third'>Polkadot</p>
              </div>
              <div className='w-max mx-auto'>
                <div className='px-8 py-6 sm:px-2 sm:py-2 xl:px-4 xl:py-4 bg-[white] flex w-max rounded-2xl justify-center items-center'>
                  <img src={ img_ico_12 } alt='blockchain networks' className='mx-auto' />
                </div>
                <p className='font_primary text-center text-third'>Tron</p>
              </div>
              <div className='w-max mx-auto'>
                <div className='px-8 py-6 sm:px-2 sm:py-2 xl:px-4 xl:py-4 bg-[white] flex w-max rounded-2xl justify-center items-center'>
                  <img src={ img_ico_13 } alt='blockchain networks' className='mx-auto' />
                </div>
                <p className='font_primary text-center text-third'>FANTOM</p>
              </div>
            </div>
          </div>
          <div className='container mx-auto'>
            <div className='grid-cols-5 gap-0 md:gap-2 lg:gap-20 block sm:flex justify-center items-center'>
              <div className='w-max mx-auto'>
                <div className='px-8 py-6 sm:px-2 sm:py-2 xl:px-4 xl:py-4 bg-[white] flex w-max rounded-2xl justify-center items-center'>
                  <img src={ img_ico_14 } alt='blockchain networks' className='mx-auto' />
                </div>
                <p className='font_primary text-center text-third'>BINANCE</p>
              </div>
              <div className='w-max mx-auto'>
                <div className='px-8 py-6 sm:px-2 sm:py-2 xl:px-4 xl:py-4 bg-[white] flex w-max rounded-2xl justify-center items-center'>
                  <img src={ img_ico_15 } alt='blockchain networks' className='mx-auto' />
                </div>
                <p className='font_primary text-center text-third'>CRONOS</p>
              </div>
              <div className='w-max mx-auto'>
                <div className='px-8 py-6 sm:px-2 sm:py-2 xl:px-4 xl:py-4 bg-[white] flex w-max rounded-2xl justify-center items-center'>
                  <img src={ img_ico_16 } alt='blockchain networks' className='mx-auto' />
                </div>
                <p className='font_primary text-center text-third'>XINFIN</p>
              </div>
              <div className='w-max mx-auto'>
                <div className='px-8 py-6 sm:px-2 sm:py-2 xl:px-4 xl:py-4 bg-[white] flex w-max rounded-2xl justify-center items-center'>
                  <img src={ img_ico_17 } alt='blockchain networks' className='mx-auto' />
                </div>
                <p className='font_primary text-center text-third'>RIPPLE</p>
              </div>
              <div className='w-max mx-auto'>
                <div className='px-8 py-6 sm:px-2 sm:py-2 xl:px-4 xl:py-4 bg-[white] flex w-max rounded-2xl justify-center items-center'>
                  <img src={ img_ico_18 } alt='blockchain networks' className='mx-auto' />
                </div>
                <p className='font_primary text-center text-third'>EOS</p>
              </div>
            </div>
          </div>
        </section>
        <section className='py-4 lg:py-20'>
          <h2 className='text-third text-center text-3xl sm:text-5xl lg:text-6xl mb-4 mt-4 lg:mt-0 font_primary capitalize'>Our Technologies</h2>
          <div className='container mx-auto flex flex-row md:flex-col justify-center'>
            <div className='flex flex-col md:flex-row md:grid-cols-2 lg:grid-cols-4 gap-2 lg:gap-4 justify-center pb-0 md:pb-4'>
              <img src={ img_tech_1 } alt='earnestroi technologies' className='mx-auto block w-1/2 md:w-[15%] lg:w-[8%] filter grayscale hover:filter-none' />
              <img src={ img_tech_2 } alt='earnestroi technologies' className='mx-auto block w-1/2 md:w-[15%] lg:w-[8%] filter grayscale hover:filter-none' />
              <img src={ img_tech_3 } alt='earnestroi technologies' className='mx-auto block w-1/2 md:w-[15%] lg:w-[8%] filter grayscale hover:filter-none' />
              <img src={ img_tech_4 } alt='earnestroi technologies' className='mx-auto block w-1/2 md:w-[15%] lg:w-[8%] filter grayscale hover:filter-none' />
            </div>
            <div className='flex flex-col md:flex-row md:grid-cols-2 lg:grid-cols-4 gap-2 lg:gap-4 justify-center pt-0 md:pt-4'>
              <img src={ img_tech_5 } alt='earnestroi technologies' className='mx-auto block w-1/2 md:w-[15%] lg:w-[8%] filter grayscale hover:filter-none' />
              <img src={ img_tech_6 } alt='earnestroi technologies' className='mx-auto block w-1/2 md:w-[15%] lg:w-[8%] filter grayscale hover:filter-none' />
              <img src={ img_tech_7 } alt='earnestroi technologies' className='mx-auto block w-1/2 md:w-[15%] lg:w-[8%] filter grayscale hover:filter-none' />
              <img src={ img_tech_8 } alt='earnestroi technologies' className='mx-auto block w-1/2 md:w-[15%] lg:w-[8%] filter grayscale hover:filter-none' />
            </div>
          </div>
        </section>
        <section className='px-2 lg:px-0 py-4 md:py-10 bg-main'>
          <div className='container mx-auto pt-4 lg:pt-8'>
            <div className='w-full lg:w-[60%] mx-auto'>
              <h2 className='text-lg lg:text-2xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third text-center' data-aos='fade-up'>Our NFT Development Methodology</h2>
              <p className='text-center text-base sm:text-lg mb-4 lg:mb-8 font_secondary text-[white]' data-aos='fade-up'>At Earnestroi, we take great pride in providing first-rate NFT token development services. We are confident that our clients have the best possible experience.</p>
            </div>
          </div>
          <div className='container mx-auto'>
            <div className='flex justify-center'>
              <div className='w-full md:w-1/2 lg:w-1/4'>
                <h2 className='text-lg lg:text-2xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third text-start'><span className='hidden md:inline-block'>01. </span> First Meeting</h2>
                <p className='text-start text-base sm:text-lg mb-4 lg:mb-8 font_secondary text-[white]'>Our experts understand your non-fungible token development requirements and long-term objectives, and they engage in brainstorming sessions to provide you with a great solution.</p>
              </div>
            </div>
            <div className='flex flex-col md:flex-row justify-center'>
              <div className='w-full md:w-1/4'>
                <h2 className='text-lg lg:text-2xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third text-start'><span className='hidden md:inline-block'>05. </span> Launch and Maintenance</h2>
                <p className='text-start text-base sm:text-lg mb-4 lg:mb-8 font_secondary text-[white]'>Our NFT developers will market your product or software with your permission. Earnestroi handles all maintenance, so you don't have to worry about anything.</p>
              </div>
              <div className='w-1/2 hidden md:block'>
                <img src={ img_nft_circle } alt='NFT Methodology' className='mx-auto block' />
              </div>
              <div className='w-full md:w-1/4'>
                <h2 className='text-lg lg:text-2xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third text-start'><span className='hidden md:inline-block'>02. </span> Blueprint</h2>
                <p className='text-start text-base sm:text-lg mb-4 lg:mb-8 font_secondary text-[white]'>Our NFT developers and subject matter experts create strategies after learning about your demands to ensure you receive the finest solution as soon as possible.</p>
              </div>
            </div>
            <div className='flex flex-col md:flex-row justify-around'>
              <div className='w-full md:w-[35%] lg:w-1/4'>
                <h2 className='text-lg lg:text-2xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third text-start'><span className='hidden md:inline-block'>04. </span> Quality Control</h2>
                <p className='text-start text-base sm:text-lg mb-4 lg:mb-8 font_secondary text-[white]'>When it comes to providing top-notch NFT software development services, Earnestroi puts quality first. In developing NFT software, our quality assurance and testing teams put in a lot of effort to fix errors.</p>
              </div>
              <div className='w-full md:w-[35%] lg:w-1/4'>
                <h2 className='text-lg lg:text-2xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third text-start'><span className='hidden md:inline-block'>03. </span> Development</h2>
                <p className='text-start text-base sm:text-lg mb-4 lg:mb-8 font_secondary text-[white]'>Earnestroi has over 500 subject matter experts to provide you with the best non-fungible token development services. Our NFT developers make sure that all of your requirements are met.</p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className='container mx-auto my-12 lg:my-16 border-l-2 border-third'>
            <p className='pl-8 font_secondary'>EARNESTROI</p>
            <br />
            <h2 className='text-base sm:text-3xl border-l-4 border-third pl-8 font_secondary capitalize text-third font_primary' data-aos="fade-up">Services for NFT Development</h2>
          </div>
        </section>
        <section className='py-10 bg-bg_img bg-no-repeat px-1 xl:px-0' >
          <div className='container mx-auto'>
            <div className='grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
              <div className='p-4 h-full bg-[#00000012] rounded bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                <div className='flex items-center'>
                  <FontAwesomeIcon icon={ faPalette } className='text-3xl mx-2 text-third' />
                  <h2 className='mb-0 text-base font_primary'>Arts</h2>
                </div>
                <p className='text-base font_secondary'>The most popular domain in the NFT domain is art. NFTs for art can help artists earn royalties and increase sales.</p>
              </div>
              <div className='p-4 h-full bg-[#00000012] rounded bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                <div className='flex items-center'>
                  <FontAwesomeIcon icon={ faMusic } className='text-3xl mx-2 text-third' />
                  <h2 className='mb-0 text-base font_primary'>Music</h2>
                </div>
                <p className='text-base font_secondary'>Piracy has long been a problem in the music industry. NFTs can help artists get their due credit and thus eliminate these issues.</p>
              </div>
              <div className='p-4 h-full bg-[#00000012] rounded bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                <div className='flex items-center'>
                  <FontAwesomeIcon icon={ faFilePen } className='text-3xl mx-2 text-third' />
                  <h2 className='mb-0 text-base font_primary'>Digital Content</h2>
                </div>
                <p className='text-base font_secondary'>Digital content NFTs guarantee that the work's creator receives the proper credit. Therefore, there are no copyright difficulties.</p>
              </div>
              <div className='p-4 h-full bg-[#00000012] rounded bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                <div className='flex items-center'>
                  <FontAwesomeIcon icon={ faRotate } className='text-3xl mx-2 text-third' />
                  <h2 className='mb-0 text-base font_primary'>Exchange</h2>
                </div>
                <p className='text-base font_secondary'>An NFT exchange platform comprises many non-fungible token collections that are great revenue generators.</p>
              </div>
              <div className='p-4 h-full bg-[#00000012] rounded bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                <div className='flex items-center'>
                  <FontAwesomeIcon icon={ faFileSignature } className='text-3xl mx-2 text-third' />
                  <h2 className='mb-0 text-base font_primary'>Smart Contract</h2>
                </div>
                <p className='text-base font_secondary'>Smart contracts are blockchain programs that execute when certain conditions are met. They are employed to automate the execution.</p>
              </div>
              <div className='p-4 h-full bg-[#00000012] rounded bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                <div className='flex items-center'>
                  <FontAwesomeIcon icon={ faHammer } className='text-3xl mx-2 text-third' />
                  <h2 className='mb-0 text-base font_primary'>Minting of NFT</h2>
                </div>
                <p className='text-base font_secondary'>Users can create, mint, and sell NFTs using the NFT minting platform. It's the first step in your NFT selling adventure.</p>
              </div>
            </div>
          </div>
        </section>
        <section className='py-4 md:py-10'>
          <div className='container mx-auto'>
            <div className='grid-cols-2 flex flex-col lg:flex-row justify-between items-center'>
              <div className='lg:w-[40%] lg:px-2 p-2 sm:p-0'>
                <h2 className='text-2xl lg:text-3xl font_primary text-third'>NFT Development Decentralized at Your Service</h2>
                <br />
                <p className='text-base font_secondary' data-aos="fade-left">A Decentralized Non-Fungible Token (DNFT) uses the DNFT protocol. This decentralized cross-chain network allows for the minting, trading, and managing of NFT assets such as art, metaverse assets, and more across blockchains.</p>
                <br />
                <p className='text-base font_secondary' data-aos="fade-left">Digital content producers and asset owners can develop in a democratic atmosphere without relying on outside hosting agencies, thanks to NFT development services. We have experience developing end-to-end DeFi products for many ecosystems in addition to NFT token development.</p>
                <br />
                <p className='text-base font_secondary' data-aos="fade-left">Whether you want to tokenize your artwork, video file, or any other asset, we offer mission-driven NFT token development services to meet the objectives of NFT organizations efficiently.</p>  
              </div>
              <div className=''>
                <img src={img_3s} alt='NFT Decentralized ' className='my-8 lg:my-0' />
              </div>
            </div>
          </div>
        </section>
        <section className='bg-main py-4 lg:py-12 px-2 xl:px-0'>
          <div className='container mx-auto pt-4 lg:pt-8'>
            <div className='w-full lg:w-[60%] mx-auto'>
              <h2 className='text-lg lg:text-2xl mb-4 mt-4 lg:mt-0 lg:mb-8 font_primary capitalize text-third text-center'>Why Choose Us for NFT Development Services?</h2>
            </div>
            <div className='container mx-auto pb-4 md:pb-6'>
              <div className='grid sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
                <div className='p-4 h-full bg-[#77c1dd14] rounded bg-clip-padding backdrop-filter backdrop-blur-md '>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faShieldHalved } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className=' text-third mb-0 text-base font_primary text-center'>Technical Process</h2>
                  </div>
                  <p className='text-[white] text-base font_secondary my-4 text-center'>We exclusively work in Blockchain technologies. We just concentrate on one thing, and we excel at it.</p>
                </div>
                <div className='p-4 h-full bg-[#77c1dd14] rounded bg-clip-padding backdrop-filter backdrop-blur-md '>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faUsers } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className=' text-third mb-0 text-base font_primary text-center'>Expert Team</h2>
                  </div>
                  <p className='text-[white] text-base font_secondary my-4 text-center'>With the support of our 500 professionals, you can improve your offering, get the finest IT solution, and even create communities and campaigns.</p>
                </div>
                <div className='p-4 h-full bg-[#77c1dd14] rounded bg-clip-padding backdrop-filter backdrop-blur-md '>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faGears } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className=' text-third mb-0 text-base font_primary text-center'>Rapid Development</h2>
                  </div>
                  <p className='text-[white] text-base font_secondary my-4 text-center'>We offer goods that are especially suited to your intended consumer base. A well-organized roadmap ensures rapid and efficient development and implementation.</p>
                </div>
              </div>
            </div>
            <div className='container mx-auto pt-4 md:pt-6'>
              <div className='flex flex-col lg:flex-row lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
                <div className='p-4 h-full lg:w-[30%] xl:w-1/4 2xl:w-[30%] w-full bg-[#77c1dd14] rounded bg-clip-padding backdrop-filter backdrop-blur-md '>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faHeadphonesSimple } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className='mb-0 text-base font_primary text-center text-third'>Complete Support</h2>
                  </div>
                  <p className='text-[white] text-base font_secondary my-4 text-center'>After the product launch, we continue to work and provide post-delivery support.</p>
                </div>
                <div className='p-4 h-full lg:w-[30%] xl:w-1/4 2xl:w-[30%] w-full bg-[#77c1dd14] rounded bg-clip-padding backdrop-filter backdrop-blur-md '>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faFileExport } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className='mb-0 text-base font_primary text-center text-third'>Meaningful Outcomes</h2>
                  </div>
                  <p className='text-[white] text-base font_secondary my-4 text-center'>We make a real difference. We make sure your investments have value for you.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
            <Footern />
        </section>
    </>
  )
}

export default NftDevelopment;