import axios from 'axios';
import React, { useState, useEffect } from 'react';
// import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'react-toastify/dist/ReactToastify.css';
import PaginationContact from './PaginationContact';
import { Table } from 'antd';

const ContactDetailsDash = () => {
  const [ContactData, setContactData] = useState([]);

  const Contact = () => {
    const CheckJwt = localStorage.getItem('token');
    const postData = { jwt: CheckJwt };

    console.log(postData)
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH + "view_contact",
      data: postData,
    })
      .then(async function (response) {
        console.log(response.data.Success);
        setContactData(response.data.Success)
      })
      .catch((err) => {
        console.log(err)
      });
  };

  useEffect(() => {
    Contact();
  }, [])

  const columns = [
    {
      title: "DATE",
      dataIndex: "Created_On",
      key: "Created_On",
    },
    {
      title: "FORM ID",
      dataIndex: "FormID",
      key: "FormID",
    },
    {
      title: "NAME",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "EMAIL",
      dataIndex: "Email",
      key: "Email",
    },
    {
      title: "PHONE",
      dataIndex: "Phone",
      key: "Phone",
    },
    {
      title: "SERVICE",
      dataIndex: "Service",
      key: "Service",
    },
    {
      title: "WEBSITE URL",
      dataIndex: "Website_URL",
      key: "Website_URL",
    },
    {
      title: "MESSAGE",
      dataIndex: "Message",
      key: "Message",
    },
  ];

  const dataSource = [];
  if (ContactData) {
    for (let i = 0; i < ContactData.length; ++i) {
      dataSource.push({
        key: i,
        Created_On: ContactData[i].Created_On,
        FormID: ContactData[i].FormID,
        Name: ContactData[i].Name,
        Email: ContactData[i].Email,
        Phone: ContactData[i].Phone,
        Service: ContactData[i].Service,
        Website_URL: ContactData[i].Website_URL,
        Message: ContactData[i].Message,
      })
    }
  }

  return (
    <>
      <section className="w-full mb-8">
        <div className="container mx-auto mt-12 px-2 sm:px-4">
          <div className="container mx-auto">
            <h2 className="text-second font_primary text-center text-6xl">
              Contact Details
            </h2>
          </div>

          <div class="table-responsive">
            <Table size="small" dataSource={dataSource} columns={columns} />
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactDetailsDash;