import React, { useEffect } from "react";

const TrafficCounter = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//cdn.livetrafficfeed.com/static/hitcounterjs/live.js?sty=15&min=7&sta=1&uni=1&tz=America%2FNew_York&ro=1";
    script.async = true;
    document.getElementById('trafficCounter').appendChild(script);
  }, []);

  return (
    <>
        <noscript id="LTF_hitcounter">
            <a href="https://earnestroi.com/">Hit Counter</a>
        </noscript>
    </>
  )
};

export default TrafficCounter;