import React from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faCartShopping, faLayerGroup, faFingerprint } from '@fortawesome/free-solid-svg-icons';

const TimelineMarketplaceNft = () => {
  return (
    <>
        <VerticalTimeline animate={ true } lineColor={ '#77c1dd' }>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                // date="2008 - 2010"
                iconStyle={{ background: 'rgb(0, 0, 0)' }}
                icon={<FontAwesomeIcon icon={ faStar } className='text-3xl font_primary text-third' />}
            >
                <h2 className='text-xl lg:text-3xl text-third font_primary my-4'>Guaranteed Royalty</h2>
                <p className='font_secondary'>Based on the recurrent purchase of any work of art, an NFT Art marketplace distributes royalty to artists and creators. This implies that the artist will receive a royalty for each artwork sale or resale. Every artist gets full credit for their work because NFTs are one of a kind, making them impossible to tamper with or alter.</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                // date="2006 - 2008"
                iconStyle={{ background: 'rgb(0, 0, 0)' }}
                icon={<FontAwesomeIcon icon={ faCartShopping } className='text-3xl font_primary text-third' />}
            >
                <h2 className='text-xl lg:text-3xl text-third font_primary my-4'>Sell NFT Art</h2>
                <p className='font_secondary'>Due to the rising demand for high-quality art, you should buy and sell NFT to increase your income and earning potential. A crypto NFT marketplace's auction processes ensure that artists get the most from presenting their creations. List your work on an NFT marketplace to increase your earnings.</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                // date="April 2013"
                iconStyle={{ background: 'rgb(0, 0, 0)' }}
                icon={<FontAwesomeIcon icon={ faLayerGroup } className='text-3xl font_primary text-third' />}
            >
                <h2 className='text-xl lg:text-3xl text-third font_primary my-4'>Stake & Sell</h2>
                <p className='font_secondary'>The most typical kind of non-fungible tokens is art NFTs, which have become incredibly popular & well-established in recent years. Investors have access to several listed markets where they can buy and sell NFT art to maximize their earning potential and raise their percentage by trading NFT art appropriately.</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                // date="November 2012"
                iconStyle={{ background: 'rgb(0, 0, 0)' }}
                icon={<FontAwesomeIcon icon={ faFingerprint } className='text-3xl font_primary text-third' />}
            >
                <h2 className='text-xl lg:text-3xl text-third font_primary my-4'>No Copyright Breaches</h2>
                <p className='font_secondary'>The tight-knit security of Earnestroi's crypto art NFT marketplace development solutions ensures excellent resistance to copyright violations and other threats. An NFT's owner is the exclusive owner of the digital asset or collectible, making it impossible to copy, sell, change, or otherwise misuse any digital content.</p>
            </VerticalTimelineElement>
        </VerticalTimeline>
    </>
  )
}

export default TimelineMarketplaceNft;