import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import seo_1 from '../img/earnestroi-search-engine-optimization-index.webp';
import Aos from 'aos';
import 'aos/dist/aos.css';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import img_becoming from '../img/earnestroi-seo-organic-search-result.png';
import img_investment from '../img/earnestroi-seo-worth-the-investment.png';
import Footern from './Footern';
import { Helmet } from 'react-helmet';
// import  from './';

const SEO = () => {

  useEffect( () => {

    Aos.init({

      disable: function() {
        var maxWidth = 1023;
        return window.innerWidth < maxWidth;
      }

    });

    $(document).ready(function(){
        $(this).scrollTop(0);
    });

  } );

  useEffect( () => {

    Aos.init({ duration: 3000 });

  } )

  return (
    <>
      <Helmet>
        <title>Earnestroi Technology | SEO Services | digital agency</title>
        <meta name="title" content="Earnestroi Technology | SEO Services | digital agency" />
        <meta name="description" content='Once you’re a part of our team, you can sit back and allow our expert SEO services to relax while they work their magic. Our SEO expert team will be with you every step of the way.' />
        <meta name="keywords" content='seo services, Search Engine Marketing, Search Engine Optimization, Media Planning, Media Buying, Search Engine Marketing' />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
      </Helmet>
      <section className=' py-10'>
        <div className='container mx-auto'>
          <div className='flex flex-col lg:flex-row justify-center items-center'>
            <img src={ seo_1 } className='' alt='Search engine optimization ' />
            <h1 className='text-second text-5xl lg:text-6xl mb-4 mt-4 lg:mt-0 capitalize text-center font_primary' data-aos="fade-right">Search Engine<br /> Optimization</h1>
          </div>
        </div>
        <div className='container mx-auto'>
          <div className='flex justify-center py-4 lg:py-10'>
            <Link to='/'><h2 className='mx-2 text-second font_primary'><FontAwesomeIcon icon={ faHome } className='mx-2 text-second' />Digital Marketing Agency</h2></Link><h2 className='mx-2'>/</h2><h2 className='mx-2 text-second font_primary'>SEO</h2>
          </div>
        </div>
      </section>
      <section>
        <div className='container mx-auto py-4 lg:py-16'>
          <div className='flex flex-col lg:flex-row justify-center items-center px-4 lg:px-0'>
            <div className='container mx-auto my-12 lg:my-16 border-l-2 border-second w-full lg:w-1/2 py-6'>
              <h2 className='text-base sm:text-3xl w-3/4 border-l-4 border-second pl-8 font_secondary' data-aos="fade-up">Why does this overpowering sensation of helplessness envelop you the instant you find yourself on Google's second page?</h2>
              <br />
              <p className='pl-8 w-3/4 font_secondary'>Does it not seem as though you have turned right into a lane of poverty and left the street with the posh restaurants and shops?</p>
            </div>
            <div className='w-full lg:w-1/2 lg:pr-8'>
              <p className='mb-6 text-base sm:text-lg font_secondary'>This is what search engine optimization (SEO) has done to our brains—it has drastically altered how we view the mere mortals who reside on Google's page two.</p>
              <p className='mb-6 text-base sm:text-lg font_secondary'>Because of this rewiring of our perspective, 32% of all clicks go to the top result on Google because we believe it to be the most accurate. But the real query is: Does it, really? Actually, the response is no; much like any other piece of software,it too is an algorithm that can be tricked.</p>
              <p className='mb-6 text-base sm:text-lg font_secondary'>Because of this, SEO is still incredibly underutilized despite being one of the best marketing tools. If done correctly, SEO has the potential to cut the cost of acquiring customers by 87.41%.</p>
              <p className='mb-6 text-base sm:text-lg font_secondary'>Google received 943 billion searches in the first quarter of 2022 alone, and 67.6% of all clicks came from the top five organic results.</p>
            </div>
          </div>
        </div>
      </section>
      <section className='bg-main py-4 lg:py-20'>
        <div className='container mx-auto'>
          <div className='grid-cols-2 flex flex-col-reverse lg:flex-row justify-around items-center'>
            <div className='px-2 w-full lg:w-[40%]'>
              <div className='border-b-2 pb-[2px] border-second w-full mb-4'>
                <p className='text-3xl text-[white] font_primary'>BECOMING ONE OF THE TOP FIVE<br /><span className='border-b-0 lg:border-b-0 xl:border-b-4 sm:border-b-4 border-second text-[white]'>ORGANIC </span> SEARCH RESULT...</p>
              </div>
              <p className='text-base sm:text-lg mb-4 text-[white] font_secondary' data-aos="fade-left">... is not an easy task for most businesses operating alone, which is why we are here to assist you.</p>
              <p className='text-base sm:text-lg mb-4 text-[white] font_secondary' data-aos="fade-left">The development of several key elements, including improved crawl accessibility so search engines can read your website, publishing compelling content that responds to common searcher's queries, keyword optimization, increasing your CTR within the rankings, and including schema mark-up to stand out in SERPs, are necessary to successfully increase your website ranking, according to our SEO experts.</p>
              <p className='text-base sm:text-lg mb-4 text-[white] font_secondary' data-aos="fade-left">While it may seem like a lot of technical speak to you, our SEO expert team will relieve you of that burden once you join us.</p>
              <p className='text-base sm:text-lg mb-4 text-[white] font_secondary' data-aos="fade-left">Our extensive list of SEO services includes an in-depth analysis of your current website to determine why you haven't been successful in achieving those higher rankings. We then highlight the crucial elements that need improvement, give you a plan to fill in the gaps, and continue monitoring the development of the plan and making adjustments until you see results!</p>
              <p className='text-base sm:text-lg mb-4 text-[white] font_secondary' data-aos="fade-left">We have a 100% success rate in getting all of our companies on Google's front page!</p>
            </div>
            <div className='px-2 w-full lg:w-[50%]'>
              <img src={ img_becoming } alt='SEO  Optimization 1' className='mx-auto d-block' />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='container mx-auto py-4 lg:py-16'>
          <div className='flex flex-col lg:flex-row justify-around items-center px-4 lg:px-0'>
            <div className='my-12 lg:my-16 w-full lg:w-[40%] py-6'>
              <img src={ img_investment } alt='SEO  Optimization 2' className='mx-auto block' />
            </div>
            <div className='px-2 w-full lg:w-[40%]'>
              <div className='border-b-2 border-second w-full mb-4 pb-[2px]'>
                <p className='text-3xl text-black text-start font_primary'><span className='border-b-0 lg:border-b-0 2xl:border-b-2 sm:border-b-2 text-second border-second text-black font_primary'>SO, IS IT</span> WORTH THE INVESTMENT?</p>
              </div>
              <p className='text-base sm:text-lg mb-4 text-black text-start font_secondary' data-aos="fade-right">Absolutely!</p>
              <p className='text-base sm:text-lg mb-4 text-black text-start font_secondary' data-aos="fade-right">The digital equivalent of sitting front row at fashion week or obtaining court side seats at the game is SEO optimization and improved website ranking.</p>
              <p className='text-base sm:text-lg mb-4 text-black text-start font_secondary' data-aos="fade-right">Being right there in the search results is how the digital client perceives you as being the best since as a brand, your customers only want the best. In actuality, studies conducted over the last five years show that only 0.78 percent of Google users click on results from the second page. However, 90.63% of pages receive no organic search traffic from Google because they lack SEO.</p>
              <p className='text-base sm:text-lg mb-4 text-black text-start font_secondary' data-aos="fade-right">Why wait any longer? Get in touch with us today to begin your SEO revolution!</p>
            </div>
          </div>
        </div>
      </section>
      <section className='bg-main py-8 lg:py-24'>
        <div className='container mx-auto'>
          <div className='flex flex-col lg:flex-row justify-around items-center'>
            <div>
              <h2 className='text-base sm:text-[1.4rem] w-full text-center text-[white] font_secondary'>Get found by the relevant people! </h2>
              <h2 className='text-base sm:text-[1.4rem] w-full text-center text-[white] font_secondary'>We'll assist you in doing it naturally.</h2>
              <br /><br />
              <Link to='/contactsl' className='relative text-[white] hover:text-second p-2 bg-second font_primary hover:bg-[white] rounded-lg mx-auto block w-max border-2 border-second'>Talk to Us <FontAwesomeIcon icon={faArrowRight} className=' pl-4 animate-right_left'/></Link>
            </div>
          </div>
        </div>
      </section>
      <Footern />
    </>
  )
}

export default SEO;