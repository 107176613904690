import React from 'react';
import pricing from '../img/landing/earnestroi-landing-beauty-3.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const Pricing = () => {
  return (
    <div className="container mx-auto px-2 lg:px-0">
        <div className="flex flex-col lg:flex-row justify-center items-center">
            <div className='w-full lg:w-1/2'>
                <img src={ pricing } alt='Design Services' className='mx-auto block' />
            </div>
            <div className='w-full lg:w-1/2'>
              <h2 className='text-xl md:text-2xl lg:text-3xl font_primary capitalize text-second'>We help you build your image within your target demographic through our professional and custom design services.</h2>
                <p className='text-base sm:text-lg font_secondary py-2 text-[white]'><FontAwesomeIcon icon={ faCheck } className='text-third mx-2' />ORGANIC GROWTH (SEO)</p>
                <p className='text-base sm:text-lg font_secondary py-2 text-[white]'><FontAwesomeIcon icon={ faCheck } className='text-third mx-2' />PAID ADS</p>
                <p className='text-base sm:text-lg font_secondary py-2 text-[white]'><FontAwesomeIcon icon={ faCheck } className='text-third mx-2' />SOCIAL MEDIA</p>
                <p className='text-base sm:text-lg font_secondary py-2 text-[white]'><FontAwesomeIcon icon={ faCheck } className='text-third mx-2' />CONTENT CREATION/ MARKETING</p>
                <p className='text-base sm:text-lg font_secondary py-2 text-[white]'><FontAwesomeIcon icon={ faCheck } className='text-third mx-2' />WEBSITE DEVELOPMENT</p>
                <p className='text-base sm:text-lg font_secondary py-2 text-[white]'><FontAwesomeIcon icon={ faCheck } className='text-third mx-2' />DIGITAL BRANDING</p>
            </div>
        </div>
    </div>
  )
}

export default Pricing;