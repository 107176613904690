import React, { useEffect } from 'react';
import smm_1 from '../img/earnestroi-social-media-index.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Aos from 'aos';
import 'aos/dist/aos.css';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import Footern from './Footern';
import { Helmet } from 'react-helmet';
// import WhatsappChat from './WhatsappChat';

const SMM = () => {

  useEffect( () => {

    Aos.init({

      disable: function() {
        var maxWidth = 1023;
        return window.innerWidth < maxWidth;
      }

    });

    $(document).ready(function(){
        $(this).scrollTop(0);
    });

  } );

  useEffect( () => {

    Aos.init({ duration: 3000 });

  } )

  return (
    <>
      <Helmet>
        <title>Earnestroi Technology | SMM | social media marketing services</title>
        <meta name="title" content="Earnestroi Technology | SMM | social media marketing services" />
        <meta name="description" content="Earnestroi's main purpose is to provide social media services and facilitate communication between users from all over the world." />
        <meta name="keywords" content="marketing strategy, social media management, social media advertising, marketing plan, digital marketing services" />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
      </Helmet>
      <div className='  bg-[white] py-12'>
        <div className='container mx-auto my-4 lg:my-16'>
          <div className='flex flex-col md:flex-row justify-center items-center'>
            <div className=''>
              <h1 className='text-second text-5xl lg:text-6xl mb-4 mt-4 lg:mt-0 capitalize font_primary' data-aos="fade-left">Social Media<br />Marketing</h1>
            </div>
            <div className=''>
              <img src={ smm_1 } alt='Social media 1' />
            </div>
          </div>
        </div>
      </div>
      <div className='container mx-auto'>
        <div className='flex justify-center py-4 lg:py-10'> 
          <Link to='/'><h2 className='mx-2 text-second font_primary'><FontAwesomeIcon icon={ faHome } className='mx-2 text-second font_primary' />Digital Marketing Agency</h2></Link><h2 className='mx-2'>/</h2><h2 className='mx-2 text-second font_primary'>Social Media Marketing</h2>
        </div>
      </div>
      <div className='container mx-auto py-4 lg:py-16'>
        <div className='flex flex-col lg:flex-row justify-center items-center px-4 lg:px-0'>
          <div className='container mx-auto my-12 lg:my-16 border-l-2 border-second w-full lg:w-1/2 py-6'>
            <p className='pl-8 font_secondary'>WE'RE SOCIALLY AWARE</p>
            <br />
            <h2 className='text-base sm:text-3xl w-3/4 border-l-4 border-second pl-8 font_secondary' data-aos="fade-up">Did you know that 93.4% of internet users globally have an active social media presence, with over 424 million new users have joined various social media platforms within the last 12 months alone?</h2>
          </div>
          <div className='w-full lg:w-1/2'>
            <p className='mb-6 text-base sm:text-lg font_secondary'>Therefore, it should come as no surprise that 93% of brands globally have at least one new consumer as a result of a social media video they released.</p>
            <p className='mb-6 text-base sm:text-lg font_secondary'>A typical social media user uses five different platforms for 95 minutes a day, which is spread out over five different platforms. What does it take to get recognized, then, becomes an obvious question?</p>
            <p className='mb-6 text-base sm:text-lg font_secondary'>According to market research, the solution unquestionably lays in developing a social media marketing campaign that is tailored to the particular requirements and target audience of a firm.</p>
            <p className='mb-6 text-base sm:text-lg font_secondary'>As an energetic social media marketing company, it is our job to stay current with industry news. Our strength lies in identifying upcoming trends, market gaps, or research data.</p>
          </div>
        </div>
      </div>
      <section className='bg-main py-4 lg:py-20'>
        <div className='container mx-auto'>
          <div className='grid-cols-2 flex flex-col lg:flex-row justify-around'>
            <div className='px-2 w-full lg:w-[40%] py-8 lg-py-4' data-aos='fade-up'>   
              <div className='border-b-2 border-second w-full mb-4'>
                <p className='text-3xl text-[white] font_primary pb-[2px]'><span className='border-b-0 lg:border-b-0 xl:border-b-4 sm:border-b-4 border-second text-[white] font_primary'>SOCIAL </span>MEDIA MANAGEMENT</p>
              </div>
              <p className='text-base sm:text-lg mb-4 text-[white] font_secondary'>Our main goal when it comes to social media management is to generate organic growth across all of your social media platforms. This is why our onboarding procedure makes sure that we have a thorough understanding of your brand personality, target market, and development objectives.</p>
              <p className='text-base sm:text-lg mb-4 text-[white] font_secondary'>We can create social media solutions that are tailored to your needs using this data and our extensive understanding.</p>
              <p className='text-base sm:text-lg mb-4 text-[white] font_secondary'>As dedicated marketers, we know that success doesn't come from gaining 100 new followers overnight if 90 of them drop off by the end of the week. Instead, our aim is to build a steady stream of clients who will stick around for the long haul.</p>
            </div>
            <div className='px-2 w-full lg:w-[40%] py-8 lg-py-4' data-aos='fade-up'>   
              <div className='border-b-2 border-second w-full mb-4'>
                <p className='text-3xl text-[white] font_primary pb-[2px]'><span className='border-b-0 lg:border-b-0 xl:border-b-4 sm:border-b-4 border-second text-[white] font_primary'>SOCIAL </span>MEDIA ADVERTISING</p>
              </div>
              <p className='text-base sm:text-lg mb-4 text-[white] font_secondary'>In 2022, it is anticipated that social media advertising would cost a total of about 75.3 billion US dollars, a 25% rise from the current year.</p>
              <p className='text-base sm:text-lg mb-4 text-[white] font_secondary'>You might wonder why.</p>
              <p className='text-base sm:text-lg mb-4 text-[white] font_secondary'>Globally high Return On Ad Spend (ROAS) rates have prompted many firms to think about raising their social media advertising budgets. At Earnestroi, we think that knowing the target audience and knowing which content is most appropriate for advertising are the fundamental factors in high ROAS rates.</p>
            </div>
          </div>
        </div>
      </section>
      <div className='container mx-auto py-4 lg:py-16'>
        <div className='flex flex-col lg:flex-row justify-center items-center px-4 lg:px-0'>
          <div className='my-12 lg:my-16 border-l-2 border-second w-full lg:w-[40%] py-6'>
            <p className='pl-8 font_secondary'>GET SOCIAL WITH US</p>
            <br />
            <h2 className='text-base sm:text-3xl w-3/4 border-l-4 border-second pl-8 font_secondary' data-aos="fade-up">Perhaps the most striking statistic from this year is that customers who have enjoyed positive social media interactions with brands recommend the brand to social contacts at a rate of 71%.</h2>
          </div>
          <div className='w-full lg:w-[40%]'>
            <h2 className='text-base sm:text-3xl w-full my-6 text-second font_primary'>Many of the client testimonies we've received from them do in fact support this statistic.</h2>
            <p className='mb-6 text-base sm:text-lg font_secondary'>We don't only think up the campaign and cast you off; instead, our social media management packages Worldwide cover the whole range of social media services.</p>
            <p className='mb-6 text-base sm:text-lg font_secondary'>Instead, we produce the material, track the development, and handle client feedback; we deliver the baby and take it home.</p>
          </div>
        </div>
      </div>
      <section className='bg-main py-4 lg:py-24'>
        <div className='container mx-auto'>
          <div className='flex flex-col lg:flex-row justify-around items-center'>
            <div className='py-8 lg:py-4'>
              <h2 className='text-base sm:text-[1.4rem] w-full text-center text-[white] font_secondary'>Ready to take your brand to the next level?</h2>
              <h2 className='text-base sm:text-[1.4rem] w-full text-center text-[white] font_secondary'>We are here to help.</h2>
              <br /><br />
              <Link to='/contactsl' className='relative font_primary text-[white] hover:text-second p-2 bg-second hover:bg-[white] font_primary rounded-lg mx-auto block w-max border-2 border-second'>Talk To Us <FontAwesomeIcon icon={faArrowRight} className=' pl-4 animate-right_left'/></Link>
            </div>
            <div className='py-8 lg:py-4'>
              <h2 className='text-base sm:text-[1.4rem] w-full text-center text-[white] font_secondary'>You're ready to advance your brand.</h2>
              <h2 className='text-base sm:text-[1.4rem] w-full text-center text-[white] font_secondary'>We're available to assist.</h2>
              <br /><br />
              <Link to='/blog' className='relative font_primary text-[white] hover:text-second p-2 bg-second hover:bg-[white] font_primary rounded-lg mx-auto block w-max border-2 border-second'>Read More <FontAwesomeIcon icon={faArrowRight} className=' pl-4 animate-right_left'/></Link>
            </div>
          </div>
        </div>
      </section>
      <Footern />
    </>
  )
}

export default SMM;