import React, { useEffect } from 'react';
import content_m_1 from '../img/earnestroi-content-marketing-main.webp';
import content_m_2 from '../img/earnestroi-content-marketing-services.webp';
import content_m_3 from '../img/earnestroi-content-marketing-services-content-creation.webp';
import content_m_4 from '../img/earnestroi-content-marketing-services-writing-content.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Aos from 'aos';
import 'aos/dist/aos.css';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import Footern from './Footern';
import { Helmet } from 'react-helmet';
// import WhatsappChat from './WhatsappChat';

const ContentM = () => {

  useEffect( () => {

    Aos.init({

      disable: function() {
        var maxWidth = 1023;
        return window.innerWidth < maxWidth;
      }

    });

    $(document).ready(function(){
        $(this).scrollTop(0);
    });

  } );

  useEffect( () => {

    Aos.init({ duration: 3000 });

  } )

  return (
      <>
        <Helmet>
          <title>Earnestroi Technology | Content Marketing | Digital Marketing Company</title>
          <meta name="title" content="Earnestroi Technology | Content Marketing | Digital Marketing Company" />
          <meta name="description" content="Our unique content marketing strategy is the key to connect with our target audience and growing your business." />
          <meta name="keywords" content='content marketing, content strategy, marketing plan, online marketing, online marketing company' />
          <meta name="robots" content="index,follow" />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="language" content="English" />
        </Helmet>
        <section>
          <div className='  bg-[white] py-12'>
            <div className='container mx-auto my-4 lg:my-16'>
              <div className='flex flex-col md:flex-row justify-center items-center'>
                <div className=''>
                  <h1 className='text-second text-5xl lg:text-6xl mb-4 mt-4 lg:mt-0 capitalize font_primary' data-aos="fade-left">Content Marketing</h1>
                </div>
                <div className=''>
                  <img src={ content_m_1 } alt='Content Marketing' />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='bg-main px-1 xl:p-0'>
          <div className='container mx-auto'>
            <div className='flex justify-center py-4 lg:py-10'>
              <Link to='/'><h2 className='mx-2 text-second font_primary'><FontAwesomeIcon icon={ faHome } className='mx-2 text-second' />Digital Marketing Agency</h2></Link><h2 className='mx-2 text-second'>/</h2><h2 className='mx-2 text-second font_primary'>Social Media Marketing</h2>
            </div>
          </div>
          <div className='container mx-auto py-4 lg:py-20'>
            <div className='w-full lg:w-[60%] mx-auto'>
              <p className='text-[white] text-center text-base sm:text-lg mb-4 lg:mb-8 font_secondary'>The distinction between a marketing campaign with and without high-quality content is similar to that between a buttered slice of warm bread and a dry slice of stale bread. According to a survey conducted in 2021, 47% of respondents said that they seek online downtime owing to digital weariness, and 59% of consumers felt that businesses had lost sight of the human component of the customer experience.</p>
              <p className='text-[white] text-center text-base sm:text-lg mb-4 lg:mb-8 font_secondary'>The objective of the game is to produce content that pleases your particular target group. According to our market research, the best way to reach your audience and boost engagement rates is by employing a combination of infographics, videos, tutorials, social media postings, and blogs.</p>
              <p className='text-[white] text-center text-base sm:text-lg mb-4 lg:mb-8 font_secondary'>In actuality, Google's search algorithms aim to go beyond  text to images, voice/podcasts, and videos, for this precise reason.</p>
            </div>
          </div>
          <div className='flex flex-col lg:flex-row justify-start items-center py-4 lg:py-16'>
            <div className='w-full lg:w-[25%]'>
              <img src={ content_m_2 } className='mx-auto lg:ml-auto lg:mr-0' alt="content_m_2" />
            </div>
            <div className='w-full lg:w-[50%]'>
              <div className='px-2'>
                <div className='border-b-2 pb-[2px] border-second w-full mb-4'>
                  <span className='text-3xl text-[white] font_primary'><span className='border-b-4 border-second text-[white]'>SERVICES</span> WE OFFER</span>
                </div>
                <p className='text-base sm:text-lg mb-4 text-[white] font_secondary' data-aos="fade-right">As they say, "content is king," and this couldn't be more true for digital marketing. We work hard to create unique content strategies that connect with your target audience.</p>
                <p className='text-base sm:text-lg mb-4 text-[white] font_secondary' data-aos="fade-right">In addition to making compelling videos for all social media platforms, including instagram reels, we also offer photography, content writing, social media post design, infographics, tutorials, and animations.Understanding your brand and determining what kinds of material would be most appropriate for your target audience are further steps in our onboarding process.</p>
                <p className='text-base sm:text-lg mb-4 text-[white] font_secondary' data-aos="fade-right">According to a market survey conducted in 2022, 86% of firms reported that videos increased website traffic, and 83% said that a video on their website enhanced the average consumer's time spent on their landing page.</p>
                <p className='text-base sm:text-lg mb-4 text-[white] font_secondary' data-aos="fade-right">We at Earnestroi will diversify your content types using statistical data and industry trends, bringing in fresh perspectives and assisting you in extending the potential of your brand!</p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className='flex flex-col lg:flex-row justify-around py-4 lg:py-20 relative'>
            <div className='px-2 w-full lg:w-[25%]'>
              <div className='border-b-2 border-second pb-[2px] w-full mb-4'>
                <p className='text-3xl text-black text-start font_primary text-second'><span className='border-b-0 lg:border-b-0 2xl:border-b-2 sm:border-b-2 border-second text-black'>GRAPHIC</span> DESIGNING</p>
              </div>
              <p className='text-base sm:text-lg mb-4 text-black text-start font_secondary'>The success of content marketing depends on effective design. Graphic design is necessary for content marketing to have an impact and good design is the secret to sticking out. Professional graphic designers are adept at using layout, typography, and colour to produce compelling designs. They are also familiar with branding principles and can give your content a unified appearance and feel.</p>
            </div>
            <div className='px-2 w-full lg:w-[25%]'>
              <div className='border-b-2 border-second pb-[2px] w-full mb-4'>
                <p className='text-3xl text-black text-start font_primary text-second'><span className='border-b-0 lg:border-b-0 2xl:border-b-2 sm:border-b-2 border-second text-black'>Video</span> Content Creation</p>
              </div>
              <p className='text-base sm:text-lg mb-4 text-black text-start font_secondary'>Every company wants to invest in video content to give its customers compelling experiences. There are several reasons why video content is crucial, not the least of which is the fact that demand for it is rapidly increasing. Video may be a fantastic method to engage clients and foster loyalty because it is such a visual medium. We assist companies in producing compelling videos for brand-centric and enhance their visual identity.</p>
            </div>
            <img src={ content_m_3 } alt='content_m_3' className='absolute top-0 right-0 -z-10 opacity-75' />
          </div>
          <div className='flex flex-col lg:flex-row justify-around py-4 lg:py-20'>
            <div className='px-2 w-full lg:w-[25%]'>
              <div className='border-b-2 border-second pb-[2px] w-full mb-4'>
                <p className='text-3xl text-black text-start font_primary text-second'><span className='border-b-0 lg:border-b-0 2xl:border-b-2 sm:border-b-2 border-second text-black'>DESIGN</span> ANIMATIONS</p>
              </div>
              <p className='text-base sm:text-lg mb-4 text-black text-start font_secondary'>What could be better, you wonder, than Earnestroi's imaginative post design? An animated post that's clever! Users are compelled to stop scrolling and monitor the post's progression by animation for some reason. Earnestroi specialises in creating imaginative animated posts that help brands attract customers and add more life to their content.</p>
            </div>
            <div className='px-2 w-full lg:w-[25%]'>
              <div className='border-b-2 border-second pb-[2px] w-full mb-4'>
                <p className='text-3xl text-black text-start font_primary text-second'><span className='border-b-0 lg:border-b-0 2xl:border-b-2 sm:border-b-2 border-second text-black'>PHOTOGRAPHY</span></p>
              </div>
              <p className='text-base sm:text-lg mb-4 text-black text-start font_secondary'>For businesses to properly use social media to market their goods or services, photography is crucial. The use of photography can encourage potential buyers to make a purchase. Anyone can snap a picture today thanks to technology, but not everyone can take a good one. In response, we are here.</p>
            </div>
          </div>
          <div className='flex flex-col lg:flex-row justify-around items-center py-4 lg:py-20'>
            <div className='px-2 w-full lg:w-[40%]'>
              <div className='border-b-2 border-second pb-[2px] w-full mb-4'>
                <p className='text-3xl text-black text-start font_primary text-second'><span className='border-b-0 lg:border-b-0 2xl:border-b-4 sm:border-b-4 border-second text-black'>WRITING</span> CONTENTS</p>
              </div>
              <h2 className='my-4 text-xl font_secondary'><b>How does the typical content creator reel in the fish? <span className='text-second font_primary'>Clickbait</span>.</b></h2>
              <p className='text-base mb-4 text-black text-start font_secondary' data-aos="fade-left">Unlike others, though, Earnestroi has perfected the art of content authoring. For blog posts, press releases, articles, and websites, we offer content writing services to our clients.</p>
              <p className='text-base mb-4 text-black text-start font_secondary' data-aos="fade-left">Writing fresh, distinctive content is only one aspect of content creation in the post-pandemic era.</p>
              <p className='text-base mb-4 text-black text-start font_secondary' data-aos="fade-left">Earnestroi is aware of the relationship between content marketing, SEO, and the effective use of strategic keywords.</p>
              <p className='text-base mb-4 text-black text-start font_secondary' data-aos="fade-left">We agree that blogging is a tool that is not used enough. Over 20 billion pages are viewed each month by over 409 million persons worldwide. A search engine was shown to index 434% more pages on websites with blogs. But blogs are completely foreign to me.</p>
              <p className='text-base mb-4 text-black text-start font_secondary' data-aos="fade-left">Through the identification of visitor segments with specific preferences or needs and the creation of tailored experiences for them, our skilled content writing team also specialises in developing personalization strategies for our clients.</p>
              <p className='text-base mb-4 text-black text-start font_secondary' data-aos="fade-left">Writer's block will be history once you join our team!</p>
            </div>
            <div className='px-2 w-full lg:w-[40%]'>
              <img src={ content_m_4 } alt='Content Marketing 1' className='mx-auto block' />
            </div>
          </div>
        </section>
        <section className='bg-main py-4 lg:py-32'>
        <div className='container mx-auto'>
          <div className='flex flex-col lg:flex-row justify-between items-center'>
            <div className='w-full lg:w-[40%]'>
              <h2 className='text-base sm:text-[1.4rem] w-full text-center text-[white] font_secondary'>Get found by the appropriate people! Allow us to assist you in doing so naturally.</h2>
              <br /><br />
              <Link to='/contactsl' className='relative text-[white] hover:text-second p-2 bg-second font_primary hover:bg-[white] rounded-lg mx-auto block w-max border-2 border-second'>Talk To Us <FontAwesomeIcon icon={faArrowRight} className=' pl-4 animate-right_left'/></Link>
            </div>
            <div className='w-full lg:w-[40%]'>
              <h2 className='text-base sm:text-[1.4rem] w-full text-center text-[white] font_secondary'>Do you wish to learn more? Explore our blog to learn more about content marketing's genius!</h2>
              <br /><br />
              <Link to='/blog' className='relative text-[white] hover:text-second p-2 bg-second font_primary hover:bg-[white] rounded-lg mx-auto block w-max border-2 border-second'>Explore <FontAwesomeIcon icon={faArrowRight} className=' pl-4 animate-right_left'/></Link>
            </div>
          </div>
        </div>
      </section>
      <Footern />
      </>
  )
}

export default ContentM;