import React from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faUserCheck, faEarth, faUserGroup, faScrewdriverWrench, faVolumeHigh } from '@fortawesome/free-solid-svg-icons';

const TimelineMetaverse = () => {
  return (
    <>
        <VerticalTimeline animate={ true } lineColor={ '#77c1dd' }>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                // date="2008 - 2010"
                iconStyle={{ background: 'rgb(0, 0, 0)' }}
                icon={<FontAwesomeIcon icon={ faChartLine } className='text-3xl font_primary text-third' />}
            >
                <h2 className='text-xl lg:text-3xl text-third font_primary my-4'>New Revenue Streams</h2>
                <p className='font_secondary'>Digital items have been sold for millions of dollars on websites like Decentraland, Sandbox, Fortnite, and others. To open up new business opportunities, create and distribute digital twins for users in the Metaverse.</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                // date="2006 - 2008"
                iconStyle={{ background: 'rgb(0, 0, 0)' }}
                icon={<FontAwesomeIcon icon={ faUserCheck } className='text-3xl font_primary text-third' />}
            >
                <h2 className='text-xl lg:text-3xl text-third font_primary my-4'>Better Customer Experience</h2>
                <p className='font_secondary'>Use virtual reality and artificial intelligence (AR) technology to allow customers to interact with your products and services (VR). Improve your customers' overall experience and hasten their purchasing procedures.</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                // date="April 2013"
                iconStyle={{ background: 'rgb(0, 0, 0)' }}
                icon={<FontAwesomeIcon icon={ faEarth } className='text-3xl font_primary text-third' />}
            >
                <h2 className='text-xl lg:text-3xl text-third font_primary my-4'>Global Reach</h2>
                <p className='font_secondary'>Profit from the chance to provide your goods and services to clients anywhere in the world, regardless of where you are. Make sure you don't have geographical restrictions limiting your earning potential.</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                // date="November 2012"
                iconStyle={{ background: 'rgb(0, 0, 0)' }}
                icon={<FontAwesomeIcon icon={ faUserGroup } className='text-3xl font_primary text-third' />}
            >
                <h2 className='text-xl lg:text-3xl text-third font_primary my-4'>Better Employee Engagement</h2>
                <p className='font_secondary'>Create a collaborative space where anybody can contribute and exchange ideas, no matter where they are. Simplify employee training and business communications.</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                // date="November 2012"
                iconStyle={{ background: 'rgb(0, 0, 0)' }}
                icon={<FontAwesomeIcon icon={ faScrewdriverWrench } className='text-3xl font_primary text-third' />}
            >
                <h2 className='text-xl lg:text-3xl text-third font_primary my-4'>Better Product Development</h2>
                <p className='font_secondary'>You can learn how your clients respond to your offerings using digital twins. You can raise your revenue by introducing comparable products into the actual world.</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                // date="November 2012"
                iconStyle={{ background: 'rgb(0, 0, 0)' }}
                icon={<FontAwesomeIcon icon={ faVolumeHigh } className='text-3xl font_primary text-third' />}
            >
                <h2 className='text-xl lg:text-3xl text-third font_primary my-4'>Advertising Opportunities</h2>
                <p className='font_secondary'>Promote your products and services to individuals who are located all over the world in the Metaverse. Meet the diverse needs of your audience by broadening your audience.</p>
            </VerticalTimelineElement>
        </VerticalTimeline>
    </>
  )
}

export default TimelineMetaverse;