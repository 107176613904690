import React, { useState, useEffect } from 'react'
import Modal from './ModalCompo';

const EgModalShow = () => {

    const [show, setShow] = useState(false);

    useEffect(()=>{
        setTimeout(()=>{
          setShow(true)
        }, 5000)
    }, [])
    
  return (
    <>
        <div className='mt-20'>
            {/* <button onClick={ () =>  setShow(true) }>Show Modal</button> */}
            <Modal show={ show } onClose={ () => { setShow( false ) } } />
        </div>
    </>
  )
}

export default EgModalShow;