import React from 'react';
import { Fragment, useState } from "react";
import { Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faFilePen, faLaptopCode, faCircleDollarToSlot, faEnvelopeCircleCheck } from '@fortawesome/free-solid-svg-icons';

const AccordionComponentOne = () => {

    const [open, setOpen] = useState(3);

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };


  return (
    <>
        <div className=' text-center w-full lg:w-[40%] px-2'>
            <Fragment>
                <Accordion open={open === 1}>
                    <AccordionHeader className='text-[white] pl-4 font_primary font-light justify-start border-[1px] border-third rounded-xl my-2 shadow-[0px_0px_6px_#fff]' onClick={() => handleOpen(1)}>
                        <FontAwesomeIcon icon={ faChartLine } className='text-5xl text-[white] mx-2' />SEO
                    </AccordionHeader>
                    <AccordionBody className='text-[white] font_secondary text-left text-base p-2 rounded-lg'>
                        Increase website traffic and visibility in Search Engine Result Pages (SERPs).
                    </AccordionBody>
                </Accordion>
                <Accordion open={open === 2}>
                    <AccordionHeader className='text-[white] pl-4 font_primary font-light justify-start border-[1px] border-second rounded-xl my-2 shadow-[0px_0px_6px_#fff]' onClick={() => handleOpen(2)}>
                    <FontAwesomeIcon icon={ faCircleDollarToSlot } className='text-5xl text-[white] mx-2' />
                    PAY PER CLICK
                    </AccordionHeader>
                    <AccordionBody className='text-[white] font_secondary text-left text-base p-2 rounded-lg'>
                    Our PPC service provides excellent value for money. 
                    We optimise your campaigns to improve your Quality Score, 
                    which can lead to more quality clicks and a higher ROI.
                    </AccordionBody>
                </Accordion>
                <Accordion open={open === 3}>
                    <AccordionHeader className='text-[white] pl-4 font_primary font-light justify-start border-[1px] border-[#e3b503] rounded-xl my-2 shadow-[0px_0px_6px_#fff]' onClick={() => handleOpen(3)}>
                    <FontAwesomeIcon icon={ faLaptopCode  } className='text-5xl text-[white] mx-2' />
                    WEBSITE DESIGN
                    </AccordionHeader>
                    <AccordionBody className='text-[white] font_secondary text-left text-base p-2 rounded-lg'>
                    Give your company a customer-centric design that UX and UI science support.
                    </AccordionBody>
                </Accordion>
                <Accordion open={open === 4}>
                    <AccordionHeader className='text-[white] pl-4 font_primary font-light justify-start border-[1px] border-[red] rounded-xl my-2 shadow-[0px_0px_6px_#fff]' onClick={() => handleOpen(4)}>
                    <FontAwesomeIcon icon={ faFilePen } className='text-5xl text-[white] mx-2' />
                    CONTENT WRITING
                    </AccordionHeader>
                    <AccordionBody className='text-[white] font_secondary text-left text-base p-2 rounded-lg'>
                    Create actionable content. Content that helps users navigate the website and convert.
                    </AccordionBody>
                </Accordion>
                <Accordion open={open === 5}>
                    <AccordionHeader className='text-[white] pl-4 font_primary font-light justify-start border-[1px] border-[#56f85b] rounded-xl my-2 shadow-[0px_0px_6px_#fff]' onClick={() => handleOpen(5)}>
                    <FontAwesomeIcon icon={ faLaptopCode } className='text-5xl text-[white] mx-2' />
                    WEBSITE DEVELOPMENT
                    </AccordionHeader>
                    <AccordionBody className='text-[white] font_secondary text-left text-base p-2 rounded-lg'>
                    We can help you power your business. Use the appropriate technologies to create a result-oriented website.
                    </AccordionBody>
                </Accordion>
                <Accordion open={open === 6}>
                    <AccordionHeader className='text-[white] pl-4 font_primary font-light justify-start border-[1px] border-[#eb64ad] rounded-xl my-2 shadow-[0px_0px_6px_#fff]' onClick={() => handleOpen(6)}>
                    <FontAwesomeIcon icon={ faEnvelopeCircleCheck } className='text-5xl text-[white] mx-2' />
                    EMAIL MARKETING
                    </AccordionHeader>
                    <AccordionBody className='text-[white] font_secondary text-left text-base p-2 rounded-lg'>
                    With effective strategy and communication, you can reach your target audience.
                    </AccordionBody>
                </Accordion>
            </Fragment>
        </div>
    </>
  )
}

export default AccordionComponentOne;