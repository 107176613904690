import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome} from '@fortawesome/free-solid-svg-icons';
import graphics_1 from '../img/earnestroi-email-marketing-one.png';
import graphics_2 from '../img/earnestroi-email-marketing-two.png';
import Aos from 'aos';
import 'aos/dist/aos.css';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// import WhatsappChat from './WhatsappChat';
import Footern from './Footern';

const EmailMarketing = () => {

  useEffect( () => {

    Aos.init({

      disable: function() {
        var maxWidth = 1023;
        return window.innerWidth < maxWidth;
      }

    });

    $(document).ready(function(){
        $(this).scrollTop(0);
    });

  } );

  useEffect( () => {

    Aos.init({ duration: 3000 });

  } )

  return (
    <>
      <Helmet>
        <title>Earnestroi Tecnology | Email Marketing | Online Marketing</title>
        <meta name="title" content="Earnestroi Tecnology | Email Marketing | Online Marketing" />
        <meta name="description" content="Email marketing delivers more results than any other marketing medium. As a result, it can generate leads and sales at a much greater rate than other forms of digital marketing." />
        <meta name="keywords" content='email marketing, online marketing, Digital Marketing Agency, Online Marketing Services, digital marketing agency' />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
      </Helmet>
      <div className=' py-10'>
        <div className='container mx-auto'>
          <div className='flex flex-col lg:flex-row justify-center items-center'>
            <img src={ graphics_1 } className='' alt='Email Marketing 1' />
            <h1 className='text-second text-5xl lg:text-6xl mb-4 mt-4 lg:mt-0 capitalize font_primary' data-aos="fade-right">Email Marketing</h1>
          </div>
        </div>
        <div className='container mx-auto'>
          <div className='flex justify-center py-4 lg:py-10'>
            <Link to='/'><h2 className='mx-2 text-second font_primary'><FontAwesomeIcon icon={ faHome } className='mx-2 text-second' />Digital Marketing Agency</h2></Link><h2 className='mx-2'>/</h2><h2 className='mx-2 text-second font_primary'>Email Marketing</h2>
          </div>
        </div>
        <div className='container mx-auto py-4 lg:py-16'>
          <div className='flex flex-col-reverse lg:flex-row justify-center items-center px-4 lg:px-0'>
            <div className='container mx-auto my-12 lg:my-16 border-l-2 border-second w-full lg:w-1/2 py-6'>
              <p className='pl-8 font_secondary'>YOU'VE NEVER HAD THE BEST!</p>
              <br />
              <h2 className='text-base sm:text-3xl w-full lg:w-3/4 border-l-4 border-second pl-8 font_secondary' data-aos="fade-up">Email marketing is the most effective digital marketing strategy when it comes to creating and converting prospects.</h2>
              <br />
              <p className='pl-8 w-full lg:w-3/4 my-2 lg:my-4 font_secondary'>Thousands of companies utilize email marketing as part of their digital marketing strategies to promote their brands, increase revenue, and cultivate customer connections.</p>
              <p className='pl-8 w-full lg:w-3/4 my-2 lg:my-4 font_secondary'>To send out automated, tailored marketing emails with brand promotions and announcements, email marketing organizations use a variety of email service providers (ESPs). Email marketing can include everything from weekly newsletters and promotional notifications to customer survey forms and event invitations.</p>
              <p className='pl-8 w-full lg:w-3/4 my-2 lg:my-4 font_secondary'>A staggering 87 percent of business-to-business (B2B) marketers and 79% of business-to-consumer (B2C) advertisers utilise email as a distribution medium for content globally. Are you making the most of email marketing services to expand and market your company? How has your email marketing strategy performed in the cutthroat digital environment?</p>
            </div>
            <div className='w-full lg:w-1/2'>
              <img src={ graphics_2 } alt='Email Strategy ' className='mx-auto block' />
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className='grid-cols-2 flex flex-col lg:flex-row justify-center items-center'>
          <div className='w-full lg:w-1/2 bg-bg_graphic bg-cover bg-center px-8'>
            <div className='container mx-auto my-12 lg:my-16 border-l-2 border-second w-full py-6 invisible'>
              <p className='pl-8 text-[white] hidden sm:block font_secondary'>EMAIL MARKETIGN SERVICES</p>
              <br />
              <h2 className='text-base hidden sm:block sm:text-3xl w-full lg:w-3/4 border-l-4 border-second pl-8 text-[white] font_primary' data-aos="fade-up">Deliver greater value to your clients and achieve significant results.</h2>
              <br />
              <p className='pl-8 w-full hidden sm:block lg:w-3/4 my-4 text-[white] font_secondary'>It is impossible to overstate the role that email marketing plays in the success of a digital brand. One of the best marketing strategies for generating business leads and providing a high return on investment is online email marketing (ROI).</p>
              <p className='pl-8 w-full lg:w-3/4 my-4 text-[white] font_secondary'>According to studies, roughly 83 percent of consumers spend more money with a business after receiving web email marketing communications, and about 61 percent of email subscribers say they would like to receive promotional marketing emails every week. Additionally, marketers reported a staggering 3,800% ROI, or $38 for every dollar spent on an email marketing campaign.</p>
            </div>
          </div>
          <div className='w-full lg:w-1/2 bg-main px-8'>
            <div className='container mx-auto my-12 lg:my-16 border-l-2 border-second w-full py-6'>
              <p className='pl-8 text-[white] font_secondary'>EMAIL MARKETIGN SERVICES</p>
              <br />
              <h2 className='text-base sm:text-3xl w-full lg:w-3/4 border-l-4 border-second pl-8 text-[white] font_primary' data-aos="fade-up">Deliver greater value to your clients and achieve significant results.</h2>
              <br />
              <p className='pl-8 w-full lg:w-3/4 my-4 text-[white] font_secondary'>It is impossible to overstate the role that email marketing plays in the success of a digital brand. One of the best marketing strategies for generating business leads and providing a high return on investment is online email marketing (ROI).</p>
              <p className='pl-8 w-full lg:w-3/4 my-4 text-[white] font_secondary'>According to studies, roughly 83 percent of consumers spend more money with a business after receiving web email marketing communications, and about 61 percent of email subscribers say they would like to receive promotional marketing emails every week. Additionally, marketers reported a staggering 3,800% ROI, or $38 for every dollar spent on an email marketing campaign.</p>
            </div>
          </div>
        </div>
      </section>
      <Footern />
    </>
  )
}

export default EmailMarketing;