import React, { useEffect } from 'react';
import corn from '../img/ninepages/earnestroi-Metaverse-Development-Services.jpg';
import img_3 from '../img/ninepages/earnestroi-Make-your-metaverse-development.jpg';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUsers, faGears, faHeadphonesSimple, faFileExport, faCode, faNetworkWired, faUserAstronaut, faUserGroup, faImage, faHandshake} from '@fortawesome/free-solid-svg-icons';
import { faShieldHalved } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import TimelineMetaverse from './TimelineMetaverse';
import Footern from './Footern';
import { Helmet } from 'react-helmet';

const MetaverseDevelopment = () => {

  useEffect( () => {

    $(document).ready(function(){
      $(this).scrollTop(0);
    });

    $(document).ready(function(){
      
      $('.slick-prev').hide();
      $('.slick-next').hide();

    });

    Aos.init({

      disable: function() {
        var maxWidth = 1024;
        return window.innerWidth < maxWidth;
      }

    });

} );

  useEffect( () => {

    Aos.init({ duration: 3000 });

} );
  
  return (
    <>
        <Helmet>
          <title>Earnestroi Technology | Metaverse Development | marketing strategy</title>
          <meta name="title" content="Earnestroi Technology | Metaverse Development | marketing strategy" />
          <meta name="description" content="We are interested in working with developers, designers and entrepreneurs who have the vision and creative drive to create new blockchain-based metaverse applications." />
          <meta name="keywords" content='meta verse, world best business opportunity, fullstack dev, software development, Internet Marketing, Digital Marketing' />
          <meta name="robots" content="index,follow" />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="language" content="English" />
        </Helmet>
        <section className=' lg:pt-4 xl:pt-0 bg-main px-1 xl:px-0'>
          <div className='container mx-auto'>
            <div className='flex flex-col-reverse lg:flex-row justify-center items-center xl:pt-20'>
              <div className='flex flex-col w-full lg:w-1/2'>
                  <h1 className='text-[white] text-5xl lg:text-6xl mb-4 mt-4 lg:mt-0 font_primary capitalize' data-aos="fade-left">Metaverse Development Services</h1>
                  <br />
                  <h2 className='text-base lg:text-2xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third'  data-aos="fade-left">Utilize our knowledge of blockchain, AR/VR, and AI to enter a new internet era</h2>
                  <Link to='/contact' data-aos="fade-left" className='text-base my-2  w-max border-2 bg-third px-2 py-1 rounded-lg hover:bg-main hover:shadow-[0_0_12px_#77c1dd] hover:text-third m-0 hover:border-third font_primary' >Lets Talk</Link>
              </div>
              <div className=' w-full lg:w-1/2'>
                <img src={corn} alt='Meta verse development service' className='mx-auto w-1/2 sm:w-1/4 lg:w-3/4' />
              </div>
            </div>
          </div>
        </section>
        <section className='bg-main py-4 lg:py-10'>
          <div className='container mx-auto'>
            <div className='flex justify-center py-4 lg:py-10'>
              <Link to='/'><h2 className='mx-2 text-third font_primary'><FontAwesomeIcon icon={ faHome } className='mx-2 text-third' />Digital Marketing Agency</h2></Link><h2 className='mx-2 text-third'>/</h2><h2 className='mx-2 text-third font_primary'>Metaverse Development</h2>
            </div>
          </div> 
        </section>
        <section className='py-4 md:py-10'>
          <div className='container mx-auto'>
            <div className='grid-cols-2 flex flex-col lg:flex-row justify-between items-center'>
              <div className=''>
                <img src={img_3} alt='Meta verse Jounery ' className='my-8 lg:my-0' />
              </div>
              <div className='lg:w-[40%] lg:px-2 p-2 sm:p-0'>
                <h2 className='text-2xl lg:text-3xl font_primary text-third'>Make your metaverse development journey more accessible and faster.</h2>
                <br /><br />
                <p className='text-base font_secondary' data-aos="fade-right">The use of Metaverse is multiplying. By 2026, 30% of global enterprises will have services and products prepared for the Metaverse, predicts Gartner. Businesses should take action to obtain a crucial competitive advantage. We combine our metaverse developers and designers to plan, execute, and accelerate our clients' metaverse journeys. We create sophisticated, aesthetically appealing, graphically interactive blockchain-based metaverse applications with features like user sovereignty, complete transparency, increased privacy, and automation. We can assist you in the creation of your metaverse applications.</p>
                <br />  
              </div>
            </div>
          </div>
        </section>
        <section className='py-4 md:py-20 bg-main'>
          <div className='container mx-auto pt-4 lg:pt-8'>
            <div className='w-full lg:w-[60%] mx-auto'>
              <h2 className='text-lg lg:text-2xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third text-center' data-aos='fade-up'>Our Solutions for Metaverse Development</h2>
              <p className=' text-center text-base sm:text-lg mb-4 lg:mb-8 font_secondary text-[white]'  data-aos='fade-up'>We offer end-to-end solutions to make it easier for you to join the Metaverse and participate.</p>
            </div>
          </div>
          <div className='container mx-auto'>
            <div className='grid sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-8 justify-center items-center'>
              <div className='p-4 h-full hover:bg-[#77c1dd14] hover:cursor-pointer rounded-2xl'>
                <FontAwesomeIcon icon={ faCode } className='text-5xl text-third' />
                <h2 className='text-lg mb-2 font_primary text-third'>Metaverse App Development</h2>
                <p className='text-base font_secondary text-[white]'>Our creative metaverse development team creates immersive experiences for many use cases, including real estate, gaming, fashion, entertainment, social gatherings, and more.</p>
              </div>
              <div className='p-4 h-full hover:bg-[#77c1dd14] hover:cursor-pointer rounded-2xl'>
                <FontAwesomeIcon icon={ faNetworkWired } className='text-5xl text-third' />
                <h2 className='text-lg mb-2 font_primary text-third'>Metaverse 3D Space Development</h2>
                <p className='text-base font_secondary text-[white]'>Based on your company's use case and concept, our 3D modeling specialists develop one-of-a-kind 3D virtual locations that are just as good as the real.</p>
              </div>
              <div className='p-4 h-full hover:bg-[#77c1dd14] hover:cursor-pointer rounded-2xl'>
                <FontAwesomeIcon icon={ faUserAstronaut  } className='text-5xl text-third' />
                <h2 className='text-lg mb-2 font_primary text-third'>Metaverse 3D Avatar Development</h2>
                <p className='text-base font_secondary text-[white]'>We can effectively generate a digital twin for your Metaverse from any real estate, product, or city.</p>
              </div>
              <div className='p-4 h-full hover:bg-[#77c1dd14] hover:cursor-pointer rounded-2xl'>
                <FontAwesomeIcon icon={ faUserGroup } className='text-5xl text-third' />
                <h2 className='text-lg mb-2 font_primary text-third'>Metaverse Digital Twin Development</h2>
                <p className='text-base font_secondary text-[white]'>Create NFT tokens with distinct product identity and authentication; NFTs are excellent instruments for addressing product duplication.</p>
              </div>
              <div className='p-4 h-full hover:bg-[#77c1dd14] hover:cursor-pointer rounded-2xl'>
                <FontAwesomeIcon icon={ faImage } className='text-5xl text-third' />
                <h2 className='text-lg mb-2 font_primary text-third'>Metaverse Land NFT Development</h2>
                <p className='text-base font_secondary text-[white]'>To enable metaverse owners to put their enterprises on virtual land, we create land NFTs for all business use cases.</p>
              </div>
              <div className='p-4 h-full hover:bg-[#77c1dd14] hover:cursor-pointer rounded-2xl'>
                <FontAwesomeIcon icon={ faHandshake } className='text-5xl text-third' />
                <h2 className='text-lg mb-2 font_primary text-third'>Metaverse Consulting</h2>
                <p className='text-base font_secondary text-[white]'>Our business-oriented consulting services will simplify your path there if you are new to the Metaverse or even seasoned but require professional guidance on anything linked to the Metaverse.</p>
              </div>
            </div>
          </div>
        </section>
        <section className='py-4 lg:py-10 px-1 xl:px-0'>
            <div className='container mx-auto py-4 lg:py-12'>
            <div className=''>
                <h2 className='mx-auto w-full lg:w-[70%] text-xl lg:text-3xl mb-2 mt-4 lg:mt-0 font_primary capitalize text-third text-center'>Business Benefits of Metaverse App Development</h2>
            </div>
            </div>
            <TimelineMetaverse />
        </section>
        <section className='bg-main py-4 lg:py-12 px-1 xl:px-0'>
          <div className='container mx-auto pt-4 lg:pt-8'>
            <div className='w-full lg:w-[60%] mx-auto'>
              <h2 className='text-lg lg:text-2xl mt-4 lg:mt-0 font_primary capitalize text-third text-center mb-4 lg:mb-8'>Why are we the best company for metaverse development?</h2>
            </div>
            <div className='container mx-auto pb-4 md:pb-6'>
              <div className='grid sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
                <div className='p-4 h-full bg-[#77c1dd14] rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md '>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faShieldHalved } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className='mb-0 text-base font_primary text-center text-third'>Technical Process</h2>
                  </div>
                  <p className='text-base font_secondary my-4 text-center text-[white]'>We exclusively work in Blockchain technologies. We just concentrate on one thing, and we excel at it.</p>
                </div>
                <div className='p-4 h-full bg-[#77c1dd14] rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md '>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faUsers } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className='mb-0 text-base font_primary text-center text-third'>Expert Team</h2>
                  </div>
                  <p className='text-base font_secondary my-4 text-center text-[white]'>With the support of our 500 professionals, you can improve your offering, get the finest IT solution, and even create communities and campaigns.</p>
                </div>
                <div className='p-4 h-full bg-[#77c1dd14] rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md '>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faGears } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className='mb-0 text-base font_primary text-center text-third'>Rapid Development</h2>
                  </div>
                  <p className='text-base font_secondary my-4 text-center text-[white]'>We offer goods that are especially suited to your intended consumer base. A well-organized roadmap ensures rapid and efficient development and implementation.</p>
                </div>
              </div>
            </div>
            <div className='container mx-auto pt-4 md:pt-6'>
              <div className='flex flex-col lg:flex-row lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
                <div className='p-4 h-full lg:w-[30%] xl:w-1/4 2xl:w-[30%] w-full bg-[#77c1dd14] rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md '>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faHeadphonesSimple } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className='mb-0 text-base font_primary text-center text-third'>Complete Support</h2>
                  </div>
                  <p className='text-base font_secondary my-4 text-center text-[white]'>After the product launch, we continue to work and provide post-delivery support.</p>
                </div>
                <div className='p-4 h-full lg:w-[30%] xl:w-1/4 2xl:w-[30%] w-full bg-[#77c1dd14] rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md '>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faFileExport } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className='mb-0 text-base font_primary text-center text-third'>Meaningful Outcomes</h2>
                  </div>
                  <p className='text-base font_secondary my-4 text-center text-[white]'>We make a real difference. We make sure your investments have value for you.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <Footern />
        </section>
    </>
  )
}

export default MetaverseDevelopment;