import React, { useEffect } from 'react';
import img_2_1 from '../img/earnestroi-services-new.webp';
// import img_2_2 from '../img/earnestroi-careers-aboutus.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import Aos from 'aos';
import 'aos/dist/aos.css';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import Footern from './Footern';
import AccordionComponentOne from './AccordionComponentOne';
import AccordionComponentTwo from './AccordionComponentTwo';
import industry1 from '../img/landing/industry/earnestroi-landing-campaign-beauty.webp';
import industry2 from '../img/landing/industry/earnestroi-landing-campaign-education.webp';
import industry3 from '../img/landing/industry/earnestroi-landing-campaign-fitness.webp';
import industry4 from '../img/landing/industry/earnestroi-landing-campaign-automobile.webp';
import industry5 from '../img/landing/industry/earnestroi-landing-campaign-healthcare.webp';
import industry6 from '../img/landing/industry/earnestroi-landing-campaign-teachnology.webp';
import industry7 from '../img/landing/industry/earnestroi-landing-campaign-real-state.webp';
import industry8 from '../img/landing/industry/earnestroi-landing-campaign-travel.webp';
import DevelopmentAccordionOne from '../Components/DevelopmentAccordionOne';
import DevelopmentAccordionTwo from '../Components/DevelopmentAccordionTwo';
import { Helmet } from 'react-helmet';
// import WhatsappChat from './WhatsappChat';

const Careers = () => {

  useEffect( () => {

    Aos.init({

      disable: function() {
        var maxWidth = 1023;
        return window.innerWidth < maxWidth;
      }

    }); 


    $(document).ready(function(){
        $(this).scrollTop(0);
    });

  } );

  useEffect( () => {

    Aos.init({ duration: 3000 });

  } )

  return (
    <>
      <Helmet>
        <title>Services | Earnestroi</title>
      </Helmet>
      <section className='  py-10'>
        <div className='container mx-auto'>
          <div className='flex flex-col lg:flex-row justify-center items-center'>
            <img src={img_2_1} className='' alt='Service Page ' />
            <h1 className='text-second text-5xl lg:text-6xl mb-4 mt-4 lg:mt-0 capitalize font_primary text-center' data-aos="fade-right">Services</h1>
          </div>
        </div>
        <div className='container mx-auto'>
          <div className='flex justify-center py-4 lg:py-10'>
            <Link to='/'><h2 className='mx-2 text-second font_primary'><FontAwesomeIcon icon={ faHome } className='mx-2 text-second font-primary' />Digital Marketing Agency</h2></Link><h2 className='mx-2'>/</h2><h2 className='mx-2 text-second font_primary'>Digital Marketing Services</h2>
          </div>
        </div>
      </section>
      <section className='py-6'>
        <h2 className='text-second text-center text-2xl md:text-3xl lg:text-5xl mb-8 lg:mt-0 font_primary capitalize'>Industry Expertise</h2>
        <div className='container mx-auto flex flex-row md:flex-col justify-center'>
            <div className='flex flex-col md:flex-row md:grid-cols-2 lg:grid-cols-4 gap-2 lg:gap-4 justify-center pb-0 md:pb-4'>
                <div className='mx-4'>
                    <img src={ industry1 } alt='earnestroi technologies' className='mx-auto block p-2 border-4 border-second rounded-full w-3/4' />
                    <p className='text-base sm:text-xl font_primary py-2 text-[black] text-center capitalize'>Beauty</p>
                </div>
                <div className='mx-4'>
                    <img src={ industry2 } alt='earnestroi technologies' className='mx-auto block p-2 border-4 border-second rounded-full w-3/4' />
                    <p className='text-base sm:text-xl font_primary py-2 text-[black] text-center capitalize'>education</p>
                </div>
                <div className='mx-4'>
                    <img src={ industry3 } alt='earnestroi technologies' className='mx-auto block p-2 border-4 border-second rounded-full w-3/4' />
                    <p className='text-base sm:text-xl font_primary py-2 text-[black] text-center capitalize'>Fitness</p>
                </div>
                <div className='mx-4'>
                    <img src={ industry4 } alt='earnestroi technologies' className='mx-auto block p-2 border-4 border-second rounded-full w-3/4' />
                    <p className='text-base sm:text-xl font_primary py-2 text-[black] text-center capitalize'>automobile</p>
                </div>
            </div>
            <div className='flex flex-col md:flex-row md:grid-cols-2 lg:grid-cols-4 gap-2 lg:gap-4 justify-center pt-0 md:pt-4 md:pb-4'>
                <div className='mx-4'>
                    <img src={ industry5 } alt='earnestroi technologies' className='mx-auto block p-2 border-4 border-second rounded-full w-3/4' />
                    <p className='text-base sm:text-xl font_primary py-2 text-[black] text-center capitalize'>healthcare</p>
                </div>
                <div className='mx-4'>
                    <img src={ industry6 } alt='earnestroi technologies' className='mx-auto block p-2 border-4 border-second rounded-full w-3/4' />
                    <p className='text-base sm:text-xl font_primary py-2 text-[black] text-center capitalize'>technology</p>
                </div>
                <div className='mx-4'>
                    <img src={ industry7 } alt='earnestroi technologies' className='mx-auto block p-2 border-4 border-second rounded-full w-3/4' />
                    <p className='text-base sm:text-xl font_primary py-2 text-[black] text-center capitalize'>real estate</p>
                </div>
                <div className='mx-4'>
                    <img src={ industry8 } alt='earnestroi technologies' className='mx-auto block p-2 border-4 border-second rounded-full w-3/4' />
                    <p className='text-base sm:text-xl font_primary py-2 text-[black] text-center capitalize'>travel</p>
                </div>
            </div>
        </div>
      </section>
      <section className='bg-main py-12 lg:py-16'>
        <div className='container mx-auto py-2'>
          <p className='text-2xl md:text-3xl lg:text-5xl text-second font_primary px-2 text-center'>Digital Marketing Services</p>
          <div className="flex flex-col lg:flex-row justify-around mt-10">
            <AccordionComponentOne />
            <AccordionComponentTwo />
          </div>
        </div>
      </section>
      <section className='py-12 lg:py-16'>
        <div className='container mx-auto py-2'>
          <p className='text-2xl md:text-3xl lg:text-5xl text-third font_primary px-2 text-center'>Development Services</p>
          <div className="flex flex-col lg:flex-row justify-around mt-10">
            <DevelopmentAccordionOne />
            <DevelopmentAccordionTwo />
          </div>
        </div>
      </section>
      <Footern />
    </>
  )
}

export default Careers;