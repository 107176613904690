import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import Header from './Header';

const Layout = ( { fixed } ) => {
  
    return (
      <>
        <div className="">
          <div className="content">
            <Header />
            <Outlet />
          </div>
        </div>
      </>
    );
}

export default Layout;