import React, { useEffect } from 'react';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome} from '@fortawesome/free-solid-svg-icons';
import corn from '../img/earnestroi-ai-one.png';
import ai_two from '../img/earnestroi-ai-two.png';
import Footern from './Footern';
import { Helmet } from 'react-helmet';
// import WhatsappChat from './WhatsappChat';

const ArtificialIntelligence = () => {
  
  useEffect( () => {

    $(document).ready(function(){
      $(this).scrollTop(0);
    });

    $(document).ready(function(){
      
      $('.slick-prev').hide();
      $('.slick-next').hide();

    });

    Aos.init({

      disable: function() {
        var maxWidth = 1024;
        return window.innerWidth < maxWidth;
      }

    });

} );

  useEffect( () => {

    Aos.init({ duration: 3000 });

} );
  

  return (
    <>
      <Helmet>
        <title>Earnestroi Technology | Artificial intelligence | programming </title>
        <meta name="title" content="Earnestroi Technology | Artificial intelligence | programming" />
        <meta name="description" content="Earnestroi is a provides artificial intelligence development services the best programming services for businesses across the globe." />
        <meta name="keywords" content='artificial intelligence, project management, digital marketing company, Creative Marketing Agency' />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
      </Helmet>
      <section className=' lg:pt-4 xl:pt-0 px-1 xl:p-0'>
        <div className='container mx-auto'>
          <div className='flex flex-col-reverse lg:flex-row justify-center items-center xl:pt-20'>
            <div className='flex flex-col w-full lg:w-1/2'>
                <h1 className='text-third text-5xl lg:text-6xl mb-4 mt-4 lg:mt-0 font_primary capitalize' data-aos="fade-left">Artificial Intelligence Services</h1>
                <br />
                {/* <h2 className='text-base lg:text-lg mb-4 mt-4 lg:mt-0 font_primary capitalize'  data-aos="fade-left">A.I. plays a significant role in helping machines learn from experience. It directs them to adapt to new inputs and carry out actions that humans would carry out. Natural language processing and deep learning are essential to every modern example, including self-driving automobiles and chess-playing computers. Through these technologies, you may teach a computer to perform specific tasks by analyzing massive volumes of data and finding patterns in the information. Earnestroi is the ideal option if you're looking for an artificial intelligence development firm.</h2> */}
            </div>
            <div className=' w-full lg:w-1/2'>
              <img src={corn} alt='Artificial Intelligence Service ' className='mx-auto w-full md:w-3/4 lg:w-3/4' />
            </div>
          </div>
        </div>
      </section>
      <section className=''>
        <div className='container mx-auto'>
          <div className='flex justify-center py-4 lg:py-10'>
            <Link to='/'><h2 className='mx-2 text-third font_primary'><FontAwesomeIcon icon={ faHome } className='mx-2 text-third' />Digital Marketing Agency</h2></Link><h2 className='mx-2 text-third'>/</h2><h2 className='mx-2 text-third font_primary'>Artificial Intelligence Services</h2>
          </div>
        </div>  
      </section>
      <section className='bg-main py-4 lg:py-20'>
        <div className='mb-20'>
          <h2 className='text-base lg:text-lg mb-4 mt-4 lg:mt-0 font_primary capitalize text-third text-center my-20 w-full md:w-3/4 mx-auto block'  data-aos="fade-left">A.I. plays a significant role in helping machines learn from experience. It directs them to adapt to new inputs and carry out actions that humans would carry out. Natural language processing and deep learning are essential to every modern example, including self-driving automobiles and chess-playing computers. Through these technologies, you may teach a computer to perform specific tasks by analyzing massive volumes of data and finding patterns in the information. Earnestroi is the ideal option if you're looking for an artificial intelligence development firm.</h2>
        </div>
        <div className='container mx-auto'>
          <div className='grid-cols-2 flex flex-col lg:flex-row justify-around items-center'>
            <div className='px-2 w-full lg:w-[50%]'>
              <div className='border-b-2 pb-[2px] border-third w-full mb-4'>
                <p className='text-3xl text-[white] font_primary'><span className='border-b-0 lg:border-b-0 2xl:border-b-4 sm:border-b-0 md:border-b-4 border-third text-[white]'>Artificial Intelligence</span> Development Company</p>
              </div>
              <div className='py-2'>
                <p className='mb-0 text-lg lg:text-xl text-third font_primary' data-aos='fade-right'>AI Development in Healthcare</p>
                <p className='mb-0 text-base text-[white] font_secondary' data-aos='fade-right'>Applications of A.I. can provide tailored medical care and X-ray readings. Personal health care assistants can be life coaches by reminding you to take medications, eat better, or exercise.</p>
              </div>
              <div className='py-2'>
                <p className='mb-0 text-lg lg:text-xl text-third font_primary' data-aos='fade-right'>AI Solutions in Retail</p>
                <p className='mb-0 text-base text-[white] font_secondary' data-aos='fade-right'>A.I. provides virtual shopping capabilities that offer customers individualized recommendations and debate different purchase options. A.I. can be used to advance site layout and inventory management technologies.</p>
              </div>
              <div className='py-2'>
                <p className='mb-0 text-lg lg:text-xl text-third font_primary' data-aos='fade-right'>AI Services in Manufacturing</p>
                <p className='mb-0 text-base text-[white] font_secondary' data-aos='fade-right'>Artificial intelligence considers factory IoT data because it comes from linked systems and is used to estimate projected demand and load using recurrent networks, a particular kind of deep learning network applied to series data.</p>
              </div>
              <div className='py-2'>
                <p className='mb-0 text-lg lg:text-xl text-third font_primary' data-aos='fade-right'>AI Development in Sports</p>
                <p className='mb-0 text-base text-[white] font_secondary' data-aos='fade-right'>A.I. is used to capture gameplay snapshots and provide coaches with reports on how they may improve game organization, including optimizing field strategy and locations.</p>
              </div>
            </div>
            <div className='px-2 w-full lg:w-[50%]'>
              <img src={ ai_two } alt='AI Company ' />
            </div>
          </div>
        </div>
      </section>
      <section className='py-8 lg:py-20'>
        <div className='container mx-auto'>
          <div className='w-full lg:w-[60%] mx-auto'>
            <h2 className='text-lg lg:text-3xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-dark text-center'>Importance Of Artificial Intelligence Development</h2>
          </div>
        </div>
        <div className='container mx-auto mt-8'>
          <div className='grid sm:grid-cols-2 lg:grid-cols-3 gap-4 xl:gap-8'>
            <div className='px-2 bg-[#77c1dd14] rounded py-2 border-t-8 border-third mx-2 sm:mx-0 my-4 md:my-0'>
              <p className='font_secondary'><span className='text-third text-xl font_primary'>AI uses data to automate repetitive learning and discovery,</span> but it is not the same as hardware-driven robotic automation. A.I. can reliably, and fatigue-free do high-volume, everyday automated operations, as opposed to automating manual tasks.</p>
            </div>
            <div className='px-2 bg-[#77c1dd14] rounded py-2 border-t-8 border-third mx-2 sm:mx-0 my-4 md:my-0'>
              <p className='font_secondary'><span className='text-third text-xl font_primary'>Artificial intelligence uses neural networks,</span> including hidden layers, to study more comprehensive data. A couple of years prior, making an extortion identification framework with five mystery levels was unthinkable.</p>
            </div>
            <div className='px-2 bg-[#77c1dd14] rounded py-2 border-t-8 border-third mx-2 sm:mx-0 my-4 md:my-0'>
              <p className='font_secondary'><span className='text-third text-xl font_primary'>Current products get intelligence thanks to AI,</span> which will only sometimes be made available as a standalone program. It may be added to products like Siri. Bots, conversational platforms, intelligent robots, and automation could work together.</p>
            </div>
            <div className='px-2 bg-[#77c1dd14] rounded py-2 border-t-8 border-third mx-2 sm:mx-0 my-4 md:my-0'>
              <p className='font_secondary'><span className='text-third text-xl font_primary'>Deep neural networks allow for remarkable accuracy in AI,</span> which was previously unachievable. For instance, deep learning is used in your interactions with Alexa, Google Photos, and Google Search, all of which continue to improve the more we use them.</p>
            </div>
            <div className='px-2 bg-[#77c1dd14] rounded py-2 border-t-8 border-third mx-2 sm:mx-0 my-4 md:my-0'>
              <p className='font_secondary'><span className='text-third text-xl font_primary'>AI adapts using algorithms for progressive learning,</span> allowing the data to perform the programming. An algorithm can learn this skill because A.I. makes patterns and structures in data visible: The set of rules becomes a predictor or a classifier</p>
            </div>
            <div className='px-2 bg-[#77c1dd14] rounded py-2 border-t-8 border-third mx-2 sm:mx-0 my-4 md:my-0'>
              <p className='font_secondary'><span className='text-third text-xl font_primary'>Algorithms are self-learning,</span> but AI makes the most of data; an information can be intellectual property. The answers are hidden in the numbers; you need to monitor AI to find them. A data's function is more important; it might result in competitive advantage.</p>
            </div>
          </div>
        </div>
      </section>
      <Footern />
    </>
  )
}

export default ArtificialIntelligence;