import React, { useState, useEffect } from 'react'
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faMessage, faRightFromBracket, faUsers } from '@fortawesome/free-solid-svg-icons';

const Admin = () => {

    const navigate = useNavigate();

    // useEffect(() => {
    //     if( !localStorage.getItem('JWT') ) {
    //         navigate("/admin");
    //     }
    //     //eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const logoutfunction = () => {
        localStorage.removeItem("JWT");
        navigate("/signin");
    }

const [open, setOpen] = useState(false);
  return (
    <section className=''>
            <div className="flex">
            <div className={` ${ open ? "w-40" : "w-60 " } flex flex-col h-screen p-3 bg-gray-800 shadow duration-300`} >
                <div className="space-y-3">
                    <div className="flex items-center justify-between">
                        <h2 className="text-lg text-white"> Dashboard </h2>
                        <button onClick={() => setOpen(!open)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2} >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h8m-8 6h16" />
                            </svg>
                        </button>
                    </div>
                    <div className="flex-1">
                        <ul className="pt-2 pb-4 space-y-1 text-sm">
                            <li className="rounded-sm">
                                <Link to="/admin/blogs" className="flex items-center p-2 space-x-3 rounded-md" >
                                    <FontAwesomeIcon icon={ faMessage } />
                                    <span className="text-gray-100">Blog</span>
                                </Link>
                            </li>
                            <li className="rounded-sm">
                                <Link to="/admin/contact-details" className="flex items-center p-2 space-x-3 rounded-md" >
                                    <FontAwesomeIcon icon={ faUsers } />
                                    <span className="text-gray-100">Contact</span>
                                </Link>
                            </li>
                            <li className="rounded-sm">
                                <Link to="/admin/resumes" className="flex items-center p-2 space-x-3 rounded-md" >
                                    <FontAwesomeIcon icon={ faFile } />
                                    <span className="text-gray-100">Resumes</span>
                                </Link>
                            </li>
                            <li className="flex items-center p-2 space-x-3 rounded-md hover:cursor-pointer" onClick={ logoutfunction }>
                                <FontAwesomeIcon icon={ faRightFromBracket } />
                                <span className="text-gray-100">Logout</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <Outlet />
        </div>
    </section>
  )
}

export default Admin;