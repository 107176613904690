import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import caro_1 from '../img/icons/earnestroi-icon-affiliate-marketing.png';
import caro_2 from '../img/icons/earnestroi-icon-analytics-insights.png';
import caro_3 from '../img/icons/earnestroi-icon-content-production.png';
import caro_4 from '../img/icons/earnestroi-icon-digital-branding.png';
import caro_5 from '../img/icons/earnestroi-icon-email-marketing.png';
import caro_6 from '../img/icons/earnestroi-icon-influencer-marketing.png';
import caro_7 from '../img/icons/earnestroi-icon-mobile-marketing.png';
import caro_8 from '../img/icons/earnestroi-icon-paid-ads-using-google-ads.png';
import caro_9 from '../img/icons/earnestroi-icon-programmatic-buying.png';
import caro_10 from '../img/icons/earnestroi-icon-search-engine-optimization.png';
import caro_11 from '../img/icons/earnestroi-icon-social-media-marketing.png';
import caro_12 from '../img/icons/earnestroi-icon-ui-ux-design.png';
import caro_13 from '../img/icons/earnestroi-icon-web-develop.png';

const Services = () => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        autoplay: true,
        responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
              }
            },
        ]
    };

  return (
    <>
        <div className="container mx-auto">
            <div className='border-b-2 border-second w-full mb-4 pb-[2px] lg:pb-[6px]'>
                <h1 className="text-3xl lg:text-5xl font_primary text-second"><span className='border-b-[4px] border-second text-3xl lg:text-5xl font_primary text-second'>Marketing</span> Services</h1>
            </div>
            <div>
                <Slider {...settings}>
                    <div className="">
                        <img alt='carousel_images' src={caro_1} className='mx-auto block' />
                        <p className='font_secondary text-base text-center text-[12px] sm:text-base'>Affiliate Marketing</p>
                    </div>
                    <div className="">
                        <img alt='carousel_images' src={caro_2} className='mx-auto block' />
                        <p className='font_secondary text-base text-center text-[12px] sm:text-base'>Analytics Insights</p>
                    </div>
                    <div className="">
                        <img alt='carousel_images' src={caro_3} className='mx-auto block' />
                        <p className='font_secondary text-base text-center text-[12px] sm:text-base'>Content Production</p>
                    </div>
                    <div className="">
                        <img alt='carousel_images' src={caro_4} className='mx-auto block' />
                        <p className='font_secondary text-base text-center text-[12px] sm:text-base'>Digital Branding</p>
                    </div>
                    <div className="">
                        <img alt='carousel_images' src={caro_5} className='mx-auto block' />
                        <p className='font_secondary text-base text-center text-[12px] sm:text-base'>Email Marketing</p>
                    </div>
                    <div className="">
                        <img alt='carousel_images' src={caro_6} className='mx-auto block' />
                        <p className='font_secondary text-base text-center text-[12px] sm:text-base'>Influencer Marketing</p>
                    </div>
                    <div className="">
                        <img alt='carousel_images' src={caro_7} className='mx-auto block' />
                        <p className='font_secondary text-base text-center text-[12px] sm:text-base'>Mobile Marketing</p>
                    </div>
                    <div className="">
                        <img alt='carousel_images' src={caro_8} className='mx-auto block' />
                        <p className='font_secondary text-base text-center text-[12px] sm:text-base'>Paid Ads Using Google Ads</p>
                    </div>
                    <div className="">
                        <img alt='carousel_images' src={caro_9} className='mx-auto block' />
                        <p className='font_secondary text-base text-center text-[12px] sm:text-base'>Programmatic Buying</p>
                    </div>
                    <div className="">
                        <img alt='carousel_images' src={caro_10} className='mx-auto block' />
                        <p className='font_secondary text-base text-center text-[12px] sm:text-base'>Search Engine Optimization</p>
                    </div>
                    <div className="">
                        <img alt='carousel_images' src={caro_11} className='mx-auto block' />
                        <p className='font_secondary text-base text-center text-[12px] sm:text-base'>Social Media Marketing</p>
                    </div>
                    <div className="">
                        <img alt='carousel_images' src={caro_12} className='mx-auto block' />
                        <p className='font_secondary text-base text-center text-[12px] sm:text-base'>Ui Ux Design</p>
                    </div>
                    <div className="">
                        <img alt='carousel_images' src={caro_13} className='mx-auto block' />
                        <p className='font_secondary text-base text-center text-[12px] sm:text-base'>Web Designing</p>
                    </div>
                </Slider>
            </div>
        </div>
    </>
  )
}

export default Services;