import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Modal = (props) => {
  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  });

  if (!props.show) {
    return null;
  }

  return (
    <div className="flex fixed inset-0 justify-center items-center z-50">
      <div
        className="modal-content lg:w-1/3 lg:h-1/3 bg-[#00000080] bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 border border-gray-100 rounded-lg shadow-[5px_5px_5px_FE8067]"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="button absolute top-1 right-1 px-2 rounded-full bg-rose-600 bg-[white] border-2 border-[white] font_primary"
          onClick={props.onClose}
        >
          X
        </button>
        <div className="modal-header rounded-t-lg pt-8 pb-4">
          <h2 className="modal-title text-center m-0 capitalize text-third font_primary text-2xl md:text-3xl lg:text-5xl">
            ARE YOU READY
          </h2>
          <br />
          <h2 className="modal-title text-center m-0 capitalize text-[white] font_primary text-xl md:text-3xl lg:text-5xl">
            to 3X your sales?
          </h2>
        </div>
        <div className="modal-body py-8">
          <Link
            to="/contacts-1"
            className="text-xl md:text-2xl lg:text-3xl mx-auto hover:shadow-[0_0_12px_#77c1dd] text-third border-2 border-third px-1 py-2 rounded-lg hover:text-[white] m-0 hover:border-third font_primary block w-3/4 md:w-1/4 text-center"
          >
            Lets Talk
          </Link>
          {/* <Link to='/contactsl' className='text-base mx-auto hover:shadow-[0_0_12px_#FE8067] text-second border-2 border-second bg-[white] px-4 py-2 rounded-lg hover:bg-[white] hover:text-second m-0 hover:border-second font_primary block w-1/4 text-center' >Lets Talk</Link> */}
        </div>
        <div className="modal-footer p-2 rounded-b-lg">
          {/* <h2 className='text-center font_secondary text-[14px] md:w-3/4 mx-auto block text-[white]'>Your Privacy is Important to us. We won't misuse your personal information.</h2> */}
        </div>
      </div>
    </div>
  );
};

export default Modal;
