import React, { useEffect } from 'react';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import img_2s from '../img/earnestroi-blockchain-solutions.png';
import corn from '../img/earnestroi-corn.jpg';
import img_3 from '../img/earnestroi-website-development.png';
import img_4 from '../img/earnestroi-market-making.png';
import img_5 from '../img/earnestroi-ico-marketing.png';
import img_1_altcoin from '../img/Altcoin-images/earnestroi-coin-sol-one.png';
import img_2_altcoin from '../img/Altcoin-images/earnestroi-coin-sol-two.png';
import img_3_altcoin from '../img/Altcoin-images/earnestroi-coin-sol-three.png';
import img_4_altcoin from '../img/Altcoin-images/earnestroi-coin-sol-four.png';
import img_5_altcoin from '../img/Altcoin-images/earnestroi-coin-sol-five.png';
import img_6_altcoin from '../img/Altcoin-images/earnestroi-coin-sol-six.png';
import img_7_altcoin from '../img/Altcoin-images/earnestroi-coin-sol-seven.png';
import img_8_altcoin from '../img/Altcoin-images/earnestroi-coin-sol-eight.png';
import BlockchainNetworks from './BlockchainNetworks';
import { Link } from 'react-router-dom';
import Footern from './Footern';
import { Helmet } from 'react-helmet';
// import WhatsappChat from './WhatsappChat';

const Blockchain = () => {
  
    useEffect( () => {

        $(document).ready(function(){
          $(this).scrollTop(0);
        });
    
        $(document).ready(function(){
          
          $('.slick-prev').hide();
          $('.slick-next').hide();
    
        });
    
        Aos.init({
    
          disable: function() {
            var maxWidth = 1024;
            return window.innerWidth < maxWidth;
          }
    
        });
    
      } );
    
      useEffect( () => {
    
        Aos.init({ duration: 3000 });
    
      } );
    
      return (
        <>
          <Helmet>
            <title>Earnestroi Technology | Blockchain | online marketingy</title>
            <meta name="title" content="Earnestroi Technology | Blockchain | online marketingy" /> 
            <meta name="description" content="We're a highly experienced Blockchain Development Team. We offer access to a variety of services that allow you to develop your business idea in the Blockchain Arena." /> 
            <meta name="keywords" content='blockchain developers, online marketing, digital marketing, project management, digital currencies' />
            <meta name="robots" content="index,follow" />
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <meta name="language" content="English" />
          </Helmet>
          <section className=' lg:pt-4 xl:pt-0 bg-main px-1 xl:p-0'>
            <div className='container mx-auto'>
              <div className='flex flex-col-reverse lg:flex-row justify-center items-center xl:pt-20'>
                <div className='flex flex-col w-full lg:w-1/2'>
                  <h2 className='text-[white] text-5xl lg:text-6xl mb-4 mt-4 lg:mt-0 font_primary capitalize' data-aos="fade-left">Applying Blockchain Technologies To The Real World</h2>
                  <br />
                  <h2 className='text-2xl md:text-3xl lg:text-6xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third text-center lg:text-left'  data-aos="fade-left">Trust. Dignity. Growth</h2>
                </div>
                <div className=' w-full lg:w-1/2'>
                  <img src={corn} alt='Blockchain' className='mx-auto w-3/4' />
                </div>  
              </div>
            </div>
            <div className='container mx-auto'>
              <div className='grid-cols-2 flex flex-col lg:flex-row justify-center items-center'>
                <div className='px-2 w-full lg:w-[50%] py-8 lg-py-4'>
                  <img src={img_2s} alt='Blockchain Solution' className='ml-auto'  />
                </div>
                <div className='px-2 w-full lg:w-[50%] py-8 lg-py-4'>
                  <div className='border-b-2 border-third w-full mb-4'>
                    <h1 className='text-3xl text-[white] font_primary pb-[2px]'><span className='border-b-0 lg:border-b-0 xl:border-b-4 sm:border-b-4 border-third text-[white] font_primary'>Blockchain</span> Solutions for Your Business</h1>
                  </div>
                  <p className='text-base sm:text-lg mb-4 text-[white] font_secondary' data-aos="fade-right">The complete blockchain development company based on multi blockchain development, DApp development and ICO development.</p>
                  <p className='text-base sm:text-lg mb-4 text-[white] font_secondary' data-aos="fade-right">Accessible Blockchain Development Solutions. With a dedicated team of Blockchain Engineers, we build solutions that achieve ROI for businesses and institutions across multiple industries.</p>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className='container mx-auto mb-8'>
              <div className='w-full px-4 lg:w-3/4 py-4 lg:py-12'>
                <div className='border-b-2 border-third w-full mb-4 pb-[2px] lg:pb-[8px]'>
                  <span className='border-0  sm:border-b-[4px] border-third text-3xl lg:text-5xl font_primary text-third'>Cryptocurrency</span><span className='text-3xl lg:text-5xl font_primary text-third'> development</span>
                </div>
                <p className='text-black my-6 text-justify md:text-start font_secondary'><Link to='/' className='text-third'>Earnestroi</Link> provides the easiest way to start your own cryptocurrency. All you need is an idea and <Link to='/' className='text-third'>Earnestroi</Link>.</p>
                <p className='text-black my-6 text-justify md:text-start font_secondary'><Link to='/' className='text-third'>Earnestroi</Link> is a global cryptocurrency development company. We help in the development of new cryptocurrencies and also in the development of smart contracts in existing cryptocurrencies since cryptocurrency is becoming a revolutionizing trend in today's time. We are confident that cryptocurrency will take over the world and hike up the world economy to an unbelievable level in the forthcoming centuries.  Our mission is to provide excellent cryptocurrency development services to help our clients increase their ROI. We create robust, unique and decentralized crypto coins with in-built high-end features that ensure a competitive advantage to the business in an ever-growing dynamic marketplace.</p>
                <p className='text-black my-6 text-justify md:text-start font_secondary'>We enable the client's success globally.</p>
              </div>
              <div className='container mx-auto'>
                <div className='grid sm:grid-cols-2 lg:grid-cols-3 gap:2 lg:gap-8 justify-center items-center'>
                  <div className='px-4 h-full flex items-center'>
                    <FontAwesomeIcon icon={ faPlus } className='text-third mx-2' />
                    <Link to='/nft' className='text-lg mb-0 font_primary'>NFT Development</Link>
                  </div>
                  <div className='px-4 h-full flex items-center'>
                    <FontAwesomeIcon icon={ faPlus } className='text-third mx-2' />
                    <Link to='/token-development' className='text-lg mb-0 font_primary'>Token Development </Link>
                  </div>
                  <div className='px-4 h-full flex items-center'>
                    <FontAwesomeIcon icon={ faPlus } className='text-third mx-2' />
                    <Link to='/smart-contract-development' className='text-lg mb-0 font_primary'>Smart contract Development</Link>
                  </div>
                  <div className='px-4 h-full flex items-center'>
                    <FontAwesomeIcon icon={ faPlus } className='text-third mx-2' />
                    <Link to='/web-three' className='text-lg mb-0 font_primary'>Web 3.0 Development</Link>
                  </div>
                  <div className='px-4 h-full flex items-center'>
                    <FontAwesomeIcon icon={ faPlus } className='text-third mx-2' />
                    <Link to='/meta-verse-development' className='text-lg mb-0 font_primary'>Metaverse Development Services</Link>
                  </div>
                  <div className='px-4 h-full flex items-center'>
                    <FontAwesomeIcon icon={ faPlus } className='text-third mx-2' />
                    <Link to='/semi-fungible-token-development' className='text-lg mb-0 font_primary'>Semi-Fungible Token Development</Link>
                  </div>
                  <div className='px-4 h-full flex items-center'>
                    <FontAwesomeIcon icon={ faPlus } className='text-third mx-2' />
                    <Link to='/nft-market-place' className='text-lg mb-0 font_primary'>NFT Marketplace</Link>
                  </div>
                  <div className='px-4 h-full flex items-center'>
                    <FontAwesomeIcon icon={ faPlus } className='text-third mx-2' />
                    <Link to='/ico-development' className='text-lg mb-0 font_primary'>ICO Development</Link>
                  </div>
                  <div className='px-4 h-full flex items-center'>
                    <FontAwesomeIcon icon={ faPlus } className='text-third mx-2' />
                    <Link to='/crypto-wallet' className='text-lg mb-0 font_primary'>Crypto Wallet Development</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className='bg-main px-1 xl:px-0'>
              <div className='container mx-auto py-12 lg:py-20'>
                <BlockchainNetworks />
              </div>
            </div>
            <div className='container mx-auto my-4 lg:my-10'>
              <div className='grid-cols-2 flex flex-col-reverse lg:flex-row justify-between items-center'>
                <div className='lg:w-[50%] lg:px-2 p-2 sm:p-0'>
                  <div className='border-b-2 border-third w-full mb-4 pb-[2px] lg:pb-[6px]'>
                    <span className='border-b-[4px] lg:border-0 xl:border-b-[4px] border-third text-3xl lg:text-5xl font_primary text-third'>Website</span><span className='text-3xl lg:text-5xl font_primary text-third'> development</span>
                  </div>
                  <br />    
                  <p className='text-base sm:text-lg font_secondary' data-aos="fade-left">With the aim of making the best website for our esteemed clients, we have come up with the highest quality and user-friendly websites.</p>
                  <br /> 
                  <p className='text-base sm:text-lg font_secondary' data-aos="fade-left"><Link to='/' className='text-third'>Earnestroi</Link> provides more than just web design and development for individual projects. We take clients business to a whole new level and help them build a strong landscape view of their overall business. </p>
                  <br /> 
                  <p className='text-base sm:text-lg font_secondary' data-aos="fade-left"><Link to='/' className='text-third'>Earnestroi</Link> is one of the most reliable and trustworthy website development company. We believe that your online presence is very important for your business growth. The process of designing and building a website is an essential factor in the success of your business. As web developers, we do an exceptional job to upgrade your existing site or to build a new one. </p>
                  <br />  
                  <p className='text-base sm:text-lg font_secondary' data-aos="fade-left"> We have an excellent team of professionals - from developers to designers- who have years of experience in developing eye-catching websites with high search engine rankings. </p>
                </div>
                <div className='lg:w-[50%]'>
                  <img src={img_3} alt='Website Development ' className='my-8 lg:my-0 w-3/4 mx-auto' />
                </div>
              </div>
            </div>
            <div className='container mx-auto my-8 px-1 xl:px-0'>
              <div className='grid-cols-2 flex flex-col lg:flex-row justify-between items-center'>
                <div className=''>
                  <img src={img_4} alt='Market making ' />
                </div>
                <div className='lg:w-[50%] px-1'>
                  <div className='border-b-2 border-third w-full mb-4 pb-[2px] lg:pb-[8px]'>
                    <span className='border-b-[4px]  border-third text-3xl lg:text-5xl font_primary text-third'>Market</span><span className='text-3xl lg:text-5xl font_primary text-third'> Making</span>
                  </div>
                  <br />
                  <p className='text-base sm:text-lg font_secondary' data-aos="fade-right"><Link to='/' className='text-third'>Earnestroi</Link>  specializes in Market Making for Business Projects. We generate traffic to our Clients' websites using a variety of platforms.   </p>
                  <br /> 
                  <p className='text-base sm:text-lg font_secondary' data-aos="fade-right">We help clients to reach a targeted audience through our social media promotion services. Our professional marketing team creates regular social media content and publish it on companies’ behalf.</p>
                  <br />
                  <p className='text-base sm:text-lg font_secondary' data-aos="fade-right">We analyse traffic to understand the strong marketing points to conduct marketing campaigns for the firm's project and execute it accordingly. We do market making on social media platforms, business project presentations on platforms such as Facebook, Twitter, Instagram, LinkedIn Promo, PPC and CPC advertising. From website content to emails and banner ads for social media, we cover all types of promotional content.  Our team works on the step to step path to understand and deliver the best strategy and solution for the client. Starting with Building a strategy - planning and publishing - listening and engagement-  analytics and reporting and ultimately advertising.</p>
                  <br />  
                </div>
              </div>
            </div>
            <div className='w-full mx-auto'>
              <div className='container mx-auto px-1 xl:px-0'>
                <div className='grid-cols-2 flex flex-col-reverse lg:flex-row justify-between items-center'>
                  <div className='lg:w-[50%] pl-0'>
                    <div className='border-b-2 border-third w-full mb-4 pb-[2px] lg:pb-[8px]'>
                      <span className='border-b-[4px]  border-third text-3xl lg:text-5xl font_primary text-third'>ICO</span><span className='text-3xl lg:text-5xl font_primary text-third'> Marketing</span>
                    </div>
                    <br />
                    <p className='text-base sm:text-lg font_secondary' data-aos="fade-left"><Link to='/' className='text-third'>Earnestroi</Link> provides more than just web design and development for individual projects. We take clients business to a whole new level and help them build a strong landscape view of their overall business.</p>
                    <br /> 
                    <p className='text-base sm:text-lg font_secondary' data-aos="fade-left"> An ICO is similar to an IPO, but clients buy assets supported by that blockchain or designed for that system instead of purchasing stock in a company. For those who don't know what are ICOs.  </p>
                    <br />
                    <p className='text-base sm:text-lg font_secondary' data-aos="fade-left"> ICOs are now overtaking the venture capital method as the main source of funds for blockchain start-ups. They are a simpler and faster way to raise a tremendous amount of capital without compromising shares in the business. </p>
                    <br />
                  </div>
                  <div className=''>
                    <img src={img_5} alt='ICO marketing' className='my-8 lg:my-0 sm:w-1/2 lg:w-full mx-auto block' />
                  </div>
                </div>
              </div>
            </div>
            <div className='container mx-auto'>
              <div className='w-full px-4 lg:w-3/4 py-4 lg:py-12'>
                <div className='border-b-2 border-third w-full mb-4 pb-[2px] lg:pb-[8px]'>
                  <span className='border-0  sm:border-b-[4px] border-third text-3xl lg:text-5xl font_primary text-third'>Smart</span><span className='text-3xl lg:text-5xl font_primary text-third'> Contract</span>
                </div>
                <p className='text-black my-6 text-justify md:text-start font_secondary'><Link to='/' className='text-third'>Earnestroi</Link> is a platform to implement smart contracts not only for security & transparency but also for managing lives, resources & properties.</p>
                <p className='text-black my-6 text-justify md:text-start font_secondary'><Link to='/' className='text-third'>Earnestroi</Link>  is a technology platform that is designed to handle the administration of smart contracts. They are called intelligent contracts, and they can be signed electronically, legally binding with an autonomous oracle machine in place in order to digitally verify and secure transactions. This smart contract is a tool that allows people to warrant the applicability of the former. The use of this contract can eliminate plundering, fraud, and any other problems caused by the authority who has the right to sign an agreement. </p>
              </div>
            </div>
            <div className='container mx-auto my-12 lg:my-16 border-l-2 border-third'>
              <p className='pl-8 font_secondary'>EARNESTROI</p>
              <br />
              <h2 className='text-base sm:text-3xl border-l-4 border-third pl-8 font_secondary uppercase text-third font_primary' data-aos="fade-up">Developing Altcoin Solutions</h2>
            </div>
          </section>
          <section className='py-10 bg-bg_img bg-no-repeat px-1 xl:px-0' >
            <div className='container mx-auto'>
              <div className='grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10 justify-center items-center'>
                <div className='p-4 h-full bg-[#00000012] bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                  <div className='flex items-center'>
                    <img src={ img_1_altcoin } alt='img_altcoin_solutions' className='mx-2 w-[15%]' />
                    <h2 className='mb-0 text-base font_primary'>Cryptocurrency solutions</h2>
                  </div>
                  <p className='text-base font_secondary'>Earnest Technology helps businesses (start-ups and enterprises) to create their own private blockchain with a secure exchange. It also offers a broad range of services to protect digital assets using an access-controlled decentralized environment.</p>
                </div>
                <div className='p-4 h-full bg-[#00000012] bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                  <div className='flex items-center'>
                    <img src={ img_2_altcoin } alt='img_altcoin_solutions' className='mx-2 w-[15%]' />
                    <h2 className='mb-0 text-base font_primary'>Smart contract creation and auditing</h2>
                  </div>
                  <p className='text-base font_secondary'>Earnestroi provides technical, legal, and strategic advice to design, build, test, execute and operate smart contracts that enable seamless integrations.</p>
                </div>
                <div className='p-4 h-full bg-[#00000012] bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                  <div className='flex items-center'>
                    <img src={ img_3_altcoin } alt='img_altcoin_solutions' className='mx-2 w-[15%]' />
                    <h2 className='mb-0 text-base font_primary'>New consensus & algorithm development</h2>
                  </div>
                  <p className='text-base font_secondary'>Earnestroi is focused on tackling scalability challenges and finally producing a truly decentralized and autonomous blockchain platform with high efficiency and scalability.</p>
                </div>
                <div className='p-4 h-full bg-[#00000012] bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                  <div className='flex items-center'>
                    <img src={ img_4_altcoin } alt='img_altcoin_solutions' className='mx-2 w-[15%]' />
                    <h2 className='mb-0 text-base font_primary'>Telegram Bot development</h2>
                  </div>
                  <p className='text-base font_secondary'>We provide a developing bot system for our clients through which they can transfer or exchange cryptocurrency quickly with Telegram, Slack, discord bot.</p>
                </div>
                <div className='p-4 h-full bg-[#00000012] bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                  <div className='flex items-center'>
                    <img src={ img_5_altcoin } alt='img_altcoin_solutions' className='mx-2 w-[15%]' />
                    <h2 className='mb-0 text-base font_primary'>Lightning network development</h2>
                  </div>
                  <p className='text-base font_secondary'>We are a team of skilled software professionals and business development consultants with a proven track record in developing blockchain-based technology. Smart contract development facilitates quick, simple and secured transactions.</p>
                </div>
                <div className='p-4 h-full bg-[#00000012] bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                  <div className='flex items-center'>
                    <img src={ img_6_altcoin } alt='img_altcoin_solutions' className='mx-2 w-[15%]' />
                    <h2 className='mb-0 text-base font_primary'>Sidechain development </h2>
                  </div>
                  <p className='text-base font_secondary'>Earnestroi Technology engineers sidechain development to the blockchain. Our professional developing team enables enterprises to create highly functional DApps by encapsulating all their data.</p>
                </div>
                <div className='p-4 h-full bg-[#00000012] bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                  <div className='flex items-center'>
                    <img src={ img_7_altcoin } alt='img_altcoin_solutions' className='mx-2 w-[15%]' />
                    <h2 className='mb-0 text-base font_primary'>Micropayment development</h2>
                  </div>
                  <p className='text-base font_secondary'>Earnestroi is a service that eases micro-payments exchange for crypto-currency. Our crypto-payment solution gives you complete freedom to introduce your cryptocurrency(s) to any market.</p>
                </div>
                <div className='p-4 h-full bg-[#00000012] bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                  <div className='flex items-center'>
                    <img src={ img_8_altcoin } alt='img_altcoin_solutions' className='mx-2 w-[15%]' />
                    <h2 className='mb-0 text-base font_primary'>Initial coin offering</h2>
                  </div>
                  <p className='text-base font_secondary'>We are experts at the marketing and promotion of cryptocurrency. The main goal- to create interest for your company among the audience. We provide all the marketing solutions required to take care of ICO marketing.</p>
                </div>
              </div>
            </div>
          </section>
          <section>
            <Footern />  
          </section>
        </>
      )

}

export default Blockchain;