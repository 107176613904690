import React, { useEffect, useState } from "react";
import Footern from "./Footern";
import WhatsappChat from "./WhatsappChat";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

const BlogLayoutTwo = () => {
  const [response, setResponse] = useState([]);

  const { blogid } = useParams();

  const location = useLocation();
  const FormID = location.state;
  console.log(FormID);

  const fetchfunction = () => {
    console.log('innnnnnnnnnn')
    axios({
      method: "POST",
      header: "accept: application/json",
      url: process.env.REACT_APP_API_PATH_USER +`view_blog`,
    })
      .then((res) => {
        console.log(res.data.Success);
        setResponse(
          res.data.Success.filter((item) => item.FormID === FormID.FormID)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchfunction();
  }, []);

  const timezone = new Date(response.updated_at).toLocaleString();

  return (
    <>
      <section className="">
        <div className="container mx-auto">
          <div className="flex flex-col lg:flex-row">
            <div className="w-full lg:w-[70%] px-2 border-r-[1px] border-second">
              <div className="px-4 pt-8">
                <img
                  src={`${response.Banner}`}
                  alt="blogimgearnestroi"
                  className="mx-auto block"
                />
                <h2 className="font_primary capitalize text-third text-xl lg:text-3xl py-4">
                  {response.header}
                </h2>
                <p>
                  by <b className="text-third"> Earnestroi</b> | {timezone}{" "}
                </p>
                <p className="font_secondary py-2"> {response.subheader} </p>
              </div>
              <div
                className="p-4 font_secondary"
                dangerouslySetInnerHTML={{ __html: response.description }}
              ></div>
              <div className="image_gallery">
                <h2 className="font_primary capitalize text-third text-xl lg:text-3xl py-4">
                  {response.gallery_title}
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-2 md:gap-4 lg:gap-6 my-4">
                  <img
                    src={`https://api.earnestroi.com/gallery/${response.gallery_img_one}`}
                    alt="imagegalleryimages"
                    className="w-3/4 mx-auto block"
                  />
                  <img
                    src={`https://api.earnestroi.com/gallery/${response.gallery_img_two}`}
                    alt="imagegalleryimages"
                    className="w-3/4 mx-auto block"
                  />
                </div>
                <img
                  alt="imagegalleryimages"
                  src={`https://api.earnestroi.com/gallery/${response.gallery_img_three}`}
                  className="mx-auto block"
                />
                <p className="font_secondary py-2">
                  {response.img_description}
                </p>
              </div>
              <div className="px-8 py-12 border-l-2 border-second bg-[#77c3e520] mt-6">
                <p className="text-second font_primary text-2xl w-3/4 text-center mx-auto block">
                  <i>{response.quote}</i>
                </p>
              </div>
              <p className="font_secondary pt-4">
                {response.quote_description}
              </p>
              <div className="py-6">
                <p className="font_secondary">
                  <b className="font_primary text-lg">Keywords: </b>{" "}
                  {response.tags}
                </p>
              </div>
              <div className="py-4">
                <h2 className="font_primary capitalize text-second text-xl lg:text-3xl py-1">
                  OH HEY READER!
                </h2>
                <p className="text-base sm:text-lg font_secondary py-2 text-justify">
                  We write about digital marketing, advertising, marketing
                  psychology & more. Sign up to get our exclusive in-depth
                  articles based on our learnings right in your inbox!
                </p>
                <form className="w-full lg:w-3/4">
                  <p className="text-base sm:text-lg font_secondary py-1">
                    Email
                  </p>
                  <input
                    type="email"
                    className="border-2 border-third py-1 px-2 rounded-lg w-full font_secondary"
                  />
                  <input
                    type="submit"
                    className="text-third border-2 border-third rounded-lg my-2 px-8 py-1 hover:cursor-pointer font_primary"
                  />
                </form>
              </div>
              <div className="py-4">
                <h2 className="font_primary capitalize text-second text-xl lg:text-3xl py-1">
                  Submit a Comment
                </h2>
                <p className="text-base sm:text-lg font_secondary py-2">
                  Your email address will not be published. Required fields are
                  marked *
                </p>
                <form className="w-full">
                  <textarea
                    placeholder="Comment"
                    className="w-full border-2 border-third py-1 px-2 rounded-lg font_secondary"
                  />
                  <div className="flex my-1">
                    <input
                      type="text"
                      placeholder="Name*"
                      className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary"
                    />
                    <input
                      type="email"
                      placeholder="Email*"
                      className="border-2 border-third py-1 px-2 rounded-lg w-full ml-2 font_secondary"
                    />
                  </div>
                  <button
                    type="submit"
                    className="text-third border-2 border-third rounded-lg my-2 px-8 py-1 hover:cursor-pointer font_primary"
                  >
                    Submit Comment
                  </button>
                </form>
              </div>
            </div>
            <div className="w-full lg:w-[30%] px-2">
              <div className="px-4 py-8">
                <h2 className="font_primary capitalize text-third text-xl lg:text-3xl py-4">
                  Schedule A Callback
                </h2>
                <form>
                  <select className="border-2 border-third py-1 px-2 rounded-lg font_secondary w-full">
                    <option value="purpose">Purpose</option>
                    <option value="marketingsolutions">
                      Marketing Solutions
                    </option>
                    <option value="technologysolutions">
                      Technology Solutions
                    </option>
                    <option value="partnerships">Partnerships/tieups</option>
                  </select>
                  <input
                    type="text"
                    placeholder="Name"
                    className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                  />
                  <input
                    type="number"
                    placeholder="Phone"
                    className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                  />
                  <input
                    type="submit"
                    className="text-third border-2 border-third rounded-lg px-8 py-1 hover:cursor-pointer font_primary my-1"
                  />
                </form>
              </div>
              <div className="px-4 py-8">
                <h2 className="font_primary capitalize text-third text-xl lg:text-3xl py-4">
                  Recent Posts
                </h2>
                <div className="py-1">
                  <a
                    href="/"
                    className="hover:text-second hover:cursor-pointer text-base sm:text-lg font_secondary py-2 "
                  >
                    Best Digital Marketing Strategies For Social Welfare
                    Industry
                  </a>
                </div>
                <div className="py-1">
                  <a
                    href="/"
                    className="hover:text-second hover:cursor-pointer text-base sm:text-lg font_secondary py-2 "
                  >
                    Digital Marketing Case Studies For Social Welfare Industry
                  </a>
                </div>
                <div className="py-1">
                  <a
                    href="/"
                    className="hover:text-second hover:cursor-pointer text-base sm:text-lg font_secondary py-2 "
                  >
                    Best Digital Marketing Strategies For Shipping & Logistics
                    Industry
                  </a>
                </div>
                <div className="py-1">
                  <a
                    href="/"
                    className="hover:text-second hover:cursor-pointer text-base sm:text-lg font_secondary py-2 "
                  >
                    Digital Marketing Case Studies For Shipping & Logistics
                    Industry
                  </a>
                </div>
                <div className="py-1">
                  <a
                    href="/"
                    className="hover:text-second hover:cursor-pointer text-base sm:text-lg font_secondary py-2 "
                  >
                    Best Digital Marketing Strategies For Health & Fitness
                    Industry
                  </a>
                </div>
              </div>
              <div className="px-4 py-4">
                <h2 className="font_primary capitalize text-second text-xl lg:text-3xl py-1">
                  OH HEY READER!
                </h2>
                <p className="text-base sm:text-lg font_secondary py-2 text-justify">
                  We write about digital marketing, advertising, marketing
                  psychology & more. Sign up to get our exclusive in-depth
                  articles based on our learnings right in your inbox!
                </p>
                <form className="w-full lg:w-3/4">
                  <p className="text-base sm:text-lg font_secondary py-1">
                    Email
                  </p>
                  <input
                    type="email"
                    className="border-2 border-third py-1 px-2 rounded-lg w-full font_secondary"
                  />
                  <input
                    type="submit"
                    className="text-third border-2 border-third rounded-lg my-2 px-8 py-1 hover:cursor-pointer font_primary"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footern />
      {/* <WhatsappChat /> */}
    </>
  );
};

export default BlogLayoutTwo;
