import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import caro_1 from '../img/development-services/earnestroi-icon-machine-learning.png';
import caro_2 from '../img/development-services/earnestroi-icon-artificial-intelligence.png';
import caro_3 from '../img/development-services/earnestroi-icon-nft-development.png';
import caro_4 from '../img/development-services/earnestroi-icon-token-development.png';
import caro_5 from '../img/development-services/earnestroi-icon-smart-contract.png';
import caro_6 from '../img/development-services/earnestroi-icon-web-three.png';
import caro_7 from '../img/development-services/earnestroi-icon-mobile-marketing.png';
import caro_8 from '../img/development-services/earnestroi-icon-paid-ads-using-google-ads.png';
import caro_9 from '../img/development-services/earnestroi-icon-metaverse-development.png';
import caro_10 from '../img/development-services/earnestroi-icon-semi-nft.png';
import caro_11 from '../img/development-services/earnestroi-icon-social-nft-marketplace.png';
import caro_12 from '../img/development-services/earnestroi-icon-ico-development.png';
import caro_13 from '../img/development-services/earnestroi-icon-crypto-wallet.png';
import img_new from "../img/bot-icon.png";


const DevelopmentServices = () => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 2,
        autoplay: true,
        responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
              }
            },
        ]
    };

  return (
    <>
      <div className="container mx-auto">
        <div className="border-b-2 border-third w-full mb-4 pb-[2px] lg:pb-[6px]">
          <h1 className="text-3xl lg:text-5xl font_primary text-third">
            <span className="border-b-[4px] border-third text-3xl lg:text-5xl font_primary text-third">
              Development
            </span>{" "}
            Services
          </h1>
        </div>
        <div>
          <Slider {...settings}>
            <div className="">
              <img
                alt="carousel_images"
                src={caro_1}
                className="mx-auto block"
              />
              <p className="font_secondary text-base text-center text-[12px] sm:text-base">
                Machine Learning
              </p>
            </div>
            <div className="">
              <img
                alt="carousel_images"
                src={caro_2}
                className="mx-auto block"
              />
              <p className="font_secondary text-base text-center text-[12px] sm:text-base">
                Artificial Intelligence
              </p>
            </div>
            <div className="">
              <img
                alt="carousel_images"
                src={caro_3}
                className="mx-auto block"
              />
              <p className="font_secondary text-base text-center text-[12px] sm:text-base">
                NFT Development
              </p>
            </div>
            <div className="">
              <img
                alt="carousel_images"
                src={caro_4}
                className="mx-auto block"
              />
              <p className="font_secondary text-base text-center text-[12px] sm:text-base">
                Token Development
              </p>
            </div>
            <div className="">
              <img
                alt="carousel_images"
                src={caro_5}
                className="mx-auto block"
              />
              <p className="font_secondary text-base text-center text-[12px] sm:text-base">
                Smart contract Development
              </p>
            </div>
            <div className="">
              <img
                alt="carousel_images"
                src={caro_6}
                className="mx-auto block"
              />
              <p className="font_secondary text-base text-center text-[12px] sm:text-base">
                Web 3.0 Development
              </p>
            </div>
            <div className="">
              <img
                alt="carousel_images"
                src={img_new}
                className="mx-auto block"
              />
              <p className="font_secondary text-base text-center text-[12px] sm:text-base">
                Trading Bot
              </p>
            </div>
            {/* <div className="">
                        <img alt='carousel_images' src={caro_7} className='mx-auto block' />
                        <p className='font_secondary text-base text-center text-[12px] sm:text-base'>Mobile Marketing</p>
                    </div>
                    <div className="">
                        <img alt='carousel_images' src={caro_8} className='mx-auto block' />
                        <p className='font_secondary text-base text-center text-[12px] sm:text-base'>Paid Ads Using Google Ads</p>
                    </div> */}
            <div className="">
              <img
                alt="carousel_images"
                src={caro_9}
                className="mx-auto block"
              />
              <p className="font_secondary text-base text-center text-[12px] sm:text-base">
                Metaverse Development Services
              </p>
            </div>
            <div className="">
              <img
                alt="carousel_images"
                src={caro_10}
                className="mx-auto block"
              />
              <p className="font_secondary text-base text-center text-[12px] sm:text-base">
                Semi-Fungible Token Development
              </p>
            </div>
            <div className="">
              <img
                alt="carousel_images"
                src={caro_11}
                className="mx-auto block"
              />
              <p className="font_secondary text-base text-center text-[12px] sm:text-base">
                NFT MARKETPLACE
              </p>
            </div>
            <div className="">
              <img
                alt="carousel_images"
                src={caro_12}
                className="mx-auto block"
              />
              <p className="font_secondary text-base text-center text-[12px] sm:text-base">
                ICO Development
              </p>
            </div>
            <div className="">
              <img
                alt="carousel_images"
                src={caro_13}
                className="mx-auto block"
              />
              <p className="font_secondary text-base text-center text-[12px] sm:text-base">
                Crypto Wallet Development
              </p>
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
}

export default DevelopmentServices;