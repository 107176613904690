import React, { useEffect } from 'react';
import corn from '../img/ninepages/earnestroi-NFT-Art-Marketplace-Development.jpg';
import img_3s from '../img/ninepages/earnestroi-Using-your-NFT-Art-Marketplace,-reshape-the-future..jpg';
import corn_n from '../img/ninepages/earnestroi-NFT-Digital-Art-Marketplace-Buy-&-Sell-NFT-Art.jpg';
import corn_ns from '../img/ninepages/earnestroi-Hire-us-to-develop-digital-art-for-the-NFT-marketplace..jpg';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUsers, faGears, faHeadphonesSimple, faFileExport, faStore, faPalette, faBoxesPacking, faWallet, faRotate, faArrowDownUpAcrossLine, faUserGroup, faSquareCheck, faCode, faStar} from '@fortawesome/free-solid-svg-icons';
import { faShieldHalved } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { faSearchengin } from '@fortawesome/free-brands-svg-icons';
import TimelineMarketplaceNft from './TimelineMarketplaceNft';
import Footern from './Footern';
import { Helmet } from 'react-helmet';

const NftMarketplace = () => {

  useEffect( () => {

    $(document).ready(function(){
      $(this).scrollTop(0);
    });

    $(document).ready(function(){
      
      $('.slick-prev').hide();
      $('.slick-next').hide();

    });

    Aos.init({

      disable: function() {
        var maxWidth = 1024;
        return window.innerWidth < maxWidth;
      }

    });

  } );

  useEffect( () => {

    Aos.init({ duration: 3000 });

  } );
  
  return (
    <>
      <Helmet>
        <title>Earnestroi Technology | NFT Marketplace | Nonfungible Token</title>
        <meta name="title" content="Earnestroi Technology | Semi Fungible token development | crypto currency | blockchain" />
        <meta name="description" content="The marketplace uses blockchain technology to tokenize your physical or digital art and make it non-fungible by marking each item with a unique digital fingerprint." />
        <meta name="keywords" content='nft marketplace, nonfungible token, digital asset, nft market, nft art, project management' />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
      </Helmet>
      <section className=' lg:pt-4 xl:pt-0 bg-main px-1 xl:px-0'>
        <div className='container mx-auto'>
          <div className='flex flex-col-reverse lg:flex-row justify-center items-center xl:pt-20'>
            <div className='flex flex-col w-full lg:w-1/2'>
                <h1 className='text-[white] text-5xl lg:text-6xl mb-4 mt-4 lg:mt-0 font_primary capitalize' data-aos="fade-left">NFT Art Marketplace Development</h1>
                <br />
                <h2 className='text-base lg:text-2xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third'  data-aos="fade-left">Allow artists to trade, buy, and sell their works.</h2>
            </div>
            <div className=' w-full lg:w-1/2'>
              <img src={corn} alt='NFT Art marketplace' className='mx-auto w-1/2 sm:w-1/4 lg:w-3/4 block' />
            </div>
          </div>
        </div>
      </section>
      <section className='bg-main py-4 lg:py-10'>
        <div className='container mx-auto'>
          <div className='flex justify-center py-4 lg:py-20'>
            <Link to='/'><h2 className='mx-2 text-third font_primary'><FontAwesomeIcon icon={ faHome } className='mx-2 text-third' />Digital Marketing Agency</h2></Link><h2 className='mx-2 text-third'>/</h2><h2 className='mx-2 text-third font_primary'>NFT Marketplace</h2>
          </div>
        </div> 
      </section>
      <section className='py-4 md:py-10'>
        <div className='container mx-auto'>
          <div className='grid-cols-2 flex flex-col lg:flex-row justify-between items-center'>
            <div className='lg:w-[40%] lg:px-2 p-2 sm:p-0'>
              <h2 className='text-2xl lg:text-3xl font_primary text-third'>Using your NFT Art Marketplace, reshape the future.</h2>
              <br /><br />
              <p className='text-base font_secondary' data-aos="fade-left">An NFT art marketplace website allows artists to present their work under one central banner. Tokenize your tangible and digital assets to create a unique, non-fungible token that cannot be transferred. These NFTs are exclusive and indivisible, providing creators with a means of making money from their work. Purchase and sell NFT artwork to a broad audience.</p>
              <br />  
            </div>
            <div className=''>
              <img src={img_3s} alt='NFT reshape the future ' className='my-8 lg:my-0' />
            </div>
          </div>
        </div>
      </section>
      <section className='bg-main py-4 lg:py-10'>
        <div className='container mx-auto'>
          <div className='grid-cols-2 flex flex-col lg:flex-row justify-around items-center'>
            <div className='w-full lg:w-[50%]'>
              <img src={corn_n} alt='Buy & Sell NFT Art ' className='my-8 lg:my-0 mx-auto lg:mr-auto w-1/2 sm:w-1/4 lg:w-1/2 block' />
            </div>
            <div className='w-full lg:w-[50%] lg:px-2 p-2 sm:p-0'>
              <h2 className='text-2xl lg:text-3xl font_primary text-third'>NFT Digital Art Marketplace: Buy & Sell NFT Art</h2>
              <br /><br />
              <p className='text-base font_secondary text-[white]' data-aos="fade-right">A profitable way for artists to demonstrate ownership of their digital art is by using non-fungible tokens. NFTs have undoubtedly improved artistic freedom and given artists additional opportunities to make money from their work. Here are a few characteristics of an NFT cryptocurrency art market.</p>
              <br />  
            </div>
          </div>
        </div>
      </section>
      <section className='py-4 md:py-20'>
        <div className='container mx-auto pt-4 lg:pt-8'>
          <div className='w-full lg:w-[60%] mx-auto'>
            <h2 className='text-lg lg:text-2xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third text-center' data-aos='fade-up'>Features</h2>
            <p className=' text-center text-base sm:text-lg mb-4 lg:mb-8 font_secondary'  data-aos='fade-up'>With the creation of the NFT art marketplace, transform your future. Join the massive income system by developing an NFT digital art marketplace. Get in touch with us today!</p>
          </div>
        </div>
        <div className='container mx-auto'>
          <div className='grid sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-8 justify-center items-center'>
            <div className='p-4 h-full hover:bg-[#77c1dd14] hover:cursor-pointer rounded-2xl'>
              <FontAwesomeIcon icon={ faStore } className='text-5xl text-third' />
              <h2 className='text-lg mb-2 font_primary text-third'>Intuitive Storefront</h2>
              <p className='text-base font_secondary'>Create a unique and captivating storefront for your consumers in the marketplace, which will draw attention and keep users coming back.</p>
            </div>
            <div className='p-4 h-full hover:bg-[#77c1dd14] hover:cursor-pointer rounded-2xl'>
              <FontAwesomeIcon icon={ faPalette } className='text-5xl text-third' />
              <h2 className='text-lg mb-2 font_primary text-third'>Art Listings</h2>
              <p className='text-base font_secondary'>By giving them a platform to create tokens, upload, and sell their art at a predetermined price, you may enable artists to list and showcase their work on your marketplace.</p>
            </div>
            <div className='p-4 h-full hover:bg-[#77c1dd14] hover:cursor-pointer rounded-2xl'>
              <FontAwesomeIcon icon={ faBoxesPacking  } className='text-5xl text-third' />
              <h2 className='text-lg mb-2 font_primary text-third'>Decentralized Platform</h2>
              <p className='text-base font_secondary'>Since all transactions are decentralized and recorded on a public ledger that is always accessible, the necessity for a middleman is eliminated.</p>
            </div>
            <div className='p-4 h-full hover:bg-[#77c1dd14] hover:cursor-pointer rounded-2xl'>
              <FontAwesomeIcon icon={ faWallet } className='text-5xl text-third' />
              <h2 className='text-lg mb-2 font_primary text-third'>Wallet Integration</h2>
              <p className='text-base font_secondary'>Create an NFT art marketplace with integrated wallet capabilities, so you can easily handle all payments, transactions, and profits.</p>
            </div>
            <div className='p-4 h-full hover:bg-[#77c1dd14] hover:cursor-pointer rounded-2xl'>
              <FontAwesomeIcon icon={ faSearchengin } className='text-5xl text-third' />
              <h2 className='text-lg mb-2 font_primary text-third'>Better Search and Filters</h2>
              <p className='text-base font_secondary'>Include a search field so users can more easily find the desired digital collectible by typing in pertinent keywords or tags.</p>
            </div>
            <div className='p-4 h-full hover:bg-[#77c1dd14] hover:cursor-pointer rounded-2xl'>
              <FontAwesomeIcon icon={ faRotate } className='text-5xl text-third' />
              <h2 className='text-lg mb-2 font_primary text-third'>Easy Currency Exchange</h2>
              <p className='text-base font_secondary'>Convert your art funds quickly between different currencies. Depending on the price they like, merchants can also convert their goods into various currencies.</p>
            </div>
          </div>
        </div>
      </section>
      <section className='py-4 lg:py-10 px-1 xl:px-0'>
        <div className='container mx-auto py-4 lg:py-12'>
          <div className=''>
              <h2 className='mx-auto w-full lg:w-[70%] text-xl lg:text-3xl mb-2 mt-4 lg:mt-0 font_primary capitalize text-third text-center'>How Can You Start Making a Fortune With the Development of the NFT Crypto Art Marketplace?</h2>
          </div>
        </div>
        <TimelineMarketplaceNft />
      </section>
      <section className='bg-main py-4 lg:py-10'>
        <div className='container mx-auto'>
          <div className='grid-cols-2 flex flex-col lg:flex-row justify-around items-center'>
              <div className='w-full lg:w-[50%] lg:px-2 p-2 sm:p-0'>
                <h2 className='text-2xl lg:text-3xl font_primary text-third'>Hire us to develop digital art for the NFT marketplace.</h2>
                <br /><br />
                <p className='text-base font_secondary text-[white]' data-aos="fade-right">With its comprehensive development solutions, Earnestroi Solutions can assist you in creating an NFT art marketplace that is prepared for the future. To your business needs, we make a marketplace employing a range of features and merge them into a single usable solution. To improve your NFT art marketplace, our committed team of engineers and consultants will design a workflow and offer you ongoing assistance.</p>
                <br />  
              </div>
              <div className='w-full lg:w-[50%]'>
                <img src={corn_ns} alt='Digital Art NFT Markeplace' className='my-8 lg:my-0 mx-auto w-1/2 sm:w-1/4 lg:w-1/2 block' />
              </div>
            </div>
        </div>
      </section>
      <section className='py-4 md:py-20 px-1 xl:px-0'>
        <div className='container mx-auto pt-4 lg:pt-8'>
          <div className='w-full lg:w-[60%] mx-auto'>
            <h2 className='text-lg lg:text-2xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third text-center' data-aos='fade-up'>Benefits of an NFT Art Marketplace</h2>
            <p className=' text-center text-base sm:text-lg mb-4 lg:mb-8 font_secondary'  data-aos='fade-up'>With the creation of the NFT art marketplace, transform your future. Join the massive income system by developing an NFT digital art marketplace. Get in touch with us today!</p>
          </div>
        </div>
        <div className='container mx-auto'>
          <div className='grid sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-8 justify-center items-center'>
            <div className='p-4 h-full hover:bg-[#77c1dd14] hover:cursor-pointer rounded-2xl'>
              <FontAwesomeIcon icon={ faArrowDownUpAcrossLine } className='text-5xl text-third' />
              <h2 className='text-lg mb-2 font_primary text-third'>Added Liquidity</h2>
              <p className='text-base font_secondary'>Trade NFTs for art anytime, anywhere, with ease. Because of high Liquidity, it is easier to attract users to buy and sell NFT art.</p>
            </div>
            <div className='p-4 h-full hover:bg-[#77c1dd14] hover:cursor-pointer rounded-2xl'>
              <FontAwesomeIcon icon={ faUserGroup } className='text-5xl text-third' />
              <h2 className='text-lg mb-2 font_primary text-third'>Multiple Ecosystem Interactions</h2>
              <p className='text-base font_secondary'>Several stands adopted, like the ERC 1155 and 721, have allowed people to generate NFTs for digital collectibles.</p>
            </div>
            <div className='p-4 h-full hover:bg-[#77c1dd14] hover:cursor-pointer rounded-2xl'>
              <FontAwesomeIcon icon={ faSquareCheck  } className='text-5xl text-third' />
              <h2 className='text-lg mb-2 font_primary text-third'>Tradability</h2>
              <p className='text-base font_secondary'>An NFT art marketplace provides endless capabilities for users to trade tokens on different improved cryptocurrency exchanges.</p>
            </div>
            <div className='p-4 h-full hover:bg-[#77c1dd14] hover:cursor-pointer rounded-2xl'>
              <FontAwesomeIcon icon={ faShieldHalved } className='text-5xl text-third' />
              <h2 className='text-lg mb-2 font_primary text-third'>Authentic Assets</h2>
              <p className='text-base font_secondary'>Every non-fungible token belongs to a different owner. This makes it very easy to prove and verify the ownership of NFTs.</p>
            </div>
            <div className='p-4 h-full hover:bg-[#77c1dd14] hover:cursor-pointer rounded-2xl'>
              <FontAwesomeIcon icon={ faCode } className='text-5xl text-third' />
              <h2 className='text-lg mb-2 font_primary text-third'>Programmability</h2>
              <p className='text-base font_secondary'>NFTs allow artists to forge, generate and craft unique artworks in different variations, making it a great creative space for artists.</p>
            </div>
            <div className='p-4 h-full hover:bg-[#77c1dd14] hover:cursor-pointer rounded-2xl'>
              <FontAwesomeIcon icon={ faStar } className='text-5xl text-third' />
              <h2 className='text-lg mb-2 font_primary text-third'>Unique Data</h2>
              <p className='text-base font_secondary'>Every NFT works like an original digital asset and comes with its own unique set of metadata allowing artists to tokenize their art.</p>
            </div>
          </div>
        </div>
      </section>
      <section className='bg-main py-4 lg:py-12 px-1 xl:px-0'>
        <div className='container mx-auto pt-4 lg:pt-8'>
          <div className='w-full lg:w-[60%] mx-auto'>
            <h2 className='text-lg lg:text-2xl mt-4 lg:mt-0 font_primary capitalize text-third text-center mb-4 lg:mb-8  '>Why are we the best company for NFT Art Marketplace Development?</h2>
          </div>
          <div className='container mx-auto pb-4 md:pb-6'>
            <div className='grid sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
              <div className='p-4 h-full bg-[#77c1dd14] rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md '>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faShieldHalved } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center text-third'>Technical Process</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center text-[white]'>We exclusively work in Blockchain technologies. We just concentrate on one thing, and we excel at it.</p>
              </div>
              <div className='p-4 h-full bg-[#77c1dd14] rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md '>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faUsers } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center text-third'>Expert Team</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center text-[white]'>With the support of our 500 professionals, you can improve your offering, get the finest IT solution, and even create communities and campaigns.</p>
              </div>
              <div className='p-4 h-full bg-[#77c1dd14] rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md '>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faGears } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center text-third'>Rapid Development</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center text-[white]'>We offer goods that are especially suited to your intended consumer base. A well-organized roadmap ensures rapid and efficient development and implementation.</p>
              </div>
            </div>
          </div>
          <div className='container mx-auto pt-4 md:pt-6'>
            <div className='flex flex-col lg:flex-row lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
              <div className='p-4 h-full lg:w-[30%] xl:w-1/4 2xl:w-[30%] w-full bg-[#77c1dd14] rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md '>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faHeadphonesSimple } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center text-third'>Complete Support</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center text-[white]'>After the product launch, we continue to work and provide post-delivery support.</p>
              </div>
              <div className='p-4 h-full lg:w-[30%] xl:w-1/4 2xl:w-[30%] w-full bg-[#77c1dd14] rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md '>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faFileExport } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center text-third'>Meaningful Outcomes</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center text-[white]'>We make a real difference. We make sure your investments have value for you.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Footern />
      </section>
    </>
  )
}

export default NftMarketplace;