import React, { useState } from "react";
import { Fragment} from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

const FAQ = () => {
  const [open, setOpen] = useState(1);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <>
      <div>
        <h1 className="text-[white] text-2xl md:text-2xl lg:text-3xl xl:text-5xl mb-4 mt-4 lg:mt-0 font_primary text-left">
          FAQ
        </h1>
        <p className="text-second text-2xl md:text-lg xl:text-2xl mb-4 mt-4 lg:mt-0 font_primary text-left">
          Get answers to your questions with our expert technical consultation.
        </p>
      </div>

      <div className=" text-center w-full  px-2">
        <Fragment>
          <Accordion open={open === 1}>
            <AccordionHeader
              className="text-[white] pl-4 font_primary font-light justify-start border-[1px] border-third rounded-xl my-2 shadow-[0px_0px_6px_#fff]"
              onClick={() => handleOpen(1)}
            >
              Q: What is a cryptocurrency trading bot?
            </AccordionHeader>
            <AccordionBody className="text-[white] font_secondary text-left text-base p-2 rounded-lg">
              A: A cryptocurrency trading bot is an automated software program
              that executes buy and sell orders on behalf of traders. It
              operates based on predefined trading strategies and algorithms,
              allowing traders to capitalize on market opportunities 24/7
              without constant manual intervention.
            </AccordionBody>
          </Accordion>
          <Accordion open={open === 2}>
            <AccordionHeader
              className="text-[white] pl-4 font_primary font-light justify-start border-[1px] border-second rounded-xl my-2 shadow-[0px_0px_6px_#fff]"
              onClick={() => handleOpen(2)}
            >
              Q: How secure are my funds and personal information with
              Earnestroi?
            </AccordionHeader>
            <AccordionBody className="text-[white] font_secondary text-left text-base p-2 rounded-lg">
              A: At Earnestroi, security is a top priority. We implement
              state-of-the-art security measures to safeguard your funds and
              personal information. Our team follows industry best practices to
              ensure your assets remain safe and protected.
            </AccordionBody>
          </Accordion>
          <Accordion open={open === 3}>
            <AccordionHeader
              className="text-[white] pl-4 font_primary font-light justify-start border-[1px] border-[#e3b503] rounded-xl my-2 shadow-[0px_0px_6px_#fff]"
              onClick={() => handleOpen(3)}
            >
              Q: Can I backtest the trading bot's strategies before deployment?
            </AccordionHeader>
            <AccordionBody className="text-[white] font_secondary text-left text-base p-2 rounded-lg">
              A: Yes, Earnestroi provides the capability to backtest the trading
              bot's strategies using historical market data. Backtesting allows
              you to evaluate the performance of your chosen strategy and make
              adjustments if needed before going live in the real market.
            </AccordionBody>
          </Accordion>
          <Accordion open={open === 4}>
            <AccordionHeader
              className="text-[white] pl-4 font_primary font-light justify-start border-[1px] border-[red] rounded-xl my-2 shadow-[0px_0px_6px_#fff]"
              onClick={() => handleOpen(4)}
            >
              Q: What control do I have over the trading bot's settings?
            </AccordionHeader>
            <AccordionBody className="text-[white] font_secondary text-left text-base p-2 rounded-lg">
              A: Earnestroi grants you complete control over the trading bot's
              settings. You can customize various parameters, including trading
              pairs, indicators, risk management, etc. This flexibility lets you
              fine-tune the bot's behavior according to your trading preferences
              and risk tolerance.
            </AccordionBody>
          </Accordion>
        </Fragment>
      </div>

      {/* Add more AccordionItem components here */}
    </>
  );
};

export default FAQ;
