import React from 'react';
import { Link } from 'react-router-dom';
import industry1 from '../img/landing/industry/earnestroi-landing-campaign-beauty.webp';
import industry2 from '../img/landing/industry/earnestroi-landing-campaign-education.webp';
import industry3 from '../img/landing/industry/earnestroi-landing-campaign-fitness.webp';
import industry4 from '../img/landing/industry/earnestroi-landing-campaign-automobile.webp';
import industry5 from '../img/landing/industry/earnestroi-landing-campaign-healthcare.webp';
import industry6 from '../img/landing/industry/earnestroi-landing-campaign-teachnology.webp';
import industry7 from '../img/landing/industry/earnestroi-landing-campaign-real-state.webp';
import industry8 from '../img/landing/industry/earnestroi-landing-campaign-travel.webp';

const IndustrialExpertise = () => {
  return (
    <>
        <div className="py-8">
            <h2 className='text-second text-center text-2xl md:text-3xl lg:text-5xl mb-8 lg:mt-0 font_primary capitalize'>Industry Expertise</h2>
            <div className='container mx-auto flex flex-row md:flex-col justify-center'>
                <div className='flex flex-col md:flex-row md:grid-cols-2 lg:grid-cols-4 gap-2 lg:gap-4 justify-center pb-0 md:pb-4'>
                    <div className='bn5 mx-4 rounded-full bg-main'>
                        <img src={ industry1 } alt='earnestroi technologies' className='mx-auto block' />
                        <p className='text-base sm:text-xl font_primary py-2 text-third text-center capitalize'>Beauty</p>
                    </div>
                    <div className='bn5 mx-4 rounded-full bg-main'>
                        <img src={ industry2 } alt='earnestroi technologies' className='mx-auto block' />
                        <p className='text-base sm:text-xl font_primary py-2 text-third text-center capitalize'>education</p>
                    </div>
                    <div className='bn5 mx-4 rounded-full bg-main'>
                        <img src={ industry3 } alt='earnestroi technologies' className='mx-auto block' />
                        <p className='text-base sm:text-xl font_primary py-2 text-third text-center capitalize'>Fitness</p>
                    </div>
                    <div className='bn5 mx-4 rounded-full bg-main'>
                        <img src={ industry4 } alt='earnestroi technologies' className='mx-auto block' />
                        <p className='text-base sm:text-xl font_primary py-2 text-third text-center capitalize'>automobile</p>
                    </div>
                </div>
                <div className='flex flex-col md:flex-row md:grid-cols-2 lg:grid-cols-4 gap-2 lg:gap-4 justify-center pt-0 md:pt-4 md:pb-4'>
                    <div className='bn5 mx-4 rounded-full bg-main'>
                        <img src={ industry5 } alt='earnestroi technologies' className='mx-auto block' />
                        <p className='text-base sm:text-xl font_primary py-2 text-third text-center capitalize'>healthcare</p>
                    </div>
                    <div className='bn5 mx-4 rounded-full bg-main'>
                        <img src={ industry6 } alt='earnestroi technologies' className='mx-auto block' />
                        <p className='text-base sm:text-xl font_primary py-2 text-third text-center capitalize'>technology</p>
                    </div>
                    <div className='bn5 mx-4 rounded-full bg-main'>
                        <img src={ industry7 } alt='earnestroi technologies' className='mx-auto block' />
                        <p className='text-base sm:text-xl font_primary py-2 text-third text-center capitalize'>real estate</p>
                    </div>
                    <div className='bn5 mx-4 rounded-full bg-main'>
                        <img src={ industry8 } alt='earnestroi technologies' className='mx-auto block' />
                        <p className='text-base sm:text-xl font_primary py-2 text-third text-center capitalize'>travel</p>
                    </div>
                </div>
            </div>
            <Link to='/contactsl' className='bn6 text-base md:text-lg lg:text-xl hover:shadow-[0_0_12px_#77c1dd] text-[white] border-2 border-[white] px-0 sm:px-12 py-2 mt-8 rounded-lg hover:text-[white] mx-auto m-0 hover:border-[white] font_primary block w-max text-center uppercase' >Connect with one of our experts.</Link>
        </div>
        {/* <div className="bg-bottomblack bg-cover h-[80px] bg-no-repeat"></div> */}
    </>
  )
}

export default IndustrialExpertise;