import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { event } from 'jquery';

const LayoutTwoDash = () => {

  const [layout, setLayout] = useState('layout_two');
  const [banner, setBanner] = useState(null);
  const [header, setHeader] = useState('');
  const [forURL, setforURL] = useState("");
  const [subheader, setSubheader] = useState('');
  const [name, setName] = useState('Earnestroi');
  const [email, setEmail] = useState('info@earnestroi.com');
  const [apisubmit, setApisubmit] = useState({ description: '', });
  const [gallerytitle, setGallerytitle] = useState('');
  const [gallery_img_one, setGallery_img_one] = useState(null);
  const [gallery_img_two, setGallery_img_two] = useState(null);
  const [gallery_img_three, setGallery_img_three] = useState(null);
  const [imgdesc, setImgdesc] = useState('');
  const [quote, setQuote] = useState('');
  const [quotedesc, setQuotedesc] = useState('');
  const [tags, setTags] = useState('');

  console.log("banner", banner)

  // settting first gallaery image ---->
  const handleFirstImg = (event) => {

    const selectedFile = event.target.files[0]; // Access the first selected file
    if (selectedFile) {
      const fileName = selectedFile.name;
      console.log("File Name:", fileName);
      setGallery_img_one(selectedFile.name)
      // You can use fileName for further processing or display.
    }
  }

  // settting Seconde gallaery image ---->
  const handlesecondImg = (event) => {

    const selectedFile = event.target.files[0]; // Access the first selected file
    if (selectedFile) {
      const fileName = selectedFile.name;
      console.log("File Name:", fileName);
      setGallery_img_two(selectedFile.name)
      // You can use fileName for further processing or display.
    }
  }

  // settting Third gallaery image ---->
  const handleThirdImg = (event) => {

    const selectedFile = event.target.files[0]; // Access the first selected file
    if (selectedFile) {
      const fileName = selectedFile.name;
      console.log("File Name:", fileName);
      setGallery_img_three(selectedFile.name)
      // You can use fileName for further processing or display.
    }
  }

  // settting Banner ---->
  const HandleBanner = (event) => {

    const selectedFile = event.target.files[0]; // Access the first selected file
    if (selectedFile) {
      const fileName = selectedFile.name;
      console.log("File Name:", fileName);
      setBanner(selectedFile.name)
      // You can use fileName for further processing or display.
    }
  }

  const imageURLs = [gallery_img_one, gallery_img_two, gallery_img_three];

  const submitform = (e) => {
    e.preventDefault();

    const jwt = localStorage.getItem("token");
    const postData = {
      jwt: jwt,
      heading: header,
      subheading: subheader,
      layout: 2,
      url: forURL,
      tag: tags,
      description: apisubmit.description,
      banner: banner,
      images: imageURLs,
      image_description: imgdesc,
      gallery_title: gallerytitle,
      quote_description: quotedesc,
      quote: quote
    }

    console.log(postData)
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH + 'add_blog',
      data: postData,
    })
      .then(res => {
        if (res.data.Success) {
          toast.success('Blog Created successfully');
        }
      })
      .catch(err => {
        console.log(err)
        if (err.res.data.Error !== '') {
          toast.error('Something Went Wrong');
        }
      })
  };

  return (
    <section className="py-8 w-full">
      <h2 className="text-third text-center text-3xl font_primary">Layout 2</h2>
      <div className="container mx-auto py-8">
        <form className="px-2" onSubmit={submitform}>
          <div className="justify-center items-center flex-col sm:flex-row hidden">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Layout: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="layout"
                value={layout}
                onChange={(e) => setLayout(e.target.value)}
                readOnly
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="layout"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Banner: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="file"
                name="banner_img"
                onChange={HandleBanner}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Sub-Header"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Header: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                name="header"
                type="text"
                value={header}
                onChange={(e) => setHeader(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Header"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Sub-Header: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                name="subheader"
                type="text"
                value={subheader}
                onChange={(e) => setSubheader(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Sub-Header"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">forURL: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="forURL"
                value={forURL}
                onChange={(e) => setforURL(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="forURL"
              />
            </div>
          </div>
          <div className="hidden justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Name: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="name"
                disabled
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Name"
              />
            </div>
          </div>
          <div className="justify-center items-center flex-col sm:flex-row hidden">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Email: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="email"
                disabled
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Email"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Description: </h2>
            </div>
            <div className="w-full sm:w-3/4 py-2">
              <CKEditor
                name="description"
                editor={ClassicEditor}
                value={apisubmit.description || ""}
                data="<p>Welcome to <b>Earnestroi</b> !!!<br />
                            <br /></p>"
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  // console.log( 'Editor is ready to use!', editor );
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  // console.log( { event, editor, data } );
                  setApisubmit({ ...apisubmit, description: data });
                }}
                onBlur={(event, editor) => {
                  // console.log( 'Blur.', editor );
                }}
                onFocus={(event, editor) => {
                  // console.log( 'Focus.', editor );
                }}
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Gallery Titile </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="gallerytitle"
                value={gallerytitle}
                onChange={(e) => setGallerytitle(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Image Gallery Title"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Image one: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="file"
                name="imgone"
                onChange={handleFirstImg}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="image 1"
              />

            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Image Two: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="file"
                name="imgtwo"
                onChange={handlesecondImg}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="image 2"
              />

            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Image Three: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="file"
                name="imgthree"
                onChange={handleThirdImg}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="image 3"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Image Description: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="imgdescription"
                value={imgdesc}
                onChange={(e) => setImgdesc(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Image Description"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Quote: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="quote"
                value={quote}
                onChange={(e) => setQuote(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Quote"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Quote Description: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="quotedescription"
                value={quotedesc}
                onChange={(e) => setQuotedesc(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Quote Description"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Tags: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="tags"
                value={tags}
                onChange={(e) => setTags(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Tags"
              />
            </div>
          </div>
          <input
            type="submit"
            className="py-2 px-4 mt-4 rounded-lg font_secondary my-1 w-1/4 bg-second ml-auto hover:cursor-pointer block font_primary"
          />
        </form>
      </div>
    </section>
  );
}

export default LayoutTwoDash;