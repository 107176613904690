import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import about_1 from '../img/earnestroi-about-us-our-journy.webp';
import about_2 from '../img/earnestroi-about-us-about-us-team.png';
import about_3 from '../img/earnestroi-about-us-about-us-bulb.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import Footern from './Footern';
import { Helmet } from 'react-helmet';
// import WhatsappChat from './WhatsappChat';

const AboutUs = () => {

  useEffect( () => {

    Aos.init({

      disable: function() {
        var maxWidth = 1023;
        return window.innerWidth < maxWidth;
      }

    });

    $(document).ready(function(){

      $('.carousel-status').hide();
      $('.control-prev').hide();
      $('.control-next').hide();
      $('.thumbs-wrapper').hide();

    })


    $(document).ready(function(){
        $(this).scrollTop(0);
    });

  } );

  useEffect( () => {

    Aos.init({ duration: 3000 });

  } )

  return (
    <>
      <Helmet>
        <title>Earnestroi Technology | About Us | Business Development company</title>
        <meta name="title" content=" Earnestroi Technology | About Us | Business Development company " />
        <meta name="description" content="We are the team of experts with extensive experience in Blockchain, Advertising & Digital Marketing, Business Development and Strategy and online marketing " />
        <meta name="keywords" content="internet branding, Online Marketing, Online Advertising, Internet Advertising, Internet Marketing, Digital Marketing, Online Marketing Agency, Digital Agency, SEO, PPC, email wallpapers, banners, virals" />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
      </Helmet>
      <section className=' py-10 bg-main'>
        <div className='container mx-auto'>
          <div className='flex flex-col lg:flex-row justify-center items-center'>
            <img src={ about_1 } className='lg:w-1/2' alt='Our Jounery ' />
            <h1 className='text-second text-5xl lg:text-6xl mb-4 mt-4 lg:mt-0 capitalize text-center font_primary' data-aos="fade-right">Our<br /> Journey</h1>
          </div>
        </div>
        <div className='container mx-auto'>
          <div className='flex justify-center py-4 lg:py-10'>
            <Link to='/' ><h2 className='mx-2 text-second font_primary'><FontAwesomeIcon icon={ faHome } className='mx-2 text-second font_primary' />Digital Marketing Agency</h2></Link><h2 className='mx-2 text-second font_primary'>/</h2><Link to='/aboutus'><h2 className='mx-2 text-second font_primary'>About Us</h2></Link>
          </div>
        </div>
        <div className='container mx-auto'>
          <div className='w-full px-4 lg:w-3/4 py-4 lg:py-12'>
            <p className='text-[white] my-6 text-justify md:text-start font_secondary'>Earnestroi was founded in the year July 2019 by Mr Ahmed, who has immense knowledge and experience in the blockchain industry. Earnestroi Technology is a team of blockchain enthusiasts driven to provide solutions for smart contracts execution. Here we help you build your blockchain solutions and develop customized Apps on top of Ethereum, Stellar, NEM and other public blockchains. Our mission is to help businesses and nonprofits harness the power and potential of blockchain technology. We are on the cutting edge of this technology, creating blockchain-based solutions on which businesses can rely. We are on a mission to help companies incorporate the benefits of blockchain.</p>
            <p className='text-[white] my-6 text-justify md:text-start font_secondary'>Whether you are a startup or an established brand, Earnestroi can offer you valuable expertise in all fields of operation of blockchain technology. We are an innovative and swiftly growing marketing agency with expertise in blockchain concepts such as Bitcoin and Ethereum. We make this technology easier to understand and use for everyone by offering branding, PPC, SEO, full-stack development, crypto consulting, and everything in between. Our team of experts has extensive experience in Blockchain, Digital Marketing, Business Management and Strategy, and Sales. We help startups and businesses leverage the decentralized network, build solutions on blockchain and introduce a new level of clarity, competence, and automation into businesses. We bring forward the blockchain's key benefits and how it would benefit the companies to attain business succession.</p>
            <p className='text-[white] my-6 text-justify md:text-start font_secondary'>We empower the people to be a part of a new decentralized world. Earnestroi Technology's blockchain team is flexible with a vast category of business, for example, Healthcare, education, finance, retail, supply chain, publishing, beauty and wellness, Entertainment, agriculture, and pharmaceuticals. From ideation to design development, the team highly contributes to building enterprise-grade decentralized applications to help clients stimulate time to market and maximize ROI.</p>
            <p></p>
          </div>
          <div className='flex flex-col lg:flex-row py-4 lg:py-12'>
            <div className='lg:ml-auto w-1/2 hidden lg:invisible'>
              <h2 className='text-[white] text-3xl'>
                IN A NUTSHELL, EVEN IF WE GAVE THAT TITLE TO OURSELVES, WE ARE THE BEST DIGITAL MARKETING AGENCY !!!
              </h2>
            </div>  
            <div className='lg:ml-auto w-full px-4 lg:w-1/2 '>
              <h2 className='text-[white] text-3xl font_secondary' data-aos='fade-right'>
                IN A NUTSHELL, EVEN IF WE GAVE THAT TITLE TO OURSELVES, WE ARE THE <span className='text-second'>BEST DIGITAL MARKETING AGENCY</span>
              </h2>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='lg:px-8 mx-auto lg:py-16 my-8 lg:my-0'>
          <div className='grid-cols-2 justify-around items-center flex flex-col-reverse lg:flex-row'>
            <div className='flex flex-col lg:w-1/2 2xl:w-[25%] mx-8 border-l-2 border-second'>
              <p className='text-black text-base pl-8 font_secondary'>ABOUT US</p>
              <br />
              <p className='text-black text-3xl border-l-4 border-second pl-8 uppercase font_secondary' data-aos="fade-up">DELIVERING A NEW DEFINITION OF SOCIAL</p>
              <br />
              <p className='text-black text-base sm:text-lg pl-8 font_secondary' data-aos="fade-up">As they say, it takes a village to raise a child, we think it requires a group of highly creative enigmas to create a digital presence.</p>
              <br />
              <p className='text-black text-base sm:text-lg pl-8 font_secondary' data-aos="fade-up">Leading talents in graphic design, SEO, content writing, and marketing make up our team at Earnestroi. These professionals were hand-selected to serve our clients depending on the requirements of each unique business.</p>
            </div>
            <div className=' mx-8'>
              <img src={ about_2 } alt='Social ' className='my-4 sm:my-0' />
            </div>
          </div>
        </div>
      </section>
      <section className='bg-main '>
        <div className='lg:px-8 mx-auto py-8 lg:py-16'>
          <div className='justify-around items-center flex flex-col lg:flex-row relative'>
            <div className='flex flex-col lg:w-[40%] mx-8 border-l-2 border-second'>
              <p className='text-[white] text-base pl-8 py-4 lg:pb-6 font_secondary'>CAREERS</p>
              <br />
              <p className='text-[white] text-3xl border-l-4 border-second pl-8 font_primary' data-aos="fade-up">WANT TO BE PART OF OUR TEAM?</p>
              <br />
              <p className='text-[white] text-base sm:text-lg pl-8 py-4 lg:py-10 font_secondary' data-aos="fade-up">Check out the available opportunities.</p>
              <Link to='/wearehiring' className='relative p-2 border-2 ml-8 text-second border-second w-max rounded-lg font_primary' data-aos="fade-up">Openings <FontAwesomeIcon icon={faArrowRight} className='text-second pl-4 animate-right_left'/></Link>
            </div>
            <div className='mx-8 w-full p-4 lg:p-0 lg:w-[30%] my-4 lg:my-0'>
              <img src={ about_3 } alt='altImg about us' className='block mx-auto lg:mx-0 lg:absolute top-[-8rem] lg:right-[4rem] xl:right-[12rem] 2xl:right-[20rem]' />              
            </div>
          </div>
        </div>
      </section>
      <Footern />
    </>
  )
}

export default AboutUs;