import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import aboutus from '../img/landing/earnestroi-landing-beauty-1.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faPhotoFilm, faFilePen, faLaptopCode, faPalette, faCheck, faCircleDollarToSlot, faEnvelopeCircleCheck, faFileCircleCheck, faLaptopFile, faScrewdriverWrench, faBullhorn, faRectangleAd, faQuoteRight, faUserGear } from '@fortawesome/free-solid-svg-icons';
import Footern from './Footern';
import Pricing from './Pricing';
import IndustrialExpertise from './IndustrialExpertise';
import work from '../img/landing/earnestroi-landing-beauty-2.webp';
import google from '../img/landing/contact/earnestroi-contact-us-google-ads.jpg';
import facebook from '../img/landing/contact/earnestroi-contact-us-facebook-ads.jpg';
import insta from '../img/landing/contact/earnestroi-contact-us-insta-ads.jpg';
import youtube from '../img/landing/contact/earnestroi-contact-us-youtube-ads.jpg';
import linkedin from '../img/landing/contact/earnestroi-contact-us-linked-in-ads.jpg';
import snapchat from '../img/landing/contact/earnestroi-contact-us-snapchat-ads.jpg';
import ModalLanding from './ModalLanding';
import $ from 'jquery';
import MainsectionLanding from './MainsectionLanding';
import WhatsappChat from './WhatsappChat';

const LandingOne = () => {

    const [show, setShow] = useState(false);

    useEffect(()=>{
        setTimeout(()=>{
          setShow(true)
        }, 5000)
    }, []);

    useEffect( () => {

        $(document).ready(function(){
            $(this).scrollTop(0);
        });

    } );

  return (
    <>  
        <ModalLanding show={ show } onClose={ () => { setShow( false ) } } />
        <section className='h-full lg:h-[100vh] bg-cover relative bg-[#090f11]'>
            {/* <div className='h-full w-full py-16 md:py-0 flex justify-center items-center'>
                <div>
                    <h2 className='modal-title text-center m-0 capitalize text-third font_primary text-2xl md:text-3xl lg:text-6xl'>Your product is awesome <br />but nobody <span className='text-second'>knows about it</span>.</h2>
                    <Link to='/contactsl' className='text-xl md:text-2xl lg:text-3xl mx-auto hover:shadow-[0_0_12px_#77c1dd] text-third border-2 border-third px-4 py-2 mt-8 rounded-lg hover:text-[white] m-0 hover:border-third font_primary block w-max text-center' >Let us help you</Link>
                </div>
            </div> */}
            <MainsectionLanding />
        </section>
        <section>
            <div className='py-12 bg-light'>
                <div className='container mx-auto'>
                    <div className='flex flex-col sm:flex-row justify-between items-center px-2'>
                        <div className=''>
                            <h2 className='text-lg md:text-xl xl:text-3xl font_primary capitalize text-[red] text-center sm:text-left'>Are you ready to take the big step towards your digital growth?</h2>
                        </div>
                        <div className=''>
                            <Link to='/contactsl' className='text-lg md:text-xl xl:text-3xl hover:shadow-[0_0_12px_#fff] text-dark border-2 border-dark px-4 py-2 rounded-lg hover:text-dark m-0 hover:border-dark font_primary block w-max text-center mx-auto uppercase' >get a free quote</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='bg-[#090f11]'> 
            <IndustrialExpertise />
        </section>
        <section>
            <div className='bg-topblue bg-cover h-[100px] bg-no-repeat'></div>
            <div className="container mx-auto px-2 lg:px-0">
                <div className="flex flex-col lg:flex-row justify-center items-center">
                    <div className='w-full lg:w-1/2'>
                        <img src={ aboutus } alt='Digital Marketing Solutions' className='mx-auto block' />
                    </div>
                    <div className='w-full lg:w-1/2 py-2'>  
                        <h2 className='text-xl md:text-2xl lg:text-3xl font_primary capitalize text-second'>Your powerhouse for digital marketing solutions</h2>
                        <p className='text-base sm:text-lg font_secondary py-2'><FontAwesomeIcon icon={ faCheck } className='text-second mx-2' />Strategy Consultation</p>
                        <p className='text-base sm:text-lg font_secondary py-2'><FontAwesomeIcon icon={ faCheck } className='text-second mx-2' />One-Stop 360-degree Solution</p>
                        <p className='text-base sm:text-lg font_secondary py-2'><FontAwesomeIcon icon={ faCheck } className='text-second mx-2' />Successful Track Record</p>
                        <p className='text-base sm:text-lg font_secondary py-2'><FontAwesomeIcon icon={ faCheck } className='text-second mx-2' />Multi-Industry Expertise</p>
                        <p className='text-base sm:text-lg font_secondary py-2'><FontAwesomeIcon icon={ faCheck } className='text-second mx-2' />Client First Approach</p>
                        <p className='text-base sm:text-lg font_secondary py-2'><FontAwesomeIcon icon={ faCheck } className='text-second mx-2' />Client Servicing</p>
                    </div>
                </div>
            </div>
        </section>
        <div className='py-8 bg-main'>
            <div className='container mx-auto'>
                <div className='flex flex-col sm:flex-row justify-between items-center px-2'>
                    <div className=''>
                        <h2 className='text-xl md:text-2xl lg:text-3xl font_primary capitalize text-third text-center sm:text-left'>We're offering a FREE consultation to new clients. </h2>
                        <p className='text-base sm:text-xl font_secondary py-2 text-[white] text-center sm:text-left'>Let our team of experts brings value to your business.</p>
                    </div>
                    <div className=''>
                        <Link to='/contactsl' className='text-base md:text-xl lg:text-2xl hover:shadow-[0_0_12px_#FE8067] text-[white] border-2 border-[white] px-4 py-2 rounded-lg hover:text-[white] m-0 hover:border-[white] font_primary block w-max text-center mx-auto' >BOOK A FREE CONSULT NOW</Link>
                    </div>
                </div>
            </div>
        </div>
        <section className=''>
            <div className="flex flex-col sm:grid grid-cols-2 lg:grid-cols-4">
                <div className="px-6 py-12 bg-[#77c1dd1c]">
                    <h2 className='text-xl md:text-2xl lg:text-3xl font_primary capitalize text-second mb-6'>OUR WORK STRATEGY</h2>
                    <h2 className='text-sm lg:text-base mt-4 lg:mt-0 font_secondary capitalize font_secondary py-2 lg:py-4 text-main'>We believe in result oriented discussions with our clients. From the moment we kick start a project to the delivery, it’s all about chasing perfection and meeting business goals for the brands.</h2>
                </div>
                <div className="px-6 py-12">
                    <h2 className='text-2xl md:text-3xl lg:text-6xl font_primary capitalize text-second mb-6'>01.</h2>
                    <h2 className='text-lg md:text-xl lg:text-2xl font_primary capitalize text-second mb-6'>PLANNING & STRATEGY</h2>
                    <h2 className='text-sm lg:text-base mt-4 lg:mt-0 font_secondary capitalize font_secondary py-2 lg:py-4'>An immense subject knowledge transfer from the client’s is a must before we kick start a new project. This lays the foundation for a wholesome long term strategy with measurable KPIs to quantify success.</h2>
                </div>
                <div className="px-6 py-12">
                    <h2 className='text-2xl md:text-3xl lg:text-6xl font_primary capitalize text-second mb-6'>02.</h2>
                    <h2 className='text-lg md:text-xl lg:text-2xl font_primary capitalize text-second mb-6'>DESIGN & EXECUTE</h2>
                    <h2 className='text-sm lg:text-base mt-4 lg:mt-0 font_secondary capitalize font_secondary py-2 lg:py-4'>The next step involves giving life to the strategy using channels & media. A carefully charted out execution plan with relevant timelines & dependencies to maintain clarity with all stakeholders involved.</h2>
                </div>
                <div className="px-6 py-12">
                    <h2 className='text-2xl md:text-3xl lg:text-6xl font_primary capitalize text-second mb-6'>03.</h2>
                    <h2 className='text-lg md:text-xl lg:text-2xl font_primary capitalize text-second mb-6'>REPORTING & ANALYTICS</h2>
                    <h2 className='text-sm lg:text-base mt-4 lg:mt-0 font_secondary capitalize font_secondary py-2 lg:py-4'>Being a performance focussed agency, we love to play with numbers and derive insights from the same. A mature reporting module along with insight driven optimizations is all you need to complete the feedback loop, and drive success.</h2>
                </div>
            </div>
        </section>
        <div className='py-8 bg-third'>
            <div className='container mx-auto'>
                <div className='flex flex-col sm:flex-row items-center justify-between px-2'>
                    <div className=''>
                        <h2 className='text-xl md:text-2xl lg:text-3xl font_primary capitalize text-main text-center sm:text-left'>We craft customized digital marketing strategies. <br />95% of our clients see results!</h2>
                        {/* <p className='text-base sm:text-xl font_secondary py-2 text-main text-center sm:text-left'>Today to find out how we can help.</p> */}
                    </div>
                    <div className=''>
                        <Link to='/contactsl' className='text-base md:text-xl lg:text-2xl mx-auto hover:shadow-[0_0_12px_#ffffff] text-main border-2 border-main px-4 py-2 rounded-lg hover:text-main m-0 hover:border-main font_primary block w-max text-center' >TALK TO US TODAY</Link>
                    </div>
                </div>
            </div>
        </div>
        <section>
            {/* <div className='bg-bottomblack bg-cover h-[80px] bg-no-repeat'></div> */}
            <div className='bg-main py-8'>
                <div className="container mx-auto">
                    <h2 className='text-2xl md:text-3xl lg:text-5xl font_primary capitalize text-second text-center'>Our Services</h2>
                </div>
                <div className="container mx-auto py-8">
                    <div className='grid sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-8 justify-center items-center px-2 lg:px-0'>
                        <div className='p-4 h-full bg-[#77c1dd38] lg:bg-[#77c1dd14] hover:bg-[#f6775f12] hover:cursor-pointer rounded-2xl'>
                            <FontAwesomeIcon icon={ faChartLine } className='text-5xl text-[white]' />
                            <h2 className='text-lg mb-2 font_primary text-second'>SEO</h2>
                            <p className='text-base font_secondary text-[white]'>Increase website traffic and visibility in Search Engine Result Pages (SERPs).</p>
                        </div>
                        <div className='p-4 h-full bg-[#77c1dd38] lg:bg-[#77c1dd14] hover:bg-[#f6775f12] hover:cursor-pointer rounded-2xl'>
                            <FontAwesomeIcon icon={ faCircleDollarToSlot } className='text-5xl text-[white]' />
                            <h2 className='text-lg mb-2 font_primary text-second'>PAY PER CLICK</h2>
                            <p className='text-base font_secondary text-[white]'>Our PPC service provides excellent value for money. We optimise your campaigns to improve your Quality Score, which can lead to more quality clicks and a higher ROI.</p>
                        </div>
                        <div className='p-4 h-full bg-[#77c1dd38] lg:bg-[#77c1dd14] hover:bg-[#f6775f12] hover:cursor-pointer rounded-2xl'>
                            <FontAwesomeIcon icon={ faLaptopCode  } className='text-5xl text-[white]' />
                            <h2 className='text-lg mb-2 font_primary text-second'>WEBSITE DESIGN</h2>
                            <p className='text-base font_secondary text-[white]'>Give your company a customer-centric design that UX and UI science support</p>
                        </div>
                        <div className='p-4 h-full bg-[#77c1dd38] lg:bg-[#77c1dd14] hover:bg-[#f6775f12] hover:cursor-pointer rounded-2xl'>
                            <FontAwesomeIcon icon={ faFilePen } className='text-5xl text-[white]' />
                            <h2 className='text-lg mb-2 font_primary text-second'>CONTENT WRITING</h2>
                            <p className='text-base font_secondary text-[white]'>Create actionable content. Content that helps users navigate the website and convert.</p>
                        </div>
                        <div className='p-4 h-full bg-[#77c1dd38] lg:bg-[#77c1dd14] hover:bg-[#f6775f12] hover:cursor-pointer rounded-2xl'>
                            <FontAwesomeIcon icon={ faLaptopCode } className='text-5xl text-[white]' />
                            <h2 className='text-lg mb-2 font_primary text-second'>WEBSITE DEVELOPMENT</h2>
                            <p className='text-base font_secondary text-[white]'>We can help you power your business. Use the appropriate technologies to create a result-oriented website.</p>
                        </div>
                        <div className='p-4 h-full bg-[#77c1dd38] lg:bg-[#77c1dd14] hover:bg-[#f6775f12] hover:cursor-pointer rounded-2xl'>
                            <FontAwesomeIcon icon={ faEnvelopeCircleCheck } className='text-5xl text-[white]' />
                            <h2 className='text-lg mb-2 font_primary text-second'>EMAIL MARKETING</h2>
                            <p className='text-base font_secondary text-[white]'>With effective strategy and communication, you can reach your target audience.</p>
                        </div>
                        <div className='p-4 h-full bg-[#77c1dd38] lg:bg-[#77c1dd14] hover:bg-[#f6775f12] hover:cursor-pointer rounded-2xl'>
                            <FontAwesomeIcon icon={ faFileCircleCheck } className='text-5xl text-[white]' />
                            <h2 className='text-lg mb-2 font_primary text-second'>CONTENT MARKETING</h2>
                            <p className='text-base font_secondary text-[white]'>With our content strategy and marketing services, you can turn good content into outstanding content</p>
                        </div>
                        <div className='p-4 h-full bg-[#77c1dd38] lg:bg-[#77c1dd14] hover:bg-[#f6775f12] hover:cursor-pointer rounded-2xl'>
                            <FontAwesomeIcon icon={ faPalette } className='text-5xl text-[white]' />
                            <h2 className='text-lg mb-2 font_primary text-second'>BRANDING</h2>
                            <p className='text-base font_secondary text-[white]'>With our creative conceptualization and brand story, you can establish assertive communication for your brand</p>
                        </div>
                        <div className='p-4 h-full bg-[#77c1dd38] lg:bg-[#77c1dd14] hover:bg-[#f6775f12] hover:cursor-pointer rounded-2xl'>
                            <FontAwesomeIcon icon={ faLaptopFile  } className='text-5xl text-[white]' />
                            <h2 className='text-lg mb-2 font_primary text-second'>UI / UX</h2>
                            <p className='text-base font_secondary text-[white]'>Make your website visitors' online experience memorable.</p>
                        </div>
                        <div className='p-4 h-full bg-[#77c1dd38] lg:bg-[#77c1dd14] hover:bg-[#f6775f12] hover:cursor-pointer rounded-2xl'>
                            <FontAwesomeIcon icon={ faPalette } className='text-5xl text-[white]' />
                            <h2 className='text-lg mb-2 font_primary text-second'>GRAPHIC DESIGN</h2>
                            <p className='text-base font_secondary text-[white]'>Change how people perceive your brand with creative concepts and designs created by a detail-oriented team.</p>
                        </div>
                        <div className='p-4 h-full bg-[#77c1dd38] lg:bg-[#77c1dd14] hover:bg-[#f6775f12] hover:cursor-pointer rounded-2xl'>
                            <FontAwesomeIcon icon={ faScrewdriverWrench } className='text-5xl text-[white]' />
                            <h2 className='text-lg mb-2 font_primary text-second'>SOCIAL MEDIA OPTIMIZATION</h2>
                            <p className='text-base font_secondary text-[white]'>With our data-backed creative campaign, you can increase brand visibility, engagement, and business</p>
                        </div>
                        <div className='p-4 h-full bg-[#77c1dd38] lg:bg-[#77c1dd14] hover:bg-[#f6775f12] hover:cursor-pointer rounded-2xl'>
                            <FontAwesomeIcon icon={ faPhotoFilm } className='text-5xl text-[white]' />
                            <h2 className='text-lg mb-2 font_primary text-second'>MEDIA PLANNING SERVICE</h2>
                            <p className='text-base font_secondary text-[white]'>Our Media Planning expertise can get your brand noticed on the right platform at the right time and place</p>
                        </div>
                    </div>
                    <div>
                        <Link to='/contactsl' className='text-base md:text-lg lg:text-xl hover:shadow-[0_0_12px_#77c1dd] text-[white] border-2 border-[white] px-0 sm:px-12 py-2 mt-8 rounded-lg hover:text-[white] mx-auto m-0 hover:border-[white] font_primary block w-max text-center bn6' >Take Advantage of Free Consultation.</Link>
                    </div>
                </div>
            </div>
            <div className='bg-topblack bg-cover h-[100px] bg-no-repeat'></div>
        </section>
        <section className='py-8 md:mb-10 lg:mb-0'>
            <div className="container mx-auto">
                <div className='flex flex-col-reverse lg:flex-row justify-center items-center'>
                    <div className='p-2 w-full lg:w-1/2'>
                        <h2 className='text-xl md:text-2xl lg:text-3xl font_primary capitalize text-second'>How We Work For You to Grow</h2>
                        <p className='text-base font_secondary py-4'>Unlock Your Brand's Unique Potential With Earnestroi</p>
                        <div className='px-1 my-1 lg:my-4'>
                            <div className='grid grid-cols-2 gap-2 lg:gap-4'>
                                <div className='p-4 bg-[#77c1dd14] hover:bg-[#fe80673b] hover:cursor-pointer rounded-2xl'>
                                    <FontAwesomeIcon icon={ faBullhorn } className='text-second text-2xl lg:text-3xl mx-auto block' />
                                    <h2 className='text-sm lg:text-base mt-4 lg:mt-0 font_secondary capitalize font_secondary py-2 text-center lg:py-4'>Build a Brand for your service with  Social Media Marketing</h2>
                                </div>
                                <div className='p-4 bg-[#77c1dd14] hover:bg-[#fe80673b] hover:cursor-pointer rounded-2xl'>
                                    <FontAwesomeIcon icon={ faRectangleAd } className='text-second text-2xl lg:text-3xl mx-auto block' />
                                    <h2 className='text-sm lg:text-base mt-4 lg:mt-0 font_secondary capitalize font_secondary py-2 text-center lg:py-4'>Focus on Paid advertising and Use Popular Beauty Influencers</h2>
                                </div>
                            </div>
                        </div>
                        <div className='px-1 my-1 lg:my-4'>
                            <div className='grid grid-cols-2 gap-2 lg:gap-4'>
                                <div className='p-4 bg-[#77c1dd14] hover:bg-[#fe80673b] hover:cursor-pointer rounded-2xl'>
                                    <FontAwesomeIcon icon={ faQuoteRight } className='text-second text-2xl lg:text-3xl mx-auto block' />
                                    <h2 className='text-sm lg:text-base mt-4 lg:mt-0 font_secondary capitalize font_secondary py-2 text-center lg:py-4'>Use Testimonials On Your Product Pages and maintain ORM</h2>
                                </div>
                                <div className='p-4 bg-[#77c1dd14] hover:bg-[#fe80673b] hover:cursor-pointer rounded-2xl'>
                                    <FontAwesomeIcon icon={ faUserGear } className='text-second text-2xl lg:text-3xl mx-auto block' />
                                    <h2 className='text-sm lg:text-base mt-4 lg:mt-0 font_secondary capitalize font_secondary py-2 text-center lg:py-4'>Service Awareness, Offers, Rewards, Vouchers, Collections</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='p-2 w-full lg:w-1/2'>
                        <img src={ work } alt='Digital Marketing Solutions 2' className='mx-auto block' />
                    </div>
                </div>
            </div>
        </section>
        <div className='py-8'>
            <div className='container mx-auto'>
                <div className='flex flex-col sm:flex-row justify-between items-center px-2'>
                    <div className=''>
                        <h2 className='text-xl md:text-2xl lg:text-3xl font_primary capitalize text-[#f00] text-center sm:text-left'>Get Free Marketing Plan !!!</h2>
                        <p className='text-base sm:text-xl font_secondary py-2 text-main text-center sm:text-left'><b>Discover how we can take your business to the next level.</b></p>
                    </div>
                    <div className=''>
                        <Link to='/contactsl' className='text-base md:text-xl lg:text-2xl mx-auto hover:shadow-[0_0_12px_#FE8067] text-main border-2 border-main px-4 py-2 rounded-lg hover:text-main m-0 hover:border-main font_primary block w-max text-center' >GET A FREE CONSULTATION</Link>
                    </div>
                </div>
            </div>
        </div>
        <section>
            <div className='bg-bottomblack bg-cover h-[80px] bg-no-repeat'></div>
        </section>
        <section className='bg-main py-10'>
            {/* <IndustrialExpertise /> */}
            <Pricing />
        </section>
        <div className='py-8 bg-light'>
            <div className='container mx-auto'>
                <div className='flex flex-col sm:flex-row justify-between items-center px-2'>
                    <div className=''>
                        <h2 className='text-xl md:text-2xl lg:text-3xl font_primary capitalize text-[red] text-center sm:text-left'>Want to get ahead of the competition?</h2>
                        <p className='text-base sm:text-xl font_secondary py-2 text-[black] text-center sm:text-left'>Our team of experts is ready to discuss your campaign goals.</p>
                    </div>
                    <div className=''>
                        <Link to='/contactsl' className='text-base md:text-xl lg:text-2xl hover:shadow-[0_0_12px_#fff] text-dark border-2 border-dark px-4 py-2 rounded-lg hover:text-dark m-0 hover:border-dark font_primary block w-max text-center mx-auto' >FREE project consultation</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-topblack bg-cover h-[100px] bg-no-repeat"></div>
        <section>
            <div className="container mx-auto pt-8 px-2 lg:px-0">
                <h2 className='text-xl md:text-2xl lg:text-3xl font_primary capitalize text-second text-center py-10'>Contact Us</h2>
                <div className="flex flex-col lg:flex-row justify-center items-center">
                    <div className='w-full lg:w-1/2'>
                        <h2 className='text-xl md:text-2xl lg:text-3xl font_primary capitalize text-second mb-2 text-center'>Are you ready to develop your business online?</h2>
                        <h2 className='text-xl md:text-2xl lg:text-3xl font_primary capitalize text-second mb-2 text-center'>We can help you achieve success!</h2>
                        <div className="grid grid-cols-2 gap-4 py-10">
                            <div className=''>
                                <img src={ google } alt='contact us imgs' className='mx-auto block rounded-xl w-3/4 border-[1px] border-third' />
                            </div>
                            <div className=''>
                                <img src={ facebook } alt='contact us imgs' className='mx-auto block rounded-xl w-3/4 border-[1px] border-third' />
                            </div>
                            <div className=''>
                                <img src={ insta } alt='contact us imgs' className='mx-auto block rounded-xl w-3/4 border-[1px] border-third' />
                            </div>
                            <div className=''>
                                <img src={ youtube } alt='contact us imgs' className='mx-auto block rounded-xl w-3/4 border-[1px] border-third' />
                            </div>
                            <div className=''>
                                <img src={ linkedin } alt='contact us imgs' className='mx-auto block rounded-xl w-3/4 border-[1px] border-third' />
                            </div>
                            <div className=''>
                                <img src={ snapchat } alt='contact us imgs' className='mx-auto block rounded-xl w-3/4 border-[1px] border-third' />
                            </div>
                        </div>
                    </div>
                    <div className='w-full lg:w-1/2'>
                        <form className='p-10 px-8 w-[90%] mx-auto block shadow-[0px_0px_16px_#77c1dd87] rounded-2xl'>
                            <p className='font_secondary'>Full Name*</p>
                            <input type='text' required className='p-2 w-full border-[1px] border-third rounded-xl focus-visible:outline-second' />
                            <p className='font_secondary'>Email Address*</p>
                            <input type='text' required className='p-2 w-full border-[1px] border-third rounded-xl focus-visible:outline-second' />
                            <p className='font_secondary'>Contact Number*</p>
                            <input type='text' required className='p-2 w-full border-[1px] border-third rounded-xl focus-visible:outline-second' />
                            <p className='font_secondary'>Website</p>
                            <input type='text' className='p-2 w-full border-[1px] border-third rounded-xl focus-visible:outline-second mb-4' />
                            <input type='submit' className='p-2 w-max border-[1px] border-third rounded-xl font_primary text-third' />
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <Footern />
        <WhatsappChat />
    </>
  )
}

export default LandingOne;