import React, { useEffect, useState } from 'react';
import Footern from './Footern';
import $ from 'jquery';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactsL = () => {

  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs(values => ({ ...values, [name]: value }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, message } = inputs;
    //  const jwt = localStorage.getItem("jwt");
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("message", message);
    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_USER + 'contact',
      data: formData,
    })
      .then(async function (response) {
        console.log(response)
        const res = await response?.data;
        toast.success(res?.Success);
      })
      .catch((err) => {
        //  console.error(err); // Log the error for debugging
        toast.error("Message already sent!");
      });
  };



  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log(inputs);
  //   const postdata = {
  //     name: inputs.name == ""|| null ? "haghsga" : inputs.name,
  //     email: inputs.email == "" ? "haghsga" : inputs.email,
  //     phone: inputs.phone == "" ? "haghsga" : inputs.phone,
  //     description: inputs.description == "" ? "haghsga" : inputs.description,
  //   };
  //    axios
  //      .post("https://api.earnestroi.com/api/contact-us", postdata)
  //      .then((response) => {
  //        if (response.data.message === "Contact successfully") {
  //          // Send a message to your Telegram channel or group
  //          const message = `New form submission:\nName: ${
  //            inputs.name == "" ? "NA" : inputs.name
  //          }\nEmail: ${inputs.email} \nPhone No: ${inputs.phone} \nWebsite: ${
  //            inputs.website_url
  //          } \nPosition: ${inputs.position} \nDescription: ${
  //            inputs.description
  //          }`;
  //          axios.post(
  //            "https://api.telegram.org/bot6246172658:AAExS93znql80AK1GJ28TbjGatZEPrDTBCo/sendMessage",
  //            {
  //              chat_id: "-872535715",
  //              text: message,
  //            }
  //          );

  //          toast.success("Response Submitted !!!");
  //        } else {
  //          toast.error("Something Went Wrong !!!");
  //        }
  //      })
  //      .catch((error) => {
  //        console.error(error);
  //        toast.error("Something Went Wrong !!!");
  //      });
  // };

  // const message = `New Form Submission: /nName: ${ inputs.name } \nEmail: ${ inputs.email }`;
  return (
    <>
      <Helmet>
        <title>
          Earnestroi Technology | Contact Us | Digital Marketing Company
        </title>
        <meta
          name="title"
          content="Earnestroi Technology | Contact Us | Digital Marketing Company"
        />
        <meta
          name="description"
          content="Contact us for the solution of complete Digital marketing and project development services. We will provide you the best service to earn your trust and become a long term customer."
        />
        <meta
          name="keywords"
          content="contact us, about us, home page, hiring, Digital Marketing Agency, Online Marketing Services, Social Media Agency, SEO Agency,Website Development & Design Services,Digital & Creative Marketing Agency"
        />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
      </Helmet>
      <section className="py-8 lg:py-10 ">
        <div className="container mx-auto w-full lg:w-1/2">
          <p className="text-xl sm:text-2xl md:text-3xl lg:text-5xl text-second lg:mt-4 font_primary text-center mb-6">
            Contact Form
          </p>
          <p className="text-base text-[#000] font_secondary">
            Connect with us by sending your views
          </p>
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col md:flex-row my-2">
              <div className="w-full lg:w-1/2 px-2">
                <p className="font_secondary">Full Name </p>
                <input
                  type="text"
                  name="name"
                  value={inputs.name || ""}
                  onChange={handleChange}
                  className="p-2 rounded-lg border-[1px] w-full border-main text-main bg-[white] focus:outline-0"
                />
              </div>
              <div className="w-full lg:w-1/2 px-2">
                <p className="font_secondary">Email *</p>
                <input
                  type="email"
                  name="email"
                  value={inputs.email || ""}
                  onChange={handleChange}
                  required
                  className="p-2 rounded-lg border-[1px] w-full border-main text-main bg-[white] focus:outline-0"
                />
              </div>
            </div>
            {/* <div className='flex flex-col md:flex-row my-2'>
                <div className='w-full lg:w-1/2 px-2'>
                  <p className='font_secondary'>Phone *</p>
                  <input type='number' name='phone' value={ inputs.phone || '' } onChange={ handleChange } required className='p-2 rounded-lg border-[1px] w-full border-main text-main bg-[white] focus:outline-0' />
                </div>
                <div className='w-full lg:w-1/2 px-2'>
                  <p className='font_secondary'>Website URL</p>
                  <input type='text' name='website_url' value={ inputs.website_url || '' } onChange={ handleChange } className='p-2 rounded-lg border-[1px] w-full border-main text-main bg-[white] focus:outline-0' />
                </div>
              </div> */}
            {/* <div className='flex flex-col md:flex-row my-2'>
                <div className='w-full lg:w-1/2 px-2'>
                  <p>Position: </p>
                  <select name="position" value={ inputs.position || '' } onChange={ handleChange } className='p-2 rounded-lg border-[1px] w-full border-main text-main bg-[white] focus:outline-0' id="positions">
                    <option value="">Services</option>
                    <option value="smm">SMM</option>
                    <option value="seo">SEO</option>
                    <option value="graphic design">Graphic Designs</option>
                    <option value="content marketing">Content Marketing</option>
                    <option value="web development">Web Development</option>
                    <option value="email marketing">Email Marketing</option>
                    <option value="ui ux development">Ui/Ux Development</option>
                    <option value="influencer marketing">Influencer Marketing</option>
                    <option value="blockchain development">Blockchain Development</option>
                    <option value="machine learning">Machine Learning</option>
                    <option value="artificial intelligence">Artificial Intelligence</option>
                    <option value="nft development">NFT Development</option>
                    <option value="token development">Token Development</option>
                    <option value="smart contract">Smart Contract</option>
                  </select>
                </div>
              </div> */}
            <div className="flex flex-col md:flex-row my-2">
              <div className="w-full px-2">
                <p className="font_secondary">Any Comments *</p>
                <textarea
                  type="text"
                  name="message"
                  value={inputs.message || ""}
                  onChange={handleChange}
                  required
                  className="p-2 rounded-lg border-[1px] w-full border-main text-main bg-[white] focus:outline-0"
                />
              </div>
            </div>
            <div style={{ transform: "scale(0.85)", display: 'flex', justifyContent: 'center' }}>
              <ReCAPTCHA style={{ display: 'flex', justifyContent: 'center' }}
                sitekey="6LdI6B0pAAAAAM7cBd1DNi6vtTGZ0VLMPRZumlMt"
              />
            </div>
            <div className="w-full px-2">
              <input
                type="submit"
                className="px-8 py-2 rounded-lg border-2 w-max cursor-pointer font_primary border-second hover:border-second font_secondary text-[white] hover:text-second bg-second hover:bg-[white] focus:outline-0"
              />
            </div>
          </form>
        </div>
      </section>
      <Footern />
      {/* <WhatsappChat /> */}
    </>
  );
}

export default ContactsL;