import React, { useEffect } from 'react';
import img_2_2 from '../img/ninepages/earnestroi-Crypto-Coin-Development.jpg';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import Aos from 'aos';
import img_4 from '../img/ninepages/earnestroi-Cryptocurrency-Development-Services.jpg';
import coin_development from '../img/earnestroi-token-development-coin-development.png';
import coin_development_2 from '../img/earnestroi-token-development-coin-development-2.png';
import img_types_coin from '../img/earnestroi-token-development-types.svg';
import 'aos/dist/aos.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faShieldHalved, faGears, faScaleBalanced, faImage, faScrewdriverWrench, faHeadphonesSimple, faFileExport, faFileSignature, faChartLine, faFile, faUsers, faMicrochip, faReplyAll, faHeadphones } from '@fortawesome/free-solid-svg-icons';
import img_3 from '../img/ninepages/earnestroi-Cryptocurrency-Using-Crypto-Development-Services.jpg';
import img_ico_1 from '../img/blockchain-icons/earnestroi-blockchain-network-1.png';
import img_ico_2 from '../img/blockchain-icons/earnestroi-blockchain-network-2.png';
import img_ico_4 from '../img/blockchain-icons/earnestroi-blockchain-network-4.png';
import img_ico_7 from '../img/blockchain-icons/earnestroi-blockchain-network-7.png';
import img_ico_8 from '../img/blockchain-icons/earnestroi-blockchain-network-8.png';
import img_ico_9 from '../img/blockchain-icons/earnestroi-blockchain-network-9.png';
import img_ico_12 from '../img/blockchain-icons/earnestroi-blockchain-network-12.png';
import Footern from './Footern';
import { Helmet } from 'react-helmet';
// import WhatsappChat from './WhatsappChat';

const TokenDevelopment = () => {

  useEffect( () => {

        $(document).ready(function(){
          $(this).scrollTop(0);
        });

        $(document).ready(function(){
          
          $('.slick-prev').hide();
          $('.slick-next').hide();

        });

        Aos.init({

          disable: function() {
            var maxWidth = 1024;
            return window.innerWidth < maxWidth;
          }

        });

    } );

      useEffect( () => {

        Aos.init({ duration: 3000 });

      } );

  return (
    <>
        <Helmet>
          <title>Earnestroi Technology | Token Development | Digital Marketing | Decentralized</title>
          <meta name="title" content="Earnestroi Technology | Token Development | Digital Marketing | Decentralized" />
          <meta name="description" content="We’re helping your startups profit from the rise of cryptocurrencies by using distributed and decentralized peer-to-peer networks for transactions." />
          <meta name="keywords" content='crypto currency, cryptocurrency market, cryptocurrency list, digital marketing agency, Creative Marketing Agency' />
          <meta name="robots" content="index,follow" />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="language" content="English" />
        </Helmet>
        <section className=' lg:pt-4 xl:pt-0 bg-main px-1 xl:px-0 py-0 md:py-16'>
          <div className='container mx-auto'>
            <div className='flex flex-col-reverse lg:flex-row justify-center items-center xl:pt-20'>
              <div className='flex flex-col w-full lg:w-1/2'>
                  <h1 className='text-[white] text-5xl lg:text-6xl mb-4 mt-4 lg:mt-0 font_primary capitalize' data-aos="fade-left">Crypto Coin Development</h1>
                  <br />
                  <h2 className='text-base lg:text-lg mb-4 mt-4 lg:mt-0 font_primary capitalize text-third'  data-aos="fade-left">Partner With Sector Experts For Crypto Coin Development To Dominate The Crypto World With Pioneered Digital Currency!</h2>
                  <Link to='/contact' data-aos="fade-left" className='text-base my-2  w-max border-2 bg-third px-2 py-1 rounded-lg hover:bg-main hover:shadow-[0_0_12px_#77c1dd] hover:text-third m-0 hover:border-third font_primary' >Lets Talk</Link>
              </div>
              <div className=' w-full lg:w-1/2'>
                <img src={img_2_2} alt='Crypto Development ' className='mx-auto' />
              </div>
            </div>
          </div>
          <div className='container mx-auto'>
            <div className='flex justify-center py-4 lg:py-10'>
              <Link to='/'><h2 className='mx-2 text-third font_primary'><FontAwesomeIcon icon={ faHome } className='mx-2 text-third' />Digital Marketing Agency</h2></Link><h2 className='mx-2 text-third'>/</h2><h2 className='mx-2 text-third font_primary'>Token Development</h2>
            </div>
          </div>  
        </section>
        <section className='px-1 xl:px-0'>
          <div className='container mx-auto my-16'>
            <div className='grid-cols-2 flex flex-col lg:flex-row justify-between items-center'>
              <div className=''>
                <img src={img_4} alt='Crypto Currency ' className='' />
              </div>
              <div className='lg:w-[50%]'>
                {/* <p className='text-base sm:text-lg font_secondary'>Utilize Our Cryptocurrency Development Services as a Route into the Crypto Future</p>
                <br /> */}
                <h2 className='text-3xl lg:text-5xl font_primary text-third'>Cryptocurrency Development Services</h2>
                <br />
                <p className='text-base sm:text-lg font_secondary' data-aos="fade-right">With the rapid growth of coin development, cryptocurrencies have introduced cutting-edge trading trends. We reduce security risks by eliminating the need for third-party transaction processing. By implementing safe and regulated digital transaction standards, your startups can profit from the rise of cryptocurrencies. Using distributed and decentralized peer-to-peer networks for transactions allows for cryptographically unique currencies and tokens.</p>
              </div>
            </div>
          </div>
        </section>
        <section className='bg-main md:py-8 px-1 xl:px-0'> 
          <div className='container mx-auto py-4 lg:py-8'>
            <div className='w-full lg:w-[60%] mx-auto'>
              <p className='text-[white] text-center mb-2 text-base sm:text-lg font_secondary'>Business Benefits Of Aiding the Top-Notch.</p>
              <h2 className='text-lg lg:text-2xl mb-2 mt-4 lg:mt-0 font_primary capitalize text-third text-center'>Crypto Development Services</h2>
              <p className='text-[white] text-center text-base sm:text-lg mb-4 lg:mb-8 font_secondary'>Crypto coins are laced with multiple features that make them highly beneficial for businesses.</p>
            </div>
          </div>
          <div className=''>
              <img src={ coin_development_2 } alt='Crypto Service' className='mx-auto block md:hidden' />
            <div className='flex justify-center'>
              <div className='md:w-1/4 relative'>
                <div className='flex flex-col md:flex-row justify-center'>
                  <p className='text-[white] text-base mx-2 md:mx-0 my-4 md:my-0 lg:text-xl font_primary static md:absolute text-center top-0'>Access to New <br />Demographic Users</p>
                  <p className='text-[white] text-base mx-2 md:mx-0 my-4 md:my-0 lg:text-xl font_primary static md:absolute text-center top-[45%]'>Positioning As <br />An Industry Leader</p>
                  <p className='text-[white] text-base mx-2 md:mx-0 my-4 md:my-0 lg:text-xl font_primary static md:absolute text-center bottom-[5%]'>Business <br />Growth</p>
                </div>
              </div>
              <div className='hidden md:block'>
                <img src={ coin_development } alt='Crypto Service' className='mx-auto block' data-aos="zoom-in-up" />
              </div>
              <div className='md:w-1/4 relative'>
                <div className='flex flex-col md:flex-row justify-center'>
                  <p className='text-[white] text-base mx-2 md:mx-0 my-4 md:my-0 lg:text-xl font_primary static md:absolute text-center top-[2%]'>New Capital <br />Flow</p>
                  <p className='text-[white] text-base mx-2 md:mx-0 my-4 md:my-0 lg:text-xl font_primary static md:absolute text-center top-[35%]'>Access to New <br />Opportunities</p>
                  <p className='text-[white] text-base mx-2 md:mx-0 my-4 md:my-0 lg:text-xl font_primary static md:absolute text-center bottom-[5%]'>Crypto Powered <br />Solutions</p>
                </div>
              </div>
            </div>
          </div>
          <div className='container mx-auto py-4 lg:py-8'>
            <div className='w-full lg:w-3/4 mx-auto'>
              <p className='text-[white] text-center text-base sm:text-lg mb-4 lg:mb-8 font_secondary'>We have a team of the best talents in the blockchain industry and are apt in crypto coin development from scratch. You may also rely on them for customizing white label token development solutions and integrating new features to match the changing needs.</p>
            </div>
          </div>
        </section>
        <section className='py-4 md:py-16'>
          <div className='container mx-auto'>
            <div className='grid-cols-2 flex flex-col lg:flex-row justify-between items-center'>
              <div className='lg:w-[40%] lg:px-2 p-2 sm:p-0'>
                {/* <p className='text-base font_secondary' data-aos="fade-left">Establish Your Own Cryptocurrency Using Crypto Development Services</p>
                <br />   */}
                <h2 className='text-2xl lg:text-3xl font_primary text-third'>Establish Your Own Cryptocurrency Using Crypto Development Services</h2>
                <br />
                <p className='text-base font_secondary' data-aos="fade-left">At Earnestroi, we provide mission-driven cryptocurrency development services to assist businesses in entering the burgeoning cryptocurrency market. Our services offer full support at every stage of cryptocurrency development, from concept to creation to deployment and marketing.</p>
                <br />
                <br />
                <p className='text-base font_secondary' data-aos="fade-left">With our high-quality coin/token development solutions, you can develop ERC20 tokens, Tron tokens, NFT tokens, Metaverse tokens, DeFi tokens, and more. We focus our expertise across three continents on assisting our clients in their development journeys to maximize benefits. Are you prepared with your business concept and particular about the type of cryptocurrency you intend to launch?</p>  
              </div>
              <div className=''>
                <img src={img_3} alt='Crypto Currency 1' className='my-8 lg:my-0' />
              </div>
            </div>
          </div>
        </section>
        <section className='bg-main py-4 lg:py-12 px-1 xl:px-0'>
          <div className='container mx-auto pt-4 lg:pt-8'>
            <div className='w-full lg:w-[60%] mx-auto'>
              <p className='text-center text-base sm:text-lg mb-2 font_secondary text-[white]'>Enhance Your Crypto Business With our Token Development Services</p>
              <h2 className='text-lg lg:text-2xl mb-2 mt-4 lg:mt-0 font_primary capitalize text-third text-center'>Token Development Services</h2>
              <p className='text-center text-base sm:text-lg mb-2 lg:mb-2 font_secondary text-[white]'>Tokenize your assets to increase your fundraising opportunities. Our blockchain engineers employ agile practices, design methodologies, and a technology-agnostic approach to put you ahead. We can assist you with ERC20 token development and TRC20 token development.</p>
              <h2 className='text-lg lg:text-2xl mb-2 mt-4 lg:mt-0 font_primary capitalize text-third text-center'>Types of Tokens We Provide:</h2>
            </div>
          </div>
          <div className='container mx-auto mt-8'>
            <img src={ img_types_coin } alt='Cypto Development Service ' className='mx-auto hidden md:block' />
            <div className='grid sm:grid-cols-2 md:grid-cols-4 gap-2 xl:gap-4'>
              <div className='px-2 bg-[#77c1dd38] rounded py-4 md:py-10 border-t-8 border-third mx-2 sm:mx-0 my-4 md:my-0'>
                <FontAwesomeIcon icon={ faShieldHalved } className='text-third text-5xl py-2' />
                <h2 className='text-third text-xl font_primary'>Security Tokens</h2>
                <p className='text-[white] font_secondary'>Support your tokens with a legal framework to gain a critical competitive advantage and raise funds quickly and securely.</p>
              </div>
              <div className='px-2 bg-[#77c1dd38] rounded py-4 md:py-10 border-t-8 border-third mx-2 sm:mx-0 my-4 md:my-0'>
                <FontAwesomeIcon icon={ faGears } className='text-third text-5xl py-2' />
                <h2 className='text-third text-xl font_primary'>Utility Tokens</h2>
                <p className='text-[white] font_secondary'>To attract investors and seize relevant market opportunities, ride the ICO market's growth wave with utility tokens.</p>
              </div>
              <div className='px-2 bg-[#77c1dd38] rounded py-4 md:py-10 border-t-8 border-third mx-2 sm:mx-0 my-4 md:my-0'>
                <FontAwesomeIcon icon={ faScaleBalanced } className='text-third text-5xl py-2' />
                <h2 className='text-third text-xl font_primary'>Equity Tokens</h2>
                <p className='text-[white] font_secondary'>Use our design-driven methodology to create equity tokens to capitalize on the fundraising opportunity.</p>
              </div>
              <div className='px-2 bg-[#77c1dd38] rounded py-4 md:py-10 border-t-8 border-third mx-2 sm:mx-0 my-4 md:my-0'>
                <FontAwesomeIcon icon={ faImage } className='text-third text-5xl py-2' />
                <h2 className='text-third text-xl font_primary'>NFT Tokens</h2>
                <p className='text-[white] font_secondary'>Launch your NFT tokens to represent ownership of one-of-a-kind items. Tokenized assets include video games, art, collectibles, and real estate.</p>
              </div>
            </div>
          </div>
        </section>
        <section className='px-1 xl:px-0'>
          <div className='container mx-auto py-4 lg:py-8'>
            <div className='w-full lg:w-3/4 mx-auto'>
              <p className=' text-center text-base sm:text-lg mb-2 lg:mb-2 font_secondary'>Services Offered By Our</p>
              <h2 className='text-lg lg:text-2xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third text-center' data-aos='fade-up'>Cryptocurrency Development Company</h2>
              <p className=' text-center text-base sm:text-lg mb-2 lg:mb-2 font_secondary' data-aos='fade-up'>Startups and businesses can access premium services for developing scalable tokens with special security implementation to control, track, and trace each token transaction. Our global team of experts collaborates with each client to develop intelligent strategies based on real-world use cases and implementation. We have real-world experience delivering comprehensive offerings to clients in various industries, from creativity and coin development to launch and marketing. Our extensive knowledge, combined with our technical prowess, helps to accelerate and simplify the coin development process. Here are a few primary services we offer to cryptocurrency investors worldwide.</p>
            </div>
          </div>
          <div className='container mx-auto pb-4 md:pb-6'>
            <div className='grid sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
              <div className='p-4 h-full bg-[#fff] rounded bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faImage } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center'>NFT Token Development</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center'>Non-fungible tokens (NFTs) are on the verge of becoming mainstream. With our non-fungible token development services, you can enter the burgeoning NFT market. Whether you want to tokenize game assets, art, or any other asset, we create non-fungible tokens on the ERC721 standard.</p>
              </div>
              <div className='p-4 h-full bg-[#fff] rounded bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faShieldHalved } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center'>Security Token Offering</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center'>STO Development helps you increase capital investment between investors and owners by providing high liquidity. The STO Platform can provide numerous benefits, including utility and equity.</p>
              </div>
              <div className='p-4 h-full bg-[#fff] rounded bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faScrewdriverWrench } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center'>Utility Token Development</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center'>You can use Earnestroi to create a utility token that gives users access to a product or service on the blockchain network. We provide fully customizable and upgradable utility token development solutions.</p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className='container mx-auto pb-4 md:pb-6'>
            <div className='grid sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
              <div className='p-4 h-full bg-[#fff] rounded bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faChartLine } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center'>Token Design & Marketing</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center'>Earnestroi's multichannel marketing will help you stay ahead of the competition. Our fact-based recommendations, in-depth knowledge of the ICO/IDO/STO industries, and effective marketing platforms influence investors' perceptions and behaviors.</p>
              </div>
              <div className='p-4 h-full bg-[#fff] rounded bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faFileSignature } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center'>Smart Contract Development</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center'>Earnestroi develops the most sophisticated smart contracts, tailoring them to the needs of our clients' businesses and ensuring that they serve their intended purpose.</p>
              </div>
              <div className='p-4 h-full bg-[#fff] rounded bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 '>
                <div className='flex flex-col items-center justify-center'>
                  <FontAwesomeIcon icon={ faFile } className='text-5xl text-third mx-auto my-4 text-center' />
                  <h2 className='mb-0 text-base font_primary text-center'>Whitepaper Drafting</h2>
                </div>
                <p className='text-base font_secondary my-4 text-center'>White papers increase your brand's credibility and demonstrate thought leadership. Our white paper creation services range from planning to publication, focusing on layout, graphics, and competent, on-brand content to communicate your vision to potential investors effectively.</p>
              </div>
            </div>
          </div>
        </section>
        <section className='bg-main py-4 sm:py-8 lg:py-12'>
          <div className='container mx-auto pb-4 lg:pb-8'>
            <div className='w-full lg:w-[60%] mx-auto'>
              <p className='text-[white] text-center text-base sm:text-lg mb-2 lg:mb-2 font_secondary'>Desired Blockchains for</p>
              <h2 className='text-lg lg:text-2xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third text-center'>Crypto Token Development Services  </h2>
              <p className='text-[white] text-center text-base sm:text-lg mb-2 lg:mb-2 font_secondary'>Earnestroi provides end-to-end token development services- ranging from ERC20 token development to NFT development. Our team of blockchain experts develops robust tokens on any blockchain network. Scale up your crypto business with our affordable crypto token development on your desired blockchain network.</p>
            </div>
          </div>
          <div className='container mx-auto'>
            <div className='grid-cols-7 gap-0 md:gap-2 lg:gap-20 block sm:flex justify-center items-center'>
              <div className='w-max mx-auto'>
                <div className='px-8 py-6 sm:px-2 sm:py-2 xl:px-4 xl:py-4 bg-[white] flex w-max rounded-2xl justify-center items-center'>
                  <img src={ img_ico_2 } alt='blockchain networks' className='mx-auto' />
                </div>
                <p className='font_primary text-center text-third'>Bitcoin</p>
              </div>
              <div className='w-max mx-auto'>
                <div className='px-8 py-6 sm:px-2 sm:py-2 xl:px-4 xl:py-4 bg-[white] flex w-max rounded-2xl justify-center items-center'>
                  <img src={ img_ico_4 } alt='blockchain networks' className='mx-auto' />
                </div>
                <p className='font_primary text-center text-third'>Ethereum</p>
              </div>
              <div className='w-max mx-auto'>
                <div className='px-8 py-6 sm:px-2 sm:py-2 xl:px-4 xl:py-4 bg-[white] flex w-max rounded-2xl justify-center items-center'>
                  <img src={ img_ico_9 } alt='blockchain networks' className='mx-auto' />
                </div>
                <p className='font_primary text-center text-third'>Solana</p>
              </div>
              <div className='w-max mx-auto'>
                <div className='px-8 py-6 sm:px-2 sm:py-2 xl:px-4 xl:py-4 bg-[white] flex w-max rounded-2xl justify-center items-center'>
                  <img src={ img_ico_8 } alt='blockchain networks' className='mx-auto' />
                </div>
                <p className='font_primary text-center text-third'>Cardano</p>
              </div>
              <div className='w-max mx-auto'>
                <div className='px-8 py-6 sm:px-2 sm:py-2 xl:px-4 xl:py-4 bg-[white] flex w-max rounded-2xl justify-center items-center'>
                  <img src={ img_ico_1 } alt='blockchain networks' className='mx-auto' />
                </div>
                <p className='font_primary text-center text-third'>Avalanche</p>
              </div>
              <div className='w-max mx-auto'>
                <div className='px-8 py-6 sm:px-2 sm:py-2 xl:px-4 xl:py-4 bg-[white] flex w-max rounded-2xl justify-center items-center'>
                  <img src={ img_ico_12 } alt='blockchain networks' className='mx-auto' />
                </div>
                <p className='font_primary text-center text-third'>Tron</p>
              </div>
              <div className='w-max mx-auto'>
                <div className='px-8 py-6 sm:px-2 sm:py-2 xl:px-4 xl:py-4 bg-[white] flex w-max rounded-2xl justify-center items-center'>
                  <img src={ img_ico_7 } alt='blockchain networks' className='mx-auto' />
                </div>
                <p className='font_primary text-center text-third'>Polygon</p>
              </div>
            </div>
          </div>
        </section>
        <section className='py-4 lg:py-10 px-1 xl:px-0'>
          <div className='container mx-auto pb-4 lg:pb-8'>
            <div className='w-full lg:w-[60%] mx-auto'>
              <h2 className='text-lg lg:text-2xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third text-center' data-aos='fade-up'>Why Choose Earnestroi For Token Development services</h2>
              <p className=' text-center text-base sm:text-lg mb-2 lg:mb-2 font_secondary' data-aos='fade-up'>We keep up with the most recent developments in blockchain technology as a top crypto coin development business. Understanding business requirements, creating strategic strategies that meet expectations, and meeting deadlines continue to be our major priorities.</p>
            </div>
          </div>
          <div className='container mx-auto mt-8'>
            <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-2 xl:gap-8'>
              <div className='px-2 bg-[#77c1dd14] rounded py-2 md:py-10 border-t-8 border-third mx-2 sm:mx-0 my-4 md:my-0'>
                <FontAwesomeIcon icon={ faShieldHalved } className='text-third text-[5rem] py-2 block mx-auto' />
                <h2 className='text-third text-center text-xl font_primary'>Secure and Reliable</h2>
                <p className='text-center font_secondary'>We build secure technologies for creating cryptocurrency wallets because there are periodic security issues with wallets.</p>
              </div>
              <div className='px-2 bg-[#77c1dd14] rounded py-2 md:py-10 border-t-8 border-third mx-2 sm:mx-0 my-4 md:my-0'>
                <FontAwesomeIcon icon={ faScrewdriverWrench } className='text-third text-[5rem] py-2 block mx-auto' />
                <h2 className='text-third text-center text-xl font_primary'>Customised Solutions</h2>
                <p className='text-center font_secondary'>We write programs without bugs when virtual transactions occur and halt criminal activity.</p>
              </div>
              <div className='px-2 bg-[#77c1dd14] rounded py-2 md:py-10 border-t-8 border-third mx-2 sm:mx-0 my-4 md:my-0'>
                <FontAwesomeIcon icon={ faUsers } className='text-third text-[5rem] py-2 block mx-auto' />
                <h2 className='text-third text-center text-xl font_primary'>Experienced Team</h2>
                <p className='text-center font_secondary'>Our team's solutions will meet or exceed your requirements.</p>
              </div>
              <div className='px-2 bg-[#77c1dd14] rounded py-2 md:py-10 border-t-8 border-third mx-2 sm:mx-0 my-4 md:my-0'>
                <FontAwesomeIcon icon={ faMicrochip } className='text-third text-[5rem] py-2 block mx-auto' />
                <h2 className='text-third text-center text-xl font_primary'>State-of-the-Art Tools and Technologies</h2>
                <p className='text-center font_secondary'>We include cutting-edge tools and technology and assist in the daily discovery of new possibilities.</p>
              </div>
              <div className='px-2 bg-[#77c1dd14] rounded py-2 md:py-10 border-t-8 border-third mx-2 sm:mx-0 my-4 md:my-0'>
                <FontAwesomeIcon icon={ faReplyAll } className='text-third text-[5rem] py-2 block mx-auto' />
                <h2 className='text-third text-center text-xl font_primary'>Respond Promptly</h2>
                <p className='text-center font_secondary'>Our development team will respond quickly to clients' most common questions.</p>
              </div>
              <div className='px-2 bg-[#77c1dd14] rounded py-2 md:py-10 border-t-8 border-third mx-2 sm:mx-0 my-4 md:my-0'>
                <FontAwesomeIcon icon={ faHeadphones } className='text-third text-[5rem] py-2 block mx-auto' />
                <h2 className='text-third text-center text-xl font_primary'>NFT Tokens</h2>
                <p className='text-center font_secondary'>Our first goal is to satisfy our customers, so we are available 24/7 to meet their needs.</p>
              </div>
            </div>
          </div>
        </section>
        <section className='bg-main py-4 lg:py-12 px-2 xl:px-0'>
          <div className='container mx-auto pt-4 lg:pt-8'>
            <div className='w-full lg:w-[60%] mx-auto'>
              <h2 className='text-lg lg:text-2xl mt-4 lg:mt-0 font_primary capitalize text-third text-center mb-4 lg:mb-8'>Why Choose Us for Token Development Services?</h2>
            </div>
            <div className='container mx-auto pb-4 md:pb-6'>
              <div className='grid sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
                <div className='p-4 h-full bg-[#77c1dd14] rounded bg-clip-padding backdrop-filter backdrop-blur-md '>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faShieldHalved } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className=' text-third mb-0 text-base font_primary text-center'>Technical Process</h2>
                  </div>
                  <p className='text-[white] text-base font_secondary my-4 text-center'>We exclusively work in Blockchain technologies. We just concentrate on one thing, and we excel at it.</p>
                </div>
                <div className='p-4 h-full bg-[#77c1dd14] rounded bg-clip-padding backdrop-filter backdrop-blur-md '>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faUsers } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className=' text-third mb-0 text-base font_primary text-center'>Expert Team</h2>
                  </div>
                  <p className='text-[white] text-base font_secondary my-4 text-center'>With the support of our 500 professionals, you can improve your offering, get the finest IT solution, and even create communities and campaigns.</p>
                </div>
                <div className='p-4 h-full bg-[#77c1dd14] rounded bg-clip-padding backdrop-filter backdrop-blur-md '>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faGears } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className=' text-third mb-0 text-base font_primary text-center'>Rapid Development</h2>
                  </div>
                  <p className='text-[white] text-base font_secondary my-4 text-center'>We offer goods that are especially suited to your intended consumer base. A well-organized roadmap ensures rapid and efficient development and implementation.</p>
                </div>
              </div>
            </div>
            <div className='container mx-auto pt-4 md:pt-6'>
              <div className='flex flex-col lg:flex-row lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
                <div className='p-4 h-full lg:w-[30%] xl:w-1/4 2xl:w-[30%] w-full bg-[#77c1dd14] rounded bg-clip-padding backdrop-filter backdrop-blur-md '>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faHeadphonesSimple } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className='mb-0 text-base font_primary text-center text-third'>Complete Support</h2>
                  </div>
                  <p className='text-[white] text-base font_secondary my-4 text-center'>After the product launch, we continue to work and provide post-delivery support.</p>
                </div>
                <div className='p-4 h-full lg:w-[30%] xl:w-1/4 2xl:w-[30%] w-full bg-[#77c1dd14] rounded bg-clip-padding backdrop-filter backdrop-blur-md '>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faFileExport } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className='mb-0 text-base font_primary text-center text-third'>Meaningful Outcomes</h2>
                  </div>
                  <p className='text-[white] text-base font_secondary my-4 text-center'>We make a real difference. We make sure your investments have value for you.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      <Footern />
    </>
  )
}

export default TokenDevelopment;