import React from 'react';
import { Fragment, useState } from "react";
import { Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePen, faBoxesStacked, faBrain, faRobot, faCoins, faGlobe, faInfinity } from '@fortawesome/free-solid-svg-icons';

const DevelopmentAccordionOne = () => {

    const [open, setOpen] = useState(2);

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };


  return (
    <>
        <div className=' text-center w-full lg:w-[40%] px-2'>
            {/* <div className='w-3/4 px-2'> */}
            <Fragment>
                <Accordion open={open === 1}>
                    <AccordionHeader className='text-dark pl-4 font_primary font-light justify-start border-[1px] bg-[#77c1dd73] rounded-xl my-2 shadow-[0px_0px_6px_#fff]' onClick={() => handleOpen(1)}>
                        <FontAwesomeIcon icon={ faBoxesStacked } className='text-5xl text-dark mx-2' />Blockchain
                    </AccordionHeader>
                    <AccordionBody className='text-dark font_secondary text-left text-base p-2 rounded-lg font-semibold'>
                    Blockchain technology is an advanced database mechanism that allows transparent information sharing within a business network
                    </AccordionBody>
                </Accordion>
                <Accordion open={open === 2}>
                    <AccordionHeader className='text-dark pl-4 font_primary font-light justify-start border-[1px] bg-[#fe806773] rounded-xl my-2 shadow-[0px_0px_6px_#fff]' onClick={() => handleOpen(2)}>
                    <FontAwesomeIcon icon={ faRobot } className='text-5xl text-dark mx-2' />
                    Machine Learning
                    </AccordionHeader>
                    <AccordionBody className='text-dark font_secondary text-left text-base p-2 rounded-lg font-semibold'>
                    Machine learning is a type of artificial intelligence that involves training algorithms to learn from data.
                    </AccordionBody>
                </Accordion>
                <Accordion open={open === 3}>
                    <AccordionHeader className='text-dark pl-4 font_primary font-light justify-start border-[1px] bg-[#e3b50373] rounded-xl my-2 shadow-[0px_0px_6px_#fff]' onClick={() => handleOpen(3)}>
                    <FontAwesomeIcon icon={ faBrain } className='text-5xl text-dark mx-2' />
                    Artificial Intelligence
                    </AccordionHeader>
                    <AccordionBody className='text-dark font_secondary text-left text-base p-2 rounded-lg font-semibold'>
                    A.I. Plays A Significant Role In Helping Machines Learn From Experience. It Directs Them To Adapt To New Inputs And Carry Out Actions
                    </AccordionBody>
                </Accordion>
                <Accordion open={open === 4}>
                    <AccordionHeader className='text-dark pl-4 font_primary font-light justify-start border-[1px] bg-[#ff000073] rounded-xl my-2 shadow-[0px_0px_6px_#fff]' onClick={() => handleOpen(4)}>
                    <FontAwesomeIcon icon={ faCoins } className='text-5xl text-dark mx-2' />
                    Token Development
                    </AccordionHeader>
                    <AccordionBody className='text-dark font_secondary text-left text-base p-2 rounded-lg font-semibold'>
                    Crypto coins are laced with multiple features that make them highly beneficial for businesses.
                    </AccordionBody>
                </Accordion>
                <Accordion open={open === 5}>
                    <AccordionHeader className='text-dark pl-4 font_primary font-light justify-start border-[1px] bg-[#56f85b73] rounded-xl my-2 shadow-[0px_0px_6px_#fff]' onClick={() => handleOpen(5)}>
                    <FontAwesomeIcon icon={ faFilePen } className='text-5xl text-dark mx-2' />
                    Smart Contract
                    </AccordionHeader>
                    <AccordionBody className='text-dark font_secondary text-left text-base p-2 rounded-lg font-semibold'>
                    Self-executing smart contracts and self-enforcing protocols governed by explicit terms and conditions can transform agreements in various industries.
                    </AccordionBody>
                </Accordion>
                <Accordion open={open === 6}>
                    <AccordionHeader className='text-dark pl-4 font_primary font-light justify-start border-[1px] bg-[#eb64ad73] rounded-xl my-2 shadow-[0px_0px_6px_#fff]' onClick={() => handleOpen(6)}>
                    <FontAwesomeIcon icon={ faGlobe } className='text-5xl text-dark mx-2' />
                    Web 3.0 Development
                    </AccordionHeader>
                    <AccordionBody className='text-dark font_secondary text-left text-base p-2 rounded-lg font-semibold'>
                    Web 3.0 seeks to improve web productivity. Businesses who decide to invest in Web 3 development might accomplish the following goals.
                    </AccordionBody>
                </Accordion>
                <Accordion open={open === 7}>
                    <AccordionHeader className='text-dark pl-4 font_primary font-light justify-start border-[1px] bg-[#77c1dd73] rounded-xl my-2 shadow-[0px_0px_6px_#fff]' onClick={() => handleOpen(7)}>
                    <FontAwesomeIcon icon={ faInfinity } className='text-5xl text-dark mx-2' />
                    Metaverse Development
                    </AccordionHeader>
                    <AccordionBody className='text-dark font_secondary text-left text-base p-2 rounded-lg font-semibold'>
                    We combine our metaverse developers and designers to plan, execute, and accelerate our clients' metaverse journeys. 
                    </AccordionBody>
                </Accordion>
            </Fragment>
            {/* </div> */}
        </div>
    </>
  )
}

export default DevelopmentAccordionOne;