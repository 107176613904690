import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Form, Input, Modal, Table } from 'antd';


const DashboardBlogs = () => {
  const [layout, setLayout] = useState(1);
  const [layoutone, setLayoutone] = useState([]);
  const [BlogData, setBlogData] = useState([]);
  const [FormId, setFormID] = useState([]);
  const [update, setupdate] = useState();
  const [apisubmit, setApisubmit] = useState({ Description: '' });
  const [editData, setEditData] = useState({
    //  id: "",
    //  name: "",
    //  header: "",
    //  forURL: "",
    //  tags: "",
    //  email: "",
  });
  const [FltrLayout, setFltrLayout] = useState([])


  // const layoutData = () => {
  //   axios
  //     .post('https://dapib.stashack.cloud:2500/public/view_blog', {
  //       header: "accept: application/json"
  //     })
  //     .then(response => {
  //       console.log("response", response.data.Success.Layout);

  //       const res = response?.data.Success;

  //       if (res) {
  //         const filtertable = res.filter((item) => item.Layout == layout);
  //         // console.log(filtertable)
  //         setFltrLayout(filtertable);
  //         setLayoutone(res);
  //       } else {
  //         toast(res?.Error);
  //         if (res.Error === "Session Expired!") {
  //           localStorage.clear();
  //           window.location.href = "/";
  //         }
  //       }


  //     })
  //     .catch(err => console.log('Error: ', err))
  // }

  const [response, setResponse] = useState([]);
  console.log("response", response)


  useEffect(() => {
    fetchfunction();
  }, [])

  const fetchfunction = async () => {
    const res = await axios
      .post('https://api.earnestroi.com/public/view_blog', {
        header: 'accept: application/json'
      }).then(res => {
        console.log(res.data.Success)
        setBlogData(res.data.Success)
        setFormID(res.data.Success)
      }).catch(err =>
        console.log('Error: ', err)
      )
  }

  const handleLayout = (event) => {
    const selectedValue = event.target.value;
    setLayout(selectedValue)

    if (selectedValue == '') {
      setLayoutone(layoutone)
    } else {
      const filterLayout = layoutone.filter(item => item.Layout == selectedValue)
      console.log("filterLayout", filterLayout)
      setFltrLayout(filterLayout)
    }
  }

  const deletefunction = (params) => {

    console.log("params", params)
    const Jwt = localStorage.getItem('JWT')
    const DeleteParams = {
      jwt: Jwt,
      id: params
    }

    const response = axios({
      method: 'POST',
      headers: 'Content-Type: application/json',
      url: 'https://dapib.stashack.cloud:2500/private/delete_blog',
      data: DeleteParams
    }).then((res) => {
      // layoutData()
      console.log(res)
      toast.success(response.data.Success)
    }).catch((err) => {
      // console.log(err)
      toast.error(err.response.data.Error)
    })
  }

  const handleUpdate = (record) => {
    console.log(record)
    const { heading, subheading, layout, tag, url, images, image_description, gallery_title, description, quote_description, Qoute, Banner, } = editData;

    const JWT = localStorage.getItem('token')
    const postData = {
      w1: {
        jwt: JWT,
        id: FormId
      },
      o1: {
        jwt: JWT,
        Name: heading,
        heading: heading,
        subheading: subheading,
        layout: 1,
        url: url,
        tag: tag,
        description: description,
        banner: Banner,
        images: images,
        image_description: image_description,
        gallery_title: gallery_title,
        quote_description: quote_description,
        quote: Qoute
      }
    }

    console.log(postData)

    const response = axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH + `edit_blog`,
      data: postData,
    })
      .then((response) => {
        console.log("Response Data:", response.data);
        if (response.data && response.data.Success) {
          toast.success(response.data.Success);
          // layoutData();
          // setShowModal(!showModal);
        } else {
          toast.error("Unexpected response from the server");
        }
      })
      .catch((err) => {
        console.error("Request Error:", err);
        if (err.response && err.response.data && err.response.data.Error) {
          toast.error(err.response.data.Error);
        } else {
          toast.error("An error occurred while processing your request.");
        }
      });
  }

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = (record) => {
    console.log(record)
    form.setFieldsValue(record); // Clear the form fields
    setIsModalVisible(true);
    setFormID(record.FormID)
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log(values.FormID)
    // console.log('Received values:', values);
    // setIsModalVisible(false);
    // console.log(values, "values")
    // // const { heading, subheading, layout, tag, url, images, image_description, gallery_title, description, quote_description, Qoute, Banner, } = editData;

    const JWT = localStorage.getItem('token')
    const postData = {
      w1: {
        jwt: JWT,
        FormID:values.FormID,
      },
      o1: {
        jwt: JWT,
        
        Name: values.Heading,
        heading: values.Heading,
        subheading: values.Subheading,
        layout: 1,
        url: values.URL,
        tag: values.Tag,
        // description: description,
        // banner: Banner,
        // images: images,
        // image_description: image_description,
        // gallery_title: gallery_title,
        // quote_description: quote_description,
        // quote: Qoute
      }
    }

    console.log(postData)

    const response = axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH + `edit_blog`,
      data: postData,
    })
      .then((response) => {
        console.log("Response Data:", response.data);
        if (response.data && response.data.Success) {
          toast.success(response.data.Success);
          // layoutData();
          // setShowModal(!showModal);
        } else {
          toast.error("Unexpected response from the server");
        }
      })
      .catch((err) => {
        console.error("Request Error:", err);
        if (err.response && err.response.data && err.response.data.Error) {
          toast.error(err.response.data.Error);
        } else {
          toast.error("An error occurred while processing your request.");
        }
      });

  };

  const modalForm = (
    <Form form={form} name="editForm" onFinish={onFinish}>
      <Form.Item label="HEADER" name="Heading">
        <Input />
      </Form.Item>
      <Form.Item label="FormID" name="FormID" hidden>
        <Input />
      </Form.Item>
      <Form.Item label="SUB-HEADER" name="Subheading">
        <Input />
      </Form.Item>
      <Form.Item label="FOR-URL" name="URL">
        <Input />
      </Form.Item>
      <Form.Item label="DISCRIPTION" name="Description">
        <Input />
      </Form.Item>
      <Form.Item label="TAG" name="Tag">
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="default" onClick={onFinish} htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );

  const columns = [
    {
      title: "FormID",
      dataIndex: "FormID",
      key: "FormID",
    },
    {
      title: "HEADER",
      dataIndex: "Heading",
      key: "Heading",
    },
    {
      title: "SUB-HEADER",
      dataIndex: "Subheading",
      key: "Subheading",
    },
    {
      title: "FOR-URL",
      dataIndex: "URL",
      key: "URL",
    },
    {
      title: "DISCRIPTION",
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: "TAG",
      dataIndex: "Tag",
      key: "Tag",
    },
    {
      title: "EDIT",
      dataIndex: "edit",
      key: "edit",
      render: (text, record) => (
        <Button className='' type="default" onClick={() => showModal(record)}>
          Edit
        </Button>
      ),
    },
    {
      title: "DELETE",
      dataIndex: "delete",
      key: "delete",
      render: (text, record) => (
        <Button className='' type="default" onClick={() => console.log(record)}>
          Delete
        </Button>
      ),
    },
  ];

  const dataSource = [];
  if (BlogData) {
    for (let i = 0; i < BlogData.length; ++i) {
      dataSource.push({
        key: i,
        FormID: BlogData[i].FormID,
        Heading: BlogData[i].Heading,
        Subheading: BlogData[i].Subheading,
        Email: BlogData[i].Email,
        URL: BlogData[i].URL,
        // Description: BlogData[i].Description,
        Tag: BlogData[i].Tag,
      })
    }
  }

  return (
    <section className="w-full">
      <div className="container mx-auto mt-12 px-2 sm:px-4">
        <div className="shadow-lg p-1 sm:p-4 rounded-xl">
          <h2 className="text-2xl text-third font_primary my-6 px-2">
            Add Blogs:{" "}
          </h2>
          <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-3">
            <Link
              to="/admin/layoutone"
              className="w-full px-4 py-5 bg-white rounded-lg shadow"
            >
              <p className="text-xl text-main font_primary text-center">
                {" "}
                Layout 1{" "}
              </p>
            </Link>
            <Link
              to="/admin/layouttwo"
              className="w-full px-4 py-5 bg-white rounded-lg shadow"
            >
              <p className="text-xl text-main font_primary text-center">
                {" "}
                Layout 2{" "}
              </p>
            </Link>
            <Link
              to="/admin/layoutthree"
              className="w-full px-4 py-5 bg-white rounded-lg shadow"
            >
              <p className="text-xl text-main font_primary text-center">
                {" "}
                Layout 3{" "}
              </p>
            </Link>
          </div>
        </div>
        <Modal
          title="Edit Item"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          {modalForm}
        </Modal>
        <div className="mt-8">
          <div class="table-responsive">
            <Table size="small" dataSource={dataSource} columns={columns} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default DashboardBlogs;