import React from 'react';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import panda from '../Lottie/notfound.json';
import WhatsappChat from './WhatsappChat';


const PageNotFound = () => {

  return (
    <>
      <section  className=' bg-main h-screen'>
        <div>
          <Lottie animationData={ panda } className='mx-auto block w-full md:w-1/2' />
            <div className='flex justify-center items-center mt-0 sm:mt-[-6rem] md:mt-[-8rem] xl:mt-[-12rem]'>
              <div>
                  <h2 className='text-third text-center font_primary text-2xl md:text-6xl capitalize'>page not found</h2>
                  <br />
                  <p className='text-second text-center font_primary capitalize text-base md:text-xl'>The page you are looking for might have been removed, had its name changed or its temporarily unavailable.</p>
                  <p className='text-second text-center font_primary capitalize text-base md:text-xl'> Please Try to <Link to='/' className='text-third uppercase text-base md:text-xl'> RETURN TO THE HOMEPAGE </Link></p>
              </div>
          </div>
        </div>
      </section>
      <WhatsappChat />
    </>
  )
}

export default PageNotFound;