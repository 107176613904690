import React, { useEffect } from 'react';
import corn from '../img/ninepages/earnestroi-ICO-Development-Company.jpg';
import img_3 from '../img/ninepages/earnestroi-Diligently-Orchestrated.jpg';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faChartLine, faFilePen, faRotate, faUsers, faGears, faHeadphonesSimple, faFileExport, faFile, faCode, faCoins, faSackDollar, faVolumeHigh, faList} from '@fortawesome/free-solid-svg-icons';
import { faShieldHalved } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import TimelineIco from './TimelineIco';
import Footern from './Footern';
import { Helmet } from 'react-helmet';


const IcoDevelopment = () => {

  useEffect( () => {

    $(document).ready(function(){
      $(this).scrollTop(0);
    });

    $(document).ready(function(){
      
      $('.slick-prev').hide();
      $('.slick-next').hide();

    });

    Aos.init({

      disable: function() {
        var maxWidth = 1024;
        return window.innerWidth < maxWidth;
      }

    });

} );

  useEffect( () => {

    Aos.init({ duration: 3000 });

} );
  
  return (
    <>
        <Helmet>
          <title>Earnestroi Technology | ICO Development Service</title>
          <meta name="title" content="Earnestroi Technology | ICO Development Service" />
          <meta name="description" content="We have an expert team of ICO developers who will help you with intelligent, innovative ideas, whilst ensuring that upcoming challenges are smoothly handled." /> 
          <meta name="keywords" content='ico development company, ico development services, ico development solutions, ico software development company, ico website development, ico token development company' />
          <meta name="robots" content="index,follow" />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="language" content="English" />
        </Helmet>
        <section className=' lg:pt-4 xl:pt-0 bg-main px-1 xl:p-0'>
          <div className='container mx-auto'>
            <div className='flex flex-col-reverse lg:flex-row justify-center items-center xl:pt-20'>
              <div className='flex flex-col w-full lg:w-1/2'>
                  <h1 className='text-[white] text-5xl lg:text-6xl mb-4 mt-4 lg:mt-0 font_primary capitalize' data-aos="fade-left">ICO Development Company</h1>
                  <br />
                  <h2 className='text-xl lg:text-2xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third'>Maximize fundraising opportunities for your project with ICO development.</h2>
              </div>
              <div className=' w-full lg:w-1/2'>
                <img src={corn} alt='ICO Development company ' className='mx-auto w-1/2 sm:w-1/4 lg:w-3/4' />
              </div>
            </div>
          </div>
        </section>
        <section className='bg-main'>
          <div className='container mx-auto'>
            <div className='flex justify-center py-4 lg:py-10'>
              <Link to='/'><h2 className='mx-2 text-third font_primary'><FontAwesomeIcon icon={ faHome } className='mx-2 text-third' />Digital Marketing Agency</h2></Link><h2 className='mx-2 text-third'>/</h2><h2 className='mx-2 text-third font_primary'>ICO Development</h2>
            </div>
          </div>  
        </section>
        <section className='py-4 md:py-10'>
          <div className='container mx-auto'>
            <div className='grid-cols-2 flex flex-col lg:flex-row justify-between items-center'>
              <div className=''>
                <img src={img_3} alt='Diligently Orchestrated ICO Service ' className='my-8 lg:my-0' />
              </div>
              <div className='lg:w-[40%] lg:px-2 p-2 sm:p-0'>
                <h2 className='text-2xl lg:text-3xl font_primary text-third'>Diligently Orchestrated ICO Development Services</h2>
                <br /><br />
                <p className='text-base font_secondary mb-0' data-aos="fade-right">Business growth is driven by strategy and innovation. With our clever techniques, we reenergize our clients' creative ideas and guide businesses toward success. Utilizing in-depth industry expertise, thorough market analysis, and extensive blockchain technology experience, our blockchain professionals constantly work to strengthen and accelerate the Initial Coin Offering (ICO) development process.</p>
                <br />  
                <p className='text-base font_secondary mb-0' data-aos="fade-right">To help you establish your ICO website as a reputable ICO development company, our team offers a wide range of services, including creativity, token design conceptualization, white paper preparation, token development, launch, and marketing.</p>
                <br />  
                <p className='text-base font_secondary mb-0' data-aos="fade-right">You may make quicker, wiser pivots and achieve successful outcomes for your ICO token development effort with the help of our consultation, development, and deployment solutions.</p>
                <br />  
              </div>
            </div>
          </div>
        </section>
        <section className='py-4 bg-main md:py-20'>
            <div className='container mx-auto mb-8'>
                <h2 className='text-2xl lg:text-3xl font_primary text-third text-center py-2 md:py-4 lg:py-10'>Our ICO Development Solutions</h2>
              <div className='container mx-auto'>
                <div className='grid sm:grid-cols-2 lg:grid-cols-3 gap:2 lg:gap-8 justify-center items-center'>
                  <div className='px-4 h-full flex items-start justify-start'>
                    <FontAwesomeIcon icon={ faFilePen } className='text-third mx-2 text-2xl lg:text-4xl' />
                    <h2 className='text-lg mb-0 font_primary text-[white]'>White paper creation and design</h2>
                  </div>
                  <div className='px-4 h-full flex items-start justify-start'>
                    <FontAwesomeIcon icon={ faFile } className='text-third mx-2 text-2xl lg:text-4xl' />
                    <h2 className='text-lg mb-0 font_primary text-[white]'>Light paper creation and design</h2>
                  </div>
                  <div className='px-4 h-full flex items-start justify-start'>
                    <FontAwesomeIcon icon={ faCode } className='text-third mx-2 text-2xl lg:text-4xl' />
                    <h2 className='text-lg mb-0 font_primary text-[white]'>Landing page design</h2>
                  </div>
                  <div className='px-4 h-full flex items-start justify-start'>
                    <FontAwesomeIcon icon={ faChartLine } className='text-third mx-2 text-2xl lg:text-4xl' />
                    <h2 className='text-lg mb-0 font_primary text-[white]'>ICO fundraising dashboard</h2>
                  </div>
                  <div className='px-4 h-full flex items-start justify-start'>
                    <FontAwesomeIcon icon={ faCoins } className='text-third mx-2 text-2xl lg:text-4xl' />
                    <h2 className='text-lg mb-0 font_primary text-[white]'>Token or coin development</h2>
                  </div>
                  <div className='px-4 h-full flex items-start justify-start'>
                    <FontAwesomeIcon icon={ faSackDollar } className='text-third mx-2 text-2xl lg:text-4xl' />
                    <h2 className='text-lg mb-0 font_primary text-[white]'>Bounty management</h2>
                  </div>
                  <div className='px-4 h-full flex items-start justify-start'>
                    <FontAwesomeIcon icon={ faVolumeHigh } className='text-third mx-2 text-2xl lg:text-4xl' />
                    <h2 className='text-lg mb-0 font_primary text-[white]'>Press release </h2>
                  </div>
                  <div className='px-4 h-full flex items-start justify-start'>
                    <FontAwesomeIcon icon={ faRotate } className='text-third mx-2 text-2xl lg:text-4xl' />
                    <h2 className='text-lg mb-0 font_primary text-[white]'>Multi-channel marketing</h2>
                  </div>
                  <div className='px-4 h-full flex items-start justify-start'>
                    <FontAwesomeIcon icon={ faList } className='text-third mx-2 text-2xl lg:text-4xl' />
                    <h2 className='text-lg mb-0 font_primary text-[white]'>Listing services</h2>
                  </div>
                </div>
              </div>
            </div>
        </section>
        <section className='py-4 lg:py-10 px-1 xl:p-0'>
            <div className='container mx-auto py-4 lg:py-12'>
                <div className=''>
                    <h2 className='mx-auto w-full lg:w-[70%] text-xl lg:text-3xl mb-2 mt-4 lg:mt-0 font_primary capitalize text-third text-center'>Your Plan for Taking Advantage of the ICO Opportunity</h2>
                    <p className='mx-auto w-full lg:w-[60%] text-center text-base sm:text-lg mb-2 lg:mb-2 font_secondary'>Here is an overview of how our ICO Script Software development firm will guide your path as you begin your ICO software development adventure with Earnestroi.</p>
                </div>
            </div>
            <TimelineIco />
        </section>
        <section className='bg-main py-4 lg:py-12 px-1 xl:p-0'>
          <div className='container mx-auto pt-4 lg:pt-8'>
            <div className='w-full lg:w-[60%] mx-auto'>
              <h2 className='text-lg lg:text-2xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third text-center'>Mission-Driven ICO Development Services</h2>
              <p className='text-center text-base sm:text-lg mb-4 lg:mb-8 font_secondary text-[white]'>As a reputable ICO development firm in India, we guarantee to provide all ICO-related services under one roof to support your journey.</p>
            </div>
            <div className='container mx-auto pb-4 md:pb-6'>
              <div className='grid sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
                <div className='p-4 h-full hover:cursor-pointer hover:bg-[#77c1dd14] rounded-2xl bg-clip-padding backdrop-filter backdrop-blur-md '>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faShieldHalved } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className=' text-third mb-0 text-base font_primary text-center'>Whitepaper Drafting</h2>
                  </div>
                  <p className='text-[white] text-base font_secondary my-4 text-normal lg:text-justify'>The foundation of every ICO is its whitepaper. We provide knowledgeable and thorough whitepaper services to introduce your project to potential ICO investors properly. Your whitepaper will be comprehensive and approachable thanks to the skill of our staff of writers.</p>
                </div>
                <div className='p-4 h-full hover:cursor-pointer hover:bg-[#77c1dd14] rounded-2xl bg-clip-padding backdrop-filter backdrop-blur-md '>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faUsers } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className=' text-third mb-0 text-base font_primary text-center'>Roadmap Creation</h2>
                  </div>
                  <p className='text-[white] text-base font_secondary my-4 text-normal lg:text-justify'>An ICO needs a solid roadmap and should be trusted. Our ICO development firm develops time-based roadmaps to launch the ICO development process, securing stakeholders and showcasing the product's trajectory.</p>
                </div>
                <div className='p-4 h-full hover:cursor-pointer hover:bg-[#77c1dd14] rounded-2xl bg-clip-padding backdrop-filter backdrop-blur-md '>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faGears } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className=' text-third mb-0 text-base font_primary text-center'>Token Development</h2>
                  </div>
                  <p className='text-[white] text-base font_secondary my-4 text-normal lg:text-justify'>On the Ethereum network, ICOs distribute ERC20-compliant tokens to participants via smart contracts that forbid the ICO organizer from issuing more tokens than are stated in the original agreement. Whether it's an ICO for real estate development or some other endeavor, our ERC20 token development company offers mission-driven solutions for token production.</p>
                </div>
              </div>
            </div>
            <div className='container mx-auto pb-4 md:pb-6'>
              <div className='grid sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
                <div className='p-4 h-full hover:cursor-pointer hover:bg-[#77c1dd14] rounded-2xl bg-clip-padding backdrop-filter backdrop-blur-md '>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faShieldHalved } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className=' text-third mb-0 text-base font_primary text-center'>Landing Page Design</h2>
                  </div>
                  <p className='text-[white] text-base font_secondary my-4 text-normal lg:text-justify'>The success of the ICO depends on a web page with an intuitive design. While offering advice on the crucial information your company landing page must have, we also give you a choice of various styles and designs.</p>
                </div>
                <div className='p-4 h-full hover:cursor-pointer hover:bg-[#77c1dd14] rounded-2xl bg-clip-padding backdrop-filter backdrop-blur-md '>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faUsers } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className=' text-third mb-0 text-base font_primary text-center'>Smart Contract Setup</h2>
                  </div>
                  <p className='text-[white] text-base font_secondary my-4 text-normal lg:text-justify'>We provide dependable and secure smart contract creation services to automate various industries. Our talented team of professionals has deep expertise in blockchain data storage solutions, customer loyalty and incentive programs, and smart business contracts.</p>
                </div>
                <div className='p-4 h-full hover:cursor-pointer hover:bg-[#77c1dd14] rounded-2xl bg-clip-padding backdrop-filter backdrop-blur-md '>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faGears } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className=' text-third mb-0 text-base font_primary text-center'>PR and Marketing</h2>
                  </div>
                  <p className='text-[white] text-base font_secondary my-4 text-normal lg:text-justify'>Strong PR and marketing initiatives can help your Initial Coin Offering succeed, leading to excellent product outcomes. At every level of the product development process, we are a team of diverse subject matter experts who work hard to deliver happy consumers.</p>
                </div>
              </div>
            </div>
            <div className='container mx-auto pb-4 md:pb-6'>
              <div className='grid sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
                <div className='p-4 h-full hover:cursor-pointer hover:bg-[#77c1dd14] rounded-2xl bg-clip-padding backdrop-filter backdrop-blur-md '>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faShieldHalved } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className=' text-third mb-0 text-base font_primary text-center'>ICO Customer Services</h2>
                  </div>
                  <p className='text-[white] text-base font_secondary my-4 text-normal lg:text-justify'>In the early stages of product development, our expert yet devoted ICO customer care answers all your questions about your product. Our highly qualified experts can answer your questions about ICO software development.</p>
                </div>
                <div className='p-4 h-full hover:cursor-pointer hover:bg-[#77c1dd14] rounded-2xl bg-clip-padding backdrop-filter backdrop-blur-md '>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faUsers } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className=' text-third mb-0 text-base font_primary text-center'>Exchange Listing Assistance</h2>
                  </div>
                  <p className='text-[white] text-base font_secondary my-4 text-normal lg:text-justify'>Following the completion of your ICO, you can publish your token or cryptocurrency on exchange and merchant platforms using our dedicated cryptocurrency exchange listing service. Our programmers create a secure and reliable platform for exchanging cryptocurrencies.</p>
                </div>
                <div className='p-4 h-full hover:cursor-pointer hover:bg-[#77c1dd14] rounded-2xl bg-clip-padding backdrop-filter backdrop-blur-md '>
                  <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faGears } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className=' text-third mb-0 text-base font_primary text-center'>Legal, Risk, and Compliance</h2>
                  </div>
                  <p className='text-[white] text-base font_secondary my-4 text-normal lg:text-justify'>We ensure that your project conforms with the most current legal requirements in the nations where the token is created, released, and promoted. We collaborate with top law corporations to prevent future compliance and legal concerns for your ICO.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-4 lg:py-12 px-1 xl:px-0'>
            <div className='container mx-auto pt-4 lg:pt-8'>
            <div className='w-full lg:w-[60%] mx-auto'>
                <h2 className='text-xl lg:text-3xl mb-4 mt-4 lg:mt-0 font_primary capitalize text-third text-center'>Why Choose Earnestroi Solutions as an ICO Development Company?</h2>
            </div>
            <div className='container mx-auto pb-4 md:pb-6'>
                <div className='grid sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
                <div className='p-4 h-full rounded bg-[#77c1dd14] border-t-8 border-third '>
                    <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faShieldHalved } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className='mb-0 text-base font_primary text-center'>Technical Process</h2>
                    </div>
                    <p className='text-base font_secondary my-4 text-center'>We exclusively work in Blockchain technologies. We just concentrate on one thing, and we excel at it.</p>
                </div>
                <div className='p-4 h-full rounded  bg-[#77c1dd14] border-t-8 border-third '>
                    <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faUsers } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className='mb-0 text-base font_primary text-center'>Expert Team</h2>
                    </div>
                    <p className='text-base font_secondary my-4 text-center'>With the support of our 500 professionals, you can improve your offering, get the finest IT solution, and even create communities and campaigns.</p>
                </div>
                <div className='p-4 h-full rounded  bg-[#77c1dd14] border-t-8 border-third '>
                    <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faGears } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className='mb-0 text-base font_primary text-center'>Rapid Development</h2>
                    </div>
                    <p className='text-base font_secondary my-4 text-center'>We offer goods that are especially suited to your intended consumer base. A well-organized roadmap ensures rapid and efficient development and implementation.</p>
                </div>
                </div>
            </div>
            <div className='container mx-auto pt-4 md:pt-6'>
                <div className='flex flex-col lg:flex-row lg:grid-cols-3 xl:grid-cols-3 gap-10 justify-center items-center'>
                <div className='p-4 h-full lg:w-[30%] xl:w-1/4 2xl:w-[30%] w-full rounded  bg-[#77c1dd14] border-t-8 border-third'>
                    <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faHeadphonesSimple } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className='mb-0 text-base font_primary text-center'>Complete Support</h2>
                    </div>
                    <p className='text-base font_secondary my-4 text-center'>After the product launch, we continue to work and provide post-delivery support.</p>
                </div>
                <div className='p-4 h-full lg:w-[30%] xl:w-1/4 2xl:w-[30%] w-full rounded  bg-[#77c1dd14] border-t-8 border-third'>
                    <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon icon={ faFileExport } className='text-5xl text-third mx-auto my-4 text-center' />
                    <h2 className='mb-0 text-base font_primary text-center'>Meaningful Outcomes</h2>
                    </div>
                    <p className='text-base font_secondary my-4 text-center'>We make a real difference. We make sure your investments have value for you.</p>
                </div>
                </div>
            </div>
            </div>
        </section>
        <section>
            <Footern />
        </section>
    </>
  )
}

export default IcoDevelopment;