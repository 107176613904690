import React from 'react';
import { Fragment, useState } from "react";
import { Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faFilePen, faLaptopCode, faCircleDollarToSlot, faCoins, faScrewdriverWrench, faBullhorn } from '@fortawesome/free-solid-svg-icons';

const DevelopmentAccordionTwo = () => {

    const [open, setOpen] = useState(3);

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };


  return (
    <>
        <div className=' text-center w-full lg:w-[40%] px-2'>
            {/* <div className='w-3/4 px-2'> */}
            <Fragment>
                <Accordion open={open === 1}>
                    <AccordionHeader className='text-dark pl-4 font_primary font-light justify-start border-[1px] bg-[#56f85b73] rounded-xl my-2 shadow-[0px_0px_6px_#fff]' onClick={() => handleOpen(1)}>
                        <FontAwesomeIcon icon={ faCoins } className='text-5xl text-dark mx-2' />Semi NFT Developmen
                    </AccordionHeader>
                    <AccordionBody className='text-dark font_secondary text-left text-base p-2 rounded-lg font-semibold'>
                    Batch token transfers are made more usable by creating semi-fungible tokens because they support fungible and non-fungible (NFT) tokens.
                    </AccordionBody>
                </Accordion>
                <Accordion open={open === 2}>
                    <AccordionHeader className='text-dark pl-4 font_primary font-light justify-start border-[1px] bg-[#eb64ad73] rounded-xl my-2 shadow-[0px_0px_6px_#fff]' onClick={() => handleOpen(2)}>
                    <FontAwesomeIcon icon={ faCircleDollarToSlot } className='text-5xl text-dark mx-2' />
                    NFT Marketplace
                    </AccordionHeader>
                    <AccordionBody className='text-dark font_secondary text-left text-base p-2 rounded-lg font-semibold'>
                    An NFT art marketplace website allows artists to present their work under one central banner.
                    </AccordionBody>
                </Accordion>
                <Accordion open={open === 3}>
                    <AccordionHeader className='text-dark pl-4 font_primary font-light justify-start border-[1px] bg-[#77c1dd73] rounded-xl my-2 shadow-[0px_0px_6px_#fff]' onClick={() => handleOpen(3)}>
                    <FontAwesomeIcon icon={ faScrewdriverWrench  } className='text-5xl text-dark mx-2' />
                    ICO Development
                    </AccordionHeader>
                    <AccordionBody className='text-dark font_secondary text-left text-base p-2 rounded-lg font-semibold'>
                    Give your company a customer-centric design that UX and UI science support.
                    </AccordionBody>
                </Accordion>
                <Accordion open={open === 4}>
                    <AccordionHeader className='text-dark pl-4 font_primary font-light justify-start border-[1px] bg-[#fe806773] rounded-xl my-2 shadow-[0px_0px_6px_#fff]' onClick={() => handleOpen(4)}>
                    <FontAwesomeIcon icon={ faFilePen } className='text-5xl text-dark mx-2' />
                    Crypto Wallet Development
                    </AccordionHeader>
                    <AccordionBody className='text-dark font_secondary text-left text-base p-2 rounded-lg font-semibold'>
                    Development of Cryptocurrency Wallets: Increasing the Value of Your Company with Blockchain-Powered Wallets.
                    </AccordionBody>
                </Accordion>
                <Accordion open={open === 5}>
                    <AccordionHeader className='text-dark pl-4 font_primary font-light justify-start border-[1px] bg-[#77c1dd73] rounded-xl my-2 shadow-[0px_0px_6px_#fff]' onClick={() => handleOpen(5)}>
                    <FontAwesomeIcon icon={ faChartLine } className='text-5xl text-dark mx-2' />
                    Market Making
                    </AccordionHeader>
                    <AccordionBody className='text-dark font_secondary text-left text-base p-2 rounded-lg font-semibold'>
                    Analyse traffic to understand the strong marketing points to conduct marketing campaigns for the firm's project and execute it accordingly. 
                    </AccordionBody>
                </Accordion>
                <Accordion open={open === 6}>
                    <AccordionHeader className='text-dark pl-4 font_primary font-light justify-start border-[1px] bg-[#e3b50373] rounded-xl my-2 shadow-[0px_0px_6px_#fff]' onClick={() => handleOpen(6)}>
                    <FontAwesomeIcon icon={ faLaptopCode } className='text-5xl text-dark mx-2' />
                    Website development
                    </AccordionHeader>
                    <AccordionBody className='text-dark font_secondary text-left text-base p-2 rounded-lg font-semibold'>
                    We can help you power your business. Use the appropriate technologies to create a result-oriented website.
                    </AccordionBody>
                </Accordion>
                <Accordion open={open === 7}>
                    <AccordionHeader className='text-dark pl-4 font_primary font-light justify-start border-[1px] bg-[#ff000073] rounded-xl my-2 shadow-[0px_0px_6px_#fff]' onClick={() => handleOpen(7)}>
                    <FontAwesomeIcon icon={ faBullhorn } className='text-5xl text-dark mx-2' />
                    ICO Marketing
                    </AccordionHeader>
                    <AccordionBody className='text-dark font_secondary text-left text-base p-2 rounded-lg font-semibold'>
                    ICOs are now overtaking the venture capital method as the main source of funds for blockchain start-ups
                    </AccordionBody>
                </Accordion>
            </Fragment>
            {/* </div> */}
        </div>
    </>
  )
}

export default DevelopmentAccordionTwo;