import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import graphics_1 from '../img/earnestroi-graphic-designing-main.webp';
import graphics_2 from '../img/earnestroi-graphic-designing-best-you-ever-had.webp';
import Aos from 'aos';
import 'aos/dist/aos.css';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import Footern from './Footern';
import { Helmet } from 'react-helmet';
// import WhatsappChat from './WhatsappChat';

const GraphicDesign = () => {

  useEffect( () => {

    Aos.init({

      disable: function() {
        var maxWidth = 1023;
        return window.innerWidth < maxWidth;
      }

    });

    $(document).ready(function(){
        $(this).scrollTop(0);
    });

  } );

  useEffect( () => {

    Aos.init({ duration: 3000 });

  } )

  return (
    <>
      <Helmet>
        <title>Earnestroi Technology | Graphic Design | Digital Marketing Agency</title>
        <meta name="title" content="Earnestroi Technology | Graphic Design | Digital Marketing Agency" />
        <meta name="description" content="We are one of the most effective digital marketing agency which also provides you graphic designing services, we will give you the design according to you perferences." />
        <meta name="keywords" content='graphic design, Photo Editing, Product Design, Website Design, Branding, Logo Design, Digital Marketing, Advertising' />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
      </Helmet>
      <div className='  py-10'>
        <div className='container mx-auto'>
          <div className='flex flex-col lg:flex-row justify-center items-center'>
            <img src={ graphics_1 } className='' alt='Graphic Desiging 1' />
            <h1 className='text-second text-5xl lg:text-6xl mb-4 mt-4 lg:mt-0 capitalize font_primary' data-aos="fade-right">Graphic Designing</h1>
          </div>
        </div>
        <div className='container mx-auto'>
          <div className='flex justify-center py-4 lg:py-10'>
            <Link to='/'><h2 className='mx-2 text-second font_primary'><FontAwesomeIcon icon={ faHome } className='mx-2 text-second' />Digital Marketing Agency</h2></Link><h2 className='mx-2'>/</h2><h2 className='mx-2 text-second font_primary'>Graphic Designing</h2>
          </div>
        </div>
        <div className='container mx-auto py-4 lg:py-16'>
          <div className='flex flex-col lg:flex-row justify-center items-center px-4 lg:px-0'>
            <div className='container mx-auto my-12 lg:my-16 border-l-2 border-second w-full lg:w-1/2 py-6'>
              <p className='pl-8 font_secondary'>THE BEST YOU EVER HAD!</p>
              <br />
              <h2 className='text-base sm:text-3xl w-full lg:w-3/4 border-l-4 border-second pl-8 font_secondary' data-aos="fade-up">Did you realize that a designer somewhere starts weeping every time you extend a font?</h2>
              <br />
              <p className='pl-8 w-full lg:w-3/4 my-2 lg:my-4 font_secondary'>Although some claim has an excessive fascination with tiny creative details, we think this need for perfection is what makes our creative team stand out from the competition.</p>
              <p className='pl-8 w-full lg:w-3/4 my-2 lg:my-4 font_secondary'>Creating impactful visuals and graphic design are two of the most difficult tasks for marketing teams, according to 52% of organizations, yet research shows that articles, blogs, and websites with photos receive 94% more views than those without.</p>
              <p className='pl-8 w-full lg:w-3/4 my-2 lg:my-4 font_secondary'>By offering a variety of services that address all of your graphic design demands, we give a solution to your creative difficulties. Our graphic designers work hard to produce original, brand-specific designs.</p>
              <p className='pl-8 w-full lg:w-3/4 my-2 lg:my-4 font_secondary'>Understanding current brand guidelines and suggesting rebranding where necessary are both parts of our onboarding process.</p>
              <p className='pl-8 w-full lg:w-3/4 my-2 lg:my-4 font_secondary'>Let us examine your brand with new, imaginative eyes!</p>
            </div>
            <div className='w-full lg:w-1/2'>
              <img src={ graphics_2 } alt='Graphic Desiging 2' className='mx-auto lg:ml-auto lg:mr-0' />
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className='grid-cols-2 flex flex-col lg:flex-row justify-center items-center'>
          <div className='w-full lg:w-1/2 bg-bg_graphic bg-cover bg-center px-8'>
            <div className='container mx-auto my-12 lg:my-16 border-l-2 border-second w-full py-6 invisible'>
              <p className='pl-8 text-[white] hidden sm:block font_secondary'>DESIGN SERVICES</p>
              <br />
              <h2 className='text-base sm:text-3xl hidden sm:block w-full lg:w-3/4 border-l-4 border-second pl-8 text-[white] font_primary' data-aos="fade-up">Helping you get with Times, New Roman!</h2>
              <br />
              <p className='pl-8 w-full lg:w-3/4 hidden sm:block my-4 text-[white] font_secondary'>We specialize in brand identity design (BID), which includes all aspects of your company's visual representations,	 including both the overall brand's perceived image and individual visual components. This comprises design services including business cards, packaging, uniform, and brand color palette choices, as well as the execution of cohesive design styles.</p>
              <p className='pl-8 w-full lg:w-3/4 my-4 text-[white] font_secondary'>One of the most effective marketing tools accessible today is a well-designed and unified BID. The Design Management Institute conducted research that showed businesses that continuously invested in BID over a ten-year period experienced a 219% boost in stock value over those that didn't. Today, the BID is a tool that can be used for more than just designing attractive logos.</p>
            </div>
          </div>
          <div className='w-full lg:w-1/2 bg-main px-8'>
            <div className='container mx-auto my-12 lg:my-16 border-l-2 border-second w-full py-6'>
              <p className='pl-8 text-[white] font_secondary'>DESIGN SERVICES</p>
              <br />
              <h2 className='text-base sm:text-3xl w-full lg:w-3/4 border-l-4 border-second pl-8 text-[white] font_primary' data-aos="fade-up">Helping you get with Times, New Roman!</h2>
              <br />
              <p className='pl-8 w-full lg:w-3/4 my-4 text-[white] font_secondary'>We specialize in brand identity design (BID), which includes all aspects of your company's visual representations,	 including both the overall brand's perceived image and individual visual components. This comprises design services including business cards, packaging, uniform, and brand color palette choices, as well as the execution of cohesive design styles.</p>
              <p className='pl-8 w-full lg:w-3/4 my-4 text-[white] font_secondary'>One of the most effective marketing tools accessible today is a well-designed and unified BID. The Design Management Institute conducted research that showed businesses that continuously invested in BID over a ten-year period experienced a 219% boost in stock value over those that didn't. Today, the BID is a tool that can be used for more than just designing attractive logos.</p>
            </div>
          </div>
        </div>
      </section>
      <section className='bg-bg_graphic_4 bg-cover bg-center'>
        <div>
          <div className='container mx-auto py-12 lg:py-48'>
            <div className='grid sm:grid-cols-2 xl:grid-cols-4 gap-8 justify-center items-center w-full lg:w-3/4 mx-auto'>
              <div className='p-10 h-full rounded-md flex items-center justify-center bg-main'>
                <h2 className='text-2xl mb-2 py-12 text-[white] text-center font_secondary'>LOGO CREATION</h2>
              </div>
              <div className='p-10 h-full rounded-md flex items-center justify-center bg-main'>
                <h2 className='text-2xl mb-2 py-12 text-[white] text-center font_secondary'>BROCHURES</h2>
              </div>
              <div className='p-10 h-full rounded-md flex items-center justify-center bg-main'>
                <h2 className='text-2xl mb-2 py-12 text-[white] text-center font_secondary'>FLYERS</h2>
              </div>
              <div className='p-10 h-full rounded-md flex items-center justify-center bg-main'>
                <h2 className='text-2xl mb-2 py-12 text-[white] text-center font_secondary'>SOCIAL MEDIA CONTENT</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='bg-main py-4 lg:py-32'>
        <div className='container mx-auto'>
          <div className='flex flex-col lg:flex-row justify-between items-center'>
            <div className='w-full lg:w-[40%] py-4 lg:py-0 px-4 lg:px-0'>
              <h2 className='text-base sm:text-[1.4rem] w-full text-center text-[white] font_secondary'>Just because you can use Photoshop doesn't mean you should! If you want to do it correctly, ask our advice.</h2>
              <br /><br />
              <Link to='/contactsl' className='relative text-[white] hover:text-second p-2 bg-second hover:bg-[white] font_primary rounded-lg mx-auto block w-max border-2 border-second'>Talk To Us <FontAwesomeIcon icon={faArrowRight} className=' pl-4 animate-right_left'/></Link>
            </div>
            <div className='w-full lg:w-[40%] py-4 lg:py-0 px-4 lg:px-0'>
              <h2 className='text-base sm:text-[1.4rem] w-full text-center text-[white] font_secondary'>If you have to explain a brilliant design, it hasn't been done well. Check out some of our innovative concepts.</h2>
              <br /><br />
              <Link to='/blog' className='relative text-[white] hover:text-second p-2 bg-second hover:bg-[white] font_primary rounded-lg mx-auto block w-max border-2 border-second'>Case Studies <FontAwesomeIcon icon={faArrowRight} className=' pl-4 animate-right_left'/></Link>
            </div>
          </div>
        </div>
      </section>
      <Footern />
    </>
  )
}

export default GraphicDesign;