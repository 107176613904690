import React from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket, faCloudArrowUp, faGear, faScrewdriverWrench  } from '@fortawesome/free-solid-svg-icons';

const Timeline2compo = () => {
  return (
    <>
        <VerticalTimeline animate={ true } lineColor={ '#77c1dd' }>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                // date="2008 - 2010"
                iconStyle={{ background: 'rgb(0, 0, 0)' }}
                icon={<FontAwesomeIcon icon={ faArrowUpFromBracket } className='text-3xl font_primary text-third' />}
            >
                <h2 className='text-xl lg:text-3xl text-third font_primary my-4'>Contextualize</h2>
                <p className='text-[white] font_secondary text-base lg:text-lg'>We work hands-on with you to comprehend your needs and contextualize Web3 for your business. We do so while exploring the benefits and challenges and co-innovating around the use case.</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                // date="2006 - 2008"
                iconStyle={{ background: 'rgb(0, 0, 0)' }}
                icon={<FontAwesomeIcon icon={ faGear } className='text-3xl font_primary text-third' />}
            >
                <h2 className='text-xl lg:text-3xl text-third font_primary my-4'>Orchestrate</h2>
                <p className='text-[white] font_secondary text-base lg:text-lg'>We help you prepare for the market disruption as a result of the successful Web 3.0 launch. Our strategy and advisory services help you gauge organizational readiness, anticipate the business impact, and devise a go-to-market plan.</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                // date="April 2013"
                iconStyle={{ background: 'rgb(0, 0, 0)' }}
                icon={<FontAwesomeIcon icon={ faScrewdriverWrench } className='text-3xl font_primary text-third' />}
            >
                <h2 className='text-xl lg:text-3xl text-third font_primary my-4'>Build</h2>
                <p className='text-[white] font_secondary text-base lg:text-lg'>We design and architect a Web 3.0 platform leveraging our rich domain knowledge and breadth of expertise. We work across the organization to engage stakeholders in legal and compliance businesses to build solutions ready for deployment.</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                // date="November 2012"
                iconStyle={{ background: 'rgb(0, 0, 0)' }}
                icon={<FontAwesomeIcon icon={ faCloudArrowUp } className='text-3xl font_primary text-third' />}
            >
                <h2 className='text-xl lg:text-3xl text-third font_primary my-4'>Implement</h2>
                <p className='text-[white] font_secondary text-base lg:text-lg'>We implement a Web 3.0 solution and authorize updates and changes. We constantly deliver our Web3 development services and support even after product deployment to ensure that our solutions have a tangible impact on your businesses.</p>
            </VerticalTimelineElement>
            </VerticalTimeline>
    </>
  )
}

export default Timeline2compo;