import React from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCoins, faFile, faLaptopCode, faVolumeHigh, faVolumeLow, faWallet  } from '@fortawesome/free-solid-svg-icons';

const TimelineIco = () => {
  return (
    <>
        <VerticalTimeline animate={ true } lineColor={ '#77c1dd' }>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                // date="2008 - 2010"
                iconStyle={{ background: 'rgb(0, 0, 0)' }}
                icon={<FontAwesomeIcon icon={ faCircleCheck } className='text-3xl font_primary text-third' />}
            >
                <h2 className='text-xl lg:text-3xl text-third font_primary my-4'>Ideation and Validation</h2>
                <p className='text-xl lg:text-3xl font_secondary'>Our blockchain experts evaluate your proposal to determine whether it is desirable, feasible, and viable. </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                // date="2006 - 2008"
                iconStyle={{ background: 'rgb(0, 0, 0)' }}
                icon={<FontAwesomeIcon icon={ faFile } className='text-3xl font_primary text-third' />}
            >
                <h2 className='text-xl lg:text-3xl text-third font_primary my-4'>White Paper/ Light Paper Creation</h2>
                <p className='text-xl lg:text-3xl font_secondary'>Our technical content writers provide clear, pertinent, and full content to convey your vision through the paper.</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                // date="April 2013"
                iconStyle={{ background: 'rgb(0, 0, 0)' }}
                icon={<FontAwesomeIcon icon={ faLaptopCode } className='text-3xl font_primary text-third' />}
            >
                <h2 className='text-xl lg:text-3xl text-third font_primary my-4'>Landing Page Design</h2>
                <p className='text-xl lg:text-3xl font_secondary'>We create a compelling and educational landing page to expose your idea to the intended audience. While building the ICO website, our professionals pay close attention to UI/UX.</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                // date="November 2012"
                iconStyle={{ background: 'rgb(0, 0, 0)' }}
                icon={<FontAwesomeIcon icon={ faVolumeLow } className='text-3xl font_primary text-third' />}
            >
                <h2 className='text-xl lg:text-3xl text-third font_primary my-4'>Pre-ICO Marketing</h2>
                <p className='text-xl lg:text-3xl font_secondary'>We disseminate convincing brand messaging and content through digital marketing channels like Steemit, Telegram, Reddit, Twitter, LinkedIn, and others.</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                // date="November 2012"
                iconStyle={{ background: 'rgb(0, 0, 0)' }}
                icon={<FontAwesomeIcon icon={ faCoins } className='text-3xl font_primary text-third' />}
            >
                <h2 className='text-xl lg:text-3xl text-third font_primary my-4'>Token Development</h2>
                <p className='text-xl lg:text-3xl font_secondary'>Whether you select ERC20 token development or another standard, our blockchain experts will create a token using that standard.</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                // date="November 2012"
                iconStyle={{ background: 'rgb(0, 0, 0)' }}
                icon={<FontAwesomeIcon icon={ faWallet } className='text-3xl font_primary text-third' />}
            >
                <h2 className='text-xl lg:text-3xl text-third font_primary my-4'>Wallet Development</h2>
                <p className='text-xl lg:text-3xl font_secondary'>We offer a highly secure and adaptable wallet architecture to transmit, receive, and store coins and tokens (fortified with multi and support for many cryptocurrencies).</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                // date="November 2012"
                iconStyle={{ background: 'rgb(0, 0, 0)' }}
                icon={<FontAwesomeIcon icon={ faVolumeHigh } className='text-3xl font_primary text-third' />}
            >
                <h2 className='text-xl lg:text-3xl text-third font_primary my-4'>Post ICO Marketing</h2>
                <p className='text-xl lg:text-3xl font_secondary'>We use multi-channel marketing to help you stand out from the competition and capture the interest of possible investors. We cover community building, PR, SEO, and SMO.</p>
            </VerticalTimelineElement>
        </VerticalTimeline>
    </>
  )
}

export default TimelineIco;