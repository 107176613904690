import React, { useEffect } from 'react';
import seo_1 from '../img/earnestroi-machine-learning-main.png';
import img_2_2 from '../img/earnestroi-machine-learning-two.png';
import img_2_s from '../img/earnestroi-machine-learning-three.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Footern from './Footern';
import { Helmet } from 'react-helmet';
// import WhatsappChat from './WhatsappChat';

const MachineLeaning = () => {

    useEffect( () => {

        $(document).ready(function(){
          $(this).scrollTop(0);
        });
    
        $(document).ready(function(){
          
          $('.slick-prev').hide();
          $('.slick-next').hide();
    
        });
    
        Aos.init({
    
          disable: function() {
            var maxWidth = 1024;
            return window.innerWidth < maxWidth;
          }
    
        });
    
    } );
    
      useEffect( () => {
    
        Aos.init({ duration: 3000 });
    
    } );

    return (
    <>
        <Helmet>
            <title>Earnestroi Technology | Machine Learning | supervised learning</title>
            <meta name="title" content="Earnestroi Technology | Machine Learning | supervised learning" />
            <meta name="description" content="Machine learning is a method used to build algorithms and models in an automated way. Supervised learning is all about building a model that takes actions in response to certain inputs." />
            <meta name="keywords" content='machine learning, supervised learning, project management, online marketing company' />
            <meta name="robots" content="index,follow" />
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <meta name="language" content="English" />
        </Helmet>
        <section className='  py-10'>
            <div className='container mx-auto'>
                <div className='flex flex-col lg:flex-row justify-center items-center'>
                    <img src={ seo_1 } className='' alt='Machine learning ' />
                    <h1 className='text-third text-5xl lg:text-6xl mb-4 mt-4 lg:mt-0 capitalize text-center font_primary' data-aos="fade-right">Machine<br /> Learning</h1>
                </div>
            </div>
            <div className='container mx-auto'>
                <div className='flex justify-center py-4 lg:py-10'>
                    <Link to='/'><h2 className='mx-2 text-third font_primary'><FontAwesomeIcon icon={ faHome } className='mx-2 text-third' />Digital Marketing Agency</h2></Link><h2 className='mx-2'>/</h2><h2 className='mx-2 text-third font_primary'>Machine Learning</h2>
                </div>
            </div>
        </section>
        <section>
            <div className='container mx-auto py-10'>
            <div className='grid-cols-2 flex flex-col lg:flex-row justify-center items-center'>
                <div className='px-2 w-full lg:w-1/2'>
                <div className='border-b-2 border-third w-full mb-4 pb-[2px]'>
                    <p className='text-3xl text-third font_primary'><span className='border-b-4 border-third text-3xl text-third font_primary'>Learning</span> Technology</p>  
                </div>
                <p className='text-base sm:text-lg mb-4 font_secondary' data-aos="fade-left">Machine Learning and Blockchain technology are two relatively new and exciting fields of technology. While they are quite different from each other, they have the potential to be used together in many interesting ways.</p>
                <p className='text-base sm:text-lg mb-4 font_secondary' data-aos="fade-left">Machine learning is a type of artificial intelligence that involves training algorithms to learn from data. This allows the algorithms to make predictions or take actions based on the information they have learned.</p>
                <p className='text-base sm:text-lg mb-4 font_secondary' data-aos="fade-left">Blockchain, on the other hand, is a distributed database technology that allows multiple parties to securely record and verify transactions without the need for a central authority.</p>
                </div>
                <div className='px-2 w-full lg:w-1/2'>
                <img src={img_2_2} alt='Learning Technology' className='mx-auto block' />
                </div>
            </div>
            </div>
        </section>
        <section className='bg-main px-1 xl:p-0'>
            <div className='container mx-auto py-4 lg:py-20'>
                <div className='w-full lg:w-[60%] mx-auto'>
                <p className='text-[white] text-center text-base sm:text-lg mb-4 lg:mb-8 font_secondary' data-aos='fade-up'>One potential use of combining machine learning with blockchain technology is in the area of predictive analytics. For example, a machine learning algorithm could be trained on a large dataset of transactions stored on a blockchain. This algorithm could then be used to make predictions about future transactions, such as identifying fraudulent activity or predicting the likelihood of a transaction being approved.</p>
                <p className='text-[white] text-center text-base sm:text-lg mb-4 lg:mb-8 font_secondary' data-aos='fade-up'>Another potential use of combining these technologies is in the creation of decentralized applications (dApps). These are applications that run on a decentralized network, such as a blockchain, rather than on a single central server. By using machine learning algorithms, dApps could become more intelligent and capable of providing a wider range of services to users.</p>
                </div>
            </div>
        </section>
        <section>
            <div className='container mx-auto py-4 lg:py-16'>
            <div className='flex flex-col lg:flex-row justify-around items-center px-4 lg:px-0'>
                <div className='w-full lg:w-[40%] py-6\'>
                    <img src={ img_2_s } alt='ML with Blockchain' className='mx-auto block' />
                </div>
                <div className='px-2 w-full lg:w-[40%]'>
                    <div className='border-b-2 border-third w-full mb-4 pb-[2px]'>
                        <p className='text-3xl text-black text-start font_primary'><span className='border-b-0 lg:border-b-0 2xl:border-b-2 sm:border-b-2 text-third border-third text-black font_primary'>Know more</span> about ML with Blockchain</p>
                    </div>
                    <p className='text-base sm:text-lg mb-4 text-black text-start font_secondary' data-aos="fade-right">Absolutely!</p>
                    <p className='text-base sm:text-lg mb-4 text-black text-start font_secondary' data-aos="fade-right">Overall, the combination of machine learning and blockchain technology has the potential to lead to many exciting developments in the future. These technologies are still relatively new, so it will be interesting to see how they are used together in the coming years.</p>
                </div>
            </div>
            </div>
        </section>
        <section className='bg-main py-8 lg:py-24'>
            <div className='container mx-auto'>
            <div className='flex flex-col lg:flex-row justify-around items-center'>
                <div>
                <h2 className='text-base sm:text-[1.4rem] w-full text-center text-[white] font_secondary'>Just because you can use ML doesn't mean you should!</h2>
                <h2 className='text-base sm:text-[1.4rem] w-full text-center text-[white] font_secondary'>If you want to do it correctly, ask our advice.</h2>
                <br /><br />
                <Link to='/contactsl' className='relative text-[white] hover:text-third p-2 bg-third font_primary hover:bg-[white] rounded-lg mx-auto block w-max border-2 border-third'>Talk to Us <FontAwesomeIcon icon={faArrowRight} className=' pl-4 animate-right_left'/></Link>
                </div>
            </div>
            </div>
        </section>
        <section>
            <Footern />  
        </section>
    </>
  )
}

export default MachineLeaning;