import React from 'react'
import Panel from './Panel';

const LayoutOneDash = () => {
  return (
    <div className='w-full'> 
        <Panel />
    </div>
  )
}

export default LayoutOneDash;