import React, {useEffect, useState } from 'react';
import img_1 from '../img/earnestroi-digital-marketing-agency.webp';
import img_2 from '../img/earnestroi-digital-marketing-agency-aboutus.webp';
import img_3 from '../img/earnestroi-digital-marketing-agency-social-media.webp';
import img_4 from '../img/earnestroi-digital-marketing-agency-graphic-design.webp';
import img_5 from '../img/earnestroi-digital-marketing-agency-seo-ppc.webp';
import img_6 from '../img/earnestroi-digital-marketing-agency-careers.webp';
import img_3_a from '../img/earnestroi-artificial-intelligence.png';
import img_3_b from '../img/earnestroi-homepage-nft-development.png';
import img_3_c from '../img/earnestroi-homepage-smart-contract-development.png';
import { Link } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faBrain, faRobot } from '@fortawesome/free-solid-svg-icons';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import PressCaro from './PressCaro';
import TestiCaro from './TestiCaro';
import $ from 'jquery'; 
import { TypeAnimation } from 'react-type-animation';
import Services from './Services';
import Modal from './ModalCompo';
import { faReact } from '@fortawesome/free-brands-svg-icons';
import DevelopmentServices from './DevelopmentServices';
import Footern from './Footern';
import { Helmet } from 'react-helmet';
// import WhatsappChat from './WhatsappChat';

const DMA = () => {

  const [show, setShow] = useState(false);

    useEffect(()=>{
        setTimeout(()=>{
          setShow(true)
        }, 8000)
    }, []);

  useEffect( () => {

    $(document).ready(function(){
      $(this).scrollTop(0);
    });

    $(document).ready(function(){
      
      $('.slick-prev').hide();
      $('.slick-next').hide();

    });

    Aos.init({

      disable: function() {
        var maxWidth = 924;
        return window.innerWidth < maxWidth;
      }

    });

  } );

  useEffect( () => {

    Aos.init({ duration: 3000 });

  } );

  return (
    <>
      <Helmet>
        <title>
          Earnestroi Technology | Digital & Creative Marketing Agency | Project
          Development Company| Blockchain |
        </title>
        <meta
          name="title"
          content="Earnestroi Technology | Digital & Creative Marketing Agency | Project Development Company| Blockchain |"
        />
        <meta
          name="description"
          content="Earnestroi Technology is a team of Expertise with help the brand for project development and Marketing. We provide solutions for every business with technology easier to understand and use for everyone by offering multiple services."
        />
        <meta
          name="image"
          content="https://earnestroi.com/earnestroi-logo.png"
        />

        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Earnestroi Technology | Digital & Creative Marketing Agency | Project Development Company| Blockchain |"
        />
        <meta
          property="og:description"
          content="Earnestroi Technology is a team of Expertise with help the brand for project development and Marketing. We provide solutions for every business with technology easier to understand and use for everyone by offering multiple services."
        />
        <meta
          property="og:image"
          content="https://earnestroi.com/earnestroi-logo.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:title"
          content="Earnestroi Technology | Digital & Creative Marketing Agency | Project Development Company| Blockchain |"
        />
        <meta
          property="twitter:description"
          content="Earnestroi Technology is a team of Expertise with help the brand for project development and Marketing. We provide solutions for every business with technology easier to understand and use for everyone by offering multiple services."
        />
        <meta
          property="twitter:image"
          content="https://earnestroi.com/earnestroi-logo.png"
        />
      </Helmet>
      {/* <Modal
        show={show}
        onClose={() => {
          setShow(false);
        }}
      /> */}

      <section className=" lg:pt-4 xl:pt-0 bg-main px-1 sm:px-0 lg:px-1 xl:px-0">
        
        <div className="container mx-auto">
          <div className="flex flex-col-reverse lg:flex-row justify-around items-center xl:pt-20">
            <div className="flex flex-col w-full lg:w-1/2">
              <h2
                className="text-[white] text-2xl md:text-3xl lg:text-5xl mb-4 mt-4 lg:mt-0 font_primary capitalize"
                data-aos="fade-right"
              >
                Pioneering the Future of Cryptocurrency with Innovative
                Solutions
              </h2>
              <br />
              <p
                className="text-second text-2xl font_primary capitalize"
                data-aos="fade-left"
              >
                Advancements in technology, advancements in your business <br />
                Find One Stop Solution for
              </p>
              <TypeAnimation
                sequence={[
                  "Blockchain",
                  1000,
                  "Smart Contract Development",
                  2000,
                  "NFT",
                  3000,
                  "Machine Learning",
                  4000,
                  "Token Development",
                  5000,
                  "Artificial Intelligence",
                ]}
                wrapper="span"
                cursor={true}
                repeat={Infinity}
                style={{
                  color: "#77c1dd",
                  fontWeight: "900",
                  fontSize: "1.5rem",
                }}
              />
              <br />
              <div className="" data-aos="fade-left">
                <p className="text-[white] font_primary my-2  text-xl animate-blinker opacity-0">
                  AMPLIFY YOUR BRAND
                </p>
                <Link
                  to="/contacts-1"
                  className="text-base my-2 text-[white] border-2 bg-second px-2 py-1 rounded-lg hover:bg-main hover:shadow-[0_0_12px_#FE8067] hover:text-second m-0 hover:border-second font_primary"
                >
                  Lets Talk
                </Link>
              </div>
            </div>
            <div className=" w-full lg:w-1/2">
              <img src={img_1} alt="Earnestroi" />
            </div>
          </div>
        </div>
        <section>
          <div className="bg-[white] mt-4 lg:py-1">
            <div className="container mx-auto my-16 lg:my-16 px-2">
              <DevelopmentServices />
            </div>
          </div>
        </section>
        <div className="container mx-auto 2xl:py-2 mt-0 lg:my-0 lg:py-4 xl:py-0">
          <div className="grid-cols-2 lg:py-2 xl-py-0 justify-around items-center flex flex-col lg:flex-row">
            <div className="flex flex-col lg:w-3/4 xl:w-1/2 mx-8 border-l-2 border-second">
              <p className="text-[white] text-base pl-8 font_secondary">
                ABOUT US
              </p>
              <br />
              <p
                className="text-[white] text-3xl border-l-4 border-second pl-8 font_secondary"
                data-aos="fade-up"
              >
                YOU HAVE FOUND US TO BE A BREATH OF FRESH AIR!
              </p>
              <br />
              <p
                className="text-[white] text-base sm:text-lg pl-8 font_secondary"
                data-aos="fade-up"
              >
                In the dynamic world of cryptocurrency, businesses seek
                innovative strategies to stay ahead in this ever-evolving
                landscape. At Earnestroi, we are on a revolutionary mission to
                reshape the business landscape with cutting-edge high-tech
                solutions tailored specifically for the cryptocurrency industry.
                Our team comprises carefully curated experts, each possessing
                diverse skills, quirks, and creative ideas, enabling us to craft
                unique strategies for our clients in cryptocurrency. With a
                touch of humor to brighten the journey, we are dedicated to
                making the cryptocurrency world an innovative and thriving realm
                for all our esteemed partners. Together, we will transform the
                landscape of business through the fusion of innovative
                technology and digital marketing in the realm of cryptocurrency.
              </p>
            </div>
            <div className=" mx-8">
              <img src={img_2} alt="About Us" />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mx-auto px-2">
          <div className="grid-cols-2 flex flex-col-reverse lg:flex-row justify-between items-center">
            <div className="lg:w-[50%] lg:px-2 p-2 sm:p-0">
              <h2 className="text-3xl lg:text-5xl font_primary text-third uppercase">
                Artificial Intelligence Services
              </h2>
              <br />
              <br />
              <p
                className="text-base sm:text-lg font_secondary"
                data-aos="fade-left"
              >
                AI helps machines learn from experience. It instructs them to
                perform activities that humans would perform and adapt to new
                inputs. Deep learning and natural language processing are
                essential to self-driving automobiles and chess-playing
                computers. These technologies allow you to program computers to
                carry out particular activities. AI goes to exchange each
                industry. However, we need to acknowledge its limitations.
              </p>
              <br />
              <Link
                to="/artificialintelligence"
                className="relative p-2 my-4 md:my-0 border-2 border-[#000] rounded-lg z-40 cursor-pointer font_primary"
              >
                Read More{" "}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-second pl-4 animate-right_left"
                />
              </Link>
            </div>
            <div className="">
              <img src={img_3_a} alt="AI Service" className="my-8 lg:my-0" />
            </div>
          </div>
        </div>
        <div className="w-full mx-auto px-2">
          <div className="container mx-auto">
            <div className="grid-cols-2 flex flex-col lg:flex-row justify-between items-center">
              <div className="">
                <img
                  src={img_3_b}
                  alt="NFT Development Service"
                  className="my-8 lg:my-0 sm:w-1/2 lg:w-full mx-auto block"
                />
              </div>
              <div className="lg:w-[50%] pl-0">
                <h2 className="text-3xl lg:text-5xl font_primary text-third uppercase">
                  NFT Development
                </h2>
                <br />
                <p className="text-base sm:text-lg font_secondary">
                  The digital contract is built using non-fungible tokens
                  representing your exceptional tangible and intangible assets.
                  The potential for this NFT marketplace to develop innovators
                  for their signature products is enormous.
                </p>
                <br />
                <p
                  className="text-base sm:text-lg font_secondary"
                  data-aos="fade-right"
                >
                  The development of non-fungible tokens finds a unique
                  application. The two main categories that must be maintained
                  and developed jointly are art and games. For fantasy fans and
                  independent publishers, investing in such variety is
                  astounding.
                </p>
                <br />
                <Link
                  to="/nft"
                  className="relative p-2 border-2 border-[#000] rounded-lg z-10 font_primary"
                >
                  Read More{" "}
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className="text-second pl-4 animate-right_left"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto my-8 px-2">
          <div className="grid-cols-2 flex flex-col-reverse lg:flex-row justify-between items-center">
            <div className="lg:w-[50%]">
              <h2 className="text-3xl lg:text-5xl font_primary text-third uppercase">
                Smart Contract Development
              </h2>
              <br />
              <br />
              <p
                className="text-base sm:text-lg font_secondary"
                data-aos="fade-left"
              >
                A smart contract is referred to as a Digital Asset contract.
                Under specified circumstances, computer software known as a
                smart contract regulates the movement of digital currencies or
                assets between parties. Similar to a typical arrangement, a
                smart contract's function includes setting the agreement's
                penalties and regulations. Additionally, smart contracts offer
                the best option for storing data because they are immutable and
                secure.
              </p>
              <br />
              <Link
                to="/smartcontractdevelopment"
                className="cursor-pointer relative p-2 my-4 md:my-0 border-2 border-[#000] rounded-lg z-10 font_primary"
              >
                Read More{" "}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-second pl-4 animate-right_left"
                />
              </Link>
            </div>
            <div className="">
              <img src={img_3_c} alt="Smart Contract Development" />
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="bg-main py-10 px-2">
          <div className="container mx-auto">
            <div className="md:grid grid-cols-3 gap-4 md:gap-2 lg:gap-10 flex flex-col">
              <div className="px-2 py-4 lg:px-4 lg:py-8 rounded-2xl hover:bg-[#77c1dd17] bg-[#fe806717] hover:cursor-pointer">
                <FontAwesomeIcon
                  icon={faReact}
                  className="mx-auto block text-6xl text-second my-6"
                />
                <div>
                  <h2 className="text-xl font_primary text-[white] text-center">
                    Data Science
                  </h2>
                  <p className="font_secondary text-[white] text-center">
                    <b>Data Science</b> is multi desciplinary field that uses
                    scientific methods, processes, algorithms and systems to
                    extract knowledgee from structured and unstructured data.
                  </p>
                </div>
                <Link
                  to="/blockchain"
                  className="font_primary hover:bg-[#fe806717] border-2 text-second border-second rounded-lg px-4 py-2 mx-auto block w-max mt-4 mb-2"
                >
                  Read More
                </Link>
              </div>
              <div className="px-2 py-4 lg:px-4 lg:py-8 rounded-2xl hover:bg-[#77c1dd17] bg-[#fe806717] hover:cursor-pointer">
                <FontAwesomeIcon
                  icon={faRobot}
                  className="mx-auto block text-6xl text-second my-6"
                />
                <div>
                  <h2 className="text-xl font_primary text-[white] text-center">
                    Machine Learning
                  </h2>
                  <p className="font_secondary text-[white] text-center">
                    What if machine did your work, <b>Machine Learning</b>{" "}
                    focuses on the development of computer programs that can
                    access data and use it learn from themselves.
                  </p>
                </div>
                <Link
                  to="/machinelearning"
                  className="font_primary hover:bg-[#fe806717] border-2 text-second border-second rounded-lg px-4 py-2 mx-auto block w-max mt-4 mb-2"
                >
                  Read More
                </Link>
              </div>
              <div className="px-2 py-4 lg:px-4 lg:py-8 rounded-2xl hover:bg-[#77c1dd17] bg-[#fe806717] hover:cursor-pointer">
                <FontAwesomeIcon
                  icon={faBrain}
                  className="mx-auto block text-6xl text-second my-6"
                />
                <div>
                  <h2 className="text-xl font_primary text-[white] text-center">
                    Artificial Intelligence
                  </h2>
                  <p className="font_secondary text-[white] text-center">
                    <b>AI</b> is branch of computer science concerned with
                    building smart machines capable of performing tasks that
                    typically require <b>Human Intelligence</b>
                  </p>
                </div>
                <Link
                  to="/artificialintelligence"
                  className="font_primary hover:bg-[#fe806717] border-2 text-second border-second rounded-lg px-4 py-2 mx-auto block w-max mt-4 mb-2"
                >
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="px-1 xl:px-0">
        <div className="container mx-auto my-12 lg:my-16">
          <Services />
        </div>
        <div className="container mx-auto">
          <div className="grid-cols-2 flex flex-col-reverse lg:flex-row justify-between items-center">
            <div className="lg:w-[40%] lg:px-2 p-2 sm:p-0">
              <h2 className="text-3xl lg:text-5xl font_primary text-second">
                SOCIAL MEDIA MARKETING
              </h2>
              <br />
              <br />
              <p
                className="text-base sm:text-lg font_secondary"
                data-aos="fade-left"
              >
                We have all seen a successful media campaign in all it's
                splendour but only few people are aware of how it is produced.
                It has quickly become the unicorn of the marketing world. At
                Earnestroi, we think that social media management entails not
                only producing original content but also paying attention to and
                interacting with your target market and functional approach data
                to support the organic growth of your social media presence. If
                you are a business seeking to start your transformation.
              </p>
              <br />
              <Link
                to="/smm"
                className="relative p-2 my-4 md:my-0 border-2 border-[#000] rounded-lg z-40 cursor-pointer font_primary"
              >
                Read More{" "}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-second pl-4 animate-right_left"
                />
              </Link>
            </div>
            <div className="">
              <img
                src={img_3}
                alt="Social media marketing "
                className="my-8 lg:my-0"
              />
            </div>
          </div>
        </div>
        <div className="w-full mx-auto">
          <div className="container mx-auto">
            <div className="grid-cols-2 flex flex-col lg:flex-row justify-between items-center">
              <div className="">
                <img
                  src={img_5}
                  alt="PPC Development "
                  className="my-8 lg:my-0 sm:w-1/2 lg:w-full mx-auto block"
                />
              </div>
              <div className="lg:w-[50%] pl-0">
                <h2 className="text-3xl lg:text-5xl font_primary text-second">
                  SEO AND PPC MANAGEMENT
                </h2>
                <br />
                <p className="text-base sm:text-lg font_secondary">
                  <b>
                    Do you recall a time before you could simply "google it"? We
                    also don't.
                  </b>
                </p>
                <br />
                <p
                  className="text-base sm:text-lg font_secondary"
                  data-aos="fade-right"
                >
                  Despite the fact that 90% of buyers often click on one of the
                  top three search results, SEO is one of the most underused
                  marketing strategies. SEO marketing is focused on finding and
                  using keywords that improve your online presence, regardless
                  of the size of your company or the industry you are in. SEO
                  optimization has emerged as one of the major driving forces of
                  digital marketing in a time when everyone uses search engines
                  to get answers. To find out more about the Mumbai SEO services
                  we provide.
                </p>
                <br />
                <Link
                  to="seo"
                  className="relative p-2 border-2 border-[#000] rounded-lg z-10 font_primary"
                >
                  Read More{" "}
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className="text-second pl-4 animate-right_left"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto my-8">
          <div className="grid-cols-2 flex flex-col-reverse lg:flex-row justify-between items-center">
            <div className="lg:w-[50%]">
              <h2 className="text-3xl lg:text-5xl font_primary text-second">
                GRAPHIC DESIGNING
              </h2>
              <br />
              <br />
              <p
                className="text-base sm:text-lg font_secondary"
                data-aos="fade-left"
              >
                What does it take to attract your attention after hours of
                mindless scrolling? Our graphic design team, which primarily
                comprises goldfish, is aware of the value of producing original,
                imaginative, and engaging content that is meant to catch your
                interest. Digital marketing and creative graphic design have
                become interchangeable terms in recent years. Similar to how
                excellent graphics are necessary for any successful social media
                campaign, a decent beat is essential to producing a song that
                will top the charts. We can meet all of your needs, whether
                you're searching for a graphic designer or animated content, or
                both.
              </p>
              <br />
              <Link
                to="graphicdesign"
                className="cursor-pointer relative p-2 my-4 md:my-0 border-2 border-[#000] rounded-lg z-10 font_primary"
              >
                Read More{" "}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-second pl-4 animate-right_left"
                />
              </Link>
            </div>
            <div className="">
              <img src={img_4} alt="Graphic Desiging " />
            </div>
          </div>
        </div>
        <div className="container mx-auto my-12 lg:my-16 border-l-2 border-second">
          <p className="pl-8 font_secondary">WHY CHOOSE EARNESTROI</p>
          <br />
          <h2
            className="text-base sm:text-3xl w-3/4 border-l-4 border-second pl-8 font_secondary"
            data-aos="fade-up"
          >
            AS YOUR DIGITAL MARKETING AGENCY
          </h2>
        </div>
      </section>
      <section className="py-10 bg-bg_img bg-no-repeat px-1 xl:px-0">
        <div className="container mx-auto">
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-10 justify-center items-center">
            <div className="p-4 h-full bg-[#00000012] bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 ">
              <h2 className="text-lg mb-2 font_primary">
                <b>Genuine</b>
              </h2>
              <p className="text-base font_secondary">
                Our clients frequently express their satisfaction with us by
                saying that we are genuine or authentic, which makes us glad .
                We attempt to treat our customers fairly and speak our thoughts.
              </p>
            </div>
            <div className="p-4 h-full bg-[#00000012] bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 ">
              <h2 className="text-lg mb-2 font_primary">
                <b>Proactive</b>
              </h2>
              <p className="text-base font_secondary">
                It's not simple to run a digital marketing agency. The dynamics
                of our activities are influenced by numerous worldwide factors.
                We commit to be proactive, plan ahead, and act to prevent any
                impact on our clients. We create backup plans to make sure there
                are solutions for all potential problems so the process runs
                smoothly.
              </p>
            </div>
            <div className="p-4 h-full bg-[#00000012] bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 ">
              <h2 className="text-lg mb-2 font_primary">
                <b>Flexible</b>
              </h2>
              <p className="text-base font_secondary">
                You'll typically be given a list of services or terms by digital
                marketing businesses and worldwide, and they'll want you to
                adhere by them. Not us. To make sure that our clients get the
                most out of dealing with a digital media firm that genuinely
                cares, we conduct ongoing conversations with them and make
                adjustments to our strategies and services.
              </p>
            </div>
            <div className="p-4 h-full bg-[#00000012] bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 ">
              <h2 className="text-lg mb-2 font_primary">
                <b>Reliability</b>
              </h2>
              <p className="text-base font_secondary">
                We are a digital marketing company you can trust in a place
                where carefree islanders rule. We never make excuses and we
                always keep our word. Your confidence in us means so much to us,
                and we work hard to be the only digital marketing company you
                will ever demand.
              </p>
            </div>
            <div className="p-4 h-full bg-[#00000012] bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 ">
              <h2 className="text-lg mb-2 font_primary">
                <b>Equality</b>
              </h2>
              <p className="text-base font_secondary">
                We differ from the majority of digital marketing firms. We treat
                all of our clients equally, regardless of whether they are
                multinationals or Mumbai-based startups. We enjoy supporting the
                outsider and cheering them on to victory. Every client receives
                the same level of care and attention from us. Count on us to
                offer your brand the attention it needs to thrive in the online
                space.
              </p>
            </div>
            <div className="p-4 h-full bg-[#00000012] bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-80 ">
              <h2 className="text-lg mb-2 font_primary">
                <b>Selective</b>
              </h2>
              <p className="text-base font_secondary">
                As a top digital marketing agency, we only take on clients who
                we believe we can benefit. Regardless of how big some of our
                prospects' budgets are, we usually choose our clients carefully.
                When discussing the client-agency match, if we suspect we may
                not be the best partner, we will be fully transparent and advise
                you accordingly.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 lg:py-28 bg-bg_laptop bg-cover bg-no-repeat">
        <div className="grid-cols-2 flex justify-around items-center">
          <div className=""></div>
          <div
            className="lg:w-[40%] sm:pl-10 pl-4 py-10 sm:pr-10 pr-0 bg-main rounded-3xl mx-2 lg:mx-0"
            data-aos="zoom-in-up"
          >
            <div className="border-l-2 border-second">
              <p className="text-base sm:text-lg text-[white] pl-8 font_secondary">
                CASE STUDIES
              </p>
              <br />
              <h2 className="text-3xl lg:text-5xl text-second border-l-[3px] border-second pl-8 font_primary">
                A PICTURE SPEAKS A THOUSAND WORDS
              </h2>
              <br />
              <p className="text-base sm:text-lg text-[white] pl-8 pr-2 lg:pr-0 font_secondary">
                Our digital marketing strategies have been incredibly inventive
                and imaginative, but in the TikTok era of 10-second attention
                spans, let us show you what we have accomplished instead.
              </p>
            </div>
            <br />
          </div>
        </div>
      </section>
      <section className="bg-main ">
        <div className="lg:px-8 mx-auto py-8 lg:py-28">
          <div className="justify-around items-center flex flex-col lg:flex-row">
            <div className="flex flex-col lg:w-[40%] mx-8 border-l-2 border-second">
              <p className="text-[white] text-base pl-8 font_secondary">
                TESTIMONIALS
              </p>
              <br />
              <p
                className="text-[white] text-3xl border-l-4 border-second pl-8 font_primary"
                data-aos="fade-up"
              >
                OUR PARTNERS
              </p>
              <br />
              <p
                className="text-[white] text-base sm:text-lg pl-8 font_secondary"
                data-aos="fade-up"
              >
                Our clients are our greatest asset, and we take much pride and
                care in transforming their digital outlook. From the moment we
                take you on board, throughout your digital marketing journey, we
                strive to provide a uniquely curated experience. At Earnestroi,
                we believe that digital marketing is about creating a connection
                between our clients and their target audience, and we are that
                intrusive matchmaking lady in between the two.
              </p>
            </div>
            <div className="mx-8 w-full p-4 lg:p-0 lg:w-[30%] my-4 lg:my-0">
              <TestiCaro />
            </div>
          </div>
        </div>
      </section>
      <section className="py-12">
        <PressCaro />
      </section>
      <section className="bg-main pt-12 lg:pt-0">
        <div className="grid-cols-2 flex flex-col lg:flex-row justify-between items-center">
          <div className="flex flex-col lg:w-[40%] mx-8 border-l-2 border-second py-0 lg:py-2 xl:py-0">
            <p className="text-[white] text-base sm:text pl-8 font_secondary">
              CAREERS
            </p>
            <br />
            <p
              className="text-[white] text-3xl border-l-4 border-second pl-8 font_primary uppercase"
              data-aos="fade-up"
            >
              WE ARE CONSTANTLY SEARCHING FOR CREATIVE TALENT TO JOIN OUR TEAM.
            </p>
            <br />
            <p className="text-[white] text-base sm:text pl-8 font_secondary">
              View all open openings and send your resume.
            </p>
            <br />
            <Link
              to="wearehiring"
              className="relative p-2 border-2 ml-8 text-second border-second w-max rounded-lg font_primary"
            >
              Openings{" "}
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-second pl-4 animate-right_left"
              />
            </Link>
          </div>
          <div className=" mx-8">
            <img src={img_6} alt="Careers" className="" data-aos="fade-up" />
          </div>
        </div>
      </section>
      <section>
        <Footern />
      </section>
    </>
  );
}

export default DMA;