import axios from "axios"; 
import React, { useState, useEffect } from "react";
import Footern from "./Footern";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const BlogLayoutOne = () => {
  const [response, setResponse] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [position, setPosition] = useState("");
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
  });
  const [comment, setComment] = useState({
    com: "",
    name: "",
    email: "",
  });

  const location = useLocation();
  const FormID = location.state;

  console.log("location", location.state);
  console.log(response);
  console.log(
    "response.filter",
    response.filter((item) => item.FormID === FormID.FormID)
  );

  useEffect(() => {
    setTimeout(() => {
      // setShow(true)
    }, 3000);
  }, []);

  const fetchfunction = () => {
    axios({
      method: "POST",
      header: "accept: application/json",
      url: process.env.REACT_APP_API_PATH_USER +`view_blog`,
    })
      .then((res) => {
        console.log(res.data.Success);
        setResponse(
          res.data.Success.filter((item) => item.FormID === FormID.FormID)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchfunction();
  }, []);

  const timezone = new Date(response.updated_at).toLocaleString();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      name: name,
      email: email,
      phone: mobile,
      purpose: position,
    };
    // formData.append("resume_url", resume_url);
    console.log(formData, "hjghg");
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_PATH_USER + "callback",
        formData
      );

      var res = response?.data;
      console.log(response, "hfdghdg");
      if (res?.Success) {
        toast.success(res?.Success);
        console.log(res?.Success, "hfdghdg");
      } else {
        console.log(res?.Error);
        toast.error(res?.Error);
      }
    } catch (error) {
      console.error("Error:", error);
      console.log("Response Data:", error.response.data.Error);
      toast.error(error.response.data.Error);
    }
  };

  const submitSubcription = async (e) => {
    e.preventDefault();
    const { name, email } = inputs;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);

    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_USER +"email_subscription",
      data: formData,
    })
      .then(async function (response) {
        const res = await response?.data;
        if (res?.Success) {
          toast.success(res.Success);
        } else {
          toast.error("Message already sent!");
          console.log(res.Error);
        }
      })
      .catch((err) => {
        toast.error("Message already sent!");
      });
  };

  const submitComment = async (e) => {
    e.preventDefault();
    const jwt = localStorage.getItem("jwt");
    const {com, name, email } = comment;
    const formData = new FormData();
    formData.append("comment", com);
    formData.append("name", name);
    formData.append("email", email);
    //  formData.append('id', "ERN86544")
    formData.append("id", FormID);

    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_USER +"add_comment",
      data: formData,
    })
      .then(async function (response) {
        const res = await response?.data;
        if (res.Success) {
          toast.success(res.Success);
        } else {
          toast.error("Message already sent!");
          console.log(res.Error);
        }
      })
      .catch((err) => {
        toast.error("Message already sent!");
      });
  };
  console.log(FormID, "hdgfhdfg");

  return (
    <>
      {/* <ModalBlog show={ show } onClose={ () => { setShow( false ) } } /> */}
      <section className="">
        <div className="container mx-auto">
          {response.map((item) => {
            return (
              <div className="flex flex-col lg:flex-row">
                <div className="w-full lg:w-[70%] px-2 border-r-[1px] border-second">
                  <div className="px-4 py-8">
                    <h2 className="font_primary capitalize text-third text-xl lg:text-3xl py-4">
                      {item.Heading}
                    </h2>
                    <p>
                      by <b className="text-third">Earnestroi</b> |{" "}
                      {item.Created_On}
                    </p>
                    <p className="font_secondary py-2">{item.Subheading}</p>
                    <img
                      // src={`https://api.earnestroi.com/banner/${response.banner_img}`}
                      src={`${item.Banner}`}
                      alt="blogimgearnestroi"
                      className="mx-auto block"
                    />
                  </div>
                  <div
                    className="p-4 font_secondary"
                    dangerouslySetInnerHTML={{ __html: item.Description }}
                  ></div>
                  <div className="py-6 px-4">
                    <p className="font_secondary">
                      <b className="font_primary text-lg text-third">
                        Comments:{" "}
                      </b>{" "}
                      {item.Comments?.map((commentItem) => {
                        if (commentItem.APPROVED === "TURE")
                          return (
                            <div className=" py-2">
                              {
                                <div>
                                  {" "}
                                  <span className=" font-semibold">
                                    {commentItem.Name}
                                  </span>{" "}
                                  : <span> {commentItem.Comment}</span>
                                </div>
                              }
                            </div>
                          );
                      })}
                    </p>
                    <p className="font_secondary">
                      <b className="font_primary text-lg">Keywords: </b>{" "}
                      {item.Tag}
                    </p>
                    <p className="font_primary text-xl text-second">
                      Earnestroi
                    </p>
                    <p className="font_secondary text-lg">
                      info@earnestroi.com
                    </p>
                  </div>
                  <div className="px-4 py-4">
                    <h2 className="font_primary capitalize text-second text-xl lg:text-3xl py-1">
                      OH HEY READER!
                    </h2>
                    <p className="text-base sm:text-lg font_secondary py-2">
                      We write about digital marketing, advertising, marketing
                      psychology & more. Sign up to get our exclusive in-depth
                      articles based on our learnings right in your inbox!
                    </p>
                    <form className="w-full " onSubmit={submitSubcription}>
                      {/* <p className="text-base sm:text-lg font_secondary py-1">
                    Email
                  </p> */}
                      <div className="flex flex-col md:flex-row w-full gap-4">
                        <input
                          type="text"
                          placeholder="Name"
                          value={inputs.name}
                          // onChange={handleChange}
                          onChange={(e) =>
                            setInputs({ ...inputs, name: e.target.value })
                          }
                          className="border-2 border-third py-1 px-2 rounded-lg w-full font_secondary"
                        />
                        <input
                          type="email"
                          placeholder="Email"
                          value={inputs.email}
                          onChange={(e) =>
                            setInputs({ ...inputs, email: e.target.value })
                          }
                          className="border-2 border-third py-1 px-2 rounded-lg w-full font_secondary"
                        />
                      </div>
                      <input
                        type="submit"
                        className="text-third border-2 border-third rounded-lg my-2 px-8 py-1 hover:cursor-pointer font_primary"
                      />
                    </form>
                  </div>
                  <div className="px-4 py-4">
                    <h2 className="font_primary capitalize text-second text-xl lg:text-3xl py-1">
                      Submit a Comment
                    </h2>
                    <p className="text-base sm:text-lg font_secondary py-2">
                      Your email address will not be published. Required fields
                      are marked *
                    </p>
                    <form className="w-full" onSubmit={submitComment}>
                      <textarea
                        placeholder="Comment"
                        value={comment.com}
                        onChange={(e) =>
                          setComment({ ...comment, com: e.target.value })
                        }
                        className="w-full border-2 border-third py-1 px-2 rounded-lg font_secondary"
                      />
                      <div className="flex my-1">
                        <input
                          type="text"
                          placeholder="Name*"
                          value={comment.name}
                          onChange={(e) =>
                            setComment({ ...comment, name: e.target.value })
                          }
                          className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary"
                        />
                        <input
                          type="email"
                          placeholder="Email*"
                          value={comment.email}
                          onChange={(e) =>
                            setComment({ ...comment, email: e.target.value })
                          }
                          className="border-2 border-third py-1 px-2 rounded-lg w-full ml-2 font_secondary"
                        />
                      </div>
                      <button
                        type="submit"
                        className="text-third border-2 border-third rounded-lg my-2 px-8 py-1 hover:cursor-pointer font_primary"
                      >
                        Submit Comment
                      </button>
                    </form>
                  </div>
                </div>
                <div className="w-full lg:w-[30%] px-2">
                  <div className="px-4 py-8">
                    <h2 className="font_primary capitalize text-third text-xl lg:text-3xl py-4">
                      Schedule A Callback
                    </h2>
                    <form onSubmit={handleSubmit}>
                      <select
                        value={position}
                        onChange={(e) => setPosition(e.target.value)}
                        className="border-2 border-third py-1 px-2 rounded-lg font_secondary w-full"
                      >
                        <option value="purpose">Purpose</option>
                        <option value="marketingsolutions">
                          Marketing Solutions
                        </option>
                        <option value="technologysolutions">
                          Technology Solutions
                        </option>
                        <option value="partnerships">
                          Partnerships/tieups
                        </option>
                      </select>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Name"
                        className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                      />
                      <input
                        type="number"
                        placeholder="Phone"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                      />
                      <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                      />
                      <input
                        type="submit"
                        className="text-third border-2 border-third rounded-lg px-8 py-1 hover:cursor-pointer font_primary my-1"
                      />
                    </form>
                  </div>
                  <div className="px-4 py-8">
                    <h2 className="font_primary capitalize text-third text-xl lg:text-3xl py-4">
                      Recent Posts
                    </h2>
                    <div className="py-1">
                      <a
                        href="/"
                        className="hover:text-second hover:cursor-pointer text-base sm:text-lg font_secondary py-2 "
                      >
                        Best Digital Marketing Strategies For Social Welfare
                        Industry
                      </a>
                    </div>
                    <div className="py-1">
                      <a
                        href="/"
                        className="hover:text-second hover:cursor-pointer text-base sm:text-lg font_secondary py-2 "
                      >
                        Digital Marketing Case Studies For Social Welfare
                        Industry
                      </a>
                    </div>
                    <div className="py-1">
                      <a
                        href="/"
                        className="hover:text-second hover:cursor-pointer text-base sm:text-lg font_secondary py-2 "
                      >
                        Best Digital Marketing Strategies For Shipping &
                        Logistics Industry
                      </a>
                    </div>
                    <div className="py-1">
                      <a
                        href="/"
                        className="hover:text-second hover:cursor-pointer text-base sm:text-lg font_secondary py-2 "
                      >
                        Digital Marketing Case Studies For Shipping & Logistics
                        Industry
                      </a>
                    </div>
                    <div className="py-1">
                      <a
                        href="/"
                        className="hover:text-second hover:cursor-pointer text-base sm:text-lg font_secondary py-2 "
                      >
                        Best Digital Marketing Strategies For Health & Fitness
                        Industry
                      </a>
                    </div>
                  </div>
                  <div className="px-4 py-4">
                    <h2 className="font_primary capitalize text-second text-xl lg:text-3xl py-1">
                      OH HEY READER!
                    </h2>
                    <p className="text-base sm:text-lg font_secondary py-2 text-justify">
                      We write about digital marketing, advertising, marketing
                      psychology & more. Sign up to get our exclusive in-depth
                      articles based on our learnings right in your inbox!
                    </p>
                    <form className="w-full lg:w-3/4" onSubmit={submitSubcription}>
                      <p className="text-base sm:text-lg font_secondary py-1">
                        Name
                      </p>
                      <input
                        type="name"
                        className="border-2 border-third py-1 px-2 rounded-lg w-full font_secondary"
                      />
                      <p className="text-base sm:text-lg font_secondary py-1">
                        Email
                      </p>
                      <input
                        type="email"
                        className="border-2 border-third py-1 px-2 rounded-lg w-full font_secondary"
                      />
                      <input
                        type="submit"
                        className="text-third border-2 border-third rounded-lg my-2 px-8 py-1 hover:cursor-pointer font_primary"
                      />
                    </form>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <Footern />
      {/* <WhatsappChat /> */}
    </>
  );
};

export default BlogLayoutOne;
